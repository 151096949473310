import React from 'react';

const ZOOM_INCREMENT = 0.2;

interface usePedigreeResponsiveActionsProps {
  stageRef: any;
  children?: React.ReactNode;

  layoutSize?: {
    width: number;
    height: number;
  };
  nodeSize: {
    width: number;
    height: number;
  };
}

const usePedigreeResponsiveActions = ({
  nodeSize,
  stageRef,
}: usePedigreeResponsiveActionsProps) => {
  const zoomStage = React.useCallback(
    (isZoomUp: boolean) => {
      if (stageRef.current !== null) {
        const stage = stageRef.current;
        const oldScale = stage.scaleX();
        const newScale = isZoomUp
          ? oldScale * (1 + ZOOM_INCREMENT)
          : oldScale * (1 - ZOOM_INCREMENT);

        const stageWidth = stage.width();
        const stageHeight = stage.height();

        const focalPointX = stageWidth / 2;
        const focalPointY = stageHeight / 2;

        const focalPointToStage = {
          x: (focalPointX - stage.x()) / oldScale,
          y: (focalPointY - stage.y()) / oldScale,
        };

        const newPosition = {
          x: focalPointX - focalPointToStage.x * newScale,
          y: focalPointY - focalPointToStage.y * newScale,
        };

        stage.scale({ x: newScale, y: newScale });
        stage.position(newPosition);
        stage.batchDraw();
      }
    },
    [stageRef],
  );

  const zoomToFill = React.useCallback(() => {
    const stage = stageRef.current;
    if (stage.getChildren().length === 0) return;

    const stageWidth = stage.width();
    const stageHeight = stage.height();
    const oldScale = stage.scaleX();

    let { width: layerWidth, height: layerHeight } = stage
      .getChildren()[0]
      .getClientRect();

    layerWidth = (layerWidth + nodeSize.width * oldScale) / oldScale;
    layerHeight = (layerHeight + nodeSize.height * oldScale) / oldScale;

    const widthScale = stageWidth / layerWidth;
    const heightScale = stageHeight / layerHeight;

    const scale = Math.min(widthScale, heightScale);

    stage.scale({ x: scale, y: scale });
    stage.position({ x: 0, y: 0 });

    const {
      x,
      y,
      height: newLayerHeight,
      width: newLayerWidth,
    } = stage.getChildren()[0].getClientRect();

    const heightOffset = (newLayerHeight - stageHeight) / 2;
    const widthOffset = (newLayerWidth - stageWidth) / 2;

    const newX = -x - widthOffset;
    const newY = -y - heightOffset;

    stage.position({ x: newX, y: newY });

    stage.batchDraw();
  }, [stageRef, nodeSize]);

  const handleZoomIn = () => {
    zoomStage(true);
  };

  const handleZoomOut = () => {
    zoomStage(false);
  };

  const handleZoomReset = () => {
    zoomToFill();
  };

  return {
    handleZoomIn,
    handleZoomOut,
    handleZoomReset,
  };
};

export default usePedigreeResponsiveActions;
