import { Alert } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import { useNotificationContext } from 'common/contexts/NotificationProvider';
import { ToastMessage } from 'components/common/ToastMessage';

export const SnackbarNotification = () => {
  const { notification, setNotification } = useNotificationContext();
  const { message, type } = notification || {};

  if (!message) {
    return null;
  }

  if (type === 'toast') {
    return (
      <Snackbar
        open={!!message}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={() => setNotification({ message: '' })}
        message={<ToastMessage message={message || ''} />}
        sx={{
          '& .MuiSnackbarContent-root': {
            minWidth: 'fit-content',
          },
        }}
      ></Snackbar>
    );
  }

  return (
    <Snackbar
      open={!!message}
      autoHideDuration={5000}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      onClose={() => setNotification({ message: '' })}
      sx={{ maxWidth: 600 }}
    >
      <Alert severity={type} onClose={() => setNotification({ message: '' })}>
        {message}
      </Alert>
    </Snackbar>
  );
};
