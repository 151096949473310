import { Button } from '@mui/material';
import { WidgetProps } from '@rjsf/utils';
import Dialog from 'components/common/Dialog';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AutocompleteWidget } from '../../widgets/AutocompleteWidget';
import { GeneticTestItemFormContext } from './GeneticTestItemForm';

type TestPanelOption = {
  label: string;
  value: string;
  genesInTestPanel?: string[];
};

export const GeneticTestPanelWidget = (props: WidgetProps) => {
  const { formData, handleOnChange } = useContext(GeneticTestItemFormContext);

  const { t } = useTranslation(['geneticTestForm']);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [previousValue, setPreviousValue] = useState<string | null>(null);
  const [genesInTestPanel, setGenesInTestPanel] = useState<string[]>([]);

  const options = useMemo(() => {
    const enumOptions = props.uiSchema?.['ui:enumOptions'] || [];

    return enumOptions.sort((a: TestPanelOption, b: TestPanelOption) => {
      if (a.value === 'other') return 1;
      if (b.value === 'other') return -1;
      return a.label.localeCompare(b.label);
    });
  }, [props.uiSchema]);

  const getGenesInTestPanel = useCallback(
    (value: string) => {
      const selectedOption = options.find(
        (option: TestPanelOption) => option.value === value,
      );
      return selectedOption?.genesInTestPanel || [];
    },
    [options],
  );

  const handleUpdateFormData = useCallback(
    (genesInTestPanel: string[], testPanel: string) => {
      if (!handleOnChange) return;
      handleOnChange({
        ...formData,
        testPanel: testPanel || undefined,
        testPanelGenes: genesInTestPanel,
      });
    },
    [formData, handleOnChange],
  );

  const handleDialogNoConfirm = useCallback(() => {
    props.onChange(previousValue);
    setIsDialogOpen(false);
  }, [previousValue, props]);

  const handleDialogConfirm = useCallback(() => {
    handleUpdateFormData(genesInTestPanel, props.value);
    setIsDialogOpen(false);
  }, [genesInTestPanel, handleUpdateFormData, props.value]);

  const dialogConfig = useMemo(
    () => ({
      open: isDialogOpen,
      closeAfterTransition: false,
      title: t('genetic-test-panel-dialog.title'),
      content: t('genetic-test-panel-dialog.content'),
      actions: [
        <Button
          key="dialog-close"
          variant="outlined"
          onClick={handleDialogNoConfirm}
        >
          {t('genetic-test-panel-dialog.button-no')}
        </Button>,
        <Button
          key="dialog-confirm"
          variant="contained"
          onClick={handleDialogConfirm}
        >
          {t('genetic-test-panel-dialog.button-yes')}
        </Button>,
      ],
    }),
    [isDialogOpen, handleDialogConfirm, handleDialogNoConfirm, t],
  );

  const handleTestPanelSelection = useCallback(
    (newValue: string) => {
      const genesForSelectedPanel = getGenesInTestPanel(newValue);
      const isDifferentPanelSelected = props.value && props.value !== newValue;
      const hasGenesInNewPanel = genesForSelectedPanel.length > 0;

      setGenesInTestPanel(genesForSelectedPanel);
      setPreviousValue(props.value);
      props.onChange(newValue);

      if (isDifferentPanelSelected && hasGenesInNewPanel) {
        setIsDialogOpen(true);
      } else if (hasGenesInNewPanel) {
        handleUpdateFormData(genesForSelectedPanel, newValue);
      }
    },
    [getGenesInTestPanel, handleUpdateFormData, props],
  );

  return (
    <>
      <AutocompleteWidget
        {...props}
        uiSchema={{
          ...props.uiSchema,
          'ui:enumOptions': options,
        }}
        onChange={handleTestPanelSelection}
      />
      <Dialog {...dialogConfig} />
    </>
  );
};
