import { useTranslation } from 'react-i18next';

export const useRelationshipOptions = ({
  optionsType = 'allRelationship',
}: { optionsType?: 'allRelationship' | 'firstDregreeRelationship' } = {}) => {
  const { t } = useTranslation(['relationship']);

  const firstRelationshipOptions = [
    {
      value: 'father',
      label: t('father'),
    },
    {
      value: 'mother',
      label: t('mother'),
    },
    {
      value: 'son',
      label: t('son'),
    },
    {
      value: 'daughter',
      label: t('daughter'),
    },
    { value: 'sister', label: t('sister') },
    {
      value: 'brother',
      label: t('brother'),
    },
    {
      value: 'partner',
      label: t('partner'),
    },
  ];

  const relationshipOptions = [
    ...firstRelationshipOptions,
    {
      value: 'half-brother',
      label: t('half-brother'),
    },
    {
      value: 'half-sister',
      label: t('half-sister'),
    },
    {
      value: 'cousin',
      label: t('cousin'),
    },
    {
      value: 'paternal-grandfather',
      label: t('paternal-grandfather'),
    },
    {
      value: 'paternal-grandmother',
      label: t('paternal-grandmother'),
    },
    {
      value: 'maternal-grandfather',
      label: t('maternal-grandfather'),
    },
    {
      value: 'maternal-grandmother',
      label: t('maternal-grandmother'),
    },
    {
      value: 'paternal-uncle',
      label: t('paternal-uncle'),
    },
    {
      value: 'paternal-aunt',
      label: t('paternal-aunt'),
    },
    {
      value: 'maternal-uncle',
      label: t('maternal-uncle'),
    },
    {
      value: 'maternal-aunt',
      label: t('maternal-aunt'),
    },
  ];

  const options =
    optionsType === 'allRelationship'
      ? relationshipOptions
      : firstRelationshipOptions;

  const translateRelationship = (value: string) => t(`${value}`);

  return { relationshipOptions: options, translateRelationship };
};
