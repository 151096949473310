import { PedigreeNode } from '../types';
import {
  getAllBloodRelativesOf,
  getAllChildrenOf,
  getAllHalfSiblingsOf,
  getAllSiblingsOf,
} from './helpers';

export class PedigreeRelationshipLabeler {
  private nodes: PedigreeNode[];
  private indexNode: PedigreeNode | undefined;
  private labelKeys: { id: string; relativeKey: RelativeKey }[];

  constructor(nodes: PedigreeNode[]) {
    this.nodes = nodes;
    this.indexNode = nodes.find((n) => n.isIndex);
    this.labelKeys = [];
  }

  public generateLabelKeys(): { id: string; relativeKey: RelativeKey }[] {
    if (!this.indexNode) return [];

    this.labelKeys = [{ id: this.indexNode.id, relativeKey: 'index' }];
    this.addChildrenLabelKeys();
    this.addSiblingLabelKeys();
    this.addParentalLabelKeys();
    this.addPiblingLabelKeys();
    this.addCousinLabelKeys();
    this.addIndexPartnersLabelKeys();
    this.addBloodRelativesLabelKeysWithoutLabelKey();
    this.addPartnerLabelKeys();

    return this.labelKeys;
  }

  private addChildrenLabelKeys(): void {
    const children = this.nodes.filter(
      (n) =>
        n.fatherId === this.indexNode!.id || n.motherId === this.indexNode!.id,
    );

    const SEX_TO_CHILD_RELATION_MAP = {
      male: 'son',
      female: 'daughter',
      unknown: 'relative',
    };

    this.labelKeys.push(
      ...children.map((child) => ({
        id: child.id,
        relativeKey: SEX_TO_CHILD_RELATION_MAP[child.sex] as RelativeKey,
      })),
    );
  }

  private addSiblingLabelKeys(): void {
    const siblings = getAllSiblingsOf(this.indexNode!, this.nodes);
    const halfSiblings = getAllHalfSiblingsOf(this.indexNode!, this.nodes);

    const SEX_TO_SIBLING_RELATION_MAP = {
      male: 'brother',
      female: 'sister',
      unknown: 'relative',
    };

    this.labelKeys.push(
      ...siblings.map((sibling) => ({
        id: sibling.id,
        relativeKey: SEX_TO_SIBLING_RELATION_MAP[sibling.sex] as RelativeKey,
      })),
      ...halfSiblings.map((sibling) => ({
        id: sibling.id,
        relativeKey: (SEX_TO_SIBLING_RELATION_MAP[sibling.sex] === 'relative'
          ? 'relative'
          : `half-${SEX_TO_SIBLING_RELATION_MAP[sibling.sex]}`) as RelativeKey,
      })),
    );
  }

  private addParentalLabelKeys(): void {
    const father = this.nodes.find((n) => n.id === this.indexNode!.fatherId);
    const mother = this.nodes.find((n) => n.id === this.indexNode!.motherId);

    if (father) {
      this.labelKeys.push({ id: father.id, relativeKey: 'father' });
      this.addGrandParentalLabelKeys(father, 'paternal');
    }
    if (mother) {
      this.labelKeys.push({ id: mother.id, relativeKey: 'mother' });
      this.addGrandParentalLabelKeys(mother, 'maternal');
    }
  }

  private addGrandParentalLabelKeys(
    parentNode: PedigreeNode,
    parentalSide: PaternalMaternalRelation,
  ): void {
    const parentsFather = this.nodes.find((n) => n.id === parentNode.fatherId);
    const parentsMother = this.nodes.find((n) => n.id === parentNode.motherId);

    if (parentsFather) {
      this.labelKeys.push({
        id: parentsFather.id,
        relativeKey: `${parentalSide}-grandfather`,
      });
    }
    if (parentsMother) {
      this.labelKeys.push({
        id: parentsMother.id,
        relativeKey: `${parentalSide}-grandmother`,
      });
    }
  }

  private addPiblingLabelKeys(): void {
    const father = this.nodes.find((n) => n.id === this.indexNode!.fatherId);
    const mother = this.nodes.find((n) => n.id === this.indexNode!.motherId);
    const paternalSiblings = father ? getAllSiblingsOf(father, this.nodes) : [];
    const maternalSiblings = mother ? getAllSiblingsOf(mother, this.nodes) : [];

    const SEX_TO_PIBLING_RELATION_MAP: Record<
      string,
      'uncle' | 'aunt' | 'relative'
    > = {
      male: 'uncle',
      female: 'aunt',
      unknown: 'relative',
    };

    this.labelKeys.push(
      ...paternalSiblings.map((sibling) => ({
        id: sibling.id,
        relativeKey: this.getPiblingRelation(
          SEX_TO_PIBLING_RELATION_MAP[sibling.sex],
          'paternal',
        ),
      })),
      ...maternalSiblings.map((sibling) => ({
        id: sibling.id,
        relativeKey: this.getPiblingRelation(
          SEX_TO_PIBLING_RELATION_MAP[sibling.sex],
          'maternal',
        ),
      })),
    );
  }

  private addCousinLabelKeys(): void {
    const parents = this.nodes.filter(
      (n) =>
        n.id === this.indexNode!.fatherId || n.id === this.indexNode!.motherId,
    );
    const parentsSiblings = parents
      .map((parent) => getAllSiblingsOf(parent, this.nodes))
      .flat();
    const cousins = parentsSiblings
      .map((sibling) => getAllChildrenOf(sibling, this.nodes))
      .flat();

    this.labelKeys.push(
      ...cousins.map((cousin) => ({
        id: cousin.id,
        relativeKey: 'cousin' as RelativeKey,
      })),
    );
  }

  private addIndexPartnersLabelKeys(): void {
    this.indexNode!.partnerIds.forEach((partnerId) => {
      this.labelKeys.push({ id: partnerId, relativeKey: 'partner' });
    });
  }

  private addBloodRelativesLabelKeysWithoutLabelKey(): void {
    const allBloodRelativesToIndex = getAllBloodRelativesOf(
      this.indexNode!,
      this.nodes,
    );
    const allBloodRelativesToIndexPartners =
      this.getAllBloodRelativesToIndexPartners();

    const bloodRelativesWithoutLabelKey = [
      ...allBloodRelativesToIndex,
      ...allBloodRelativesToIndexPartners,
    ].filter(
      (relative) => !this.labelKeys.some((label) => label.id === relative.id),
    );

    this.addRelativeLabelKeys(bloodRelativesWithoutLabelKey);
  }

  private addRelativeLabelKeys(nodes: PedigreeNode[]): void {
    this.labelKeys.push(
      ...nodes.map((relative) => ({
        id: relative.id,
        relativeKey: 'relative' as RelativeKey,
      })),
    );
  }

  private getAllBloodRelativesToIndexPartners(): PedigreeNode[] {
    return (
      this.indexNode?.partnerIds
        .map((partnerId: string) => {
          const node = this.nodes.find((node) => node.id === partnerId);

          if (!node) return [];

          return getAllBloodRelativesOf(node, this.nodes);
        })
        .flat() || []
    );
  }

  private addPartnerLabelKeys(): void {
    const nodesWithoutLabelKey = this.nodes.filter(
      (node) =>
        node.id !== this.indexNode!.id &&
        !this.labelKeys.some((label) => label.id === node.id),
    );

    const partners = nodesWithoutLabelKey
      .filter((node) =>
        node.partnerIds.some((id) =>
          this.labelKeys.some((label) => label.id === id),
        ),
      )
      .map((node) => ({
        id: node.id,
        relativeKey: 'partner' as RelativeKey,
      }));

    this.labelKeys.push(...partners);
  }

  private getPiblingRelation(
    relation: 'uncle' | 'aunt' | 'relative',
    prefix: PaternalMaternalRelation,
  ): PiblingRelation {
    return (
      relation === 'relative' ? relation : `${prefix}-${relation}`
    ) as PiblingRelation;
  }
}

// ... Keep the type definitions at the end of the file
type PaternalMaternalRelation = 'paternal' | 'maternal';
type ParentalRelation = 'father' | 'mother';
type GrandParentalRelation =
  `${PaternalMaternalRelation}-grand${ParentalRelation}`;
type PiblingRelation = `${PaternalMaternalRelation}-${'uncle' | 'aunt'}`;
type SiblingRelation = 'brother' | 'sister';
type HalfSiblingRelation = `half-${SiblingRelation}`;
type ChildRelation = 'son' | 'daughter';
export type RelativeKey =
  | 'index'
  | ParentalRelation
  | GrandParentalRelation
  | PiblingRelation
  | SiblingRelation
  | HalfSiblingRelation
  | ChildRelation
  | 'cousin'
  | 'relative'
  | 'partner';
