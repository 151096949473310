import { PedigreeNode } from '../types';

export const findNodeById = (
  nodes: PedigreeNode[],
  nodeId: string,
): PedigreeNode | undefined => nodes.find((node) => node.id === nodeId);

export const findIndexNode = (nodes: PedigreeNode[]) =>
  nodes.find((node) => node.isIndex);

export const nodeHasParents = (nodes: PedigreeNode[], nodeId: string) => {
  const pedigreeNode = findNodeById(nodes, nodeId);
  return Boolean(pedigreeNode?.fatherId && pedigreeNode?.motherId);
};

export const isBloodRelative = (
  node: PedigreeNode,
  nodes: PedigreeNode[],
  pedigreeNodeId: string,
) => {
  const bloodRelatives = getAllBloodRelativesOf(node, nodes);
  return bloodRelatives.some((node) => node.id === pedigreeNodeId);
};

const isRootAscendants = (node: PedigreeNode) =>
  node.fatherId === undefined && node.motherId === undefined;

export function getAllBloodRelativesOf(
  node: PedigreeNode,
  nodes: PedigreeNode[],
): PedigreeNode[] {
  const rootAscendants = getRootAscendants(node, nodes);
  const queue = [...rootAscendants];
  const visited = new Set<PedigreeNode>();

  while (queue.length) {
    const current = queue.pop();
    if (current && !visited.has(current)) {
      visited.add(current);
      queue.push(...getAllChildrenOf(current, nodes));
    }
  }

  return Array.from(visited);
}

export const getAllAscendants = (node: PedigreeNode, nodes: PedigreeNode[]) => {
  const getAscendants = (node: PedigreeNode): PedigreeNode[] => {
    const father = nodes.find((n) => n.id === node.fatherId);
    const mother = nodes.find((n) => n.id === node.motherId);
    if (!father && !mother) return [];
    const parents = [];
    if (father) parents.push(father);
    if (mother) parents.push(mother);

    return parents.concat(
      father ? getAscendants(father) : [],
      mother ? getAscendants(mother) : [],
    );
  };
  const allAscendants = getAscendants(node);
  return allAscendants;
};

export const getRootAscendants = (
  node: PedigreeNode,
  nodes: PedigreeNode[],
): PedigreeNode[] => {
  let allAscendants = getAllAscendants(node, nodes);

  if (allAscendants.length === 0) {
    allAscendants = [node];
  }

  return allAscendants.filter(isRootAscendants);
};

export function getAllChildrenOf(
  node: PedigreeNode,
  nodes: PedigreeNode[],
): PedigreeNode[] {
  return nodes.filter((n) => n.fatherId === node.id || n.motherId === node.id);
}

export function getAllSiblingsOf(node: PedigreeNode, nodes: PedigreeNode[]) {
  return nodes.filter((n) => {
    if (n.id === node.id) {
      return false;
    }
    if (node.fatherId === undefined && node.motherId === undefined) {
      return false;
    }

    return n.fatherId === node.fatherId && n.motherId === node.motherId;
  });
}

export function getAllHalfSiblingsOf(
  node: PedigreeNode,
  nodes: PedigreeNode[],
) {
  return nodes.filter((n) => {
    return (
      n.id !== node.id &&
      (n.fatherId === node.fatherId || n.motherId === node.motherId) &&
      (n.fatherId !== node.fatherId || n.motherId !== node.motherId)
    );
  });
}
