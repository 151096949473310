import { PedigreeNodeSex } from '../types';
import { PedigreeNode } from './pedigree-layout';

const label = { width: 50, height: 50 };

export const mockPedigree: PedigreeNode[] = [
  {
    id: '6196',
    fatherId: '6197',
    motherId: '6198',
    partnerIds: ['6217'],
    isIndex: true,
    sex: PedigreeNodeSex.FEMALE,
    twinRelation: {
      id: '9',
      type: 'identical',
    },
    label,
  },
  {
    id: '6198',
    fatherId: '6201',
    motherId: '6202',
    partnerIds: ['6197', '6215'],
    isIndex: false,
    sex: PedigreeNodeSex.FEMALE,
    twinRelation: {
      id: '8',
      type: 'fraternal',
    },
    label,
  },
  {
    id: '6197',
    fatherId: '6199',
    motherId: '6200',
    partnerIds: ['6198'],
    isIndex: false,
    sex: PedigreeNodeSex.MALE,
    label,
  },
  {
    id: '6200',
    partnerIds: ['6199'],
    isIndex: false,
    sex: PedigreeNodeSex.FEMALE,
    label,
  },
  {
    id: '6199',
    partnerIds: ['6200'],
    isIndex: false,
    sex: PedigreeNodeSex.MALE,
    label,
  },
  {
    id: '6202',
    partnerIds: ['6201'],
    isIndex: false,
    sex: PedigreeNodeSex.FEMALE,
    label,
  },
  {
    id: '6201',
    partnerIds: ['6202'],
    isIndex: false,
    sex: PedigreeNodeSex.MALE,
    label,
  },
  {
    id: '6204',
    fatherId: '6201',
    motherId: '6202',
    partnerIds: ['6206'],
    isIndex: false,
    sex: PedigreeNodeSex.MALE,
    label,
    twinRelation: {
      id: '8',
      type: 'fraternal',
    },
  },
  {
    id: '6206',
    partnerIds: ['6204'],
    isIndex: false,
    sex: PedigreeNodeSex.FEMALE,
    label,
  },
  {
    id: '6208',
    fatherId: '6197',
    motherId: '6198',
    partnerIds: ['6212'],
    isIndex: false,
    sex: PedigreeNodeSex.FEMALE,
    label,
    twinRelation: {
      id: '9',
      type: 'identical',
    },
  },
  {
    id: '6212',
    partnerIds: ['6208'],
    isIndex: false,
    sex: PedigreeNodeSex.MALE,
    label,
  },
  {
    id: '6215',
    partnerIds: ['6198'],
    isIndex: false,
    sex: PedigreeNodeSex.MALE,
    label,
  },
  {
    id: '6217',
    partnerIds: ['6196'],
    isIndex: false,
    sex: PedigreeNodeSex.MALE,
    label,
  },
  {
    id: '6205',
    fatherId: '6201',
    motherId: '6202',
    partnerIds: [],
    isIndex: false,
    sex: PedigreeNodeSex.MALE,
    label,
  },
  {
    id: '6216',
    fatherId: '6215',
    motherId: '6198',
    partnerIds: [],
    isIndex: false,
    sex: PedigreeNodeSex.FEMALE,
    label,
  },
  {
    id: '6209',
    fatherId: '6201',
    motherId: '6202',
    partnerIds: [],
    isIndex: false,
    sex: PedigreeNodeSex.FEMALE,
    label,
    twinRelation: {
      id: '8',
      type: 'fraternal',
    },
  },
  {
    id: '6210',
    fatherId: '6197',
    motherId: '6198',
    partnerIds: [],
    isIndex: false,
    sex: PedigreeNodeSex.MALE,
    label,
  },
  {
    id: '6214',
    fatherId: '6212',
    motherId: '6208',
    partnerIds: [],
    isIndex: false,
    sex: PedigreeNodeSex.MALE,
    label,
  },
];
