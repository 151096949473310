import { Button, CardContent, Stack, Typography } from '@mui/material';
import { PedigreeNodeSex } from 'common/features/pedigree/common/types';
import { CardWithBorder } from 'myfamilytree/common';
import { useTranslation } from 'react-i18next';
import { InstructionBox } from './InstructionBox';
import PedigreeDrawerNodeCardView from './PedigreeDrawerNodeCardView';

interface PedigreeCreatedDrawerContentViewProps {
  onConfirmClick: () => void;
}

export const PedigreeCreatedDrawerContentView = (
  props: PedigreeCreatedDrawerContentViewProps,
) => {
  const { t } = useTranslation('myFamilyTree');
  return (
    <>
      <Stack gap={3} p={3}>
        <Typography variant="h3">
          {t('pedigree.onboarding-drawer.created.title')}
        </Typography>
        <Typography>
          {t('pedigree.onboarding-drawer.created.description.paragraph1')}
        </Typography>
        <CardWithBorder variant="outlined">
          <CardContent>
            <PedigreeDrawerNodeCardView
              sex={PedigreeNodeSex.FEMALE}
              relativeRelationLabel={t(
                'onBoarding-drawer.description.relative-relation-label',
              )}
            />
          </CardContent>
        </CardWithBorder>
        <Button variant="contained" onClick={props.onConfirmClick}>
          {t('pedigree.onboarding-drawer.created.textButton')}
        </Button>
      </Stack>
      <Stack
        sx={{
          position: 'absolute',
          width: '100%',
          bottom: 0,
        }}
      >
        <InstructionBox />
      </Stack>
    </>
  );
};
