import { LoadingButton } from '@mui/lab';
import { Stack, Typography } from '@mui/material';
import { formatPersonalNumber } from 'common/utils';
import { TextInput } from 'components/inputs';
import RelationshipInput from 'features/common/controllers/RelationshipInput';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface RelativeInformationFormViewProps {
  deceased: boolean;
  patientName: string;
  deceasedName: string | null;
  personalNumber: string;
  name: string;
  phoneNumber: string;
  onPhoneNumberChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  phoneNumberError: string;
  relationshipToPatient: string | null;
  onRelationshipToPatientChange: (newValue: string | null) => void;
  relationshipToPatientError: string;
  signerRelationshipToRelative: string;
  onSignerRelationshipToRelativeChange: (newValue: string | null) => void;
  signerRelationshipToRelativeError: string;
  deceasedPersonalNumber: string;
  onDeceasedPersonalNumberChange: (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void;
  deceasedPersonalNumberError: string;
  geneticInvestigationInfo: string;
  onGeneticInvestigationInformationChange: (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void;
  geneticInvestigationInformationError: string;
  onContinueClick: () => void;
  continueButtonLoading?: boolean;
  disabled?: boolean;
  geneticCounselorName: string;
  geneticCounselorPhoneNumber: string;
}

function RelativeInformationFormView(props: RelativeInformationFormViewProps) {
  const { t } = useTranslation(['all']);
  const { disabled, deceased, continueButtonLoading } = props;

  let geneticInvestigationInfoLabel = t(
    'consent-submission.relative-information-form.genetic-test-information',
  );
  if (deceased) {
    geneticInvestigationInfoLabel = t(
      'consent-submission.relative-information-form.deceased-genetic-test-information',
    );
  }

  return (
    <Stack spacing={3}>
      <Typography>
        {t('consent-submission.relative-information-form.title')}
      </Typography>
      <Stack spacing={2}>
        <Typography variant="body1">
          {t('consent-submission.relative-information-form.name') +
            ': ' +
            props.name}
        </Typography>
        <Typography variant="body1">
          {t('personal-number') +
            ': ' +
            formatPersonalNumber(props.personalNumber)}
        </Typography>
        <TextInput
          id="phoneNumber"
          label={t('consent-submission.relative-information-form.phone-number')}
          value={props.phoneNumber}
          onChange={props.onPhoneNumberChange}
          required
          error={
            props.phoneNumberError &&
            t(`input-validation.${props.phoneNumberError}`)
          }
          textFieldProps={{
            placeholder: '07XXXXXXXX',
            inputProps: { inputMode: 'numeric' },
            disabled,
          }}
        />

        {deceased && (
          <>
            <Typography>
              {t(
                'consent-submission.relative-information-form.add-info-about-deceased',
                { deceasedName: props.deceasedName },
              )}
            </Typography>
            <Stack spacing={0.5}>
              <TextInput
                id="deceasedPersonalNumber"
                label={t(
                  'consent-submission.relative-information-form.deceased-personal-number',
                  { deceasedName: props.deceasedName },
                )}
                value={props.deceasedPersonalNumber}
                onChange={props.onDeceasedPersonalNumberChange}
                required
                error={
                  props.deceasedPersonalNumberError &&
                  t(`input-validation.${props.deceasedPersonalNumberError}`)
                }
                textFieldProps={{ disabled }}
                placeholder={t('personal-number.placeholder')}
              />
              <Typography variant="caption">
                {t(
                  'consent-submission.relative-information-form.deceased-personal-number-info',
                  {
                    geneticCounselorName: props.geneticCounselorName,
                    geneticCounselorPhoneNumber:
                      props.geneticCounselorPhoneNumber,
                  },
                )}
              </Typography>
            </Stack>

            <RelationshipInput
              label={t(
                'consent-submission.relative-information-form.signer-relation-to-relative',
                { deceasedName: props.deceasedName },
              )}
              value={props.signerRelationshipToRelative || ''}
              onChange={props.onSignerRelationshipToRelativeChange}
              required
              error={
                props.signerRelationshipToRelativeError &&
                t(`input-validation.${props.signerRelationshipToRelativeError}`)
              }
              disabled={disabled}
              placeholder={t(
                'consent-submission.relative-information-form.signer-relation-to-relative.placeholder',
              )}
            />
          </>
        )}

        <TextInput
          id="geneticInvestigationInfo"
          label={geneticInvestigationInfoLabel}
          value={props.geneticInvestigationInfo}
          onChange={props.onGeneticInvestigationInformationChange}
          error={
            props.geneticInvestigationInformationError &&
            t(`input-validation.${props.geneticInvestigationInformationError}`)
          }
          textFieldProps={{ disabled }}
        />
      </Stack>
      <LoadingButton
        variant="contained"
        color="primary"
        onClick={props.onContinueClick}
        loading={continueButtonLoading}
      >
        {t('continue')}
      </LoadingButton>
    </Stack>
  );
}

export default RelativeInformationFormView;
