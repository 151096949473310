import { Button, Stack, useMediaQuery, useTheme } from '@mui/material';
import {
  PatientData,
  RelativeData,
} from 'api/consentManagement/consent-management-service';
import HeaderWithBackButton from 'components/common/HeaderWithBackButton';
import {
  emailSchema,
  phoneNumberSchema,
} from 'features/common/validation-schemas';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PatientForm } from '../../common/PatientForm';
import RelativeSelector, { Relative } from './RelativeSelector';

export interface ReminderData {
  patientContactDetails: PatientsContactDetails;
  relatives: Relative[];
}

interface ReminderFormProps {
  onSubmit: (reminderData: ReminderData) => void;
  onCancel: () => void;
  initialPatientData: PatientData;
  relativesWithPendingConsents: RelativeData[];
  consentFor: string;
}

type PatientsContactDetails = {
  phoneNumber: string;
  email: string;
};

const ReminderForm: React.FC<ReminderFormProps> = ({
  onSubmit,
  onCancel,
  initialPatientData,
  relativesWithPendingConsents,
  consentFor,
}) => {
  const { t } = useTranslation(['consent', 'commn']);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [patientContactDetails, setPatientContactDetails] =
    useState<PatientsContactDetails>({
      phoneNumber: initialPatientData.phoneNumber,
      email: initialPatientData.email || '',
    });
  const [selectedRelatives, setSelectedRelatives] = useState<Relative[]>([]);
  const [errors, setErrors] = useState<{
    phoneNumber?: string;
    email?: string;
    relatives?: string;
  }>({});

  const handlePatientDataChange = (patientData: PatientData) => {
    setPatientContactDetails({
      phoneNumber: patientData.phoneNumber,
      email: patientData.email || '',
    });
  };

  const handleSubmit = () => {
    const { valid, errors } = validateData({
      contactDetails: patientContactDetails,
      relatives: selectedRelatives,
    });

    if (!valid) {
      if (errors.phoneNumber === 'phone-or-email.required') {
        setErrors({
          ...errors,
          phoneNumber: t('input-validation.phone-or-email.required'),
        });
      } else {
        setErrors(errors);
      }
    }

    if (valid) {
      onSubmit({ patientContactDetails, relatives: selectedRelatives });
    }
  };

  const relativesOptions = useMemo<Relative[]>(
    () =>
      relativesWithPendingConsents.map((relative) => ({
        id: relative.consentId,
        name: relative.name,
        relationship: relative.relationshipToPatient || '',
        deceased: relative.deceased,
        status: relative.consentStatus,
        date: relative.createdAt,
      })),
    [relativesWithPendingConsents],
  );

  const resetErrors = () => setErrors({});

  return (
    <Stack direction="column" gap={3}>
      <HeaderWithBackButton headerVariant="h1" onBackButtonClick={onCancel}>
        {t('send-consent-reminder.title')}
      </HeaderWithBackButton>
      <PatientForm
        patientData={{
          name: initialPatientData.name,
          personalNumber: initialPatientData.personalNumber,
          phoneNumber: patientContactDetails.phoneNumber,
          email: patientContactDetails.email,
        }}
        setPatientData={handlePatientDataChange}
        errors={{
          phoneNumber: errors.phoneNumber,
          email: errors.email,
        }}
        setErrors={resetErrors}
        consentFor={consentFor}
        localizationKeyPrefix="send-consent-reminder"
      />
      <RelativeSelector
        relatives={relativesOptions}
        onSelect={setSelectedRelatives}
        error={errors.relatives === 'no-relatives-selected'}
        setError={resetErrors}
      />
      <Stack
        direction={isMobile ? 'column' : 'row'}
        justifyContent="center"
        spacing={4}
      >
        <Button variant="outlined" onClick={onCancel} sx={{ minWidth: 334 }}>
          {t('common:button.cancel')}
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          sx={{ minWidth: 334 }}
        >
          {t('common:button.continue')}
        </Button>
      </Stack>
    </Stack>
  );
};

export default ReminderForm;

interface ValidationErrors {
  phoneNumber?: 'phoneNumber.invalid' | 'phone-or-email.required';
  email?: 'string.email';
  relatives?: 'no-relatives-selected';
}

const validateData = (data: {
  contactDetails: PatientsContactDetails;
  relatives: Relative[];
}): { valid: boolean; errors: ValidationErrors } => {
  const { contactDetails, relatives } = data;

  const errors: ValidationErrors = {};

  const phoneNumberAndEmailAreMissing =
    !contactDetails.phoneNumber && !contactDetails.email;

  const phoneNumberValidation = phoneNumberSchema.validate(
    contactDetails.phoneNumber,
  );

  const emailValidation = emailSchema.validate(contactDetails.email);

  if (relatives.length === 0) {
    errors.relatives = 'no-relatives-selected';
  }

  if (phoneNumberAndEmailAreMissing) {
    errors.phoneNumber = 'phone-or-email.required';
  }

  if (phoneNumberValidation.error) {
    errors.phoneNumber = 'phoneNumber.invalid';
  }

  if (emailValidation.error) {
    errors.email = 'string.email';
  }

  return { valid: Object.keys(errors).length === 0, errors };
};
