import { DynamicForm } from '../common/features/dynamicForm/index';

import { RJSFSchema, UiSchema } from '@rjsf/utils';

export const DynamicFormPlayground = () => {
  const onSubmit = (data: any) => {
    console.log('submitted data:', data.formData);
  };

  const { staffSchema } = getSchemas();
  const schema = staffSchema.jsonSchema;
  const uiSchema = staffSchema.uiSchema;

  const formData = {
    medicalHistory: {
      mastectomy: {
        hasHadAMastectomy: 'yes',
      },
      isDiagnosed: 'yes',
      oophorectomy: {
        hasHadAnOophorectomy: 'yes',
      },
      endometriosis: 'yes',
      tubalLigation: 'no',
      diagnoses: [
        {
          id: '1130',
          disease: 'Pancreatic cancer',
          ageAtDiagnosis: 42,
          readonly: false,
          status: {
            state: 'created',
            performedBy: 'staff',
            performedAt: '2024.10.28',
            name: 'Firstname Lastname',
          },
        },
        {
          id: '1127',
          disease: 'Breast cancer',
          ageAtDiagnosis: 34,
          readonly: false,
          status: {
            state: 'created',
            performedBy: 'staff',
            performedAt: '2024.10.28',
            name: 'Firstname Lastname',
          },
        },
        {
          id: '1128',
          disease: 'Breast cancer',
          ageAtDiagnosis: 46,
          readonly: false,
          status: {
            state: 'created',
            performedBy: 'staff',
            performedAt: '2024.10.28',
            name: 'Firstname Lastname',
          },
        },
        {
          id: '1129',
          disease: 'Ovarian cancer',
          ageAtDiagnosis: 33,
          readonly: false,
          status: {
            state: 'created',
            performedBy: 'staff',
            performedAt: '2024.10.28',
            name: 'Firstname Lastname',
          },
        },
      ],
    },
    geneticTesting: {
      geneticTests: [
        {
          id: '293',
          status: 'Created by Firstname Lastname on 2024.10.28.',
          pathogenicVariantsFound: 'yes',
          geneticTestVariants: [
            {
              id: '280',
              name: 'PALB2',
              variants: {},
            },
          ],
          testType: 'screening',
          testPanel: 'prostatacancerpanel',
          testPanelGenes: [
            'BRIP1',
            'RAD51C',
            'RAD51D',
            'BARD1',
            'CHEK2',
            'ATM',
            'PALB2',
            'BRCA2',
            'BRCA1',
          ],
        },
      ],
    },
  };

  return (
    <DynamicForm
      formData={formData}
      schema={schema}
      uiSchema={{ ...uiSchema, ...{ 'ui:accordionsGap': 0.5 } }}
      onSubmit={onSubmit}
    />
  );
};

export const getSchemas = (): {
  staffSchema: {
    jsonSchema: RJSFSchema;
    uiSchema: UiSchema;
  };
  patientSchema: {
    jsonSchema: RJSFSchema;
    uiSchema: UiSchema;
  };
} => {
  return {
    staffSchema: {
      jsonSchema: {
        type: 'object',
        properties: {
          formInfo: {
            type: 'object',
            properties: {
              medicalFormPurpose: {
                type: 'string',
                readOnly: true,
              },
            },
          },
          medicalHistory: {
            type: 'object',
            properties: {
              isDiagnosed: {
                $ref: '#/definitions/yesNoQuestion',
              },
            },
            dependencies: {
              isDiagnosed: {
                oneOf: [
                  {
                    properties: {
                      isDiagnosed: {
                        enum: ['no', ''],
                      },
                    },
                  },
                  {
                    properties: {
                      isDiagnosed: {
                        const: 'yes',
                      },
                      diagnoses: {
                        type: 'array',
                        minItems: 1,
                        items: {
                          $ref: '#/definitions/diagnosisItemForStaff',
                        },
                      },
                    },
                  },
                ],
              },
            },
          },
          geneticTesting: {
            $ref: '#/definitions/geneticTestingStaffConsumer',
          },
        },
        definitions: {
          yesNoQuestion: {
            type: 'string',
            oneOf: [
              {
                const: 'yes',
                title: 'Yes',
              },
              {
                const: 'no',
                title: 'No',
              },
            ],
          },
          yesNoNotSureQuestion: {
            type: 'string',
            oneOf: [
              {
                const: 'yes',
                title: 'Yes',
              },
              {
                const: 'no',
                title: 'No',
              },
              {
                const: 'notSure',
                title: 'Not sure',
              },
            ],
          },
          genes: {
            type: 'array',
            uniqueItems: true,
            items: {
              type: 'string',
            },
          },
          geneticTestingPatientConsumer: {
            type: 'object',
            properties: {
              doneGeneticTesting: {
                $ref: '#/definitions/yesNoQuestion',
              },
            },
            dependencies: {
              doneGeneticTesting: {
                oneOf: [
                  {
                    properties: {
                      doneGeneticTesting: {
                        enum: ['no', ''],
                      },
                    },
                  },
                  {
                    properties: {
                      doneGeneticTesting: {
                        enum: ['yes'],
                      },
                      testLocation: {
                        type: 'string',
                      },
                      hasPathogenicGenes: {
                        $ref: '#/definitions/yesNoNotSureQuestion',
                      },
                    },
                  },
                ],
              },
              hasPathogenicGenes: {
                oneOf: [
                  {
                    properties: {
                      hasPathogenicGenes: {
                        enum: ['no', 'notSure', ''],
                      },
                    },
                  },
                  {
                    properties: {
                      hasPathogenicGenes: {
                        enum: ['yes'],
                      },
                      pathogenicGenes: {
                        $ref: '#/definitions/genes',
                      },
                    },
                  },
                ],
              },
            },
          },
          geneticTestingStaffConsumer: {
            type: 'object',
            properties: {
              doneGeneticTesting: {
                $ref: '#/definitions/yesNoQuestion',
              },
              geneticTests: {
                type: 'array',
                minItems: 0,
                items: {
                  $ref: '#/definitions/geneticTestingItem',
                },
              },
            },
          },
          geneticTestingItem: {
            type: 'object',
            properties: {
              id: {
                oneOf: [
                  {
                    type: 'string',
                  },
                  {
                    type: 'null',
                  },
                ],
                readOnly: true,
              },
              status: {
                type: 'string',
                readOnly: true,
              },
              pathogenicVariantsFound: {
                $ref: '#/definitions/yesNoNotSureQuestion',
              },
              testDate: {
                type: 'string',
              },
              testType: {
                type: 'string',
                oneOf: [
                  {
                    title: 'Gene panel screen',
                    const: 'screening',
                  },
                  {
                    title: 'Targeted carrier test',
                    const: 'targeted',
                  },
                ],
              },
              testLocation: {
                type: 'string',
              },
              testPanelGenes: {
                $ref: '#/definitions/genes',
              },
            },
            dependencies: {
              testType: {
                oneOf: [
                  {
                    properties: {
                      testType: {
                        enum: ['targeted', ''],
                      },
                    },
                  },
                  {
                    properties: {
                      testType: {
                        enum: ['screening'],
                      },
                      testPanel: {
                        type: 'string',
                      },
                    },
                  },
                ],
              },
              pathogenicVariantsFound: {
                oneOf: [
                  {
                    properties: {
                      pathogenicVariantsFound: {
                        enum: ['no', 'notSure', ''],
                      },
                    },
                  },
                  {
                    properties: {
                      pathogenicVariantsFound: {
                        enum: ['yes'],
                      },
                      geneticTestVariants: {
                        type: 'array',
                        minItems: 1,
                        items: {
                          $ref: '#/definitions/geneticTestVariantsItem',
                        },
                      },
                    },
                  },
                ],
              },
            },
          },
          geneticTestVariantsItem: {
            type: 'object',
            properties: {
              id: {
                oneOf: [
                  {
                    type: 'string',
                  },
                  {
                    type: 'null',
                  },
                ],
                readOnly: true,
              },
              name: {
                type: 'string',
              },
            },
            dependencies: {
              name: {
                oneOf: [
                  {
                    properties: {
                      name: {
                        type: 'string',
                      },
                      variants: {
                        type: 'object',
                        properties: {
                          rsid: {
                            type: 'string',
                          },
                          identifier: {
                            type: 'string',
                          },
                          location: {
                            type: 'string',
                          },
                        },
                      },
                    },
                  },
                ],
              },
            },
            required: ['name'],
          },
          diagnosisItemForStaff: {
            type: 'object',
            properties: {
              id: {
                oneOf: [
                  {
                    type: 'string',
                  },
                  {
                    type: 'null',
                  },
                ],
                readOnly: true,
              },
              confirmed: {
                type: 'boolean',
              },
              status: {
                type: 'object',
                properties: {
                  state: {
                    type: 'string',
                    readOnly: true,
                  },
                  performedBy: {
                    type: 'string',
                    readOnly: true,
                  },
                  performedAt: {
                    type: 'string',
                    readOnly: true,
                  },
                  name: {
                    type: 'string',
                    readOnly: true,
                  },
                },
              },
              disease: {
                type: 'string',
              },
              ageAtDiagnosis: {
                type: 'integer',
              },
              ageAtDiagnosisIsApproximate: {
                type: 'boolean',
              },
              treatedBy: {
                type: 'string',
              },
              notes: {
                type: 'string',
              },
            },
            required: ['disease'],
          },
          diagnosisItemForPatient: {
            type: 'object',
            properties: {
              id: {
                oneOf: [
                  {
                    type: 'string',
                  },
                  {
                    type: 'null',
                  },
                ],
                readOnly: true,
              },
              readonly: {
                type: 'boolean',
                readOnly: true,
              },
              disease: {
                type: 'string',
              },
              ageAtDiagnosis: {
                type: 'integer',
              },
              ageAtDiagnosisIsApproximate: {
                type: 'boolean',
              },
              treatedBy: {
                type: 'string',
              },
              notes: {
                type: 'string',
              },
            },
          },
        },
        additionalProperties: false,
      },
      uiSchema: {
        formInformation: {
          'ui:type': 'plainBox',
          medicalFormPurpose: {
            'ui:title': 'Currently showing information relevant to:',
          },
        },
        medicalHistory: {
          'ui:title': 'Medical history',
          'ui:type': 'box',
          'ui:order': ['isDiagnosed', 'diagnoses'],
          isDiagnosed: {
            'ui:title': 'Diagnosed with cancer or a cancer syndrome?',
            'ui:widget': 'radio',
          },
          diagnoses: {
            'ui:title': 'Diagnosis',
            'ui:arrayFieldTemplate': 'DiagnosisForm',
            'ui:options': {
              orderable: false,
            },
            items: {
              'ui:gapItems': 2,
              'ui:alignItems': 'end',
              id: {
                'ui:widget': 'hidden',
              },
              readonly: {
                'ui:widget': 'hidden',
              },
              disease: {
                'ui:title': 'Select diagnosis',
                'ui:placeholder': 'Select or type here',
                'ui:freeSolo': true,
                'ui:inputWidth': '25rem',
                'ui:widget': 'AutocompleteWidget',
                'ui:enumOptions': [
                  {
                    value: 'bladderCancer',
                    label: 'Bladder cancer',
                  },
                  {
                    value: 'breastCancer',
                    label: 'Breast cancer',
                  },
                  {
                    value: 'colonCancer',
                    label: 'Colorectal/Bowel cancer',
                  },
                  {
                    value: 'cowdensSyndrome',
                    label: 'Cowden syndrome',
                  },
                  {
                    value: 'endometrialCancer',
                    label: 'Endometrial cancer',
                  },
                  {
                    value: 'hnpcc',
                    label: 'Hereditary Nonpolyposis Colorectal Cancer (HNPCC)',
                  },
                  {
                    value: 'kidneyCancer',
                    label: 'Kidney/renal cancer',
                  },
                  {
                    value: 'bloodCancer',
                    label: 'Leukemia/blood cancer',
                  },
                  {
                    value: 'liFraumeniSyndrome',
                    label: 'Li-Fraumeni syndrome (LFS)',
                  },
                  {
                    value: 'lungCancer',
                    label: 'Lung cancer',
                  },
                  {
                    value: 'lynchSyndrome',
                    label: 'Lynch syndrome',
                  },
                  {
                    value: 'melanoma',
                    label: 'Melanoma',
                  },
                  {
                    value: 'myelodysplasticSyndrome',
                    label: 'Myelodysplastic syndromes (MDS)',
                  },
                  {
                    value: 'nonMelanomaSkinCancer',
                    label: 'Non-melanoma skin cancer',
                  },
                  {
                    value: 'ovarianCancer',
                    label: 'Ovarian cancer',
                  },
                  {
                    value: 'pancreaticCancer',
                    label: 'Pancreatic cancer',
                  },
                  {
                    value: 'polyps',
                    label: 'Polyps',
                  },
                  {
                    value: 'prostateCancer',
                    label: 'Prostate cancer',
                  },
                  {
                    value: 'thyroidCancer',
                    label: 'Thyroid cancer',
                  },
                  {
                    value: 'other',
                    label: 'Other',
                  },
                  {
                    value: 'notSure',
                    label: 'Not sure',
                  },
                ],
              },
              ageAtDiagnosis: {
                'ui:title': 'Age at diagnosis',
                'ui:gridColumnWidth': 'auto',
              },
              ageAtDiagnosisIsApproximate: {
                'ui:title': 'Approximate',
                'ui:gridColumnWidth': 'auto',
              },
              treatedBy: {
                'ui:title': 'Name of the clinic where treated',
                'ui:help': 'If treated outside of Sweden, use country name',
              },
              notes: {
                'ui:title': 'Diagnosis notes',
                'ui:widget': 'textarea',
                'ui:help': 'Optional',
              },
              confirmed: {
                'ui:widget': 'hidden',
              },
              status: {
                'ui:widget': 'hidden',
              },
            },
          },
        },
        geneticTesting: {
          'ui:title': 'Genetic testing',
          'ui:type': 'box',
          'ui:border': false,
          doneGeneticTesting: {
            'ui:widget': 'hidden',
          },
          geneticTests: {
            'ui:title': 'Genetic test',
            'ui:arrayFieldTemplate': 'GeneticTestsForm',
            'ui:buttonText': 'Add a genetic test result',
            items: {
              'ui:gapItems': 2,
              'ui:order': [
                'id',
                'status',
                'pathogenicVariantsFound',
                'geneticTestVariants',
                'testLocation',
                'testDate',
                'testType',
                'testPanel',
                'testPanelGenes',
              ],
              id: {
                'ui:widget': 'hidden',
              },
              status: {
                'ui:widget': 'hidden',
              },
              pathogenicVariantsFound: {
                'ui:title': 'Were any pathogenic variants identified?',
                'ui:widget': 'radio',
              },
              testDate: {
                'ui:title': 'Date of the test',
                'ui:placeholder': 'YYYY.MM.DD',
                'ui:help':
                  'You can provide the full date, the year and month, or just the year.',
                'ui:widget': 'DateFieldWidget',
              },
              testType: {
                'ui:title': 'Genetic test type',
                'ui:placeholder': 'Select',
                'ui:widget': 'AutocompleteWidget',
                'ui:inputWidth': '25rem',
                'ui:enumOptions': [
                  {
                    value: 'screening',
                    label: 'Gene panel screen',
                  },
                  {
                    value: 'targeted',
                    label: 'Targeted carrier test',
                  },
                ],
              },
              testLocation: {
                'ui:title': 'Where was the test done?',
                'ui:help':
                  'Clinic name or country (if tested outside of Sweden)',
              },
              testPanel: {
                'ui:title': 'Gene panel',
                'ui:placeholder': 'Select or type here',
                'ui:inputWidth': '25rem',
                'ui:widget': 'GeneticTestPanelWidget',
                'ui:freeSolo': true,
                'ui:inputByValue': true,
                'ui:enumOptions': [
                  {
                    label: 'Melanompanel',
                    value: 'melanompanel',
                    genesInTestPanel: ['CDKN2A', 'CDK4', 'BAP1', 'POT1'],
                  },
                  {
                    label: 'Prostatacancerpanel',
                    value: 'prostatacancerpanel',
                    genesInTestPanel: ['BRCA1', 'BRCA2', 'TP53', 'MSH2'],
                  },
                  {
                    label: 'Other',
                    value: 'other',
                  },
                ],
              },
              testPanelGenes: {
                'ui:title': 'Genes in the test panel',
                'ui:placeholder': 'Select or type here',
                'ui:help':
                  'You can select multiple genes from the list. If a gene is not listed, you can type its name.',
                'ui:inputWidth': '25rem',
                'ui:widget': 'AutocompleteWidget',
                'ui:freeSolo': true,
                'ui:inputByValue': true,
                'ui:enumOptions': [
                  {
                    value: 'APC',
                    label: 'APC',
                  },
                  {
                    value: 'ATM',
                    label: 'ATM',
                  },
                  {
                    value: 'MLH1',
                    label: 'MLH1',
                  },
                  {
                    value: 'MSH2',
                    label: 'MSH2',
                  },
                  {
                    value: 'MSH6',
                    label: 'MSH6',
                  },
                  {
                    value: 'PMS2',
                    label: 'PMS2',
                  },
                  {
                    value: 'MUTYH',
                    label: 'MUTYH',
                  },
                  {
                    value: 'EPCAM',
                    label: 'EPCAM',
                  },
                  {
                    value: 'BRCA1',
                    label: 'BRCA1',
                  },
                  {
                    value: 'BRCA2',
                    label: 'BRCA2',
                  },
                  {
                    value: 'PALB2',
                    label: 'PALB2',
                  },
                  {
                    value: 'CHEK2',
                    label: 'CHEK2',
                  },
                  {
                    value: 'BARD1',
                    label: 'BARD1',
                  },
                  {
                    value: 'BRIP1',
                    label: 'BRIP1',
                  },
                  {
                    value: 'RAD51C',
                    label: 'RAD51C',
                  },
                  {
                    value: 'RAD51D',
                    label: 'RAD51D',
                  },
                  {
                    value: 'UNKNOWN',
                    label: 'Mutation(s) with unknown name',
                  },
                ],
              },
              geneticTestVariants: {
                'ui:title': 'List of pathogenic variants',
                'ui:arrayFieldTemplate': 'GeneticTestVariantsForm',
                'ui:buttonText': 'Add another pathogenic variant',
                items: {
                  id: {
                    'ui:widget': 'hidden',
                  },
                  name: {
                    'ui:title': 'Gene',
                    'ui:placeholder': 'Select or type here',
                    'ui:widget': 'AutocompleteWidget',
                    'ui:freeSolo': true,
                    'ui:inputByValue': true,
                    'ui:enumOptions': [
                      {
                        value: 'APC',
                        label: 'APC',
                      },
                      {
                        value: 'ATM',
                        label: 'ATM',
                      },
                      {
                        value: 'MLH1',
                        label: 'MLH1',
                      },
                      {
                        value: 'MSH2',
                        label: 'MSH2',
                      },
                      {
                        value: 'MSH6',
                        label: 'MSH6',
                      },
                      {
                        value: 'PMS2',
                        label: 'PMS2',
                      },
                      {
                        value: 'MUTYH',
                        label: 'MUTYH',
                      },
                      {
                        value: 'EPCAM',
                        label: 'EPCAM',
                      },
                      {
                        value: 'BRCA1',
                        label: 'BRCA1',
                      },
                      {
                        value: 'BRCA2',
                        label: 'BRCA2',
                      },
                      {
                        value: 'PALB2',
                        label: 'PALB2',
                      },
                      {
                        value: 'CHEK2',
                        label: 'CHEK2',
                      },
                      {
                        value: 'BARD1',
                        label: 'BARD1',
                      },
                      {
                        value: 'BRIP1',
                        label: 'BRIP1',
                      },
                      {
                        value: 'RAD51C',
                        label: 'RAD51C',
                      },
                      {
                        value: 'RAD51D',
                        label: 'RAD51D',
                      },
                      {
                        value: 'UNKNOWN',
                        label: 'Mutation(s) with unknown name',
                      },
                    ],
                  },
                  variants: {
                    'ui:title': 'Variant',
                    'ui:titleVariant': 'h5',
                    rsid: {
                      'ui:title': 'rsID number',
                    },
                    identifier: {
                      'ui:title': 'Identifier',
                    },
                    location: {
                      'ui:title': 'Location',
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
    patientSchema: {
      jsonSchema: {
        type: 'object',
        properties: {
          medicalHistory: {
            type: 'object',
            properties: {
              isDiagnosed: {
                $ref: '#/definitions/yesNoQuestion',
              },
            },
            dependencies: {
              isDiagnosed: {
                oneOf: [
                  {
                    properties: {
                      isDiagnosed: {
                        enum: ['no', ''],
                      },
                    },
                  },
                  {
                    properties: {
                      isDiagnosed: {
                        const: 'yes',
                      },
                      diagnoses: {
                        type: 'array',
                        minItems: 1,
                        items: {
                          $ref: '#/definitions/diagnosisItemForPatient',
                        },
                      },
                    },
                  },
                ],
              },
            },
          },
          geneticTesting: {
            $ref: '#/definitions/geneticTestingPatientConsumer',
          },
        },
        definitions: {
          yesNoQuestion: {
            type: 'string',
            oneOf: [
              {
                const: 'yes',
                title: 'Yes',
              },
              {
                const: 'no',
                title: 'No',
              },
            ],
          },
          yesNoNotSureQuestion: {
            type: 'string',
            oneOf: [
              {
                const: 'yes',
                title: 'Yes',
              },
              {
                const: 'no',
                title: 'No',
              },
              {
                const: 'notSure',
                title: 'Not sure',
              },
            ],
          },
          diagnosisItemForPatient: {
            type: 'object',
            properties: {
              id: {
                oneOf: [
                  {
                    type: 'string',
                  },
                  {
                    type: 'null',
                  },
                ],
                readOnly: true,
              },
              readonly: {
                type: 'boolean',
                readOnly: true,
              },
              disease: {
                type: 'string',
              },
              ageAtDiagnosis: {
                type: 'integer',
              },
              ageAtDiagnosisIsApproximate: {
                type: 'boolean',
              },
              treatedBy: {
                type: 'string',
              },
              notes: {
                type: 'string',
              },
            },
            required: ['disease'],
          },
          genes: {
            type: 'array',
            uniqueItems: true,
            items: {
              type: 'string',
            },
          },
          geneticTestingPatientConsumer: {
            type: 'object',
            properties: {
              doneGeneticTesting: {
                $ref: '#/definitions/yesNoQuestion',
              },
            },
            dependencies: {
              doneGeneticTesting: {
                oneOf: [
                  {
                    properties: {
                      doneGeneticTesting: { enum: ['no'] },
                    },
                  },
                  {
                    properties: {
                      doneGeneticTesting: { enum: ['yes'] },
                      testLocation: {
                        type: 'string',
                      },
                      hasPathogenicGenes: {
                        $ref: '#/definitions/yesNoNotSureQuestion',
                      },
                    },
                    dependencies: {
                      hasPathogenicGenes: {
                        oneOf: [
                          {
                            properties: {
                              hasPathogenicGenes: { enum: ['no', 'notSure'] },
                            },
                          },
                          {
                            properties: {
                              hasPathogenicGenes: { enum: ['yes'] },
                              pathogenicGenes: {
                                $ref: '#/definitions/genes',
                              },
                            },
                          },
                        ],
                      },
                    },
                  },
                ],
              },
            },
          },
        },
        additionalProperties: false,
      },
      uiSchema: {
        medicalHistory: {
          'ui:title': 'Medical history',
          'ui:type': 'box',
          'ui:order': ['isDiagnosed', 'diagnoses'],
          isDiagnosed: {
            'ui:title': 'Diagnosed with cancer or a cancer syndrome?',
            'ui:widget': 'radio',
          },
          diagnoses: {
            'ui:title': 'Diagnosis',
            'ui:arrayFieldTemplate': 'DiagnosisForm',
            'ui:options': {
              orderable: false,
            },
            items: {
              'ui:alignItems': 'end',
              'ui:gapItems': 2,
              id: {
                'ui:widget': 'hidden',
              },
              disease: {
                'ui:title': 'Select diagnosis',
                'ui:placeholder': 'Select or type here',
                'ui:widget': 'AutocompleteWidget',
                'ui:inputWidth': '25rem',
                'ui:freeSolo': true,
                'ui:enumOptions': [
                  {
                    value: 'bladderCancer',
                    label: 'Bladder cancer',
                  },
                  {
                    value: 'breastCancer',
                    label: 'Breast cancer',
                  },
                  {
                    value: 'colonCancer',
                    label: 'Colorectal/Bowel cancer',
                  },
                  {
                    value: 'cowdensSyndrome',
                    label: 'Cowden syndrome',
                  },
                  {
                    value: 'endometrialCancer',
                    label: 'Endometrial cancer',
                  },
                  {
                    value: 'hnpcc',
                    label: 'Hereditary Nonpolyposis Colorectal Cancer (HNPCC)',
                  },
                  {
                    value: 'kidneyCancer',
                    label: 'Kidney/renal cancer',
                  },
                  {
                    value: 'bloodCancer',
                    label: 'Leukemia/blood cancer',
                  },
                  {
                    value: 'liFraumeniSyndrome',
                    label: 'Li-Fraumeni syndrome (LFS)',
                  },
                  {
                    value: 'lungCancer',
                    label: 'Lung cancer',
                  },
                  {
                    value: 'lynchSyndrome',
                    label: 'Lynch syndrome',
                  },
                  {
                    value: 'melanoma',
                    label: 'Melanoma',
                  },
                  {
                    value: 'myelodysplasticSyndrome',
                    label: 'Myelodysplastic syndromes (MDS)',
                  },
                  {
                    value: 'nonMelanomaSkinCancer',
                    label: 'Non-melanoma skin cancer',
                  },
                  {
                    value: 'ovarianCancer',
                    label: 'Ovarian cancer',
                  },
                  {
                    value: 'pancreaticCancer',
                    label: 'Pancreatic cancer',
                  },
                  {
                    value: 'polyps',
                    label: 'Polyps',
                  },
                  {
                    value: 'prostateCancer',
                    label: 'Prostate cancer',
                  },
                  {
                    value: 'thyroidCancer',
                    label: 'Thyroid cancer',
                  },
                  {
                    value: 'other',
                    label: 'Other',
                  },
                  {
                    value: 'notSure',
                    label: 'Not sure',
                  },
                ],
              },
              readonly: {
                'ui:widget': 'hidden',
              },
              ageAtDiagnosis: {
                'ui:title': 'Age at diagnosis',
                'ui:gridColumnWidth': 'auto',
              },
              ageAtDiagnosisIsApproximate: {
                'ui:title': 'Approximate',
                'ui:gridColumnWidth': 'auto',
              },
              treatedBy: {
                'ui:title': 'Name of the clinic where treated',
                'ui:description':
                  'If treated outside of Sweden, use country name',
              },
              notes: {
                'ui:title': 'Diagnosis notes',
                'ui:widget': 'textarea',
                'ui:description': 'Optional',
              },
            },
          },
        },
        geneticTesting: {
          'ui:title': 'Genetic testing',
          'ui:type': 'box',
          doneGeneticTesting: {
            'ui:title':
              'Has the person ever done health-related genetic tests?',
            'ui:widget': 'radio',
          },
          testLocation: {
            'ui:title': 'Where was the test done?',
            'ui:placeholder': 'Clinic XYZ',
            'ui:description':
              'Clinic name or country (if tested outside of Sweden)',
            'ui:inputWidth': '25rem',
          },
          hasPathogenicGenes: {
            'ui:title': 'Were any pathogenic mutations found in the genes?',
            'ui:widget': 'radio',
          },
          pathogenicGenes: {
            'ui:title': 'Which gene(s) had pathogenic mutations?',
            'ui:placeholder': 'Type here or select',
            'ui:inputWidth': '25rem',
            'ui:widget': 'AutocompleteWidget',
            'ui:freeSolo': true,
            'ui:enumOptions': [
              {
                value: 'apc',
                label: 'APC',
              },
              {
                value: 'atm',
                label: 'ATM',
              },
              {
                value: 'mlh1',
                label: 'MLH1',
              },
              {
                value: 'msh2',
                label: 'MSH2',
              },
              {
                value: 'msh6',
                label: 'MSH6',
              },
              {
                value: 'pms2',
                label: 'PMS2',
              },
              {
                value: 'mutyh',
                label: 'MUTYH',
              },
              {
                value: 'epcam',
                label: 'EPCAM',
              },
              {
                value: 'brca1',
                label: 'BRCA1',
              },
              {
                value: 'brca2',
                label: 'BRCA2',
              },
              {
                value: 'palb2',
                label: 'PALB2',
              },
              {
                value: 'chek2',
                label: 'CHEK2',
              },
              {
                value: 'bard1',
                label: 'BARD1',
              },
              {
                value: 'brip1',
                label: 'BRIP1',
              },
              {
                value: 'rad51c',
                label: 'RAD51C',
              },
              {
                value: 'rad51d',
                label: 'RAD51D',
              },
              {
                value: 'unknownGenes',
                label: 'mutation(s) with unknown name',
              },
            ],
          },
        },
      },
    },
  };
};
