import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { ArrayFieldTemplateProps } from '@rjsf/utils';
import {
  Children,
  cloneElement,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from 'react';

export const GeneticTestVariantsTemplate = (props: ArrayFieldTemplateProps) => {
  useEffect(() => {
    if (props.formData.length === 0) {
      props.onAddClick();
    }
  }, [props]);

  return (
    <>
      {props.readonly ? (
        <GeneticTestVariantsReadOnly {...props} />
      ) : (
        <GeneticTestVariantsEditable {...props} />
      )}
    </>
  );
};

const GeneticTestVariantsEditable = (props: ArrayFieldTemplateProps) => {
  const theme = useTheme();
  const addButtonText = props.uiSchema?.['ui:buttonText'] || undefined;

  return (
    <Stack spacing={3}>
      <Stack gap={3}>
        {props.items.map((element, index) => (
          <Stack
            key={index}
            p={2}
            border={1}
            borderRadius={1}
            borderColor={theme.palette.common.opacity.darkBlue[25]}
            gap={2}
          >
            <Stack>{element.children}</Stack>
          </Stack>
        ))}
      </Stack>
      <Button variant="outlined" onClick={props.onAddClick}>
        {addButtonText}
      </Button>
    </Stack>
  );
};

const GeneticTestVariantsReadOnly = (props: ArrayFieldTemplateProps) => {
  return (
    <Stack gap={1}>
      {props.items.map((element, index) => (
        <GeneticTestVariantsItemReadOnly
          key={index}
          children={element.children}
        />
      ))}
    </Stack>
  );
};

interface GeneticTestVariantsItemReadOnlyProps {
  children: ReactElement;
}
const GeneticTestVariantsItemReadOnly = (
  props: GeneticTestVariantsItemReadOnlyProps,
) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const theme = useTheme();

  const noRenderKeys = useMemo(() => {
    return ['name'];
  }, []);

  const readOnlyModeElements = useMemo(() => {
    return Children.map(props.children, (child) => {
      return cloneElement(child, {
        uiSchema: {
          ...child.props.uiSchema,
          variants: {
            ...child.props.uiSchema.variants,
            'ui:titleVariant': 'body2',
            'ui:gapItems': 1,
            identifier: {
              ...child.props.uiSchema.variants.identifier,
              'ui:variant': 'body2',
            },
            location: {
              ...child.props.uiSchema.variants.location,
              'ui:variant': 'body2',
            },
            rsid: {
              ...child.props.uiSchema.variants.rsid,
              'ui:variant': 'body2',
            },
          },
          ...noRenderKeys.reduce(
            (acc: Record<string, { 'ui:widget': 'hidden' }>, key) => {
              acc[key] = { 'ui:widget': 'hidden' };
              return acc;
            },
            {},
          ),
        },
      });
    });
  }, [noRenderKeys, props.children]);

  const variantName = useMemo(() => {
    const value = props.children.props.uiSchema.name['ui:enumOptions'].find(
      (option: { value: string; label: string }) =>
        option.value === props.children.props.formData.name,
    );
    return value?.label || props.children.props.formData.name;
  }, [props.children]);

  const hasDetails =
    Object.keys(props.children.props.formData.variants || {}).length > 0;

  return (
    <Accordion
      expanded={isExpanded}
      elevation={0}
      disableGutters
      onChange={() => hasDetails && setIsExpanded(!isExpanded)}
      sx={{
        backgroundColor: theme.palette.common.neutral[100],
        '&:before': {
          display: 'none',
        },
        '& .MuiAccordionSummary-root:hover, .MuiButtonBase-root:hover': {
          cursor: hasDetails ? 'pointer' : 'default',
        },
      }}
    >
      <AccordionSummary
        expandIcon={
          hasDetails && (
            <FontAwesomeIcon
              color={theme.palette.common.brand.darkBlue}
              icon={faCaretDown}
            />
          )
        }
      >
        <Typography variant="h6">{variantName}</Typography>
      </AccordionSummary>
      {hasDetails && (
        <AccordionDetails>{readOnlyModeElements}</AccordionDetails>
      )}
    </Accordion>
  );
};
