import { User, useUserService } from 'api/user/user-service';
import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useQuery } from 'react-query';

export type SelectedUser = {
  id: number;
  name: string;
};

export type ContextProps = {
  currentLoggedInUser: User | null;
  userDataIsLoading: boolean;
  selectedUser: SelectedUser | null;
  setSelectedUser: (user: SelectedUser | null) => void;
};

export const Context = createContext<ContextProps>({
  currentLoggedInUser: null,
  userDataIsLoading: true,
  selectedUser: null,
  setSelectedUser: () => {},
});

export const UserProvider = ({ children }: PropsWithChildren) => {
  const service = useUserService();

  const [currentLoggedInUser, setCurrentLoggedInUser] = useState<User | null>(
    null,
  );
  const [selectedUser, setSelectedUser] = useState<SelectedUser | null>(null);

  const { data: user, isLoading: userDataIsLoading } = useQuery(
    ['user'],
    service.getCurrentUser,
    {
      enabled: !currentLoggedInUser,
    },
  );

  const contextValue = {
    currentLoggedInUser,
    userDataIsLoading,
    selectedUser,
    setSelectedUser,
  };

  useEffect(() => {
    const hasUserChanged = user && user.id !== currentLoggedInUser?.id;
    if (hasUserChanged) {
      setCurrentLoggedInUser(user);
      setSelectedUser({
        id: user.id,
        name: user.name,
      });
    }
  }, [currentLoggedInUser, user]);

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

export const useUserContext = () => useContext(Context);
