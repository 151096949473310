import { Button, Stack } from '@mui/material';
import { ArrayFieldTemplateProps } from '@rjsf/utils';
import { GeneticTestItem } from './GeneticTestItem';

export const GeneticTestTemplate = (props: ArrayFieldTemplateProps) => {
  const addButtonText = props.uiSchema?.['ui:buttonText'] || undefined;
  const title = props.uiSchema?.['ui:title'] ?? '';

  const showAddButton = props.canAdd && !props.readonly;

  return (
    <Stack spacing={3}>
      {props.items.map((item, index) => (
        <GeneticTestItem
          key={props.formData[index]?.id || item.key}
          title={props.items.length > 1 ? `${title} ${index + 1}` : title}
          geneticTest={props.formData[index]}
          children={item.children}
          deleteGeneticTest={item.onDropIndexClick(index)}
          showAction={!props.readonly}
        />
      ))}
      {showAddButton && (
        <Button variant="outlined" onClick={props.onAddClick}>
          {addButtonText}
        </Button>
      )}
    </Stack>
  );
};
