import { LoadingButton } from '@mui/lab';
import { Alert, Button, Snackbar, Stack, Typography } from '@mui/material';
import { RJSFSchema, UiSchema } from '@rjsf/utils';
import { useStaffPedigreeCaseService } from 'api/pedigreeCase/staff-pedigree-case-service';
import { DynamicForm } from 'common/features/dynamicForm';
import useDialog from 'common/hooks/useDialog';
import { TFunction } from 'i18next';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { PatientOverviewItem } from './PatientOverviewPanel';

interface PatientOverviewContactDetailsProps {
  patientId: string;
}

function PatientOverviewContactDetails(
  props: PatientOverviewContactDetailsProps,
) {
  const { t } = useTranslation('pedigreeCasePatientOverview');
  const { patientId } = props;
  const [mode, setMode] = useState<'view' | 'edit'>('view');

  const service = useStaffPedigreeCaseService();

  const { data: pedigreeCase, isLoading } = useQuery(
    ['pedigreeCaseContactDetails', patientId],
    () => service.getPedigreeCase(patientId),
    {
      refetchOnWindowFocus: false,
    },
  );

  if (isLoading) {
    return null;
  }

  if (!pedigreeCase) {
    return <>{t('error.no-pedigree-case-data-found')}</>;
  }

  return (
    <>
      <Typography variant="h5">{t('contact-details')}</Typography>
      {mode === 'view' && (
        <PatientOverviewContactDetailsViewMode
          phoneNumber={pedigreeCase.patientDetails.phoneNumber || ''}
          email={pedigreeCase.patientDetails.email || ''}
          switchToEditMode={() => setMode('edit')}
        />
      )}
      {mode === 'edit' && (
        <PatientOverviewContactDetailsEditMode
          switchToViewMode={() => setMode('view')}
          pedigreeCaseId={pedigreeCase.id}
          patientId={patientId}
          initialValues={{
            phoneNumber: pedigreeCase.patientDetails.phoneNumber || undefined,
            email: pedigreeCase.patientDetails.email || undefined,
          }}
        />
      )}
    </>
  );
}

interface PatientOverviewContactDetailsViewModeProps {
  phoneNumber: string;
  email: string;
  switchToEditMode: () => void;
}

function PatientOverviewContactDetailsViewMode(
  props: PatientOverviewContactDetailsViewModeProps,
) {
  const { t } = useTranslation('pedigreeCasePatientOverview');
  return (
    <>
      <PatientOverviewItem
        label={t('mobile-number')}
        value={props.phoneNumber}
        direction="column"
      />
      <PatientOverviewItem
        label={t('email')}
        value={props.email}
        direction="column"
      />
      <Button onClick={props.switchToEditMode} variant="outlined">
        {t('action.edit')}
      </Button>
    </>
  );
}

interface PatientOverviewContactDetailsEditModeProps {
  switchToViewMode: () => void;
  pedigreeCaseId: string;
  patientId: string;
  initialValues: {
    phoneNumber?: string;
    email?: string;
  };
}

function PatientOverviewContactDetailsEditMode(
  props: PatientOverviewContactDetailsEditModeProps,
) {
  const { t } = useTranslation('pedigreeCasePatientOverview');
  const [formData, setFormData] = useState<{
    phoneNumber?: string;
    email?: string;
  }>();
  const [updateFailedSnackbarIsOpen, setUpdateFailedSnackbarIsOpen] =
    useState(false);
  const handleCloseSnackbar = () => {
    setUpdateFailedSnackbarIsOpen(false);
  };

  const queryClient = useQueryClient();
  const service = useStaffPedigreeCaseService();
  const updatePatientDetailsMutation = useMutation(
    (data: { phoneNumber: string; email: string }) =>
      service.updatePatientContactDetails(props.pedigreeCaseId, data),
    {
      onSuccess: () => {
        queryClient.refetchQueries([
          'pedigreeCaseContactDetails',
          props.patientId,
        ]);
        props.switchToViewMode();
      },
    },
  );

  useEffect(() => {
    setFormData({
      phoneNumber: props.initialValues.phoneNumber,
      email: props.initialValues.email,
    });
  }, [props.initialValues]);

  const contactDetailsSchema = getContactDetailsSchema(t);

  const { dialog: cancelDialog, openDialog: openCancelDialog } = useDialog({
    onDialogConfirmClick: () => {
      props.switchToViewMode();
    },
    dialogContent: {
      title: t('cancel-dialog.title'),
      content: t('cancel-dialog.content'),
      textCancel: t('cancel-dialog.cancel'),
      textConfirm: t('cancel-dialog.confirm'),
    },
  });

  const handleCancelClick = () => {
    if (
      formData?.phoneNumber === props.initialValues.phoneNumber &&
      formData?.email === props.initialValues.email
    ) {
      return props.switchToViewMode();
    }
    return openCancelDialog();
  };

  const isSubmitting = updatePatientDetailsMutation.isLoading;

  return (
    <>
      <DynamicForm
        uiSchema={contactDetailsUiSchema}
        schema={contactDetailsSchema}
        formData={formData}
        onSubmit={(data) => updatePatientDetailsMutation.mutate(data.formData)}
        onChange={(data) => setFormData(data.formData)}
      >
        <Stack spacing={2} direction="row" mt={3}>
          <LoadingButton
            onClick={handleCancelClick}
            variant="outlined"
            loading={isSubmitting}
            fullWidth
          >
            {t('action.cancel')}
          </LoadingButton>
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            loading={isSubmitting}
            fullWidth
          >
            {t('action.submit')}
          </LoadingButton>
        </Stack>
      </DynamicForm>
      <Snackbar
        open={updateFailedSnackbarIsOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="error" variant="filled">
          {t('error.update-patient-contact-details-failed')}
        </Alert>
      </Snackbar>
      {cancelDialog}
    </>
  );
}

function getContactDetailsSchema(t: TFunction): RJSFSchema {
  return {
    type: 'object',
    properties: {
      phoneNumber: {
        type: 'string',
        title: t('mobile-number'),
      },
      email: {
        type: 'string',
        title: t('email'),
        format: 'email',
      },
    },
  };
}

const contactDetailsUiSchema: UiSchema = {
  'ui:accordionsGap': 3,
  phoneNumber: {
    'ui:options': {
      inputType: 'tel',
    },
    'ui:placeholder': '07XXXXXXXX',
  },
};

export default PatientOverviewContactDetails;
