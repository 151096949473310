import IPedigreeCasePagesTabNavBar from 'iPedigree/features/patient/TabNavBar';
import { EditRelativeDeceasedPage } from 'iPedigree/pages/patient/consent/EditRelativeDeceasedPage';
import { PatientConsentsPage } from 'iPedigree/pages/patient/consent/PatientConsentsPage';
import { RequestNewConsentPage } from 'iPedigree/pages/patient/consent/RequestNewConsentPage';
import SendConsentReminderPage from 'iPedigree/pages/patient/consent/SendConsentReminderPage';
import PatientOverviewPage from 'iPedigree/pages/patient/index';
import { PatientPedigreePage } from 'iPedigree/pages/patient/pedigree/PatientPedigreePage';
import SendReminderPage from 'iPedigree/pages/patient/pedigree/SendReminderPage';
import SendRequestPage from 'iPedigree/pages/patient/pedigree/SendRequestPage';
import { Outlet, Route, Routes } from 'react-router-dom';

function PageWithNavBar() {
  return (
    <>
      <IPedigreeCasePagesTabNavBar />
      <Outlet />
    </>
  );
}

function PatientPageRouter() {
  return (
    <Routes>
      <Route path="/" element={<PageWithNavBar />}>
        <Route path="/" element={<PatientOverviewPage />} />
        <Route path="/pedigree" element={<PatientPedigreePage />} />
        <Route path="/consents" element={<PatientConsentsPage />} />
      </Route>
      <Route path="/pedigree/send-request" element={<SendRequestPage />} />
      <Route path="/pedigree/send-reminder" element={<SendReminderPage />} />
      <Route path="/consents/new-consent" element={<RequestNewConsentPage />} />
      <Route
        path="/consents/send-reminder"
        element={<SendConsentReminderPage />}
      />
      <Route
        path="/consents/edit-relative-deceased"
        element={<EditRelativeDeceasedPage />}
      />
    </Routes>
  );
}

export default PatientPageRouter;
