import { BaseService } from '../base-service';

export abstract class AuthServiceAbstract {
  abstract signin: (
    email: string,
    password: string,
  ) => Promise<{ accessToken: string; passwordChangeRequired: boolean }>;
}

export class AuthService extends BaseService implements AuthServiceAbstract {
  name = 'AuthService';

  signin = async (email: string, password: string) =>
    this.axiosInstance
      .post(`/auth/logins`, { email, password })
      .then((response) => response.data);

  updatePassword = async (password: string, token: string) =>
    this.axiosInstance.put(
      `/auth/password`,
      { password },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

  validatePasswordResetToken = async (
    token: string,
  ): Promise<{ valid: boolean }> =>
    this.axiosInstance
      .post('/auth/password-reset-request-validations', {
        token,
      })
      .then((response) => response.data);

  requestResetPassword = async (email: string) =>
    this.axiosInstance
      .post(`/auth/password-reset-requests`, { email })
      .then((response) => response.data);

  resetPassword = async (newPassword: string, token: string) =>
    this.axiosInstance
      .post(`/auth/password-resets`, { newPassword, token })
      .then((response) => response.data);
}
