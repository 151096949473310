import { faCircle, faSquare } from '@fortawesome/free-regular-svg-icons';
import {
  faChevronUp,
  faPencil,
  faPlus,
  faTrashCan,
  faUser,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import DiamondIcon from 'components/icons/DiamondIcon';
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { ChangeNodeSex } from '../../hooks/updateNode/useChangeNodeSex';
import {
  HandleNodeAction,
  PedigreeNodeAction,
} from '../../hooks/useHandleNodeAction';
import { NodeActionsMenuPopper, Option } from './NodeActionsMenuPopper';

type PedigreeNodeActionWithSubMenu =
  | 'addChild'
  | 'addHalfSibling'
  | 'addSibling'
  | 'changeSex';

export type Action =
  | PedigreeNodeAction
  | ChangeNodeSex
  | PedigreeNodeActionWithSubMenu;

interface ListOption {
  value: Action;
  hidden?: boolean;
  selected?: boolean;
  icon?: IconDefinition;
  iconComponent?: ReactNode;
  subMenuOptions?: ListOption[];
  divider?: boolean;
}

interface NodeActionsMenuViewProps {
  handleNodeMenuOptionClick: (action: HandleNodeAction) => void;
  anchorElement: HTMLElement | null;
  pedigreeNodeId: string | null;
  hasEditAndChangeSexAction?: boolean;
  selectedElement?: {
    [key: string]: boolean;
  } | null;
}

export const NodeActionsMenu = ({
  handleNodeMenuOptionClick,
  anchorElement,
  pedigreeNodeId,
  hasEditAndChangeSexAction,
  selectedElement,
}: NodeActionsMenuViewProps) => {
  const { t } = useTranslation('pedigree');
  const [currentAnchorElementId, setCurrentAnchorElementId] = useState<
    string | null
  >(null);
  const [subMenuAnchorElement, setSubMenuAnchorElement] =
    useState<HTMLElement | null>(null);

  const menuOptions: Option[] = useMemo(() => {
    const listOptions = getListOptions({
      areEditAndChangeSexActionsHidden: !hasEditAndChangeSexAction,
      selectedElement,
    });

    return listOptions.map((option) => ({
      ...option,
      label: t(`node-menu-member-option.${option.value}`),
      subMenuOptions: option.subMenuOptions
        ?.filter((option): option is Option => Boolean(option))
        .map((subOption) => ({
          ...subOption,
          label: t(`node-menu-member-option.${subOption.value}`),
        })),
    }));
  }, [hasEditAndChangeSexAction, selectedElement, t]);

  const subMenuOptions = useMemo(() => {
    return menuOptions
      .filter(
        (option) => `option-menu-${option.value}` === subMenuAnchorElement?.id,
      )
      .flatMap((option) => option.subMenuOptions || [])
      .filter((option): option is Option => Boolean(option));
  }, [subMenuAnchorElement?.id, menuOptions]);

  const onMenuOptionClick = (action: Action) => {
    if (!pedigreeNodeId) return;

    const actionHasSubMenu = menuOptions.some(
      (option) => option.value === action && !!option.subMenuOptions,
    );

    if (actionHasSubMenu) return;

    handleNodeMenuOptionClick({
      action: action as PedigreeNodeAction,
      pedigreeNodeId,
    });
    setSubMenuAnchorElement(null);
  };

  const onMenuOptionMouseOver = useCallback(
    (event: React.MouseEvent<HTMLElement>, action: Action) => {
      const actionHasSubMenu = menuOptions.some(
        (option) => option.value === action && !!option.subMenuOptions,
      );

      if (actionHasSubMenu) {
        setSubMenuAnchorElement(event.currentTarget);
      } else {
        setSubMenuAnchorElement(null);
      }
    },
    [menuOptions],
  );

  useEffect(() => {
    if (currentAnchorElementId !== pedigreeNodeId) {
      setCurrentAnchorElementId(pedigreeNodeId);
      setSubMenuAnchorElement(null);
    }

    if (!anchorElement && subMenuAnchorElement) {
      setSubMenuAnchorElement(null);
    }
  }, [
    pedigreeNodeId,
    anchorElement,
    currentAnchorElementId,
    subMenuAnchorElement,
  ]);

  return (
    <>
      <NodeActionsMenuPopper
        id="menu"
        showArrow
        anchorElement={anchorElement}
        options={menuOptions}
        onMenuOptionClick={onMenuOptionClick}
        onMenuOptionMouseOver={onMenuOptionMouseOver}
      />
      <NodeActionsMenuPopper
        id="sub-menu"
        offset={4}
        placement="right-start"
        anchorElement={anchorElement ? subMenuAnchorElement : null}
        options={subMenuOptions}
        onMenuOptionClick={onMenuOptionClick}
      />
    </>
  );
};

interface GetListOptions {
  areEditAndChangeSexActionsHidden: boolean;
  selectedElement?: { [key: string]: boolean } | null;
}

const getListOptions = ({
  areEditAndChangeSexActionsHidden,
  selectedElement,
}: GetListOptions): ListOption[] => {
  return [
    { value: 'addParents', icon: faPlus },
    { value: 'addPartner', icon: faPlus },
    {
      value: 'addSibling',
      icon: faPlus,
      subMenuOptions: [
        { value: 'addBrother', icon: faSquare },
        { value: 'addSister', icon: faCircle },
        { value: 'addSiblingOfUnknownSex', iconComponent: <DiamondIcon /> },
        { value: 'addTwin', icon: faChevronUp },
      ],
    },
    {
      value: 'addHalfSibling',
      icon: faPlus,
      subMenuOptions: [
        { value: 'addHalfBrother', icon: faSquare },
        { value: 'addHalfSister', icon: faCircle },
        { value: 'addHalfSiblingOfUnknownSex', iconComponent: <DiamondIcon /> },
      ],
    },
    {
      value: 'addChild',
      icon: faPlus,
      subMenuOptions: [
        { value: 'addSon', icon: faSquare },
        { value: 'addDaughter', icon: faCircle },
        { value: 'addChildOfUnknownSex', iconComponent: <DiamondIcon /> },
      ],
      divider: true,
    },
    { value: 'edit', icon: faPencil, hidden: areEditAndChangeSexActionsHidden },
    {
      value: 'changeSex',
      icon: faUser,
      hidden: areEditAndChangeSexActionsHidden,
      subMenuOptions: [
        {
          value: 'changeSexToMale',
          icon: faSquare,
          selected: selectedElement?.changeSexToMale,
        },
        {
          value: 'changeSexToFemale',
          icon: faCircle,
          selected: selectedElement?.changeSexToFemale,
        },
        {
          value: 'changeSexToUnknown',
          iconComponent: <DiamondIcon />,
          selected: selectedElement?.changeSexToUnknown,
        },
      ],
    },
    { value: 'delete', icon: faTrashCan },
  ];
};
