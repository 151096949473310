import {
  FormHelperTextProps,
  FormLabelProps,
  TextField,
  TextFieldProps,
} from '@mui/material';
import React from 'react';
import InputWrapper from './InputWrapper';

export type TextInputProps = {
  label: string;
  id: string;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error?: string;
  showErrorText?: boolean;
  inlineLabel?: boolean;
  required?: boolean;
  placeholder?: string;
  textFieldProps?: TextFieldProps;
  formLabelProps?: FormLabelProps;
  labelBefore?: boolean;
  fullWidth?: boolean;
  labelInfo?: string;
  type?: string;
  errorBorder?: boolean;
  formHelperTextProps?: FormHelperTextProps;
};

const TextInput = ({
  label,
  id,
  value,
  onChange,
  textFieldProps,
  error,
  showErrorText = true,
  inlineLabel,
  required = false,
  placeholder,
  formLabelProps,
  labelInfo,
  labelBefore = false,
  fullWidth = true,
  type,
  errorBorder = true,
  formHelperTextProps,
}: TextInputProps) => {
  const randomId = React.useId();
  id = `${id}-${randomId}`;

  return (
    <InputWrapper
      label={label}
      childId={id}
      error={error}
      showErrorText={showErrorText}
      inlineLabel={inlineLabel}
      required={required}
      formLabelProps={formLabelProps}
      labelBefore={labelBefore}
      labelInfo={labelInfo}
      fullWidth={fullWidth}
      formHelperTextProps={formHelperTextProps}
    >
      <TextField
        error={errorBorder && !!error}
        type={type || 'text'}
        id={id}
        value={value as string}
        onChange={onChange}
        size="small"
        required={required}
        placeholder={placeholder}
        {...textFieldProps}
      />
    </InputWrapper>
  );
};

export default TextInput;
