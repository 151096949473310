import { createContext, ReactElement } from 'react';
import { GeneticTest } from './GeneticTestItem';

export const GeneticTestItemFormContext = createContext<{
  formData: GeneticTest | {};
  handleOnChange?: (formData: GeneticTest | {}) => void;
}>({
  formData: {},
  handleOnChange: () => {},
});

export const GeneticTestItemForm = (props: { children: ReactElement }) => {
  const { children } = props;
  const onChange = children.props.onChange;
  const formData = children.props.formData;

  return (
    <GeneticTestItemFormContext.Provider
      value={{
        formData,
        handleOnChange: onChange,
      }}
    >
      {children}
    </GeneticTestItemFormContext.Provider>
  );
};
