import { Container, Stack } from '@mui/material';
import ConsentListPanel from 'iPedigree/features/dashboard/ConsentListPanel';
import PatientListPanel from 'iPedigree/features/dashboard/PatientListPanel';
import PedigreeListPanel from 'iPedigree/features/dashboard/PedigreeListPanel';
import TabMenu, { TabIdentifier } from 'iPedigree/features/dashboard/TabMenu';
import { useState } from 'react';

function DashboardPage() {
  const [currentTab, setCurrentTab] = useState<TabIdentifier>('pedigreeList');

  return (
    <Container maxWidth="xl">
      <Stack spacing={4} direction="row" alignItems="flex-start">
        <TabMenu currentTab={currentTab} handleChange={setCurrentTab} />
        {currentTab === 'patientList' && <PatientListPanel />}
        {currentTab === 'consent' && <ConsentListPanel />}
        {currentTab === 'pedigreeList' && <PedigreeListPanel />}
      </Stack>
    </Container>
  );
}

export default DashboardPage;
