import { DrawerState } from 'common/features/pedigree';
import { Pedigree, PedigreeService } from '../types';
import { nodeHasParents } from '../utils/helpers';
import { DialogContentType } from '../views/Dialog';
import { PedigreeNodeAddAction } from './addNode/types';
import { useAllowedToAddNode } from './addNode/useAllowedToAddNode';
import { useHandleAddNode } from './addNode/useHandleAddNode';
import { useDeleteNode } from './deleteNode/useDeleteNode';
import { ChangeNodeSex, useChangeNodeSex } from './updateNode/useChangeNodeSex';
import { useUpdateNodeParents } from './updateNode/useUpdateNodeParents';

export type PedigreeNodeAction =
  | PedigreeNodeAddAction
  | ChangeNodeSex
  | 'changeParents'
  | 'delete'
  | 'edit'
  | 'select';

export type HandleNodeAction = {
  action: PedigreeNodeAction;
  pedigreeNodeId: string;
};

interface UseHandleNodeActionProps {
  pedigree?: Pedigree | null;
  setDialogContent: (content: DialogContentType) => void;
  setSelectedNodeId: (pedigreeNodeId: string | null) => void;
  setPedigree: (Pedigree: Pedigree | null) => void;
  service: PedigreeService;
  setDrawerState: (info: DrawerState | null) => void;
  pedigreeType: 'professional' | 'patient';
  isEditMode: boolean;
}

function useHandleNodeAction({
  pedigree,
  service,
  pedigreeType,
  isEditMode,
  setDialogContent,
  setSelectedNodeId,
  setPedigree,
  setDrawerState,
}: UseHandleNodeActionProps) {
  const { handleAddNodeAction } = useHandleAddNode({
    pedigree,
    setDialogContent,
    setDrawerState,
    setSelectedNodeId,
    setPedigree,
    addNewPedigreeNode: service.addNewPedigreeNode,
  });

  const { handleDeleteNodeAction } = useDeleteNode({
    pedigreeId: pedigree?.id,
    setDialogContent,
    deletePedigreeNode: service.deletePedigreeNode,
    deletablePedigreeNode: service.deletablePedigreeNode,
    pedigreeType,
  });

  const { handleChangingParents } = useUpdateNodeParents({
    pedigree,
    setPedigree,
    setDialogContent,
    setDrawerState,
    setSelectedNodeId,
    updatePedigreeNodeParents: service.updatePedigreeNodeParents,
  });

  const { isAllowedToAddNode } = useAllowedToAddNode({
    pedigree,
    setDialogContent,
  });

  const { handleChangingSex } = useChangeNodeSex({
    pedigree,
    setDialogContent,
    setPedigree,
    canChangeSex: service.canChangeSex,
    updateNodeSex: service.updateNodeSex,
  });

  const handleNodeAction = ({ action, pedigreeNodeId }: HandleNodeAction) => {
    if (!pedigreeNodeId) return;

    switch (action) {
      case 'delete':
        setSelectedNodeId(null);
        handleDeleteNodeAction(pedigreeNodeId);
        break;

      case 'edit':
        setDrawerState({ action, pedigreeNodeId, type: 'nodeForm' });
        setSelectedNodeId(pedigreeNodeId);
        break;

      case 'select':
        if (!isEditMode) {
          setDrawerState({ action, pedigreeNodeId, type: 'nodeInformation' });
        }
        setSelectedNodeId(pedigreeNodeId);
        break;

      case 'changeSexToMale':
      case 'changeSexToFemale':
      case 'changeSexToUnknown':
        handleChangingSex(action, pedigreeNodeId);
        break;

      default:
        if (isAllowedToAddNode(action, pedigreeNodeId)) {
          const addNodeAction =
            action === 'addParents'
              ? handleAddParentsAction
              : handleAddNodeAction;
          return addNodeAction(action, pedigreeNodeId);
        }
        break;
    }
  };

  const handleAddParentsAction = (
    action: PedigreeNodeAction,
    pedigreeNodeId: string,
  ) => {
    if (!pedigree) return;

    if (nodeHasParents(pedigree.nodes, pedigreeNodeId)) {
      return handleChangingParents(pedigreeNodeId);
    }

    return handleAddNodeAction(action, pedigreeNodeId);
  };

  return { handleNodeAction };
}

export default useHandleNodeAction;
