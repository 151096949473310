import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Pedigree, PedigreeNode } from '../../types';
import { findIndexNode, isBloodRelative } from '../../utils/helpers';
import { DialogContentType } from '../../views/Dialog';

interface UseAllowedToAddNodeProps {
  pedigree?: Pedigree | null;
  setDialogContent: (content: DialogContentType) => void;
}

function isNodeBloodRelativeToAnyIndexPartner(
  indexNode: PedigreeNode,
  pedigree: Pedigree,
  pedigreeNodeId: string,
) {
  return indexNode.partnerIds.some((partnerId) => {
    const partnerNode = pedigree.nodes.find((node) => node.id === partnerId);

    if (!partnerNode) {
      return false;
    }

    return isBloodRelative(partnerNode, pedigree.nodes, pedigreeNodeId);
  });
}

export const useAllowedToAddNode = (props: UseAllowedToAddNodeProps) => {
  const { pedigree, setDialogContent } = props;

  const { t } = useTranslation('pedigree');

  const closeDialog = () => {
    setDialogContent({
      open: false,
      title: '',
      content: '',
      actions: [],
    });
  };

  const isAllowedToAddNode = (
    action: string,
    pedigreeNodeId: string,
  ): boolean => {
    if (!pedigree) return false;

    const indexNode = findIndexNode(pedigree.nodes);
    if (!indexNode) return false;

    const allowedActionsForNonBloodRelative = [
      'addSon',
      'addDaughter',
      'addChildOfUnknownSex',
    ];
    if (allowedActionsForNonBloodRelative.includes(action)) {
      return true;
    }

    const nodeIsBloodRelativeToIndex = isBloodRelative(
      indexNode,
      pedigree.nodes,
      pedigreeNodeId,
    );
    if (nodeIsBloodRelativeToIndex) {
      return true;
    }

    const nodeIsBloodRelativeToAnyIndexPartner =
      isNodeBloodRelativeToAnyIndexPartner(indexNode, pedigree, pedigreeNodeId);
    if (nodeIsBloodRelativeToAnyIndexPartner) {
      return true;
    }

    openCannotAddNodeDialog();
    return false;
  };

  const openCannotAddNodeDialog = () => {
    props.setDialogContent({
      open: true,
      title: t('node-member-dialog.not-allowed-add.title'),
      content: t('node-member-dialog.not-allowed-add.content'),
      actions: [
        <Button
          key="close-not-allowed-add-dialog"
          variant="contained"
          onClick={closeDialog}
        >
          {t('node-member-dialog.not-allowed-add.button.confirm')}
        </Button>,
      ],
    });
  };

  return {
    isAllowedToAddNode,
  };
};
