import { zxcvbnAsync, zxcvbnOptions, ZxcvbnResult } from '@zxcvbn-ts/core';
import * as zxcvbnCommonPackage from '@zxcvbn-ts/language-common';
import * as zxcvbnEnPackage from '@zxcvbn-ts/language-en';
import { useDeferredValue, useEffect, useMemo, useState } from 'react';
const passwordValidator = require('password-validator');

const options = {
  dictionary: {
    ...zxcvbnCommonPackage.dictionary,
    ...zxcvbnEnPackage.dictionary,
  },
  graphs: zxcvbnCommonPackage.adjacencyGraphs,
  useLevenshteinDistance: true,
};
zxcvbnOptions.setOptions(options);

export type SatisfiedPasswordCriteria =
  | 'minLength'
  | 'maxLength'
  | 'minUperLowercase'
  | 'minNumbers'
  | 'minSymbols'
  | 'notGuessable';

type PasswordPolicy = {
  score: number;
  satisfiedPasswordCriteria: SatisfiedPasswordCriteria[];
};

export type PasswordValidation = {
  isValid: boolean;
} & PasswordPolicy;

export const usePasswordStrength = (password: string) => {
  const deferredPassword = useDeferredValue(password);

  const [result, setResult] = useState<ZxcvbnResult | null>(null);

  const passwordValidation: PasswordValidation = useMemo(() => {
    const { satisfiedPasswordCriteria, score } = checkPasswordPolicy(
      password,
      result?.score || 0,
    );

    const hasMaxLength = satisfiedPasswordCriteria.includes('maxLength');
    const isPasswordValid = score >= 3 && hasMaxLength;

    return {
      score,
      satisfiedPasswordCriteria,
      isValid: isPasswordValid,
    };
  }, [password, result]);

  useEffect(() => {
    zxcvbnAsync(deferredPassword).then((response) => setResult(response));
  }, [deferredPassword]);

  return {
    passwordValidation,
  };
};

const checkPasswordPolicy = (
  password: string,
  score: number,
): PasswordPolicy => {
  const baseRules = [
    'minLength',
    'minUperLowercase',
    'minNumbers',
    'minSymbols',
  ];

  let satisfiedPasswordCriteria: SatisfiedPasswordCriteria[] = [];

  const minLength = 12;
  const maxLength = 100;
  const minLowercase = 1;
  const minUppercase = 1;
  const minNumbers = 1;
  const minSymbols = 1;

  const schema = new passwordValidator();
  schema.is().min(minLength);
  schema.is().max(maxLength);
  schema.has().uppercase(minUppercase);
  schema.has().lowercase(minLowercase);
  schema.has().digits(minNumbers);
  schema.has().symbols(minSymbols);

  const validate = schema.validate(password, { list: true });

  if (!validate.includes('min')) {
    satisfiedPasswordCriteria.push('minLength');
  }

  if (!validate.includes('max')) {
    satisfiedPasswordCriteria.push('maxLength');
  }

  if (!validate.includes('uppercase') && !validate.includes('lowercase')) {
    satisfiedPasswordCriteria.push('minUperLowercase');
  }

  if (!validate.includes('digits')) {
    satisfiedPasswordCriteria.push('minNumbers');
  }

  if (!validate.includes('symbols')) {
    satisfiedPasswordCriteria.push('minSymbols');
  }

  if (score >= 3) {
    satisfiedPasswordCriteria.push('notGuessable');
  }

  const matchingBaseRules = satisfiedPasswordCriteria.filter((rule) =>
    baseRules.includes(rule),
  );
  const strongScore = matchingBaseRules.length < baseRules.length ? 2 : score;
  const scoreValue = score > 2 ? strongScore : score;

  return {
    satisfiedPasswordCriteria,
    score: scoreValue,
  };
};
