import { BaseService } from 'api/base-service';
import {
  serviceHookFactory,
  serviceProviderFactory,
} from 'api/serviceProviderFactory';
import React from 'react';

export type UserPreferences = {
  notificationEmail?: string;
  notificationPhoneNumber?: string;
  receiveNotifications: boolean;
};

type UserList = {
  id: number;
  name: string;
}[];

export type User = {
  id: number;
  name: string;
  email: string;
  phoneNumber: string;
  tenantId: number;
};

export class UserService extends BaseService {
  getCurrentUser = async (): Promise<User> => {
    const response = await this.axiosInstance.get('/users/me');
    return response.data;
  };

  getCurrentUserPreferences = async (): Promise<UserPreferences | null> => {
    const response = await this.axiosInstance.get('/users/preferences');

    if (Object.keys(response.data).length === 0) {
      return null;
    }
    return response.data;
  };

  putCurrentUserPreferences = async (
    preferences: UserPreferences,
  ): Promise<UserPreferences> => {
    const response = await this.axiosInstance.put(
      '/users/preferences',
      preferences,
    );
    return response.data;
  };

  getUserList = async (): Promise<UserList> => {
    const response = await this.axiosInstance.get('/users');
    return response.data;
  };
}

// Create context and provider
const UserServiceContext = React.createContext<{
  service: UserService | null;
}>({ service: null });

export const UserServiceProvider = serviceProviderFactory(
  UserService,
  UserServiceContext,
);

export const useUserService = serviceHookFactory(
  UserService,
  UserServiceContext,
);
