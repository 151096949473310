import { Alert, Box, Button, Stack, Typography } from '@mui/material';
import { BankidLaunchMethod } from 'api/bankid/bankid-service';
import { GetConsentResponse } from 'api/consentSubmission/consent-submission-service';
import { formatPersonalNumber } from 'common/utils';
import { RadioInput } from 'components/inputs';
import { useRelationshipOptions } from 'features/common/hooks/useRelationshipOptions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BankidSignButton } from '../../bankid/views/BankidSignButton';

interface ConsentOverviewViewProps {
  consent: GetConsentResponse;
  onSignClick: (bankidLaunchMethod: BankidLaunchMethod) => void;
  onConfirmDeclineClick: () => void;
  onBackClick: () => void;
  preferredBankidLaunchMethod?: BankidLaunchMethod;
}

function ConsentOverviewView(props: ConsentOverviewViewProps) {
  const { t } = useTranslation(['all']);
  const { translateRelationship } = useRelationshipOptions();
  const [consentAccept, setConsentAccept] = React.useState<boolean | null>(
    null,
  );
  const { consent } = props;
  let signerName = consent.name;
  let signerPersonalNumber = consent.personalNumber;
  let signerPhoneNumber = consent.phoneNumber;
  let deceased = false;
  let signerRelationshipToRelative = undefined;
  if ('proxySigner' in consent) {
    deceased = true;
    signerName = consent.proxySigner.name;
    signerPersonalNumber = consent.proxySigner.personalNumber;
    signerPhoneNumber = consent.proxySigner.phoneNumber;
    signerRelationshipToRelative = translateRelationship(
      consent.proxySigner.signerToConsenteeRelationship || '',
    );
  }

  let actionButtons = null;
  if (consentAccept === false) {
    actionButtons = (
      <>
        <Alert severity="info">
          {t('consent-submission-confirmation.decline.info-card.text')}
        </Alert>
        <Button
          variant="contained"
          color="primary"
          onClick={props.onConfirmDeclineClick}
        >
          {t('consent-submission-confirmation.button.confirm')}
        </Button>
      </>
    );
  } else if (consentAccept === true) {
    actionButtons = (
      <BankidSignActions
        onSignClick={props.onSignClick}
        preferredBankidLaunchMethod={props.preferredBankidLaunchMethod}
      />
    );
  }

  return (
    <Stack spacing={4}>
      <Box>
        <Typography variant="h4">
          {t('consent-submission-confirmation.title')}
        </Typography>
        <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
          {consent.consentFor}
        </Typography>
      </Box>

      {deceased && (
        <Box>
          <Typography variant="h4">
            {t('consent-submission-confirmation.relative.consent-for-deceased')}
          </Typography>
          <Typography variant="body1">
            {t(
              'consent-submission-confirmation.relative.consent-for-deceased-name',
              { name: consent.name },
            )}
          </Typography>
          <Typography variant="body1">
            {t(
              'consent-submission-confirmation.relative.consent-for-deceased-personal-number',
              { personalNumber: formatPersonalNumber(consent.personalNumber) },
            )}
          </Typography>
        </Box>
      )}

      <Box>
        <Typography variant="h4">
          {t('consent-submission-confirmation.relative.relationship.title')}
        </Typography>
        <Typography variant="body1">
          {t(
            'consent-submission-confirmation.relative.relationship.to-proband',
            { relation: translateRelationship(consent.relationshipToPatient) },
          )}
        </Typography>
      </Box>

      {deceased && (
        <>
          <Box>
            <Typography variant="h4">
              {t('consent-submission-confirmation.signer.sign-on-behalf-of')}
            </Typography>
            <Typography variant="body1">{signerName}</Typography>
          </Box>
          <Box>
            <Typography variant="h4">
              {t(
                'consent-submission-confirmation.signer.signer-relation-to-relative',
              )}
            </Typography>
            <Typography variant="body1">
              {signerRelationshipToRelative}
            </Typography>
          </Box>
        </>
      )}

      <Box>
        <Typography variant="body1">
          {deceased
            ? t(
                'consent-submission-confirmation.relative.i-give-my-consent.paragraph.deceased',
              )
            : t(
                'consent-submission-confirmation.relative.i-give-my-consent.paragraph.living',
              )}
        </Typography>
      </Box>

      <Box>
        <Typography variant="h4">
          {t(
            'consent-submission-confirmation.relative.i-give-my-consent.title',
          )}
        </Typography>
        <Typography variant="body1">
          {t('consent-submission-confirmation.relative.name')}: {signerName}
        </Typography>
        <Typography variant="body1">
          {t('consent-submission-confirmation.relative.personalNumber')}:{' '}
          {formatPersonalNumber(signerPersonalNumber)}
        </Typography>
        <Typography variant="body1">
          {t('consent-submission-confirmation.relative.phoneNumber')}:{' '}
          {signerPhoneNumber}
        </Typography>
      </Box>

      <Box>
        <Typography variant="h4">
          {t('consent-submission-confirmation.patient.title')}
        </Typography>
        <Typography variant="body1">
          {t('consent-submission-confirmation.requestee.name')}:{' '}
          {consent.patient.name}
        </Typography>
        <Typography variant="body1">
          {t('consent-submission-confirmation.patient.personalNumber')}:{' '}
          {formatPersonalNumber(consent.patient.personalNumber)}
        </Typography>
      </Box>

      {consent.additionalInfo && (
        <Box>
          <Typography variant="h4">
            {deceased
              ? t(
                  'consent-submission.relative-information-form.deceased-genetic-test-information',
                )
              : t(
                  'consent-submission.relative-information-form.genetic-test-information',
                )}
            :
          </Typography>
          <Typography variant="body1">{consent.additionalInfo}</Typography>
        </Box>
      )}

      <Box>
        <Typography variant="body2">
          {t('consent-submission-confirmation.gc-information', {
            name: consent.staff.name,
            phoneNumber: consent.staff.phoneNumber,
          })}
        </Typography>
      </Box>

      <Box>
        <Typography variant="h4">
          {t('consent-submission-confirmation.confirmation.question')}
        </Typography>
        <RadioInput
          label=""
          value={consentAccept === null ? '' : consentAccept ? 'true' : 'false'}
          onChange={(e) => setConsentAccept(e.target.value === 'true')}
          options={[
            {
              label: t('consent-submission-confirmation.confirmation.yes'),
              value: 'true',
            },
            {
              label: t('consent-submission-confirmation.confirmation.no'),
              value: 'false',
            },
          ]}
          id="consentAccept"
        />
      </Box>

      <Stack spacing={2}>{actionButtons}</Stack>
    </Stack>
  );
}

export default ConsentOverviewView;

interface BankidSignActionsProps {
  onSignClick: (bankidLaunchMethod: BankidLaunchMethod) => void;
  preferredBankidLaunchMethod?: BankidLaunchMethod;
}

const BankidSignActions: React.FC<BankidSignActionsProps> = (props) => {
  const { t } = useTranslation(['all']);
  const signWithQrButton = (
    <BankidSignButton
      onClick={() => props.onSignClick('qrCode')}
      variant="outlined"
    >
      {t('consent-submission-confirmation.button.sign-on-different-device')}
    </BankidSignButton>
  );
  const signOnSameDeviceButton = (
    <BankidSignButton onClick={() => props.onSignClick('autoStart')}>
      {t('consent-submission-confirmation.button.sign-on-same-device')}
    </BankidSignButton>
  );

  let actions = (
    <>
      {signOnSameDeviceButton}
      {signWithQrButton}
    </>
  );
  if (props.preferredBankidLaunchMethod === 'qrCode')
    actions = signWithQrButton;
  else if (props.preferredBankidLaunchMethod === 'autoStart')
    actions = signOnSameDeviceButton;

  return (
    <>
      {actions}
      <Alert severity="info">
        {t('consent-submission-confirmation.accept.info-card.text')}
      </Alert>
    </>
  );
};
