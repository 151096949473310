import { Button, Stack } from '@mui/material';
import { Diagnose } from 'api/pedigreeCase/staff-pedigree-case-service';
import Dialog from 'components/common/Dialog';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DiagnoseValue,
  DiagnosisFormData,
  DiagnosisItemErrors,
} from '../controllers/PatientDiagnosisFormController';
import { DiagnosisFormFields, DiseaseOption } from './DiagnosisFormFields';
import { DiagnosisFormHeader } from './DiagnosisFormHeader';

interface DiagnosisFormItemProps {
  diseaseOptions: DiseaseOption[];
  diagnoses: DiagnosisFormData;
  errors: DiagnosisItemErrors;
  showDeleteButton?: boolean;
  titleItemNumber: number;
  deleteDiagnosis: () => void;
  onDiagnoseFieldChange: <K extends keyof Diagnose>(
    key: K,
    value: DiagnoseValue[K],
  ) => void;
}

export const DiagnosisFormItem = (props: DiagnosisFormItemProps) => {
  const {
    diagnoses,
    diseaseOptions,
    errors,
    titleItemNumber,
    onDiagnoseFieldChange,
    deleteDiagnosis,
  } = props;

  const { t } = useTranslation(['pedigreeCaseNewPatient']);

  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState<boolean>(false);

  const title = t('diagnosis-form.title', { number: `${titleItemNumber}` });

  const handleDialogClose = () => {
    setIsOpenDeleteDialog(false);
  };

  const deleteDialogProps = useMemo(() => {
    return {
      open: isOpenDeleteDialog,
      title: t('dialog-delete.title'),
      content: t('dialog-delete.content'),
      actions: [
        <Button
          key="confirm-button-dialog"
          variant="contained"
          onClick={() => {
            deleteDiagnosis();
            handleDialogClose();
          }}
        >
          {t('dialog-delete.button.delete')}
        </Button>,
        <Button
          key="close-button-dialog"
          variant="outlined"
          onClick={handleDialogClose}
        >
          {t('dialog-delete.button.cancel')}
        </Button>,
      ],
    };
  }, [deleteDiagnosis, isOpenDeleteDialog, t]);

  const isDirty = useMemo(() => {
    return (
      diagnoses.disease !== '' ||
      diagnoses.ageAtDiagnosis !== null ||
      diagnoses.treatedBy !== '' ||
      diagnoses.ageIsApproximate ||
      diagnoses.notes !== ''
    );
  }, [diagnoses]);

  const handleDeleteDiagnosis = () => {
    if (isDirty) {
      setIsOpenDeleteDialog(true);
      return;
    }
    deleteDiagnosis();
  };

  return (
    <>
      <Stack gap={2}>
        <DiagnosisFormHeader
          onDeleteDiagnosisClick={handleDeleteDiagnosis}
          title={title}
        />
        <DiagnosisFormFields
          item={diagnoses}
          diseaseOptions={diseaseOptions}
          handleOnchange={onDiagnoseFieldChange}
          errors={errors}
        />
      </Stack>
      <Dialog {...deleteDialogProps} />
    </>
  );
};
