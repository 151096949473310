import RelativeSelector from 'iPedigree/features/patient/consent/consentReminder/RelativeSelector';
import { useState } from 'react';
import { mockRelatives } from './mocks/relative-selector-data.mock';

export const RelativeSelectorWrapper = () => {
  const [error, setError] = useState(true);

  return (
    <RelativeSelector
      error={error}
      setError={setError}
      relatives={mockRelatives}
      onSelect={() => {}}
    />
  );
};
