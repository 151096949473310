import { Autocomplete, TextField } from '@mui/material';
import { InputWrapper } from 'components/inputs';
import React, { useMemo } from 'react';
import { useRelationshipOptions } from '../hooks/useRelationshipOptions';

interface Props {
  label: string;
  value: string;
  onChange: (newValue: string) => void;
  required?: boolean;
  error?: string;
  disabled?: boolean;
  placeholder?: string;
  optionsType?: 'allRelationship' | 'firstDregreeRelationship';
}

const RelationshipInput = ({
  label,
  value,
  onChange,
  error,
  required,
  disabled,
  placeholder,
  optionsType,
}: Props) => {
  const { relationshipOptions: options } = useRelationshipOptions({
    optionsType,
  });

  const randomId = React.useId();
  const id = `relationship-input-${randomId}`;

  const inputValue = useMemo(() => {
    return options.find((option) => option.value === value) || value;
  }, [options, value]);

  const handleOnChange = (_: any, newValue: any) => {
    if (!!newValue?.value) {
      return onChange(newValue.value);
    }
    return onChange(newValue);
  };

  const handleInputChange = (_: any, newInputValue: string) => {
    const isOption = options.some((option) => option?.label === newInputValue);
    if (!isOption) onChange(newInputValue);
  };

  return (
    <InputWrapper
      label={label}
      childId={id}
      required={required}
      error={error}
      fullWidth={true}
    >
      <Autocomplete
        id={id}
        value={inputValue}
        onChange={handleOnChange}
        onInputChange={handleInputChange}
        options={options}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            error={!!error}
            {...params}
            size="small"
            placeholder={placeholder}
          />
        )}
        freeSolo
      />
    </InputWrapper>
  );
};

export default RelationshipInput;
