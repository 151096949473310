import { Autocomplete as MuiAutocomplete, TextField } from '@mui/material';
import { InputWrapper } from 'components/inputs';
import React from 'react';

type AutoCompleteOption = {
  label: string;
  value: string;
};

type NewValue = AutoCompleteOption | AutoCompleteOption[] | null | string;

interface AutoCompleteInputProps {
  label: string;
  value: string | string[] | null;
  id: string;
  onChange?: (value: string | null) => void;
  onMultipleChange?: (value: string[]) => void;
  options: AutoCompleteOption[];
  autocompleteProps?: any;
  labelInfo?: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  error?: string;
  inlineLabel?: boolean;
  labelBefore?: boolean;
  fullWidth?: boolean;
  readOnlyInput?: boolean;
}

const AutoCompleteInput = ({
  label,
  value,
  id,
  onChange,
  onMultipleChange,
  options,
  autocompleteProps,
  labelInfo,
  placeholder,
  error,
  disabled = false,
  required = false,
  inlineLabel = false,
  labelBefore = false,
  fullWidth = true,
  readOnlyInput = false,
}: AutoCompleteInputProps) => {
  if (!onChange && !onMultipleChange)
    throw new Error('onChange or onMultipleChange must be defined');

  const randomId = React.useId();
  id = `${id}-${randomId}`;

  return (
    <InputWrapper
      label={label}
      childId={id}
      labelInfo={labelInfo}
      required={required}
      error={error}
      inlineLabel={inlineLabel}
      labelBefore={labelBefore}
      fullWidth={fullWidth}
    >
      <AutoComplete
        id={id}
        value={value}
        options={options}
        onChange={onChange}
        onMultipleChange={onMultipleChange}
        placeholder={placeholder}
        disabled={disabled}
        error={error}
        readOnlyInput={readOnlyInput}
        autocompleteProps={autocompleteProps}
      />
    </InputWrapper>
  );
};

interface AutoCompleteComponentProps {
  id: string;
  value: string | string[] | null;
  options: AutoCompleteOption[];
  onChange?: (value: string | null) => void;
  onMultipleChange?: (value: string[]) => void;
  placeholder?: string;
  disabled?: boolean;
  error?: string;
  readOnlyInput?: boolean;
  autocompleteProps?: any;
}

export const AutoComplete = ({
  id,
  value,
  options,
  onChange,
  onMultipleChange,
  placeholder,
  disabled,
  error,
  readOnlyInput,
  autocompleteProps,
}: AutoCompleteComponentProps) => {
  const isOptionEqualToValue = (
    option: AutoCompleteOption | string,
    value: string,
  ) => {
    if (typeof option === 'string') {
      return option === value;
    } else {
      return option.value === value;
    }
  };

  const getOptionLabel = (option: AutoCompleteOption | string) => {
    if (typeof option === 'string') {
      return options.find((el) => el.value === option)?.label;
    }
    return option.label;
  };

  const onChangeValue = (_: any, newValue: NewValue) => {
    const isMultiple = autocompleteProps?.multiple;
    const isArray = Array.isArray(newValue);

    if (isMultiple && onMultipleChange && isArray) {
      const values = newValue.map((el) => (el?.value || el) as string);
      return onMultipleChange(values || []);
    }

    if (onChange && !isArray && typeof newValue !== 'string') {
      onChange(newValue?.value || null);
    }
  };

  return (
    <MuiAutocomplete
      id={id}
      value={value}
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionLabel={getOptionLabel}
      onChange={onChangeValue}
      options={options}
      disabled={disabled}
      {...autocompleteProps}
      renderInput={(params) => (
        <TextField
          error={!!error}
          {...params}
          size="small"
          placeholder={placeholder}
          inputProps={{
            ...params.inputProps,
            readOnly: readOnlyInput,
          }}
        />
      )}
    />
  );
};

export default AutoCompleteInput;
