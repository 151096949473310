import { Stack } from '@mui/material';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PedigreeNodeAction } from '../../hooks/useHandleNodeAction';
import { usePedigreeContext } from '../../pedigreeContext';
import { SharedParentRelationshipType } from '../../types';
import Drawer from '../../views/Drawer';
import AddChildParentSelectionFormController from './AddChildParentSelectionFormController';
import ParentSelectionFormController from './ParentSelectionFormController';

export type SelectionParentParams = {
  action: PedigreeNodeAction;
  otherParentNodeId: string | null;
  pedigreeNodeId: string;
  sharedRelationshipType?: SharedParentRelationshipType;
};

type Content = 'addChild' | 'addHalfSibling' | 'changeParents' | null;
type Label =
  | 'son'
  | 'daughter'
  | 'half-brother'
  | 'half-sister'
  | 'unknown-child'
  | 'unknown-half-sibling'
  | null;

const CONTENT_MAP: Record<string, Content> = {
  addSon: 'addChild',
  addDaughter: 'addChild',
  addChildOfUnknownSex: 'addChild',
  addHalfBrother: 'addHalfSibling',
  addHalfSister: 'addHalfSibling',
  addHalfSiblingOfUnknownSex: 'addHalfSibling',
  changeParents: 'changeParents',
};

const RELATIVE_LABEL_MAP: Record<string, Label> = {
  addSon: 'son',
  addDaughter: 'daughter',
  addChildOfUnknownSex: 'unknown-child',
  addHalfBrother: 'half-brother',
  addHalfSister: 'half-sister',
  addHalfSiblingOfUnknownSex: 'unknown-half-sibling',
};

const ParentSelectionController = () => {
  const {
    pedigree,
    selectedNodeId,
    drawerState,
    setSelectedNodeId,
    setDrawerState,
    setHighlightedNodeIds,
  } = usePedigreeContext();
  const { t } = useTranslation('pedigree');

  const [formError, setFormError] = useState(false);
  const [showSelectParentForm, setShowSelectParentForm] =
    useState<boolean>(false);

  const { action, pedigreeNodeId } = drawerState || {};

  const content: Content = action ? CONTENT_MAP[action] : null;
  const relativeLabel: Label = action ? RELATIVE_LABEL_MAP[action] : null;

  const pedigreeNode = useMemo(() => {
    return pedigree?.nodes.find((node) => node.id === pedigreeNodeId);
  }, [pedigree?.nodes, pedigreeNodeId]);

  const parentLabel = pedigreeNode?.sex === 'male' ? 'mother' : 'father';

  const title = useMemo(() => {
    const selectParentTitle = t('node-select-parent-form.title.select-parent', {
      parent: t(`node-select-parent-form.parent.label.${parentLabel}`),
    });

    const addRelativeTitle = t('node-select-parent-form.title.add-relative', {
      relative: t(`node-select-parent-form.relative.${relativeLabel}`),
    });

    const changeParentsTitle = t('node-select-parent-form.title.change-parent');

    if (content === 'changeParents') {
      return changeParentsTitle;
    }

    if (showSelectParentForm && content === 'addChild') {
      return selectParentTitle;
    }

    return addRelativeTitle;
  }, [t, parentLabel, relativeLabel, content, showSelectParentForm]);

  const handleCloseForm = () => {
    setSelectedNodeId(null);
    setDrawerState(null);
    setHighlightedNodeIds(null);
  };

  const handleBackConfirmParentForm = () => {
    setShowSelectParentForm(false);
    setFormError(false);
    setHighlightedNodeIds(null);
    setSelectedNodeId(pedigreeNodeId || '');
  };

  return (
    <Drawer
      title={title}
      isOpen={!!selectedNodeId}
      onClose={handleCloseForm}
      showCloseButton={false}
    >
      <Stack paddingLeft={3} paddingRight={3} paddingTop={2}>
        {content === 'addChild' && (
          <AddChildParentSelectionFormController
            formError={formError}
            showSelectParentForm={showSelectParentForm}
            setShowSelectParentForm={setShowSelectParentForm}
            setFormError={setFormError}
            handleCloseForm={handleCloseForm}
            handleBackConfirmParentForm={handleBackConfirmParentForm}
            pedigreeNode={pedigreeNode}
          />
        )}
        {(content === 'addHalfSibling' || content === 'changeParents') && (
          <ParentSelectionFormController
            formError={formError}
            showSelectParentForm={showSelectParentForm}
            setShowSelectParentForm={setShowSelectParentForm}
            setFormError={setFormError}
            handleCloseForm={handleCloseForm}
            handleBackConfirmParentForm={handleBackConfirmParentForm}
            pedigreeNode={pedigreeNode}
          />
        )}
      </Stack>
    </Drawer>
  );
};

export default ParentSelectionController;
