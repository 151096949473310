import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, MenuItem, Typography } from '@mui/material';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import enLogo from '../../assets/images/en-icon.svg';
import seLogo from '../../assets/images/se-icon.svg';

export function LanguageSelector() {
  const { t, i18n } = useTranslation(['common']);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleLanguageChange = (lang: string) => {
    i18n.changeLanguage(lang);
    setOpen(false);
  };

  const currentLanguage = i18n.language === 'se' ? 'SE' : 'EN';
  const currentIcon = i18n.language === 'se' ? seLogo : enLogo;

  return (
    <>
      <Typography
        variant="body1"
        ref={anchorRef}
        onClick={() => setOpen(!open)}
        sx={{
          cursor: 'pointer',
          m: 2,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <img
          src={currentIcon}
          alt={currentLanguage}
          style={{ width: '24px' }}
        />
        <FontAwesomeIcon
          icon={faCaretDown}
          style={{
            marginLeft: '8px',
            transition: 'transform 0.3s ease',
            transform: open
              ? 'rotate(180deg) translateY(-2px)'
              : 'rotate(0deg) translateY(0)',
          }}
        />
      </Typography>
      <Menu
        open={open}
        anchorEl={anchorRef.current}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={() => handleLanguageChange('en')}>
          <img
            src={enLogo}
            alt="English"
            style={{ width: '24px', marginRight: '10px' }}
          />
          {t('language.choice.english')}
        </MenuItem>
        <MenuItem onClick={() => handleLanguageChange('se')}>
          <img
            src={seLogo}
            alt="Swedish"
            style={{ width: '24px', marginRight: '10px' }}
          />
          {t('language.choice.swedish')}
        </MenuItem>
      </Menu>
    </>
  );
}
