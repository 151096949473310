import { Palette, useTheme } from '@mui/material';
import { useMemo } from 'react';

type NodeState = {
  isIndex: boolean;
  hasCancerHistory: boolean;
  hasError: boolean;
  isSelected: boolean;
};

type usePedigreeNodeConsumerStylesReturnType = {
  iconColor: string;
  textColor?: string;
  backgroundColor: string;
  hoverBackgroundColor: string;
  borderColor?: string;
  hoverBorderColor?: string;
  borderWidth: number;
};

const usePedigreeNodeConsumerStyles = (
  state: NodeState,
): usePedigreeNodeConsumerStylesReturnType => {
  const theme = useTheme();
  const palette = theme.palette;

  const iconColor = useMemo(
    () => getIconColor(state, palette),
    [state, palette],
  );

  const textColor = useMemo(
    () => getTextColor(state, palette),
    [state, palette],
  );

  const backgroundColor = useMemo(
    () => getBackgroundColor(state, palette),
    [state, palette],
  );

  const hoverBackgroundColor = useMemo(
    () => getHoverBackgroundColor(state, palette),
    [state, palette],
  );

  const borderColor = useMemo(
    () => getBorderColor(state, palette),
    [state, palette],
  );

  const hoverBorderColor = useMemo(
    () => getHoverBorderColor(state, palette),
    [state, palette],
  );

  const borderWidth = useMemo(() => getBorderWidth(state), [state]);

  return {
    iconColor,
    textColor,
    backgroundColor,
    hoverBackgroundColor,
    borderColor,
    hoverBorderColor,
    borderWidth,
  };
};

const getIconColor = (state: NodeState, palette: Palette) => {
  const { isIndex } = state;

  if (isIndex) {
    return palette.common.white;
  }

  return palette.common.brand.gray;
};

const getTextColor = (
  state: NodeState,
  palette: Palette,
): string | undefined => {
  const { isIndex } = state;

  if (isIndex) return palette.common.white;
  return;
};

const getBackgroundColor = (state: NodeState, palette: Palette) => {
  const { isIndex, hasCancerHistory, hasError, isSelected } = state;

  if (isIndex) return palette.common.brand.blue;
  if (hasCancerHistory) return palette.common.brand.lightRose;
  if (!isIndex) return palette.common.white;
  if (hasError) {
    if (isSelected) return palette.error.light;
    return palette.error.main;
  }
  if (isSelected) return palette.common.brand.lightPurple;
  return palette.common.brand.blue;
};

const getHoverBackgroundColor = (state: NodeState, palette: Palette) => {
  const { isIndex, hasCancerHistory, hasError, isSelected } = state;

  if (hasCancerHistory) return palette.common.hover.beige;
  if (isIndex) {
    if (hasError) return palette.error.dark;
    return palette.common.hover.blue;
  }
  if (!hasError && !isSelected) return palette.common.brand.lightPurple;
  return palette.common.white;
};

const getBorderColor = (
  state: NodeState,
  palette: Palette,
): string | undefined => {
  const { hasError, isSelected } = state;

  if (hasError) return palette.error.main;
  if (isSelected) return palette.common.brand.blue;
  return;
};

const getHoverBorderColor = (
  state: NodeState,
  palette: Palette,
): string | undefined => {
  const { hasError } = state;
  if (hasError) return palette.error.dark;
  return;
};

const getBorderWidth = (state: NodeState): number => {
  const { isSelected, hasError } = state;
  return isSelected ? 3 : hasError ? 2 : 0;
};

export default usePedigreeNodeConsumerStyles;
