import { WidgetProps } from '@rjsf/utils';
import { TextInput } from 'components/inputs';
import { ChangeEvent, useCallback } from 'react';
import { ReadonlyView } from '../views/ReadonlyView';

export const TextWidget = (props: WidgetProps) => {
  const label = props.uiSchema?.['ui:title'] || props.label;
  const inputWidth = props.uiSchema?.['ui:inputWidth'] || '100%';
  const onChange = props.onChange;
  const inputFieldType = props.options.inputType === 'tel' ? 'tel' : 'text';
  const variant = props.uiSchema?.['ui:variant'] || 'body1';

  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      if (value === '') {
        onChange(undefined);
      } else if (inputFieldType === 'tel') {
        handlePhoneValueChange(value, onChange);
      } else {
        onChange(value);
      }
    },
    [onChange, inputFieldType],
  );

  if (props.readonly) {
    return <ReadonlyView title={label} value={props.value} variant={variant} />;
  }

  return (
    <TextInput
      value={props.value || ''}
      onChange={handleOnChange}
      id={props.name}
      label={label}
      placeholder={props.placeholder}
      textFieldProps={{ style: { width: inputWidth } }}
      type={inputFieldType}
      required={props.required}
    />
  );
};

const handlePhoneValueChange = (
  value: string,
  onChange: (value: string) => void,
) => {
  const phoneValue = value.replace(/[^0-9]/g, '');
  onChange(phoneValue);
};
