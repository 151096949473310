import { Button, Fade, Stack, Typography } from '@mui/material';
import { useAuthService } from 'api/auth/AuthServicesProvider';
import { useNotificationContext } from 'common/contexts/NotificationProvider';
import AuthLayout from 'components/common/AuthLayout';
import PasswordInput from 'components/inputs/PasswordInput';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { usePasswordStrength } from '../passwordStrenght/hooks/usePasswordStrength';
import { PasswordStrength } from '../passwordStrenght/PasswordStrength';

interface CreateNewPasswordProps {
  token: string;
  handleTokenValidation: (valid: boolean) => void;
}

export const CreateNewPassword = (props: CreateNewPasswordProps) => {
  const service = useAuthService();
  const navigate = useNavigate();
  const { setNotification } = useNotificationContext();
  const { t } = useTranslation(['passwordSettings', 'common']);

  const [
    requestedValidatePasswordResetToken,
    setRequestedValidatePasswordResetToken,
  ] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [showPasswordStrength, setShowPasswordStrength] =
    useState<boolean>(false);

  const { passwordValidation } = usePasswordStrength(password);

  const createNewPasswordMutation = useMutation(
    (params: { newPassword: string; token: string }) =>
      service.resetPassword(params.newPassword, params.token),
    {
      onSuccess: () => {
        navigate('/');
        return setNotification({
          message: t('create-new-password.success-message'),
          type: 'toast',
        });
      },
      onError: () => {
        return setNotification({
          message: t('common:message.error'),
          type: 'error',
        });
      },
    },
  );

  const validatePasswordResetTokenMutation = useMutation(
    () => service.validatePasswordResetToken(props.token),
    {
      onSuccess: (data) => {
        props.handleTokenValidation(data.valid);
      },
    },
  );

  const handleCreateNewPassword = () => {
    if (passwordValidation.isValid) {
      return createNewPasswordMutation.mutate({
        newPassword: password,
        token: props.token || '',
      });
    }
  };

  const maxLengthError = useMemo(() => {
    const { satisfiedPasswordCriteria } = passwordValidation;

    if (!satisfiedPasswordCriteria.includes('maxLength')) {
      return t('create-new-password.password-input.error.max-length');
    }
    return '';
  }, [passwordValidation, t]);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPassword(value);
  };

  const handleOnFocus = () => {
    setShowPasswordStrength(true);
  };

  const handleOnBlur = () => {
    if (!password) {
      setShowPasswordStrength(false);
    }
  };

  useEffect(() => {
    if (!requestedValidatePasswordResetToken) {
      validatePasswordResetTokenMutation.mutate();
      setRequestedValidatePasswordResetToken(true);
    }
  }, [requestedValidatePasswordResetToken, validatePasswordResetTokenMutation]);

  return (
    <AuthLayout>
      <Stack direction="column" spacing={3} sx={{ minWidth: 416 }}>
        <Typography variant="h3">{t('create-new-password.title')}</Typography>
        <Typography variant="body1">
          {t('create-new-password.description')}
        </Typography>
        <PasswordInput
          label={t('create-new-password.password-input.label')}
          disabled={createNewPasswordMutation.isLoading}
          error={maxLengthError}
          handleOnChange={handleOnChange}
          handleOnFocus={handleOnFocus}
          handleOnBlur={handleOnBlur}
        />
        {showPasswordStrength && (
          <Fade in={showPasswordStrength} timeout={250}>
            <Stack>
              <PasswordStrength
                password={password}
                passwordValidation={passwordValidation}
              />
            </Stack>
          </Fade>
        )}
        <Button
          variant="contained"
          onClick={handleCreateNewPassword}
          disabled={!passwordValidation.isValid}
        >
          {t('create-new-password.update-button')}
        </Button>
      </Stack>
    </AuthLayout>
  );
};
