import { Box, Grid, Stack, Typography } from '@mui/material';
import { formatPersonalNumber } from 'common/utils';
import { SectionBox } from 'components/common';
import { TextInput } from 'components/inputs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export type PatientData = {
  personalNumber: string;
  name: string;
  phoneNumber: string;
  email: string;
};

export type PatientDataErrors = {
  [key in keyof PatientData]?: string | undefined;
};

interface PatientFormProps {
  patientData: PatientData;
  setPatientData: (data: PatientData) => void;
  errors: PatientDataErrors;
  setErrors: (errors: PatientDataErrors) => void;
  consentFor: string;
  localizationKeyPrefix?: 'request-consent' | 'send-consent-reminder';
}

export const PatientForm = (props: PatientFormProps) => {
  const { patientData, setPatientData, errors, setErrors, consentFor } = props;
  const { t } = useTranslation(['consent', 'common']);

  const localizationKeyPrefix =
    props.localizationKeyPrefix ?? 'request-consent';

  const handleTextInputChangeFactory =
    (key: keyof PatientData) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setPatientData({ ...patientData, [key]: event.target.value });
      setErrors({ ...errors, [key]: '' });
    };

  const hasPhoneNumberOrEmail = useMemo(() => {
    return patientData.email || patientData.phoneNumber;
  }, [patientData.email, patientData.phoneNumber]);

  const phoneNumberError = useMemo(() => {
    if (errors.phoneNumber?.startsWith('phoneNumber')) {
      return t(`input-validation.${errors.phoneNumber}`);
    }
    return errors.phoneNumber;
  }, [errors.phoneNumber, t]);

  const emailError = useMemo(() => {
    if (!hasPhoneNumberOrEmail) {
      return phoneNumberError;
    }

    if (errors.email) {
      return t(`input-validation.${errors.email}`);
    }
  }, [errors.email, hasPhoneNumberOrEmail, phoneNumberError, t]);

  const personalNumberValue = useMemo(() => {
    return formatPersonalNumber(patientData.personalNumber);
  }, [patientData.personalNumber]);

  return (
    <SectionBox>
      <Stack gap={4}>
        <Typography variant="h2">
          {t(`${localizationKeyPrefix}.section1.title`)}
        </Typography>
        <Stack gap={1}>
          <Typography variant="h4">{patientData.name}</Typography>
          <Typography variant="body1">{`${t(
            'common:personal-number',
          )}: ${personalNumberValue}`}</Typography>
        </Stack>
        <Box>
          <Grid
            container
            justifyContent="center"
            alignItems="stretch"
            spacing={3}
          >
            <Grid xs={12} md={6} item>
              <Stack direction="row" alignItems="center">
                <TextInput
                  label={t(`${localizationKeyPrefix}.section1.phoneNumber`)}
                  id="phoneNumber"
                  value={patientData.phoneNumber}
                  onChange={handleTextInputChangeFactory('phoneNumber')}
                  error={phoneNumberError}
                  textFieldProps={{
                    helperText: !phoneNumberError
                      ? t(
                          `${localizationKeyPrefix}.section1.phoneNumber.helper-text`,
                        )
                      : '',
                    placeholder: '07XXXXXXXX',
                    inputProps: {
                      inputMode: 'numeric',
                    },
                  }}
                />
                <Typography pt={phoneNumberError ? 0 : 1} pl={3} variant="h5">
                  {t(`${localizationKeyPrefix}.section1.or`)}
                </Typography>
              </Stack>
            </Grid>
            <Grid xs={12} md={6} item>
              <TextInput
                label={t(`${localizationKeyPrefix}.section1.email`)}
                id="email"
                value={patientData.email}
                onChange={handleTextInputChangeFactory('email')}
                error={emailError}
                showErrorText={!!patientData.email}
              />
            </Grid>
            <Grid xs={12} item>
              <Stack direction="column" spacing={0.5}>
                <Typography variant="body1">
                  {t(`${localizationKeyPrefix}.section1.consentFor`)}
                </Typography>

                <Typography variant="body1">{consentFor}</Typography>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </SectionBox>
  );
};
