import { Typography } from '@mui/material';
import {
  ConsentListItem,
  ListedConsentRequest,
} from 'api/consentManagement/consent-management-service';
import LoadingBackdrop from 'components/common/LoadingBackdrop';
import {
  CollapsibleRowType,
  HiddenRow,
  TableCellWithCollapsedBox,
} from 'components/tables/CollapsibleRow';
import ConsentStatusCell from 'components/tables/ConsentStatusCell';
import { useRelationshipOptions } from 'features/common/hooks/useRelationshipOptions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useConsentActions from '../hooks/useConsentActions';
import useSendReminderClickHandler from '../hooks/useSendReminderHandle';
import { getConsentDate } from '../utils';
import ConsentRequestCardView from '../views/ConsentRequestCardView';
import TableRowCellWithToggleButtonView from '../views/TableRowCellWithToggleButtonView';

interface Props {
  consentRequest: ListedConsentRequest;
  onCompleteClick: (consentRequestId: string) => void;
}

const ConsentRequestCardController: React.FC<Props> = (props) => {
  const { t } = useTranslation('all');
  const navigate = useNavigate();
  const { getSendReminderClickHandler } = useSendReminderClickHandler();

  const headings = [
    t('consent-request-list-item.table.heading.relative-name'),
    t('consent-request-list-item.table.heading.relationship'),
    t('consent-request-list-item.table.heading.deceased'),
    t('consent-request-list-item.content.table-head.consent-status'),
    t('consent-request-list-item.table.heading.date'),
    t('consent-request-list-item.content.table-head.actions'),
  ];

  const showActionButtons = props.consentRequest.completedAt === null;
  const showShortDateOnly = props.consentRequest.completedAt !== null;
  const { mapConsentTableRow, isMutating } =
    useMapRelativeTableRow(showShortDateOnly);

  const handleAddRelativeClick = () => {
    navigate(`${props.consentRequest.consentRequestId}/add-relative/`);
  };

  const handleSendReminderClick = getSendReminderClickHandler(
    props.consentRequest,
  );

  const createdAt = props.consentRequest.consentRequestCreatedAt;
  const completedAt = props.consentRequest.completedAt || undefined;

  return (
    <>
      {isMutating && <LoadingBackdrop />}
      <ConsentRequestCardView
        patientName={props.consentRequest.patientName}
        patientPersonalNumber={props.consentRequest.patientPersonalNumber}
        createdAt={createdAt}
        completedAt={completedAt}
        onAddRelativeClick={
          showActionButtons ? handleAddRelativeClick : undefined
        }
        onCompleteClick={
          showActionButtons
            ? () =>
                props.onCompleteClick?.(props.consentRequest.consentRequestId)
            : undefined
        }
        onSendReminderClick={
          showActionButtons ? handleSendReminderClick : undefined
        }
        relativeTableHeadings={headings}
        relativeTableRows={props.consentRequest.consents.map(
          mapConsentTableRow,
        )}
      />
    </>
  );
};

export default ConsentRequestCardController;

const useMapRelativeTableRow = (showShortDateOnly: boolean) => {
  const { translateRelationship } = useRelationshipOptions();
  const [openRow, setOpenRow] = React.useState<number | null>(null);
  const { getConsentActions, isMutating } = useConsentActions();
  const { t, i18n } = useTranslation('all');

  const mapConsentTableRow = (
    consent: ConsentListItem,
    idx: number,
  ): CollapsibleRowType => {
    const hasHiddenRows = consent.deceased;
    const isOpen = openRow === idx;
    let firstCell: React.ReactNode = consent.relativeName;
    if (hasHiddenRows) {
      firstCell = (
        <TableRowCellWithToggleButtonView
          isOpenRow={isOpen}
          idx={idx}
          setOpenRow={setOpenRow}
          name={consent.relativeName}
        />
      );
    }
    const hiddenRows: HiddenRow[] = [];
    if (hasHiddenRows) {
      let cellContent: React.ReactNode = t(
        'consent-management.consent-card.deceased-row.any-first-degree',
      );
      if (consent.proxySignerToConsenteeRelationship) {
        cellContent = (
          <>
            <b>
              {t(
                'consent-management.consent-card.deceased-row.relative-to-deceased',
              )}
            </b>
            {` ${consent.proxySignerName} (${translateRelationship(
              consent.proxySignerToConsenteeRelationship,
            )})`}
          </>
        );
      }
      hiddenRows.push({
        cells: [
          <TableCellWithCollapsedBox
            key="hidden-cells-first-row-box"
            colSpan={5}
            sx={{ textAlign: 'left' }}
          >
            <Typography variant="body2">{cellContent}</Typography>
          </TableCellWithCollapsedBox>,
          <TableCellWithCollapsedBox key="hidden-cells-second-row-box" />,
        ],
      });
    }

    return {
      cells: [
        firstCell,
        translateRelationship(consent.relationshipToPatient),
        consent.deceased ? t('yes') : t('no'),
        <ConsentStatusCell consentStatus={consent.consentStatus} />,
        getConsentDate(consent, showShortDateOnly, i18n),
        getConsentActions(consent),
      ],
      hiddenRows,
      isOpen,
    };
  };

  return {
    mapConsentTableRow,
    isMutating,
  };
};
