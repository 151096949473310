import {
  faCaretDown,
  faCaretUp,
  faPen,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { ConsentStatusView } from 'iPedigree/common/ConsentStatus';
import DateView from 'iPedigree/common/DateView';
import { RelativeRelation } from 'iPedigree/common/RelativeRelation';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ConsentStatus } from 'types';

interface Consent {
  consentId: string;
  relative: string;
  relation?: string;
  personalNumber?: string;
  deceased: boolean;
  deceasedSigner?: {
    name: string;
    relation: string;
    personalNumber?: string;
  };
  consentStatus: ConsentStatus;
  date: string;
  actions: React.ReactNode;
}

const ExpandedDetails: React.FC<{ consent: Consent; consentFor: string }> = ({
  consent,
  consentFor,
}) => {
  const { t } = useTranslation('consent');
  const navigate = useNavigate();
  const location = useLocation();

  const editDeceasedRelativeSignerOnClick = () => {
    const { deceasedSigner, personalNumber, relative, relation, consentId } =
      consent;

    return navigate(`${location.pathname}/edit-relative-deceased`, {
      state: {
        consent: {
          relative: relative,
          relation: relation,
          consentId: consentId,
          consentFor: consentFor,
          personalNumber: personalNumber,
          proxySignerName: deceasedSigner?.name,
          proxySignerToConsenteeRelationship: deceasedSigner?.relation,
          proxySignerPersonalNumber: deceasedSigner?.personalNumber,
        },
      },
    });
  };

  return (
    <Box sx={{ py: 2 }}>
      <Typography variant="h5" gutterBottom component="span">
        {t('patient-consents-table.expanded-details.relative-of-deceased')}
      </Typography>{' '}
      <Typography gutterBottom component="span">
        <>
          {consent.deceasedSigner ? (
            <DeceasedSigner
              name={consent.deceasedSigner.name}
              relation={consent.deceasedSigner.relation}
            />
          ) : (
            t('patient-consents-table.expanded-details.first-degree-relative')
          )}
          <Tooltip
            title={t('patient-consents-table.expanded-details.edit')}
            placement="bottom"
            arrow
            sx={{ ml: 1 }}
          >
            <IconButton
              color="primary"
              size="small"
              onClick={editDeceasedRelativeSignerOnClick}
            >
              <FontAwesomeIcon icon={faPen} />
            </IconButton>
          </Tooltip>
        </>
      </Typography>
    </Box>
  );
};

const PatientConsentsTable: React.FC<{
  consents: Consent[];
  consentFor: string;
}> = ({ consents, consentFor }) => {
  const [expandedRow, setExpandedRow] = useState<number | null>(null);
  const { t } = useTranslation(['consent', 'common']);

  const handleExpandClick = (index: number) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  const renderTableRow = (consent: Consent, index: number) => (
    <React.Fragment key={index}>
      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell padding="checkbox" sx={{ borderBottom: 'none' }}>
          {consent.deceased && (
            <IconButton
              onClick={() => handleExpandClick(index)}
              sx={{
                color: 'primary.main',
                height: 24,
                width: 24,
              }}
            >
              <FontAwesomeIcon
                icon={expandedRow === index ? faCaretUp : faCaretDown}
              />
            </IconButton>
          )}
        </TableCell>
        <TableCell component="th" scope="row" sx={{ borderBottom: 'none' }}>
          {consent.relative}
        </TableCell>
        <TableCell sx={{ borderBottom: 'none' }}>
          {consent.relation && <RelativeRelation relation={consent.relation} />}
        </TableCell>
        <TableCell sx={{ borderBottom: 'none' }}>
          {consent.deceased ? t('common:yes') : t('common:no')}
        </TableCell>
        <TableCell sx={{ borderBottom: 'none' }}>
          <ConsentStatusView status={consent.consentStatus} />
        </TableCell>
        <TableCell sx={{ borderBottom: 'none' }}>
          <DateView isoDate={consent.date} />
        </TableCell>
        <TableCell sx={{ borderBottom: 'none', padding: 0.5 }}>
          {consent.actions}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, borderTop: 'none' }}
        />
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, borderTop: 'none' }}
          colSpan={6}
        >
          <Collapse in={expandedRow === index} timeout="auto" unmountOnExit>
            <ExpandedDetails consent={consent} consentFor={consentFor} />
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );

  return (
    <Table sx={{ minWidth: 650 }} aria-label="patient consent table">
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox" />
          <TableCell>
            {t('patient-consents-table.table.heading.relative-name')}
          </TableCell>
          <TableCell>
            {t('patient-consents-table.table.heading.relation')}
          </TableCell>
          <TableCell>
            {t('patient-consents-table.table.heading.deceased')}
          </TableCell>
          <TableCell>
            {t('patient-consents-table.table.heading.consent-status')}
          </TableCell>
          <TableCell>
            {t('patient-consents-table.table.heading.date')}
          </TableCell>
          <TableCell>
            {t('patient-consents-table.table.heading.actions')}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>{consents.map(renderTableRow)}</TableBody>
    </Table>
  );
};

const DeceasedSigner = ({
  name,
  relation,
}: {
  name?: string;
  relation: string;
}) => {
  if (name) {
    return (
      <>
        {name}, {''}
        <RelativeRelation relation={relation} lowercase />
      </>
    );
  }

  return <>({<RelativeRelation relation={relation} />})</>;
};

export default PatientConsentsTable;
