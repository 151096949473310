import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Stack, Typography, useTheme } from '@mui/material';
import {
  EligibilityStatus,
  SatisfiedCriteria,
} from 'api/patient/staff-patient-service';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EligibilityCriteriaList } from './EligibilityCriteriaList';

interface EligibilityResultsContentProps {
  criteriaType: string;
  eligibilityStatus: EligibilityStatus;
  satisfiedCriteria: SatisfiedCriteria[];
  footnotes: { id: number; description: string }[];
  hasMultipleResults: boolean;
}

export const EligibilityResultsContent = (
  props: EligibilityResultsContentProps,
) => {
  const {
    criteriaType,
    eligibilityStatus,
    satisfiedCriteria,
    footnotes,
    hasMultipleResults,
  } = props;

  const theme = useTheme();
  const [showMore, setShowMore] = useState<boolean>(false);

  const hasFootnotes = footnotes?.length > 0;
  const singleCriteria = satisfiedCriteria?.length === 1;
  const multipleCriteria = satisfiedCriteria?.length > 1;
  const isEligible = eligibilityStatus === 'ELIGIBLE';
  const isNotEligible = eligibilityStatus === 'NOT_ELIGIBLE';

  const showFooterNotes = hasFootnotes && (showMore || singleCriteria);

  const shouldApplyGap = showMore || isEligible || !hasMultipleResults;
  const gap = shouldApplyGap ? 2 : 0;

  const style = !isNotEligible
    ? {
        borderLeft: 1,
        borderColor: theme.palette.common.opacity.darkBlue[25],
        pl: 2,
      }
    : {};

  return (
    <Stack gap={2}>
      <EligibilityStatusText
        criteriaType={criteriaType}
        eligibilityStatus={eligibilityStatus}
      />
      <Stack
        gap={gap}
        sx={{
          ...style,
        }}
      >
        <Stack>
          <EligibilityCriteriaList
            satisfiedCriteria={satisfiedCriteria}
            eligibilityStatus={eligibilityStatus}
            showMore={showMore}
            hasMultipleResults={hasMultipleResults}
          />
          {showFooterNotes && (
            <EligibilityFootnotesList footnotes={footnotes} />
          )}
        </Stack>
        {multipleCriteria && (
          <ShowMoreButton
            showMore={showMore}
            onClick={() => setShowMore(!showMore)}
          />
        )}
      </Stack>
    </Stack>
  );
};

const EligibilityStatusText = ({
  criteriaType,
  eligibilityStatus,
}: {
  criteriaType: string;
  eligibilityStatus: EligibilityStatus;
}) => {
  const { t } = useTranslation(['iPedigree']);

  const eligibilityLabel =
    'page-patient-eligibility-genetic-testing.eligibility-status';

  const text = useMemo(() => {
    const labels = {
      ELIGIBLE: `${eligibilityLabel}.eligible`,
      POTENTIALLY_ELIGIBLE: `${eligibilityLabel}.potentially-eligible`,
      NOT_ELIGIBLE: `${eligibilityLabel}.not-eligible`,
    };
    return t(labels[eligibilityStatus], { criteriaType });
  }, [criteriaType, eligibilityStatus, t]);

  return <Typography variant="h5">{text}</Typography>;
};

const EligibilityFootnotesList = ({
  footnotes,
}: {
  footnotes: { id: number; description: string }[];
}) => {
  return (
    <Stack pt="12px">
      {footnotes?.map(({ id, description }, index) => (
        <Typography
          key={index}
          variant="caption"
        >{`${id} ${description}`}</Typography>
      ))}
    </Stack>
  );
};

const ShowMoreButton = ({
  showMore,
  onClick,
}: {
  showMore: boolean;
  onClick: () => void;
}) => {
  const { t } = useTranslation(['iPedigree']);

  const icon = showMore ? (
    <FontAwesomeIcon icon={faCaretUp} />
  ) : (
    <FontAwesomeIcon icon={faCaretDown} />
  );

  return (
    <Button
      onClick={onClick}
      endIcon={icon}
      variant="text"
      size="small"
      sx={{ width: 'fit-content' }}
    >
      {showMore ? t('common:button.show-less') : t('common:button.show-more')}
    </Button>
  );
};
