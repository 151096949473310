import { Typography } from '@mui/material';
import { FieldTemplateProps } from '@rjsf/utils';

export const FieldTemplate = (props: FieldTemplateProps) => {
  const { help, description, errors, children } = props;
  const helpWhiteSpace = props.uiSchema?.['ui:helpWhiteSpace'] ?? 'normal';

  if (props.readonly) {
    return <>{children}</>;
  }

  return (
    <>
      {children}
      <Typography variant="caption" color="textSecondary">
        {description}
      </Typography>
      <Typography
        variant="caption"
        color="textSecondary"
        sx={{
          whiteSpace: helpWhiteSpace,
        }}
      >
        {help}
      </Typography>
      {errors}
    </>
  );
};
