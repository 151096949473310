import { i18n } from 'i18next';
import { DateTime } from 'luxon';

export const getCurrentDate = () => {
  const date = new Date().toISOString();
  const targetDate = DateTime.fromISO(date);
  const locale = targetDate.toLocaleString(DateTime.DATE_SHORT, {
    locale: 'sv',
  });
  return locale.replace(/-/g, '.');
};

export const calculateAgeFromDateOfBirth = (dateOfBirth: string) => {
  let birthDateTime: DateTime = DateTime.fromISO(dateOfBirth);
  const currentDate = DateTime.now();
  const age = currentDate.diff(birthDateTime, 'years').years;
  return Math.abs(Math.floor(age));
};

export const formatPersonalNumber = (personalNumber?: string): string => {
  if (!personalNumber) return '';
  const birthDate = personalNumber.slice(0, 8);
  const lastFourDigits = personalNumber.slice(8);
  return `${birthDate}-${lastFourDigits}`;
};

export const capitalizeFirstLetter = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const isoToRelativeDate = (date: string, i18n: i18n) => {
  const targetDate = DateTime.fromISO(date);
  return targetDate.toRelative({ locale: getLocaleForDateTime(i18n) });
};

export const isoToShortDate = (date: string, i18n: i18n) => {
  const targetDate = DateTime.fromISO(date);
  const locale = targetDate.toLocaleString(DateTime.DATE_SHORT, {
    locale: 'sv',
  });

  return locale.replace(/-/g, '.');
};

export const getLocaleForDateTime = (i18n: i18n) => {
  const currentLocale = i18n.language;
  if (currentLocale === 'se') {
    return 'sv';
  }
  return 'en-GB';
};
