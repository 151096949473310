import {
  Card,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
} from '@mui/material';
import { isoToShortDate } from 'common/utils';
import { useTranslation } from 'react-i18next';
import { Filter } from '../../hooks/usePedigreeFilter';

interface NodeDisplayFilterProps {
  receivedAt?: string;
  filters: Record<string, boolean>;
  setFilterValue: (filter: Filter) => void;
}

export const NodeDisplayFilter = (props: NodeDisplayFilterProps) => {
  const { t, i18n } = useTranslation('pedigree');

  return (
    <Card
      id="node-display-filter"
      elevation={3}
      sx={{
        p: 2,
      }}
    >
      <Typography variant="h6">{t('pedigree-filter-label')}:</Typography>
      <FormGroup sx={{ paddingLeft: 1 }}>
        {Object.keys(props.filters).map((filterName: string, index: number) => (
          <FormControlLabel
            key={index}
            sx={{ height: 36, fontSize: '12px' }}
            disableTypography
            control={
              <Checkbox
                checked={props.filters[filterName]}
                size="small"
                onChange={() =>
                  props.setFilterValue({
                    [filterName]: !props.filters[filterName],
                  })
                }
              />
            }
            label={t(`pedigree-filter-${filterName}-label`)}
          />
        ))}
      </FormGroup>
      {props.receivedAt && (
        <Stack mt={2}>
          <Typography
            variant="caption"
            sx={{
              lineHeight: '16px',
            }}
          >
            {t('pedigree-received-at-label', {
              date: isoToShortDate(props.receivedAt, i18n),
            })}
          </Typography>
        </Stack>
      )}
    </Card>
  );
};
