import { AppBar, Container, Toolbar, Typography } from '@mui/material';
import { LanguageSelector } from 'common/layouts/LanguageSelector';
import { Link, Outlet } from 'react-router-dom';

function Root() {
  return (
    <>
      <AppBar position="static">
        <Toolbar>
          {[
            { to: '/', label: 'Home' },
            { to: '/pedigree', label: 'Pedigree' },
            { to: '/dynamic-form', label: 'Dynamic Form' },
            {
              to: '/notification-is-sent-confirmation',
              label: 'Notification Is Sent Confirmation',
            },
            { to: '/patient-consents', label: 'Patient Consents' },
            {
              to: '/dashboard-consent-table',
              label: 'Dashboard Consent Table',
            },
          ].map(({ to, label }) => (
            <Link key={to} to={to} style={{ textDecoration: 'none' }}>
              <Typography variant="h4" color="white" p={3}>
                {label}
              </Typography>
            </Link>
          ))}
          <LanguageSelector />
        </Toolbar>
      </AppBar>
      <Container sx={{ py: 3 }}>
        <Outlet />
      </Container>
    </>
  );
}

export default Root;
