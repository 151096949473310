import {
  faCheck,
  faSitemap,
  faUsers,
  faVial,
} from '@fortawesome/free-solid-svg-icons';
import { Stack } from '@mui/material';
import { useConsentManagementService } from 'api/consentManagement/ConsentManagementServiceProvider';
import { useStaffPedigreeCaseService } from 'api/pedigreeCase/staff-pedigree-case-service';
import { useUserContext } from 'common/contexts/UserProvider';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import Tab from './Tab';

export type TabIdentifier =
  | 'pedigreeList'
  | 'patientList'
  | 'consent'
  | 'eligibility';

interface TabMenuProps {
  currentTab: TabIdentifier;
  handleChange: (newTab: TabIdentifier) => void;
}

function TabMenu(props: TabMenuProps) {
  const service = useStaffPedigreeCaseService();
  const { selectedUser } = useUserContext();
  const forUserId = selectedUser?.id ? `${selectedUser?.id}` : undefined;
  const consentManagementService = useConsentManagementService();
  const { t } = useTranslation('iPedigree');

  const { data: pedigreeStats } = useQuery(['pedigreeStats', forUserId], () =>
    service.getPedigreeStats({
      forUserId,
    }),
  );

  const { data: consentStats } = useQuery(['consentStats', forUserId], () =>
    consentManagementService.getConsentStats({
      forUserId,
    }),
  );

  return (
    <Stack spacing={3} pt={6} minWidth={250}>
      <Tab
        title={t('page-dashboard.tab.pedigree.title')}
        onClick={() => {
          props.handleChange('pedigreeList');
        }}
        icon={faSitemap}
        data={[
          {
            value: pedigreeStats?.receivedNotViewedCount ?? 0,
            label: t('page-dashboard.tab.pedigree.message.new'),
          },
          {
            value: pedigreeStats?.requestedNotReceivedCount ?? 0,
            label: t('page-dashboard.tab.pedigree.message.requested'),
          },
        ]}
        selected={props.currentTab === 'pedigreeList'}
      />
      <Tab
        title={t('page-dashboard.tab.consent.title')}
        icon={faCheck}
        onClick={() => {
          props.handleChange('consent');
        }}
        data={[
          {
            value: consentStats?.new ?? 0,
            label: t('page-dashboard.tab.consent.message.new'),
          },
          {
            value: consentStats?.pending ?? 0,
            label: t('page-dashboard.tab.consent.message.pending'),
          },
        ]}
        selected={props.currentTab === 'consent'}
      />
      <Tab
        title={t('page-dashboard.tab.eligible.title')}
        icon={faVial}
        data={[
          {
            value: 0,
            label: t('page-dashboard.tab.eligible.message.eligible'),
          },
          {
            value: 0,
            label: t('page-dashboard.tab.eligible.message.confirm'),
          },
        ]}
        selected={props.currentTab === 'eligibility'}
      />
      <Tab
        title={t('page-dashboard.tab.patient.title')}
        onClick={() => {
          props.handleChange('patientList');
        }}
        icon={faUsers}
        data={[
          {
            value: pedigreeStats?.totalCount ?? 0,
            label: t('page-dashboard.tab.patient.message.active'),
          },
        ]}
        selected={props.currentTab === 'patientList'}
      />
    </Stack>
  );
}

export default TabMenu;
