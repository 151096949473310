import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material';
import { ConsentStatusView } from 'iPedigree/common/ConsentStatus';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ConsentStatus } from 'types';
import DateView from '../../../common/DateView';
import NewTagView from './NewTagView';
import PersonalNumberView from './PersonalNumberView';
import { TableLoading } from './TableLoading';

export interface Consent {
  id: string;
  isNew: boolean;
  relative: string;
  patient: string;
  patientUrl: string;
  patientPersonalNumber: string;
  status: ConsentStatus;
  date: string;
  actions: React.ReactNode;
}

interface ConsentsTableProps {
  consents: Consent[];
  isLoading: boolean;
}

const ConsentsTable: React.FC<ConsentsTableProps> = ({
  consents,
  isLoading,
}) => {
  const { t } = useTranslation('consent');

  return (
    <TableContainer>
      <Table aria-label="consents table">
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox" />
            <TableCell>{t('page-dashboard.table.header.relative')}</TableCell>
            <TableCell>{t('page-dashboard.table.header.patient')}</TableCell>
            <TableCell>
              {t('page-dashboard.table.header.personalNumber')}
            </TableCell>
            <TableCell>
              {t('page-dashboard.table.header.consentStatus')}
            </TableCell>
            <TableCell>{t('page-dashboard.table.header.date')}</TableCell>
            <TableCell>{t('page-dashboard.table.header.actions')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading && <TableLoading colSpan={6} />}
          {!isLoading && <ConsentListTable consents={consents} />}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const ConsentListTable = ({ consents }: { consents: Consent[] }) => {
  const theme = useTheme();

  return (
    <>
      {consents.map((consent) => (
        <TableRow key={consent.id}>
          <TableCell
            sx={{
              padding: 0,
              paddingLeft: 2,
            }}
          >
            {consent.isNew && <NewTagView />}
          </TableCell>
          <TableCell>{consent.relative}</TableCell>
          <TableCell>
            <Link
              to={consent.patientUrl}
              style={{
                color: theme.palette.primary.main,
                textDecoration: 'none',
              }}
            >
              {consent.patient}
            </Link>
          </TableCell>
          <TableCell>
            <PersonalNumberView
              personalNumber={consent.patientPersonalNumber}
            />
          </TableCell>
          <TableCell>
            <ConsentStatusView status={consent.status} />
          </TableCell>
          <TableCell>
            <DateView isoDate={consent.date} />
          </TableCell>
          <TableCell sx={{ padding: 0.5 }}>{consent.actions}</TableCell>
        </TableRow>
      ))}
    </>
  );
};

export default ConsentsTable;
