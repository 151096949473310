import { ThemeProvider } from '@mui/material/styles';
import { IPedigreeRouter } from 'iPedigree/routes';
import MyFamilyTreeRouter from 'myfamilytree/routes/MyFamilyTreeRouter';
import PlaygroundRouter from 'playground/Router';
import { QueryClient, QueryClientProvider } from 'react-query';
import { EConsentRouter } from 'routes/EConsentRouter';
import theme from './theme';

const queryClient = new QueryClient();
const subdomain = window.location.hostname.split('.')[0];
const appToRender_env = process.env.REACT_APP_TO_RENDER;

const getAppToRender = () => {
  if (subdomain === 'my-family-tree') {
    return 'my-family-tree';
  }
  if (subdomain === 'myfamilytree') {
    return 'my-family-tree';
  }
  if (subdomain === 'econsent') {
    return 'e-consent';
  }
  if (subdomain === 'ipedigree') {
    return 'i-pedigree';
  }

  if (appToRender_env === 'e-consent') {
    return 'e-consent';
  }
  if (appToRender_env === 'my-family-tree') {
    return 'my-family-tree';
  }
  if (appToRender_env === 'i-pedigree') {
    return 'i-pedigree';
  }
  if (appToRender_env === 'playground') {
    return 'playground';
  }
  return undefined;
};
const appToRender = getAppToRender();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        {appToRender === 'e-consent' && <EConsentRouter />}
        {appToRender === 'my-family-tree' && <MyFamilyTreeRouter />}
        {appToRender === 'i-pedigree' && <IPedigreeRouter />}
        {appToRender === 'playground' && <PlaygroundRouter />}
        {appToRender === undefined && <div>App not found</div>}
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
