import { Grid, Stack, Typography, useTheme } from '@mui/material';
import { formatPersonalNumber } from 'common/utils';
import { useMemo } from 'react';

interface ReadonlyViewProps {
  title: string;
  value: string;
  padding?: number | string;
  direction?: 'row' | 'column' | 'row-reverse';
  gap?: number;
  format?: 'date' | 'personalNumber';
  variant?: 'body1' | 'body2';
}

export const ReadonlyView = ({
  title,
  value,
  padding,
  direction,
  gap,
  format,
  variant = 'body1',
}: ReadonlyViewProps) => {
  const theme = useTheme();

  // This should be removed when the backend returns the correct format
  const newValue = useMemo(() => {
    if (format === 'date' && value) {
      const date = value.replaceAll('-', '.');
      return `${date}.`;
    }

    if (format === 'personalNumber' && value) {
      return formatPersonalNumber(value);
    }

    return value;
  }, [format, value]);

  return (
    <Grid container p={padding}>
      <Grid item>
        <Stack direction={direction} gap={gap}>
          <Typography
            variant={variant}
            color={theme.palette.common.opacity.darkBlue[70]}
          >
            {title}
          </Typography>
          <Typography mt={0.5} variant={variant}>
            {newValue}
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};
