import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  useTheme,
} from '@mui/material';
import { IPedigreeItem } from 'api/pedigreeCase/staff-pedigree-case-service';
import { ROUTES } from 'iPedigree/routes';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PedigreeStatusView from './PedigreeStatusView';
import PersonalNumberView from './PersonalNumberView';
import { TableLoading } from './TableLoading';

export const PatientTable = ({
  patientData,
  totalNumberOfRows,
  page,
  limit,
  isLoading,
  setPage,
}: {
  patientData?: IPedigreeItem[];
  totalNumberOfRows: number;
  page: number;
  limit: number;
  isLoading: boolean;
  setPage: (page: number) => void;
}) => {
  const { t } = useTranslation('iPedigree');

  const headings = [
    t('page-dashboard.table.heading.name'),
    t('page-dashboard.table.heading.personalName'),
    t('page-dashboard.table.heading.status'),
    t('page-dashboard.table.heading.familyId'),
  ];

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {headings.map((heading, index) => (
                <TableCell key={`heading-${index}`}>{heading}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && <TableLoading colSpan={headings.length} />}
            {!isLoading && patientData && (
              <TablePatientList patientData={patientData} />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!isLoading && (
        <TablePagination
          component="div"
          count={totalNumberOfRows}
          page={page}
          onPageChange={(_, nextPage) => {
            setPage(nextPage);
          }}
          rowsPerPage={limit}
          rowsPerPageOptions={[]}
        />
      )}
    </>
  );
};

const TablePatientList = ({
  patientData,
}: {
  patientData: IPedigreeItem[];
}) => {
  const theme = useTheme();

  return (
    <>
      {patientData?.map((patientRow, rowIdx: number) => (
        <TableRow key={`row-${rowIdx}`}>
          <TableCell>
            <Link
              to={`${ROUTES.patient}/${patientRow.patientId}`}
              style={{
                textDecoration: 'none',
                color: theme.palette.common.brand.blue,
              }}
            >
              {patientRow.patientName}
            </Link>
          </TableCell>
          <TableCell>
            <PersonalNumberView
              personalNumber={patientRow.patientPersonalNumber}
            />
          </TableCell>
          <TableCell>
            <PedigreeStatusView pedigreeStatus={patientRow.pedigreeStatus} />
          </TableCell>
          <TableCell>{patientRow.familyId}</TableCell>
        </TableRow>
      ))}
    </>
  );
};
