import {
  FormControl,
  FormHelperTextProps,
  FormLabel,
  FormLabelProps,
  Stack,
} from '@mui/material';
import { FormError } from 'components/forms';
import { LabelInfo } from 'components/inputs';
import React from 'react';

interface InputWrapperProps {
  label?: string;
  labelId?: string;
  childId?: string;
  error?: string;
  showErrorText?: boolean;
  children: React.ReactNode;
  formLabelProps?: FormLabelProps;
  formControlProps?: any;
  labelInfo?: string;
  inlineLabel?: boolean;
  disabled?: boolean;
  required?: boolean;
  labelBefore?: boolean;
  fullWidth?: boolean;
  formHelperTextProps?: FormHelperTextProps;
}

const InputWrapper = ({
  label,
  labelId,
  childId,
  error,
  showErrorText = true,
  children,
  formLabelProps,
  labelInfo,
  formHelperTextProps,
  inlineLabel = false,
  disabled = false,
  required = false,
  labelBefore = false,
  fullWidth = true,
}: InputWrapperProps) => {
  const labelComponent = (
    <FormLabel id={labelId} htmlFor={childId} {...formLabelProps}>
      {label}
    </FormLabel>
  );

  if (inlineLabel) {
    return (
      <FormControl
        disabled={disabled}
        required={required}
        fullWidth={fullWidth}
      >
        <Stack direction="row" alignItems="center" spacing={2}>
          {labelBefore && labelComponent}
          {children}
          {!labelBefore && labelComponent}
        </Stack>
      </FormControl>
    );
  }

  return (
    <FormControl required={required} fullWidth={fullWidth}>
      <Stack direction="column" spacing={0.5}>
        <Stack direction="row" spacing={1} alignItems="center">
          {labelComponent}
          {labelInfo && <LabelInfo labelInfo={labelInfo} />}
        </Stack>
        {children}
        {error && showErrorText && (
          <FormError error={error} formHelperTextProps={formHelperTextProps} />
        )}
      </Stack>
    </FormControl>
  );
};

export default InputWrapper;
