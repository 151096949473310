import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputAdornment, TextField, useTheme } from '@mui/material';
import { InputWrapper } from 'components/inputs';
import React from 'react';
import { useDebounce } from 'react-use';

interface SearchProps {
  searchValue: string;
  setSearchValue: (value: string) => void;
  searchPlaceholder: string;
  searchLabel: string;
}

function Search(props: SearchProps) {
  const theme = useTheme();

  const [currentSearchValue, setCurrentSearchValue] = React.useState(
    props.searchValue,
  );

  useDebounce(
    () => {
      props.setSearchValue(currentSearchValue);
    },
    500,
    [currentSearchValue],
  );

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentSearchValue(event.target.value);
  };

  return (
    <InputWrapper
      inlineLabel
      label={props.searchLabel}
      labelBefore
      formLabelProps={{
        sx: { ...theme.typography.body2 },
      }}
    >
      <TextField
        value={currentSearchValue}
        onChange={handleSearchChange}
        size="small"
        placeholder={props.searchPlaceholder}
        InputProps={{
          sx: { minWidth: '25rem', ...theme.typography.body2 },
          endAdornment: (
            <InputAdornment position="end">
              <FontAwesomeIcon icon={faSearch} />
            </InputAdornment>
          ),
        }}
      />
    </InputWrapper>
  );
}

export default Search;
