import { Container, Stack, TablePagination } from '@mui/material';
import { useConsentManagementService } from 'api/consentManagement/ConsentManagementServiceProvider';
import { useUserContext } from 'common/contexts/UserProvider';
import useConsentActions from 'features/consentManagement/listPatients/hooks/useConsentActions';
import EmptyState from 'iPedigree/common/EmptyState';
import Search from 'iPedigree/common/Search';
import { UserFilter } from 'iPedigree/common/UserFilter';
import { ROUTES } from 'iPedigree/routes';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import PanelHeader from './PanelHeader';
import ConsentsTable, { Consent } from './table/ConsentsTable';

const ConsentListPanel: React.FC = () => {
  const { t } = useTranslation('iPedigree');
  const navigate = useNavigate();
  const { selectedUser } = useUserContext();
  const service = useConsentManagementService();
  const { getConsentActions } = useConsentActions();

  const forUserId = selectedUser?.id;
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const limit = 20;

  const {
    data: paginatedConsents,
    isLoading,
    error,
  } = useQuery(['consents', search, page, limit, forUserId], () =>
    service.listConsents({ search, limit, offset: page * limit, forUserId }),
  );

  const showNoSearchResults = Boolean(
    !isLoading && search && paginatedConsents?.items.length === 0,
  );
  const showNoConsents =
    !isLoading && paginatedConsents?.totalCount === 0 && !showNoSearchResults;
  const showPagination = Boolean(paginatedConsents?.totalCount ?? 0 > limit);
  const showError = Boolean(error);

  const consents = useMemo((): Consent[] => {
    return (
      paginatedConsents?.items.map((consent) => ({
        ...consent,
        isNew: consent.new,
        patientUrl: `${ROUTES.patient}/${consent.patientId}/consents`,
        actions: getConsentActions({
          ...consent,
          consentId: consent.id,
          consentStatus: consent.status,
        }),
        date: consent.updatedAt,
        relative: consent.name,
        patient: consent.patientName,
      })) || []
    );
  }, [paginatedConsents, getConsentActions]);

  return (
    <Container maxWidth={false} disableGutters>
      <PanelHeader
        onAddNewPatientClick={() => {
          navigate(ROUTES.newPatient);
        }}
        title={t('page-dashboard.consent-list.title')}
        buttonText={t('page-dashboard.new-patient.buttonText')}
      />
      <Stack direction="row" alignItems="center" gap={8}>
        <UserFilter />
        <Search
          searchValue={search}
          setSearchValue={setSearch}
          searchPlaceholder={t('search.placeholder-text')}
          searchLabel={t('search.search')}
        />
      </Stack>
      {showNoSearchResults && (
        <EmptyState
          title={t(
            'page-dashboard.consent-list.empty-state.no-search-results.title',
            { searchQuery: search },
          )}
          message={t(
            'page-dashboard.consent-list.empty-state.no-search-results.message',
          )}
        />
      )}
      {showNoConsents && (
        <EmptyState
          title={t('page-dashboard.consent-list.empty-state.no-consents.title')}
          message={t(
            'page-dashboard.consent-list.empty-state.no-consents.message',
          )}
        />
      )}
      {!showNoSearchResults && !showNoConsents && (
        <ConsentsTable consents={consents} isLoading={isLoading} />
      )}
      {showPagination && (
        <TablePagination
          component="div"
          count={paginatedConsents?.totalCount || 0}
          page={page}
          onPageChange={(_, nextPage) => {
            setPage(nextPage);
          }}
          rowsPerPage={limit}
          rowsPerPageOptions={[]}
        />
      )}
      {showError && (
        <EmptyState
          title={t('page-dashboard.consent-list.error.title')}
          message={t('page-dashboard.consent-list.error.message')}
        />
      )}
    </Container>
  );
};

export default ConsentListPanel;
