import {
  DrawerFormConfirmationData,
  DrawerState,
} from 'common/features/pedigree';
import { AddNewPedigreeNode, Pedigree, PedigreeNode } from '../../types';
import { findNodeById } from '../../utils/helpers';
import { DialogContentType } from '../../views/Dialog';
import { PedigreeNodeAction } from './../useHandleNodeAction';
import { AddChild, AddHalfSibling } from './types';
import { useAddNode } from './useAddNode';

interface useHandleAddNodeProps {
  pedigree?: Pedigree | null;
  setDialogContent: (content: DialogContentType) => void;
  addNewPedigreeNode: AddNewPedigreeNode;
  setDrawerState: (info: DrawerState) => void;
  setSelectedNodeId: (pedigreeNodeId: string | null) => void;
  setPedigree: (Pedigree: Pedigree | null) => void;
}

export const useHandleAddNode = (props: useHandleAddNodeProps) => {
  const {
    pedigree,
    setDialogContent,
    setDrawerState,
    setSelectedNodeId,
    setPedigree,
    addNewPedigreeNode,
  } = props;

  const { handleCreateNewNodeMember } = useAddNode({
    pedigree,
    setDialogContent,
    setDrawerState,
    setSelectedNodeId,
    setPedigree,
    addNewPedigreeNode,
  });

  const openDrawerSelectionForm = (
    type: 'parentSelection' | 'twinSelection',
    action: AddChild | AddHalfSibling | 'addTwin',
    pedigreeNodeId: string,
    onClickConfirm: (data: DrawerFormConfirmationData) => void,
  ) => {
    props.setDrawerState({
      type,
      pedigreeNodeId,
      action,
      onClickConfirm,
    });
    props.setSelectedNodeId(pedigreeNodeId);
  };

  const handleAddChild = (action: AddChild, pedigreeNodeId: string) => {
    openDrawerSelectionForm(
      'parentSelection',
      action,
      pedigreeNodeId,
      (data) => {
        const addChildAction =
          data.action === 'addSon' ||
          data.action === 'addDaughter' ||
          data.action === 'addChildOfUnknownSex';

        if (addChildAction) {
          handleCreateNewNodeMember(data);
        }
      },
    );
  };

  const handleAddTwin = (action: 'addTwin', pedigreeNodeId: string) => {
    openDrawerSelectionForm('twinSelection', action, pedigreeNodeId, (data) => {
      const addTwinAction =
        data.action === 'addFraternalTwins' ||
        data.action === 'addIdenticalTwins';

      if (addTwinAction) {
        handleCreateNewNodeMember(data);
      }
    });
  };

  const handleAddHalfSibling = (
    action: AddHalfSibling,
    pedigreeNode: PedigreeNode,
    nodes: PedigreeNode[],
  ) => {
    const hasBothParents = Boolean(
      pedigreeNode.fatherId && pedigreeNode.motherId,
    );
    const parentId = pedigreeNode.fatherId || pedigreeNode.motherId;

    const openDrawerToAddHalfSibling = () => {
      return openDrawerSelectionForm(
        'parentSelection',
        action,
        pedigreeNode.id,
        (data) => {
          if (data.action === action) {
            handleCreateNewNodeMember(data);
          }
        },
      );
    };

    if (parentId && !hasBothParents) {
      const parentNode = findNodeById(nodes, parentId);
      const parentHasPartner = Boolean(parentNode?.partnerIds?.length);

      if (parentHasPartner) {
        return openDrawerToAddHalfSibling();
      }

      return handleCreateNewNodeMember({
        action,
        pedigreeNodeId: pedigreeNode.id,
        sharedRelationshipType: pedigreeNode.fatherId ? 'father' : 'mother',
      });
    }

    return openDrawerToAddHalfSibling();
  };

  const handleAddNodeAction = (
    action: Partial<PedigreeNodeAction>,
    pedigreeNodeId: string,
  ) => {
    if (!props.pedigree) return;

    const pedigreeNode = findNodeById(props.pedigree.nodes, pedigreeNodeId);
    if (!pedigreeNode) return;

    switch (action) {
      case 'addParents':
      case 'addPartner':
      case 'addSister':
      case 'addBrother':
      case 'addSiblingOfUnknownSex':
        handleCreateNewNodeMember({ action, pedigreeNodeId });
        break;

      case 'addHalfBrother':
      case 'addHalfSister':
      case 'addHalfSiblingOfUnknownSex':
        handleAddHalfSibling(action, pedigreeNode, props.pedigree.nodes);
        break;

      case 'addSon':
      case 'addDaughter':
      case 'addChildOfUnknownSex':
        if (pedigreeNode.partnerIds.length > 1) {
          handleAddChild(action, pedigreeNodeId);
          break;
        }
        handleCreateNewNodeMember({ action, pedigreeNodeId });
        break;

      case 'addTwin':
        handleAddTwin(action, pedigreeNodeId);
        break;

      default:
        console.warn(`Unsupported action: ${action}`);
    }
  };

  return { handleAddNodeAction };
};
