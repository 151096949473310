import { Divider, Stack, Typography, useTheme } from '@mui/material';
import { useUserService } from 'api/user/user-service';
import { useUserContext } from 'common/contexts/UserProvider';
import useElementSize from 'common/hooks/useElementSize';
import { AutoComplete } from 'components/inputs/AutoCompleteInput';
import { Fragment, HTMLAttributes, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

type Option = {
  value: string;
  label: string;
};

export const UserFilter = () => {
  const service = useUserService();
  const { selectedUser, setSelectedUser } = useUserContext();

  const theme = useTheme();
  const { t } = useTranslation('iPedigree');

  const [userNameWidth, setUserNameWidth] = useState<number>(145);

  const { data: users, isLoading } = useQuery(['users'], service.getUserList);

  const showAllLabel = t('page-dashboard.table.filter-by-staff.all');

  const value = useMemo(() => {
    const { id } = selectedUser || {};
    return id ? `${id}` : 'show-all';
  }, [selectedUser]);

  const options = useMemo<Option[]>(() => {
    const showAll = {
      value: 'show-all',
      label: showAllLabel,
    };

    const usersOptions =
      users?.map(({ id, name }) => ({
        value: `${id}`,
        label: name,
      })) ?? [];

    return [...usersOptions, showAll];
  }, [showAllLabel, users]);

  const handleOnChange = (value: string | null) => {
    if (value === null || value === 'show-all') {
      return setSelectedUser(null);
    }
    const user = users?.find((user) => user.id === parseInt(value));
    setSelectedUser({
      id: parseInt(value),
      name: user?.name ?? '',
    });
  };

  if (isLoading) return null;

  return (
    <Stack direction="row" alignItems="center" gap={2} minWidth="fit-content">
      <Typography variant="body2">
        {t('page-dashboard.table.filter-by-staff')}:
      </Typography>
      <UserNameSizer
        userName={selectedUser?.name ?? showAllLabel}
        setUserNameWidth={setUserNameWidth}
      />
      <div style={{ position: 'relative' }}>
        <AutoComplete
          id="select-user"
          options={options}
          onChange={handleOnChange}
          value={value}
          autocompleteProps={{
            disablePortal: true,
            disableClearable: true,
            sx: {
              minWidth: userNameWidth,
              '& .MuiAutocomplete-inputRoot': {
                ...theme.typography.body2,
                '& .MuiAutocomplete-input': {
                  minWidth: userNameWidth,
                },
              },
              '& + .MuiAutocomplete-popper': {
                left: 0,
                top: '40px !important',
                minWidth: 'fit-content !important',
                maxWidth: 400,
                transform: 'none !important',
              },
            },
            ListboxProps: {
              sx: {
                maxHeight: 264,
                paddingTop: 0,
                paddingBottom: 0,
                minWidth: 'fit-content',
                ...theme.typography.body2,
              },
            },
            renderOption: (
              props: HTMLAttributes<HTMLLIElement>,
              option: Option,
            ) => RenderOption(props, option, options),
          }}
        />
      </div>
    </Stack>
  );
};

interface UserNameSizerProps {
  userName?: string;
  setUserNameWidth: (width: number) => void;
}

const UserNameSizer = ({ userName, setUserNameWidth }: UserNameSizerProps) => {
  const { ref: userNameRef, size } = useElementSize();

  useEffect(() => {
    if (size.width) {
      setUserNameWidth(size.width);
    }
  }, [setUserNameWidth, size.width]);

  return (
    <Typography
      ref={userNameRef}
      id="user-name-value"
      aria-hidden="true"
      sx={{ position: 'absolute', visibility: 'hidden' }}
      variant="body2"
    >
      {userName}
    </Typography>
  );
};

const RenderOption = (props: any, option: Option, options: Option[]) => {
  const isLast = options[options.length - 1].value === option.value;
  return (
    <Fragment key={option.value}>
      {isLast && <Divider />}
      <li
        {...props}
        style={{
          whiteSpace: 'nowrap',
          width: '100%',
          padding: '12px 16px',
        }}
      >
        {option.label}
      </li>
    </Fragment>
  );
};
