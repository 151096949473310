import {
  faCheckCircle,
  faExclamationCircle,
  faExclamationTriangle,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ConsentStatus } from 'types';

export const ConsentStatusView: React.FC<{ status: ConsentStatus }> = ({
  status,
}) => {
  const { t } = useTranslation('consent');
  const theme = useTheme();
  const iconMap: Record<
    ConsentStatus,
    { icon: IconDefinition; color: string }
  > = {
    accepted: { icon: faCheckCircle, color: theme.palette.success.main },
    pending: { icon: faExclamationCircle, color: theme.palette.warning.main },
    declined: { icon: faExclamationTriangle, color: theme.palette.error.main },
  };

  const { icon, color } = iconMap[status];

  return (
    <Typography sx={{ display: 'flex', alignItems: 'center' }}>
      <FontAwesomeIcon icon={icon} style={{ marginRight: '8px', color }} />
      {t(`consent-status.${status}`)}
    </Typography>
  );
};
