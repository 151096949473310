import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IconButton,
  Drawer as MuiDrawer,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { ReactNode } from 'react';
import { usePedigreeContext } from '../pedigreeContext';

interface DrawerProps {
  title: string;
  isOpen: boolean;
  children: ReactNode;
  hideBackdrop?: boolean;
  zIndex?: number;
  showCloseButton?: boolean;
  onClose: () => void;
}

const Drawer = ({
  title,
  isOpen,
  children,
  hideBackdrop = true,
  zIndex = 10,
  showCloseButton = true,
  onClose,
}: DrawerProps) => {
  const theme = useTheme();
  const { drawerDefaultWidth } = usePedigreeContext();

  return (
    <MuiDrawer
      sx={{
        width: 0,
        zIndex,
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          overflow: 'hidden',
          width: drawerDefaultWidth || 464,
        },
      }}
      hideBackdrop={hideBackdrop}
      anchor="right"
      open={isOpen}
    >
      <Stack
        justifyContent="space-between"
        alignItems="center"
        p={1.5}
        pl={3}
        direction="row"
      >
        <Typography variant="h3">{title}</Typography>
        {showCloseButton && (
          <IconButton
            onClick={onClose}
            sx={{
              width: 40,
              height: 40,
              color: theme.palette.common.brand.darkBlue,
            }}
          >
            <FontAwesomeIcon icon={faClose} />
          </IconButton>
        )}
      </Stack>
      <Stack
        sx={{
          overflowY: 'auto',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        {children}
      </Stack>
    </MuiDrawer>
  );
};

export default Drawer;
