import { usePedigreeContext } from '../../common/pedigreeContext';

import {
  faCircleQuestion,
  faDownload,
  faMinimize,
  faPrint,
  faSearchMinus,
  faSearchPlus,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, IconButton, Stack, styled, useTheme } from '@mui/material';

interface PedigreeActionsButtonsProps {
  handlePrint?: () => void;
  handleDownload?: () => void;
  handleInfoIconClick?: () => void;
  direction?: 'row' | 'column';
  right?: number;
}
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  fontSize: 18,
  width: 40,
  height: 40,
  color: theme.palette.primary.main,
}));

export const PedigreeActionsButtons = ({
  handlePrint,
  handleDownload,
  handleInfoIconClick,
  direction,
  right,
}: PedigreeActionsButtonsProps) => {
  const theme = useTheme();
  const { handleZoomIn, handleZoomOut, handleZoomReset } = usePedigreeContext();

  return (
    <Stack
      gap={2}
      direction={direction}
      sx={{
        position: 'absolute',
        zIndex: 11,
        bottom: theme.spacing(2),
        right: right ?? theme.spacing(2),
      }}
    >
      <Card elevation={3}>
        <Stack direction={direction} p={0.5} spacing={1}>
          <StyledIconButton onClick={handleZoomIn}>
            <FontAwesomeIcon icon={faSearchPlus} />
          </StyledIconButton>
          <StyledIconButton onClick={handleZoomOut}>
            <FontAwesomeIcon icon={faSearchMinus} />
          </StyledIconButton>
          <StyledIconButton onClick={handleZoomReset}>
            <FontAwesomeIcon icon={faMinimize} />
          </StyledIconButton>
        </Stack>
      </Card>
      {(handlePrint || handleDownload) && (
        <Card elevation={3}>
          <Stack direction={direction} p={0.5} spacing={1}>
            {handlePrint && (
              <StyledIconButton onClick={handlePrint}>
                <FontAwesomeIcon icon={faPrint} />
              </StyledIconButton>
            )}
            {handleDownload && (
              <StyledIconButton onClick={handleDownload}>
                <FontAwesomeIcon icon={faDownload} />
              </StyledIconButton>
            )}
          </Stack>
        </Card>
      )}
      {handleInfoIconClick && (
        <Card elevation={3}>
          <Stack direction={direction} p={0.5}>
            <StyledIconButton onClick={() => handleInfoIconClick()}>
              <FontAwesomeIcon icon={faCircleQuestion} />
            </StyledIconButton>
          </Stack>
        </Card>
      )}
    </Stack>
  );
};
