import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Link, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const InstructionBox = () => {
  const { t } = useTranslation('myFamilyTree');
  const theme = useTheme();

  const INSTRUCTION_URL = 'https://arkus.ai/myfamilytree-guide/';

  return (
    <Alert
      severity="info"
      icon={<FontAwesomeIcon icon={faInfoCircle} />}
      sx={{
        paddingTop: 1,
        paddingBottom: 1,
        backgroundColor: theme.palette.common.brand.infoBackground,
        borderRadius: 0,
      }}
    >
      <Typography variant="body2">{t('instructional.help-text')}</Typography>
      <Typography variant="body2" mt={0.5}>
        <Link variant="body2" href={INSTRUCTION_URL} target="_blank">
          {t('instructional.link-text')}
        </Link>{' '}
        {t('instructional.link-text2')}
      </Typography>
    </Alert>
  );
};
