import { Checkbox, FormControlLabel, Grid, useTheme } from '@mui/material';
import { Diagnose } from 'api/pedigreeCase/staff-pedigree-case-service';
import { FormError } from 'components/forms';
import { TextAreaInput, TextInput } from 'components/inputs';
import { FreeSoloAutoCompleteInput } from 'components/inputs/FreeSoloAutoCompleteInput';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DiagnoseValue,
  DiagnosisFormData,
  DiagnosisItemErrors,
} from '../controllers/PatientDiagnosisFormController';

export type DiseaseOption = {
  label: string;
  value: string;
};

interface DiagnosisFormFieldsProps {
  item: DiagnosisFormData;
  diseaseOptions: DiseaseOption[];
  errors: DiagnosisItemErrors;
  handleOnchange: <K extends keyof Diagnose>(
    key: K,
    value: DiagnoseValue[K],
  ) => void;
}

export const DiagnosisFormFields = (props: DiagnosisFormFieldsProps) => {
  const { t, i18n } = useTranslation(['pedigreeCaseNewPatient']);
  const theme = useTheme();

  const { item, diseaseOptions, handleOnchange, errors } = props;

  const ageAtDiagnosisValue = item.ageAtDiagnosis
    ? `${item.ageAtDiagnosis}`
    : '';

  const handleAgeAtDiagnosisChange = (value: string) => {
    const min = 0;
    const max = 150;

    let inputValue = value ? parseInt(value) : null;

    if (inputValue !== null) {
      if (inputValue > max) inputValue = max;
      if (inputValue < min) inputValue = min;
    }

    handleOnchange('ageAtDiagnosis', inputValue);
  };

  const handleWheel = (event: React.WheelEvent<HTMLInputElement>) => {
    if (document.activeElement === event.currentTarget) {
      event.currentTarget.blur();
    }
  };

  const errorMessages: DiagnosisItemErrors = useMemo(() => {
    const { disease, ageAtDiagnosis, ageIsApproximate, treatedBy, notes } =
      errors || {};

    const getErrorMessage = (error: string) =>
      i18n.exists(`pedigreeCaseNewPatient:input-validation.${error}`)
        ? t(`input-validation.${error}`)
        : error;

    return {
      disease: disease && getErrorMessage(disease),
      ageAtDiagnosis: ageAtDiagnosis && getErrorMessage(ageAtDiagnosis),
      ageIsApproximate: ageIsApproximate && getErrorMessage(ageIsApproximate),
      treatedBy: treatedBy && getErrorMessage(treatedBy),
      notes: notes && getErrorMessage(notes),
    };
  }, [errors, i18n, t]);

  return (
    <Grid
      container
      spacing={2}
      borderLeft={`1px solid ${theme.palette.common.opacity.darkBlue[25]}`}
      marginLeft="1px"
      pr={2}
    >
      <Grid item xs={12}>
        <FreeSoloAutoCompleteInput
          label={t('diagnosis-form.disease.label')}
          placeholder={t('diagnosis-form.disease.placeholder')}
          onChange={(val) => {
            handleOnchange('disease', val || '');
          }}
          value={item.disease}
          options={diseaseOptions}
          required
          showPopupIcon
          error={errorMessages.disease}
        />
      </Grid>
      <Grid item xs="auto">
        <TextInput
          label={t('diagnosis-form.age-at-diagnosis.label')}
          value={ageAtDiagnosisValue}
          id="ageAtDiagnosis"
          onChange={(e) => handleAgeAtDiagnosisChange(e.target.value)}
          textFieldProps={{
            InputProps: { type: 'number' },
            inputProps: {
              onWheel: handleWheel,
            },
            sx: {
              width: 133,
            },
          }}
          formHelperTextProps={{ style: { whiteSpace: 'nowrap' } }}
          error={errorMessages.ageAtDiagnosis}
        ></TextInput>
      </Grid>
      <Grid item xs="auto">
        <FormControlLabel
          sx={{ paddingTop: 3 }}
          control={
            <Checkbox
              checked={item.ageIsApproximate}
              disabled={item.ageAtDiagnosis === null}
              onChange={(e) =>
                handleOnchange('ageIsApproximate', e.target.checked)
              }
            />
          }
          label={t('diagnosis-form.age-is-approximate.label')}
        />
        {errorMessages?.ageIsApproximate && (
          <FormError error={errorMessages.ageIsApproximate} />
        )}
      </Grid>
      <Grid item xs={12}>
        <TextInput
          label={t('diagnosis-form.treated-by.label')}
          value={item.treatedBy}
          id="treatedBy"
          onChange={(e) => handleOnchange('treatedBy', e.target.value)}
          textFieldProps={{
            helperText: t('diagnosis-form.treated-by.helper-text'),
          }}
          error={errorMessages.treatedBy}
        ></TextInput>
      </Grid>
      <Grid item xs={12}>
        <TextAreaInput
          label={t('diagnosis-form.notes.label')}
          value={item.notes}
          id="notes"
          onChange={(e) => handleOnchange('notes', e.target.value)}
          error={errorMessages.notes}
          helperText={t('diagnosis-form.notes.helper-text')}
        ></TextAreaInput>
      </Grid>
    </Grid>
  );
};
