import { useState } from 'react';
import { NodeActionsMenu } from '../views/nodeActionsMenu/NodeActionsMenu';
import { HandleNodeAction } from './useHandleNodeAction';

interface useNodeMenuProps {
  handleNodeAction: ({ action, pedigreeNodeId }: HandleNodeAction) => void;
  isEditMode: boolean;
  setSelectedNodeState: (nodeId: string[] | null) => void;
}

export const useNodeMenu = (props: useNodeMenuProps) => {
  const [nodeMenuState, setNodeMenuState] = useState<{
    anchorElement: HTMLElement | null;
    pedigreeNodeId: string | null;
    selectedElement?: { [key: string]: boolean } | null;
  }>({
    anchorElement: null,
    pedigreeNodeId: null,
  });

  const openNodeMenu = (
    anchorElement: HTMLElement,
    pedigreeNodeId: string,
    selectedElement?: { [key: string]: boolean } | null,
  ) => {
    props.setSelectedNodeState([pedigreeNodeId]);
    setNodeMenuState({
      anchorElement,
      pedigreeNodeId,
      selectedElement,
    });
  };

  const closeNodeMenu = () => {
    props.setSelectedNodeState(null);
    setNodeMenuState({
      anchorElement: null,
      pedigreeNodeId: null,
      selectedElement: null,
    });
  };

  return {
    openNodeMenu,
    closeNodeMenu,
    NodeMenu: (
      <NodeActionsMenu
        {...nodeMenuState}
        hasEditAndChangeSexAction={props.isEditMode}
        handleNodeMenuOptionClick={props.handleNodeAction}
      />
    ),
  };
};
