import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Collapse, Stack, Typography } from '@mui/material';
import { capitalizeFirstLetter } from 'common/utils';
import { RadioInput } from 'components/inputs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SharedParentRelationshipType } from '../../types';

interface SelectBiologicalParentsFormProps {
  parentsValue: {
    firstParent: SharedParentRelationshipType | null;
    otherParent: 'newPartner' | 'existingPartner' | null;
  };
  hasFixedParent: boolean;
  selectParentSubtitle: string;
  existingPartnerLabel: string;
  newPartnerLabel: string;
  formError: boolean;
  showSecondBiologicalParentForm: boolean;
  handleParentChange: (
    value: string,
    id: 'firstParent' | 'otherParent',
  ) => void;
  onCloseForm: () => void;
  onConfirmParents: () => void;
}

const SelectBiologicalParentsForm = ({
  parentsValue,
  hasFixedParent,
  selectParentSubtitle,
  existingPartnerLabel,
  newPartnerLabel,
  formError,
  showSecondBiologicalParentForm,
  handleParentChange,
  onCloseForm,
  onConfirmParents,
}: SelectBiologicalParentsFormProps) => {
  const { t } = useTranslation('pedigree');

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  return (
    <Stack gap={3}>
      <Stack>
        <RadioInput
          id="select-parent"
          label={selectParentSubtitle}
          disabled={hasFixedParent}
          options={[
            {
              label: capitalizeFirstLetter(
                t('node-select-parent-form.parent.label.father'),
              ),
              value: 'father',
            },
            {
              label: capitalizeFirstLetter(
                t('node-select-parent-form.parent.label.mother'),
              ),
              value: 'mother',
            },
          ]}
          radioGroupProps={{
            sx: { paddingLeft: '10px' },
          }}
          value={parentsValue.firstParent || ''}
          onChange={(e) => handleParentChange(e.target.value, 'firstParent')}
          error={
            formError && !parentsValue.firstParent
              ? t(
                  'node-select-parent-form.error-message.no-shared-parent-selected',
                )
              : ''
          }
          required
        />
        {hasFixedParent && (
          <Stack alignItems="flex-start">
            <Button
              onClick={() => setIsExpanded(!isExpanded)}
              endIcon={
                <FontAwesomeIcon icon={isExpanded ? faCaretUp : faCaretDown} />
              }
              variant="text"
              size="small"
            >
              {t('node-select-parent-form.info.button.text')}
            </Button>
            <Collapse in={isExpanded}>
              <Stack spacing={2} p={1}>
                <Typography variant="body2">
                  {t('node-select-parent-form.info.content')}
                </Typography>
              </Stack>
            </Collapse>
          </Stack>
        )}
      </Stack>
      {showSecondBiologicalParentForm && (
        <RadioInput
          id="select-second-parent"
          label={t('node-select-parent-form.subtitle.second-biological-parent')}
          options={[
            {
              label: existingPartnerLabel,
              value: 'existingPartner',
            },
            {
              label: newPartnerLabel,
              value: 'newPartner',
            },
          ]}
          radioGroupProps={{
            sx: { paddingLeft: '10px' },
          }}
          value={parentsValue.otherParent || ''}
          onChange={(e) => handleParentChange(e.target.value, 'otherParent')}
          error={
            formError && !parentsValue.otherParent
              ? t(
                  'node-select-parent-form.error-message.no-second-parent-selected',
                )
              : ''
          }
          required
        />
      )}
      <Stack direction="row" gap={3}>
        <Button variant="outlined" fullWidth onClick={onCloseForm}>
          {t('node-select-parent-form.button.cancel')}
        </Button>
        <Button variant="contained" fullWidth onClick={onConfirmParents}>
          {t('node-select-parent-form.button.continue')}
        </Button>
      </Stack>
    </Stack>
  );
};

export default SelectBiologicalParentsForm;
