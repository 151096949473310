import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputAdornment } from '@mui/material';
import { TextInput } from 'components/inputs';
import React from 'react';

interface Props {
  label: string;
  disabled: boolean;
  error?: string;
  clearError?: () => void;
  handleOnFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  handleOnBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  handleOnChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const PasswordInput: React.FC<Props> = (props) => {
  const [showPasswordInput, setShowPasswordInput] =
    React.useState<boolean>(false);

  return (
    <TextInput
      label={props.label}
      id="password"
      error={props.error}
      errorBorder={false}
      textFieldProps={{
        type: showPasswordInput ? 'text' : 'password',
        disabled: props.disabled,
        style: { backgroundColor: 'white' },
        InputProps: {
          name: 'password',
          onChange: props.handleOnChange
            ? props.handleOnChange
            : props.clearError,
          onFocus: props.handleOnFocus,
          onBlur: props.handleOnBlur,
          endAdornment: (
            <InputAdornment position="end">
              <FontAwesomeIcon
                icon={showPasswordInput ? faEyeSlash : faEye}
                onClick={() => setShowPasswordInput(!showPasswordInput)}
                style={{ cursor: 'pointer' }}
              />
            </InputAdornment>
          ),
        },
      }}
    />
  );
};

export default PasswordInput;
