import {
  Pedigree,
  PedigreeNode,
  PedigreeNodeSex,
  SharedParentRelationshipType,
} from './types';

type BaseParams = {
  pedigree: Pedigree;
  targetNodeId: string;
};

type ParentOrPartnerParams = BaseParams & {
  relationshipType: ParentRelationshipType | PartnerRelationshipType;
};

type SiblingParams = BaseParams & {
  relationshipType: SiblingRelationshipType;
  otherParentNodeId?: string | null;
  sex: PedigreeNodeSex;
};

type HalfSiblingParams = BaseParams & {
  relationshipType: HalfSiblingRelationshipType;
  sex: PedigreeNodeSex;
  otherParentNodeId: string | null;
  sharedRelationshipType: SharedParentRelationshipType;
};

type ChildParams = BaseParams & {
  relationshipType: ChildRelationshipType;
  sex: PedigreeNodeSex;
  otherParentNodeId: string | null;
};

type TwinParams = BaseParams &
  (
    | {
        relationshipType: 'identical-twins';
        numberOfSiblings: number;
      }
    | {
        relationshipType: 'fraternal-twins';
        numberOfFemaleSiblings: number;
        numberOfMaleSiblings: number;
        numberOfUnknownSexSiblings: number;
      }
  );

export type GenerateCreateNodeParams =
  | ParentOrPartnerParams
  | SiblingParams
  | HalfSiblingParams
  | ChildParams
  | TwinParams;

type SiblingRelationshipType = 'sibling';
type HalfSiblingRelationshipType = 'half-sibling';
type TwinRelationshipType = 'identical-twins' | 'fraternal-twins';
type ChildRelationshipType = 'child';
type PartnerRelationshipType = 'partner';
type ParentRelationshipType = 'parents';

export type PedigreeRelationshipType =
  | SiblingRelationshipType
  | HalfSiblingRelationshipType
  | TwinRelationshipType
  | ChildRelationshipType
  | PartnerRelationshipType
  | ParentRelationshipType;

type BaseCreatePedigreeNode = {
  relationshipType: PedigreeRelationshipType;
};

type ParentsIds = {
  fatherId?: number;
  motherId?: number;
};

export type CreateParentsNodeParams = BaseCreatePedigreeNode & {
  relationshipType: ParentRelationshipType;
  commonChildrenIds: number[];
};

export type CreatePartnerNodeParams = BaseCreatePedigreeNode & {
  relationshipType: PartnerRelationshipType;
  targetNodeId: number;
  commonChildrenIds: number[];
};

export type CreateSiblingNodeParams = BaseCreatePedigreeNode & {
  relationshipType: SiblingRelationshipType;
  sex: PedigreeNodeSex;
  targetNodeId: number;
};

export type CreateHalfSiblingNodeParams = BaseCreatePedigreeNode & {
  relationshipType: HalfSiblingRelationshipType;
  sex: PedigreeNodeSex;
  targetNodeId: number;
  sharedBiologicalParent?: SharedParentRelationshipType;
  otherParentId?: number;
};

type TwinNodeParams =
  | {
      relationshipType: 'identical-twins';
      numberOfSiblings: number;
    }
  | {
      relationshipType: 'fraternal-twins';
      numberOfMaleSiblings: number;
      numberOfFemaleSiblings: number;
      numberOfUnknownSexSiblings: number;
    };

export type CreateTwinNodeParams =
  | {
      targetNodeId: number;
    } & TwinNodeParams;

export type CreateChildNodeParams = BaseCreatePedigreeNode & {
  relationshipType: ChildRelationshipType;
  sex: PedigreeNodeSex;
  parentsIds: ParentsIds;
};

function generateCreateParentsNodeParams(
  pedigree: Pedigree,
  targetNodeId: string,
): CreateParentsNodeParams {
  const targetNode = getNodeOrFail(pedigree, targetNodeId);
  const siblings: PedigreeNode[] = [];
  if (!targetNode.fatherId && !targetNode.motherId) {
    siblings.push(targetNode);
  } else {
    siblings.push(
      ...pedigree.nodes.filter(
        (node) =>
          node.fatherId === targetNode.fatherId &&
          node.motherId === targetNode.motherId,
      ),
    );
  }
  return {
    relationshipType: 'parents',
    commonChildrenIds: siblings.map((sibling) => parseInt(sibling.id)),
  };
}

function generateCreatePartnerNodeParams(
  pedigree: Pedigree,
  targetNodeId: string,
): CreatePartnerNodeParams {
  const targetNode = getNodeOrFail(pedigree, targetNodeId);

  const targetNodeChildrenIds = pedigree.nodes
    .filter(
      ({ motherId, fatherId }) =>
        (motherId === targetNode.id && !fatherId) ||
        (fatherId === targetNode.id && !motherId),
    )
    .map((node) => parseInt(node.id));

  const commonChildrenIds =
    targetNode.partnerIds.length > 0 ? [] : targetNodeChildrenIds;

  return {
    relationshipType: 'partner',
    targetNodeId: parseInt(targetNode.id),
    commonChildrenIds,
  };
}

function generateCreateSiblingNodeParams({
  pedigree,
  sex,
  targetNodeId,
}: {
  pedigree: Pedigree;
  sex: PedigreeNodeSex;
  targetNodeId: string;
}): CreateSiblingNodeParams {
  const targetNode = getNodeOrFail(pedigree, targetNodeId);
  return {
    relationshipType: 'sibling',
    sex,
    targetNodeId: parseInt(targetNode.id),
  };
}

function generateCreateHalfSiblingNodeParams({
  pedigree,
  sex,
  targetNodeId,
  otherParentNodeId,
  sharedRelationshipType,
}: {
  pedigree: Pedigree;
  sex: PedigreeNodeSex;
  targetNodeId: string;
  otherParentNodeId?: string | null;
  sharedRelationshipType?: SharedParentRelationshipType;
}): CreateHalfSiblingNodeParams {
  const targetNode = getNodeOrFail(pedigree, targetNodeId);

  const otherParentNode = otherParentNodeId
    ? getNodeOrFail(pedigree, otherParentNodeId)
    : undefined;

  const otherParentId = otherParentNode
    ? parseInt(otherParentNode.id)
    : undefined;

  return {
    relationshipType: 'half-sibling',
    sex,
    targetNodeId: parseInt(targetNode.id),
    sharedBiologicalParent: sharedRelationshipType,
    otherParentId,
  };
}

function generateCreateTwinNodeParams(
  params: {
    targetNodeId: string;
  } & TwinNodeParams,
): CreateTwinNodeParams {
  if (params.relationshipType === 'identical-twins') {
    return {
      relationshipType: params.relationshipType,
      targetNodeId: parseInt(params.targetNodeId),
      numberOfSiblings: params.numberOfSiblings,
    };
  }

  if (params.relationshipType === 'fraternal-twins') {
    return {
      relationshipType: params.relationshipType,
      targetNodeId: parseInt(params.targetNodeId),
      numberOfMaleSiblings: params.numberOfMaleSiblings,
      numberOfFemaleSiblings: params.numberOfFemaleSiblings,
      numberOfUnknownSexSiblings: params.numberOfUnknownSexSiblings,
    };
  }

  throw new Error('Invalid relationship type');
}

function generateCreateChildNodeParams({
  pedigree,
  sex,
  targetNodeId,
  otherParentNodeId,
}: {
  pedigree: Pedigree;
  sex: PedigreeNodeSex;
  targetNodeId: string;
  otherParentNodeId?: string | null;
}): CreateChildNodeParams {
  const targetNode = getNodeOrFail(pedigree, targetNodeId);

  const newTargetNodeData = otherParentNodeId
    ? {
        ...targetNode,
        partnerIds:
          otherParentNodeId === targetNode.id ? [] : [otherParentNodeId],
      }
    : targetNode;

  const partnerNode =
    newTargetNodeData.partnerIds.length > 0
      ? getNodeOrFail(pedigree, newTargetNodeData.partnerIds[0] || '')
      : undefined;

  const targetId = parseInt(newTargetNodeData.id);
  const partnerId = partnerNode ? parseInt(partnerNode.id) : undefined;

  const parentsIds: ParentsIds =
    newTargetNodeData.sex === PedigreeNodeSex.MALE
      ? { fatherId: targetId, motherId: partnerId }
      : { motherId: targetId, fatherId: partnerId };

  return {
    relationshipType: 'child',
    sex,
    parentsIds,
  };
}

function getNodeOrFail(pedigree: Pedigree, targetNodeId: string): PedigreeNode {
  const node = pedigree.nodes.find((node) => node.id === targetNodeId);
  if (!node) {
    throw new NodeNotFoundError(targetNodeId);
  }
  return node;
}

export function generateCreateNodeParams(
  params: GenerateCreateNodeParams,
):
  | CreateParentsNodeParams
  | CreatePartnerNodeParams
  | CreateSiblingNodeParams
  | CreateChildNodeParams
  | CreateHalfSiblingNodeParams
  | CreateTwinNodeParams {
  switch (params.relationshipType) {
    case 'parents':
      return generateCreateParentsNodeParams(
        params.pedigree,
        params.targetNodeId,
      );
    case 'partner':
      return generateCreatePartnerNodeParams(
        params.pedigree,
        params.targetNodeId,
      );
    case 'sibling':
      return generateCreateSiblingNodeParams({
        pedigree: params.pedigree,
        sex: params.sex,
        targetNodeId: params.targetNodeId,
      });
    case 'child':
      return generateCreateChildNodeParams({
        pedigree: params.pedigree,
        targetNodeId: params.targetNodeId,
        sex: params.sex,
        otherParentNodeId: params.otherParentNodeId,
      });
    case 'half-sibling':
      return generateCreateHalfSiblingNodeParams({
        pedigree: params.pedigree,
        sex: params.sex,
        targetNodeId: params.targetNodeId,
        otherParentNodeId: params.otherParentNodeId,
        sharedRelationshipType: params.sharedRelationshipType,
      });
    case 'fraternal-twins':
      return generateCreateTwinNodeParams({
        targetNodeId: params.targetNodeId,
        relationshipType: 'fraternal-twins',
        numberOfMaleSiblings: params.numberOfMaleSiblings,
        numberOfFemaleSiblings: params.numberOfFemaleSiblings,
        numberOfUnknownSexSiblings: params.numberOfUnknownSexSiblings,
      });
    case 'identical-twins':
      return generateCreateTwinNodeParams({
        targetNodeId: params.targetNodeId,
        relationshipType: 'identical-twins',
        numberOfSiblings: params.numberOfSiblings!,
      });
    default:
      throw new Error('Invalid relationship type');
  }
}

class NodeNotFoundError extends Error {
  constructor(pedigreeNodeId: string) {
    super(`Node with id ${pedigreeNodeId} not found`);
  }
}
