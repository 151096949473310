import { Grid } from '@mui/material';
import { ObjectFieldTemplateProps } from '@rjsf/utils';
import { useMemo } from 'react';
import {
  ContentsFieldsWithSubsection,
  Element,
  Item,
  Subsection,
} from './ContentsFieldsWithSubsection';

export interface GroupElement extends Element {
  subsection?: Subsection;
  hiddenSubsection?: boolean;
}

interface Subsections {
  'ui:title': string;
  'ui:items': {
    'ui:title': string;
    'ui:properties': string[];
  }[];
}

export const ListFieldContentsView = (props: ObjectFieldTemplateProps) => {
  const isNarrowForm = props.formContext.narrowForm;
  const numberOfColumns = isNarrowForm ? 6 : 12;
  const alignItems = props.uiSchema?.['ui:alignItems'] || 'initial';
  const gap =
    props.uiSchema?.['ui:accordionsGap'] ||
    props.uiSchema?.['ui:gapItems'] ||
    3;

  const subsections: Subsections[] = props.uiSchema?.['ui:subsections'];

  const elements: GroupElement[] = useMemo(() => {
    return groupElements({
      properties: props.properties,
      subsections,
      readonly: props.readonly,
    });
  }, [props.properties, props.readonly, subsections]);

  return (
    <Grid container gap={gap} columns={numberOfColumns} alignItems={alignItems}>
      {elements.map((element, index) => {
        const uiSchema = props.uiSchema?.[element.name];
        const gridColumnWidth = uiSchema?.['ui:gridColumnWidth'];

        const isNumber = typeof gridColumnWidth === 'number';
        const gridColumnWidthValue = isNumber
          ? gridColumnWidth -
            calculateAdjustedColumnWidth(gridColumnWidth, gap, numberOfColumns)
          : gridColumnWidth;

        const flexGrow = isNarrowForm ? uiSchema?.['ui:flexGrow'] : 'initial';
        const alignSelf = uiSchema?.['ui:alingSelf'] || 'initial';

        if (uiSchema?.['ui:widget'] === 'hidden') {
          return null;
        }

        const formData = (element.content as React.ReactElement)?.props
          ?.formData;
        const answerIsMissing =
          formData === undefined || formData?.length === 0;
        const noRenderer = props.readonly && answerIsMissing;

        if (element.subsection && element.subsection.hiddenSubsection) {
          return null;
        }

        if (element.subsection) {
          return (
            <Grid
              item
              key={index}
              xs={gridColumnWidthValue || numberOfColumns}
              style={{ flexGrow, alignSelf }}
            >
              <ContentsFieldsWithSubsection
                gap={gap}
                subsection={element.subsection}
              />
            </Grid>
          );
        }

        if (noRenderer) {
          return null;
        }

        return (
          <Grid
            item
            key={index}
            xs={gridColumnWidthValue || numberOfColumns}
            style={{ flexGrow, alignSelf }}
          >
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};

const calculateAdjustedColumnWidth = (
  gridColumnWidth: number,
  gap: number,
  numberOfColumns: number,
): number => {
  const baseColumnWidth = gridColumnWidth / numberOfColumns;
  const gapFraction = (gap * 8) / 100;
  const gapPerColumn = gapFraction / numberOfColumns;
  const finalColumnWidth = baseColumnWidth - gapPerColumn;
  return finalColumnWidth;
};

interface GroupElementsProps {
  properties: Element[];
  subsections: Subsections[];
  readonly?: boolean;
}

const groupElements = (props: GroupElementsProps) => {
  const { properties, subsections, readonly } = props;

  const elements: GroupElement[] = [];

  const belongToSubsection = (property: Element) => {
    return subsections?.some((subsection) =>
      subsection['ui:items']?.some((item) =>
        item['ui:properties']?.includes(property.name),
      ),
    );
  };

  const addElementsWithoutSubsection = () => {
    properties.forEach((property) => {
      if (!belongToSubsection(property)) {
        elements.push(property);
      }
    });
  };

  const addGroupItems = (
    groupedItems: Item[],
    title: string,
    hiddenSubsection: boolean,
  ) => {
    elements.push({
      name: '',
      content: null,
      subsection: {
        title,
        items: groupedItems,
        hiddenSubsection,
      },
    });
  };

  const addElementsWithSubsection = () => {
    subsections?.forEach((subsection) => {
      const groupedItems = subsection['ui:items']
        ?.map((item) => {
          const itemElements = properties.filter((property) =>
            item['ui:properties'].includes(property.name),
          );

          const answerIsMissing = itemElements.every(
            (element) =>
              (element.content as React.ReactElement).props.formData ===
              undefined,
          );
          return {
            title: item['ui:title'],
            elements: itemElements,
            hiddenItem: readonly ? answerIsMissing : false,
          };
        })
        .filter((groupedItem) => groupedItem.elements.length > 0);

      if (groupedItems.length > 0) {
        let hiddenSubsection = false;

        if (readonly) {
          hiddenSubsection = groupedItems.every(
            (groupedItem) => groupedItem.hiddenItem,
          );
        }

        addGroupItems(groupedItems, subsection['ui:title'], hiddenSubsection);
      }
    });
  };

  addElementsWithoutSubsection();
  addElementsWithSubsection();

  return elements;
};
