import { AxiosError } from 'axios';
import {
  GenerateCreateNodeParams,
  generateCreateNodeParams,
} from 'common/features/pedigree/common/generate-create-node-params';
import { PedigreeNodeSex } from 'common/features/pedigree/common/types';
import useDialog, { DialogContent } from 'common/hooks/useDialog';
import { useMyFamilyTreeSubmissionService } from 'myfamilytree/api/myFamilyTreeSubmission/MyFamilyTreeSubmissionServiceProvider';
import {
  CreateNodeParams,
  Pedigree,
} from 'myfamilytree/api/myFamilyTreeSubmission/my-family-tree-submission-service';
import { ErrorSnackbar } from 'myfamilytree/common';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { AddNodeAction } from '../views/PedigreeNodeMemberMenuView';

interface usePedigreeAddNodeActionProps {
  pedigree: Pedigree;
}

const RELATIONSHIP_TYPE_MAP: Record<string, any> = {
  parents: 'parents',
  partner: 'partner',
  brother: 'sibling',
  sister: 'sibling',
  son: 'child',
  daughter: 'child',
};

const PedigreeNodeSex_MAP: Record<string, PedigreeNodeSex> = {
  brother: PedigreeNodeSex.MALE,
  sister: PedigreeNodeSex.FEMALE,
  son: PedigreeNodeSex.MALE,
  daughter: PedigreeNodeSex.FEMALE,
};

export const usePedigreeAddNodeAction = (
  props: usePedigreeAddNodeActionProps,
) => {
  const [dialogContent, setDialogContent] = useState<
    DialogContent | undefined
  >();
  const [snackbarErrorMessage, setSnackbarError] = useState<string>('');

  const service = useMyFamilyTreeSubmissionService();
  const { t } = useTranslation('myFamilyTree');
  const queryClient = useQueryClient();

  const createMemberMutation = useMutation(
    (nodeParams: CreateNodeParams) =>
      service?.addNewPedigreeNode(props.pedigree.id, nodeParams),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('pedigree');
      },
      onError: (error: AxiosError) => {
        handleActionError(error.response?.data.message);
      },
    },
  );

  const createNewNodeMember = async (params: GenerateCreateNodeParams) => {
    try {
      const nodeParams = generateCreateNodeParams(params);
      return createMemberMutation.mutate(nodeParams);
    } catch (error) {
      handleActionError(error as string);
    }
  };

  const { dialog, openDialog, closeDialog } = useDialog({
    dialogContent,
    onDialogConfirmClick: () => {
      closeDialog();
    },
  });

  const handleActionError = (message: string) => {
    if (message === 'Child already has both parents') {
      setDialogContent({
        title: t(`pedigree.node.member.dialog.title.has-parents-error.add`),
        content: t(`pedigree.node.member.dialog.content.has-parents-error.add`),
        textConfirm: t(`pedigree.node.member.dialog.button.isNotAllow.add`),
        textCancel: undefined,
      });
      openDialog();
    } else {
      setSnackbarError(t('common-snackBar-message.error'));
    }
  };

  const handleAddNodeAction = (
    value: AddNodeAction,
    pedigreeNodeId: string,
  ) => {
    createNewNodeMember({
      pedigree: props.pedigree,
      sex: PedigreeNodeSex_MAP[value] || null,
      targetNodeId: pedigreeNodeId,
      relationshipType: RELATIONSHIP_TYPE_MAP[value],
    });
  };

  return {
    addNodeDialog: dialog,
    addNodeSnackbar: (
      <ErrorSnackbar
        onClose={() => setSnackbarError('')}
        errorMessage={snackbarErrorMessage}
      ></ErrorSnackbar>
    ),
    handleAddNodeAction,
  };
};
