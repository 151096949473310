import { StaffPedigreeCaseServiceProvider } from 'api/pedigreeCase/staff-pedigree-case-service';
import TopNav from 'common/layouts/TopNav';
import { withStaffAuthProvider } from 'features/auth/staff/StaffAuthProvider';
import { ROUTES } from 'iPedigree/routes';
import { useStaffAuth } from 'myfamilytree/common/index';
import { useEffect } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';

const DOMAIN_MAP: { [key: string]: string } = {
  'myfamilytree.dev.arkus.ai': 'ipedigree.dev.arkus.ai',
  'myfamilytree.arkus.ai': 'ipedigree.arkus.ai',
};

type Path =
  | 'dashboard'
  | 'newPatient'
  | 'sendRequest'
  | 'sendReminder'
  | 'patient';

const RedirectToNewDomain = ({ path }: { path: Path }) => {
  const { patientId } = useParams();

  useEffect(() => {
    const subdomain = window.location.hostname;
    const newDomain = DOMAIN_MAP[subdomain] || subdomain;

    if (subdomain === newDomain) return;

    const protocolAndDomain = `${window.location.protocol}//${newDomain}`;

    const urlMap = {
      dashboard: `${protocolAndDomain}${window.location.pathname}`,
      newPatient: `${protocolAndDomain}${ROUTES.newPatient}`,
      sendRequest: `${protocolAndDomain}${ROUTES.patient}/${patientId}/pedigree/send-request`,
      sendReminder: `${protocolAndDomain}${ROUTES.patient}/${patientId}/pedigree/send-reminder`,
      patient: `${protocolAndDomain}${ROUTES.patient}/${patientId}${
        new URLSearchParams(window.location.search).get('tab') === 'pedigree'
          ? '/pedigree'
          : ''
      }`,
    };

    window.location.href = urlMap[path] || protocolAndDomain;
  }, [path, patientId]);

  return null;
};

function MyFamilyTreeManagementRouter() {
  const { accessToken, logout } = useStaffAuth();

  return (
    <StaffPedigreeCaseServiceProvider accessToken={accessToken} logout={logout}>
      <TopNav />
      <Routes>
        <Route path="/" element={<RedirectToNewDomain path="dashboard" />} />
        <Route
          path="/new-patient"
          element={<RedirectToNewDomain path="newPatient" />}
        />
        <Route
          path="/patient/:patientId/send-request"
          element={<RedirectToNewDomain path="sendRequest" />}
        />
        <Route
          path="/patient/:patientId/send-reminder"
          element={<RedirectToNewDomain path="sendReminder" />}
        />
        <Route
          path="/patient/:patientId"
          element={<RedirectToNewDomain path="patient" />}
        />
      </Routes>
    </StaffPedigreeCaseServiceProvider>
  );
}

export default withStaffAuthProvider(MyFamilyTreeManagementRouter);
