import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import se from '../translations/se/all.json';
import commonSe from '../translations/se/common.json';
import myFamilyTreeSe from '../translations/se/myFamilyTree.json';
import passwordSettingsSe from '../translations/se/passwordSettings.json';
import relationshipSe from '../translations/se/relationship.json';

const eConsentI18nInstance = i18n.createInstance();

eConsentI18nInstance
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      se: {
        all: se,
        relationship: relationshipSe,
        myFamilyTree: myFamilyTreeSe,
        common: commonSe,
        passwordSettings: passwordSettingsSe,
      },
    },
    fallbackLng: 'se',
    detection: {
      order: ['cookie', 'localStorage'],
      caches: ['cookie', 'localStorage'],
    },
    interpolation: {
      escapeValue: false,
    },
  });

eConsentI18nInstance.services.formatter?.add(
  'capitalize',
  (value: string) => value.charAt(0).toUpperCase() + value.slice(1),
);

eConsentI18nInstance.services.formatter?.add(
  'capitalizeWords',
  (value: string) =>
    value
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' '),
);

export default eConsentI18nInstance;
