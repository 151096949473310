import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useStaffPatientService } from 'api/patient/staff-patient-service';
import { CircularLoading } from 'components/common/CircularLoading';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { EligibilityResultsContent } from './EligibilityResultsContent';

export const EligibilityOfGeneticTesting = (props: { patientId: string }) => {
  const patientService = useStaffPatientService();

  const theme = useTheme();
  const { t } = useTranslation(['iPedigree', 'common']);

  const { data: eligibilityResults, isLoading } = useQuery(
    ['eligibilityResults', props.patientId],
    () => patientService.getEligibilityResults(props.patientId),
  );

  const hasMultipleCriteria = eligibilityResults?.length ?? 0 > 1;

  return (
    <Accordion
      elevation={0}
      disableGutters
      sx={{
        border: `1px solid ${theme.palette.common.opacity.darkBlue[25]}`,
        borderRadius: '4px',
        '&:before': {
          height: 0,
        },
      }}
    >
      <AccordionSummary
        expandIcon={
          <FontAwesomeIcon
            color={theme.palette.common.brand.darkBlue}
            icon={faCaretDown}
          />
        }
      >
        <Typography variant="h4">
          {t('page-patient-eligibility-genetic-testing.title')}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: theme.spacing(3), paddingTop: '12px' }}>
        <Stack gap={3}>
          {isLoading && (
            <Stack alignItems="center">
              <CircularLoading label={t('common:loading-label')} />
            </Stack>
          )}
          {eligibilityResults &&
            eligibilityResults?.map((result, index) => (
              <Stack key={index}>
                <EligibilityResultsContent
                  criteriaType={result.criteriaType}
                  eligibilityStatus={result.eligibilityStatus}
                  satisfiedCriteria={result.satisfiedCriteria}
                  footnotes={result.footnotes}
                  hasMultipleResults={!!hasMultipleCriteria}
                />
              </Stack>
            ))}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};
