import { faPen, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

interface GeneticTestHeaderProps {
  title: string;
  status?: string;
  showAction: boolean;
  headerAction: 'edit' | 'delete';
  onGeneticTestDelete: () => void;
  onGeneticTestEdit?: () => void;
}

export const GeneticTestHeader = (props: GeneticTestHeaderProps) => {
  const {
    title,
    status,
    headerAction,
    showAction,
    onGeneticTestEdit,
    onGeneticTestDelete,
  } = props;

  const theme = useTheme();
  const { t } = useTranslation(['geneticTestForm']);

  const ACTION_MAP = {
    edit: {
      icon: faPen,
      title: t('tooltip-edit.title'),
      onClick: onGeneticTestEdit,
    },
    delete: {
      icon: faTrashCan,
      title: t('tooltip-delete.title'),
      onClick: onGeneticTestDelete,
    },
  };

  const { icon, title: tooltipTitle, onClick } = ACTION_MAP[headerAction];

  return (
    <Stack>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h5">{title}</Typography>
        {showAction && (
          <Tooltip title={tooltipTitle} arrow>
            <IconButton
              size="small"
              onClick={onClick}
              sx={{
                color: theme.palette.common.brand.darkBlue,
                width: 24,
                height: 24,
              }}
            >
              <FontAwesomeIcon icon={icon} />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
      <Typography variant="caption">{status}</Typography>
    </Stack>
  );
};
