import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons/faCircleInfo';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Divider, Link, Stack, Typography, useTheme } from '@mui/material';
import {
  EligibilityStatus,
  SatisfiedCriteria,
  SupportingEvidence,
} from 'api/patient/staff-patient-service';
import { useRelationshipOptions } from 'features/common/hooks/useRelationshipOptions';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface EligibilityCriteriaListProps {
  satisfiedCriteria: SatisfiedCriteria[];
  eligibilityStatus: EligibilityStatus;
  showMore: boolean;
  hasMultipleResults: boolean;
}

export const EligibilityCriteriaList = (
  props: EligibilityCriteriaListProps,
) => {
  const { satisfiedCriteria, showMore, eligibilityStatus, hasMultipleResults } =
    props;

  const isPotentiallyEligible = eligibilityStatus === 'POTENTIALLY_ELIGIBLE';
  const isEligible = eligibilityStatus === 'ELIGIBLE';
  const isNotEligible = eligibilityStatus === 'NOT_ELIGIBLE';
  const multipleCriteria = satisfiedCriteria?.length > 1;
  const showfirstCriteria =
    isEligible || (isPotentiallyEligible && !hasMultipleResults);
  const allItemsHasDivider = multipleCriteria && isPotentiallyEligible;

  const showCriteria = useCallback(
    (index: number) => {
      return showMore || (showfirstCriteria && index === 0);
    },
    [showMore, showfirstCriteria],
  );

  const showDivider = useCallback(
    (index: number) => {
      return (
        (showMore && allItemsHasDivider) ||
        index === satisfiedCriteria.length - 1
      );
    },
    [allItemsHasDivider, satisfiedCriteria.length, showMore],
  );

  if (isNotEligible) {
    return (
      <Stack gap={3}>
        {satisfiedCriteria?.map((criteria, index) => (
          <Typography key={index} variant="body1">
            {criteria.description}
          </Typography>
        ))}
      </Stack>
    );
  }

  return (
    <Stack gap={3}>
      {satisfiedCriteria?.map((criteria, index) =>
        showCriteria(index) ? (
          <Stack key={index} gap={3}>
            <CriteriaItem
              description={criteria.description}
              sourceUrl={criteria.sourceUrl}
              supportingEvidence={criteria.supportingEvidence}
            />
            {showDivider(index) && <Divider />}
          </Stack>
        ) : null,
      )}
    </Stack>
  );
};

interface CriteriaItemProps {
  description: string;
  sourceUrl: string | null;
  supportingEvidence: SupportingEvidence[] | null;
}

const CriteriaItem = (props: CriteriaItemProps) => {
  const { description, sourceUrl, supportingEvidence } = props;

  const { t } = useTranslation(['iPedigree']);
  const theme = useTheme();

  const allConfirmed = useMemo(
    () => supportingEvidence?.every((e) => e.isDiagnosisConfirmed),
    [supportingEvidence],
  );

  const statusColor = allConfirmed
    ? theme.palette.success.main
    : theme.palette.warning.main;

  const icon = allConfirmed ? faCheckCircle : faCircleInfo;

  return (
    <Stack gap={2}>
      <Stack direction="row" gap={1}>
        <FontAwesomeIcon size="lg" icon={icon} color={statusColor} />
        <Typography variant="body1">
          {description}
          {sourceUrl && (
            <Link href={sourceUrl} target="_blank" sx={{ marginLeft: '4px' }}>
              <span>
                {t(
                  'page-patient-eligibility-genetic-testing.satisfied-criteria.view-guideline',
                )}{' '}
                <FontAwesomeIcon icon={faExternalLinkAlt} />
              </span>
            </Link>
          )}
        </Typography>
      </Stack>
      {supportingEvidence && (
        <SupportingEvidenceList supportingEvidence={supportingEvidence} />
      )}
    </Stack>
  );
};

const SupportingEvidenceList = ({
  supportingEvidence,
}: {
  supportingEvidence: SupportingEvidence[];
}) => {
  const { t } = useTranslation(['iPedigree']);
  return (
    <Stack gap={1} ml={3.5}>
      <Typography variant="h5">
        {t(
          'page-patient-eligibility-genetic-testing.satisfied-criteria.supporting-evidence',
        )}
        :
      </Typography>
      <Stack ml={1.5}>
        {supportingEvidence?.map((evidence, index) => (
          <SupportingEvidenceItem key={index} {...evidence} />
        ))}
      </Stack>
    </Stack>
  );
};

interface SupportingEvidenceItemProps extends SupportingEvidence {}

const SupportingEvidenceItem = (props: SupportingEvidenceItemProps) => {
  const { isDiagnosisConfirmed, relationship, diseaseName, ageAtDiagnosis } =
    props;

  const theme = useTheme();
  const { t } = useTranslation(['iPedigree']);
  const { translateRelationship } = useRelationshipOptions();

  const confirmedTextColor = isDiagnosisConfirmed
    ? theme.palette.success.main
    : theme.palette.warning.main;

  const baseLabel =
    'page-patient-eligibility-genetic-testing.supporting-evidence';
  const diagnosisStatusText = isDiagnosisConfirmed
    ? t(`${baseLabel}.confirmed`)
    : t(`${baseLabel}.not-confirmed`);

  return (
    <Typography variant="body1">
      {`\u2022 ${translateRelationship(relationship)}, ${diseaseName} ${t(
        `${baseLabel}.at`,
      )} ${ageAtDiagnosis}, `}
      <span style={{ color: confirmedTextColor }}>{diagnosisStatusText}</span>
    </Typography>
  );
};
