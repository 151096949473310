import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog as MuiDialog,
} from '@mui/material';
import { usePedigreeContext } from 'common/features/pedigree/common/pedigreeContext';
import { ReactNode } from 'react';

export type DialogContentType = {
  open: boolean;
  title: string;
  content: ReactNode;
  actions: React.ReactNode[];
};

const Dialog = () => {
  const { dialogContent } = usePedigreeContext();
  const { open, title, content, actions } = dialogContent;

  return (
    <MuiDialog open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>{actions}</DialogActions>
    </MuiDialog>
  );
};

export default Dialog;
