import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, Stack, Typography } from '@mui/material';
import { PedigreeNodeSex } from 'common/features/pedigree/common/types';
import { Trans, useTranslation } from 'react-i18next';
import PedigreeNodeConsumerView from './PedigreeNodeConsumerView';

interface PedigreeSubmitDialogElementViewProps {
  showNodeCard?: boolean;
  href?: string;
  content?: string;
}

export const PedigreeSubmitDialogElementView = ({
  showNodeCard = false,
  content,
  href,
}: PedigreeSubmitDialogElementViewProps) => {
  const { t } = useTranslation('myFamilyTree');

  return (
    <>
      {content && (
        <Typography sx={{ whiteSpace: 'pre-wrap' }}>
          <Trans
            t={t}
            i18nKey={content}
            components={{
              anchor: <Link href={href}></Link>,
            }}
          ></Trans>
        </Typography>
      )}
      {showNodeCard && (
        <Stack alignItems="center" mt={3} mb={3} sx={{ position: 'relative' }}>
          <PedigreeNodeConsumerView
            sex={PedigreeNodeSex.FEMALE}
            isIndex={false}
            isSelected={true}
            hasCancerHistory={true}
            hasError={true}
            onClick={() => {}}
            nodeSize={{ width: 165, height: 152 }}
            addDiagnosisLabel={t('pedigree.node.member.addDiagnosis.label')}
            cancerHistoryLabel={t('pedigree.node.member.cancerHistory.label')}
            relativeRelationLabel={t(
              'onBoarding-drawer.description.relative-relation-label',
            )}
            styleProps={{ borderWidth: 2 }}
          ></PedigreeNodeConsumerView>
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              position: 'absolute',
              bottom: -18,
              p: 1,
              width: 20,
              height: 20,
              border: 0,
              borderRadius: '50%',
              background: 'white',
              boxShadow: 2,
              color: 'common.brand.blue',
            }}
          >
            <FontAwesomeIcon icon={faPlus} size="2x" />
          </Stack>
        </Stack>
      )}
    </>
  );
};
