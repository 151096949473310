import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  useTheme,
} from '@mui/material';
import { IPedigreeItem } from 'api/pedigreeCase/staff-pedigree-case-service';
import DateView from 'iPedigree/common/DateView';
import { ROUTES } from 'iPedigree/routes';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import ActionButtons from './ActionButtons';
import NewTagView from './NewTagView';
import PedigreeStatusView from './PedigreeStatusView';
import PersonalNumberView from './PersonalNumberView';
import { TableLoading } from './TableLoading';

interface PedigreeTableProps {
  pedigreeList?: IPedigreeItem[];
  totalNumberOfRows: number;
  page: number;
  limit: number;
  isLoading: boolean;
  setPage: (page: number) => void;
  onPedigreeDownloadClick: (pedigreeCaseId: string) => void;
  onPedigreePrintClick: (pedigreeCaseId: string) => void;
}

export const PedigreeTable = (props: PedigreeTableProps) => {
  const {
    pedigreeList,
    totalNumberOfRows,
    page,
    limit,
    isLoading,
    setPage,
    onPedigreeDownloadClick,
    onPedigreePrintClick,
  } = props;

  const { t } = useTranslation('iPedigree');
  const navigate = useNavigate();

  const headings = [
    '',
    t('page-dashboard.table.heading.name'),
    t('page-dashboard.table.heading.personalName'),
    t('page-dashboard.table.heading.status'),
    t('page-dashboard.table.heading.date'),
    t('page-dashboard.table.heading.familyId'),
    t('page-dashboard.table.heading.actions'),
  ];

  const handleOnViewClick = (patientId: string) => {
    navigate(`${ROUTES.patient}/${patientId}/pedigree`);
  };

  const handleOnReminderClick = (patientId: string) => {
    navigate(`${ROUTES.patient}/${patientId}/pedigree/send-reminder`);
  };

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {headings.map((heading) => (
                <TableCell align="left" key={heading}>
                  {heading}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && <TableLoading colSpan={headings.length} />}
            {!isLoading && pedigreeList && (
              <TablePedigreeList
                pedigreeList={pedigreeList}
                onPedigreeDownloadClick={onPedigreeDownloadClick}
                onPedigreePrintClick={onPedigreePrintClick}
                handleOnViewClick={handleOnViewClick}
                handleOnReminderClick={handleOnReminderClick}
              />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!isLoading && (
        <TablePagination
          component="div"
          count={totalNumberOfRows}
          page={page}
          onPageChange={(_, nextPage) => {
            setPage(nextPage);
          }}
          rowsPerPage={limit}
          rowsPerPageOptions={[]}
        />
      )}
    </>
  );
};

const TablePedigreeList = ({
  pedigreeList,
  onPedigreeDownloadClick,
  onPedigreePrintClick,
  handleOnViewClick,
  handleOnReminderClick,
}: {
  pedigreeList: IPedigreeItem[];
  onPedigreeDownloadClick: (pedigreeCaseId: string) => void;
  onPedigreePrintClick: (pedigreeCaseId: string) => void;
  handleOnViewClick: (patientId: string) => void;
  handleOnReminderClick: (patientId: string) => void;
}) => {
  const theme = useTheme();

  return (
    <>
      {pedigreeList?.map((pedigree) => (
        <TableRow key={pedigree.patientId}>
          <TableCell sx={{ p: 0, pl: 2, width: 65 }}>
            {pedigree.new && <NewTagView />}
          </TableCell>
          <TableCell>
            <Link
              to={`${ROUTES.patient}/${pedigree.patientId}`}
              style={{
                textDecoration: 'none',
                color: theme.palette.common.brand.blue,
              }}
            >
              {pedigree.patientName}
            </Link>
          </TableCell>
          <TableCell>
            <PersonalNumberView
              personalNumber={pedigree.patientPersonalNumber}
            />
          </TableCell>
          <TableCell>
            <PedigreeStatusView pedigreeStatus={pedigree.pedigreeStatus} />
          </TableCell>
          <TableCell>
            <DateView isoDate={pedigree.pedigreeStatusDate} />
          </TableCell>
          <TableCell>{pedigree.familyId}</TableCell>
          <TableCell sx={{ padding: 0.5 }}>
            {pedigree.pedigreeStatus === 'requested' && (
              <ActionButtons
                onReminderClick={() =>
                  handleOnReminderClick(pedigree.patientId)
                }
              />
            )}
            {pedigree.pedigreeStatus === 'received' && (
              <ActionButtons
                onViewClick={() => handleOnViewClick(pedigree.patientId)}
              />
            )}
            {pedigree.pedigreeStatus === 'created' && (
              <ActionButtons
                onDownloadClick={() =>
                  onPedigreeDownloadClick(pedigree.pedigreeCaseId)
                }
                onPrintClick={() =>
                  onPedigreePrintClick(pedigree.pedigreeCaseId)
                }
                onViewClick={() => handleOnViewClick(pedigree.patientId)}
              />
            )}
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};
