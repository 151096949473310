import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import { Box, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useTheme } from '@mui/material/styles';
import SectionBox from 'components/common/SectionBox';
import { FormError, FormHelper } from 'components/forms';
import { TextInput } from 'components/inputs';
import { useTranslation } from 'react-i18next';

interface AddPersonalNumberFormViewProps {
  personalNumber: string;
  setPersonalNumber: (personalNumber: string) => void;
  personalNumberError: string;
  personalNumberValid: boolean;
  handleProceedClick: () => void;
  isValidating?: boolean;
  disabled?: boolean;
}

function AddPersonalNumberFormView(props: AddPersonalNumberFormViewProps) {
  const theme = useTheme();
  const { t } = useTranslation(['all']);

  return (
    <SectionBox
      sx={{
        p: 4,
      }}
    >
      <Stack direction="column" spacing={3}>
        <Typography variant="h2">
          {t('consent-management.new-patient.section1.title')}
        </Typography>
        <Stack spacing={0.5}>
          <Box>
            <Grid container alignItems="end" spacing={3}>
              <Grid xs={12} md={6}>
                <TextInput
                  label={t(
                    'consent-management.new-patient.section1.personal-number',
                  )}
                  id="personal-number"
                  value={props.personalNumber}
                  onChange={(event) => {
                    props.setPersonalNumber(event.target.value);
                  }}
                  required
                  textFieldProps={{
                    placeholder: t('personal-number.placeholder'),
                    disabled: props.disabled,
                    inputProps: {
                      maxLength: 15,
                      minLength: 12,
                    },
                  }}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{
                    lineHeight: '28px',
                    fontWeight: 400,
                  }}
                  onClick={props.handleProceedClick}
                  disabled={props.disabled}
                  loading={props.isValidating}
                >
                  {t('consent-management.new-patient.section1.proceed.button')}
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
          {props.personalNumberError ? (
            <FormError error={props.personalNumberError} />
          ) : props.personalNumberValid ? (
            <FormHelper
              formHelperTextProps={{
                sx: { color: theme.palette.success.main },
              }}
            >
              <FontAwesomeIcon
                icon={faCircleCheck}
                style={{
                  paddingRight: theme.spacing(1),
                }}
              />
              {t('consent-management.new-patient.section1.proceed.valid')}
            </FormHelper>
          ) : (
            <FormHelper>
              {t(
                'consent-management.new-patient.section1.personal-number-helper-text',
              )}
            </FormHelper>
          )}
        </Stack>
      </Stack>
    </SectionBox>
  );
}

export default AddPersonalNumberFormView;
