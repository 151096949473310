import { Stack, Typography, useTheme } from '@mui/material';
import { FormError } from 'components/forms';
import { TextInput } from 'components/inputs';
import { useTranslation } from 'react-i18next';
import { NumberOfSiblings } from '../../controllers/nodeSelectionForm/TwinSelectionFormController';

interface SelectionSiblingsFormProps {
  isIdenticalTwins: 'yes' | 'no' | 'notSure' | null;
  fields: {
    label: string;
    value: string;
    setValue: (value: string) => void;
  }[];
  error: Record<string, string>;
  numberOfSiblings: string | null;
  handleNumberSiblings: (
    key: keyof NumberOfSiblings,
  ) => (value: string) => void;
}

export const SelectionSiblingsForm = ({
  isIdenticalTwins,
  fields,
  error,
  numberOfSiblings,
  handleNumberSiblings,
}: SelectionSiblingsFormProps) => {
  return (
    <>
      {isIdenticalTwins === 'yes' ? (
        <RenderSiblingQuantityInput
          errorMessage={error.numberOfSiblings}
          numberOfSiblings={numberOfSiblings}
          handleNumberSiblings={handleNumberSiblings}
        />
      ) : (
        <RenderFraternalSiblingsInputs
          errorMessage={error.numberOfFraternalSiblings}
          fields={fields}
        />
      )}
    </>
  );
};

interface RenderSiblingQuantityInputProps {
  errorMessage: string;
  numberOfSiblings: string | null;
  handleNumberSiblings: (
    key: keyof NumberOfSiblings,
  ) => (value: string) => void;
}

const RenderSiblingQuantityInput = (props: RenderSiblingQuantityInputProps) => {
  const { errorMessage, numberOfSiblings, handleNumberSiblings } = props;
  const { t } = useTranslation('pedigree');

  return (
    <Stack maxWidth="fit-content">
      <TextInput
        key="sibling-quantity"
        id="sibling-quantity"
        label={t('node-form-add-twin.sibling-quantity.label')}
        value={numberOfSiblings || ''}
        onChange={(e) =>
          handleNumberSiblings('numberOfSiblings')(e.target.value)
        }
        required
        error={errorMessage}
        textFieldProps={{
          type: 'number',
          inputMode: 'numeric',
          inputProps: { min: 0 },
          helperText: t('node-form-add-twin.sibling-quantity.helper-text'),
        }}
      />
    </Stack>
  );
};

interface RenderFraternalSiblingsInputsProps {
  fields: {
    label: string;
    value: string;
    setValue: (value: string) => void;
  }[];
  errorMessage: string;
}

const RenderFraternalSiblingsInputs = (
  props: RenderFraternalSiblingsInputsProps,
) => {
  const { fields, errorMessage } = props;

  const theme = useTheme();
  const { t } = useTranslation('pedigree');

  return (
    <Stack spacing={2}>
      <Typography variant="body1">
        {t('node-form-add-twin.sibling-quantity.label')}
        <span style={{ color: theme.palette.error.main, paddingLeft: '3px' }}>
          *
        </span>
      </Typography>
      {fields.map(({ label, value, setValue }) => (
        <TextInput
          key={`sibling-${label}`}
          id={`sibling-${label}`}
          label={label}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          inlineLabel
          error={errorMessage}
          textFieldProps={{
            sx: { maxWidth: 50 },
            type: 'number',
            inputMode: 'numeric',
            inputProps: { min: 0 },
          }}
        />
      ))}
      {errorMessage && <FormError error={errorMessage} />}
    </Stack>
  );
};
