import { RelativeInfo } from 'api/consentManagement/consent-management-service';
import { constructDataValidator } from 'features/common/form-validation-utils';
import {
  emailSchema,
  phoneNumberSchema,
} from 'features/common/validation-schemas';
import Joi from 'joi';
import { PatientData } from '../PatientForm';

export type RelativeFormData = RelativeInfo & {
  id?: number;
  disabled?: boolean;
  specificProxySigner?: boolean;
};

const personalNumberSchema = Joi.string()
  .length(12)
  .pattern(/^[0-9]+$/);

const patientInformationSchema = Joi.object({
  name: Joi.string().required(),
  phoneNumber: phoneNumberSchema,
  email: emailSchema,
  consentFor: Joi.string().optional().allow(''),
  personalNumber: personalNumberSchema.optional().allow(''),
});

const relativeSchema = Joi.object({
  id: Joi.number().optional(),
  disabled: Joi.boolean().optional(),
  personId: Joi.number().optional(),
  name: Joi.string().optional().allow(''),
  personalNumber: personalNumberSchema.optional().allow(''),
  relationshipToPatient: Joi.string().required(),
  proxySignerName: Joi.string().when('specificProxySigner', {
    is: true,
    then: Joi.string().optional().allow(''),
    otherwise: Joi.string().optional().allow(''),
  }),
  proxySignerPersonalNumber: Joi.string().when('specificProxySigner', {
    is: true,
    then: personalNumberSchema.optional().allow(''),
    otherwise: Joi.string().optional().allow(''),
  }),
  proxySignerToConsenteeRelationship: Joi.string().when('specificProxySigner', {
    is: true,
    then: Joi.string().required(),
    otherwise: Joi.string().optional().allow(''),
  }),
  deceased: Joi.boolean().optional(),
  specificProxySigner: Joi.boolean().required(),
});

export const validatePatientData = constructDataValidator<PatientData>(
  patientInformationSchema,
);

export const validateSingleRelativeData =
  constructDataValidator<RelativeFormData>(relativeSchema);

export const validateRelativeData = (data: RelativeFormData[]) => {
  const result = data.map((relativeData) =>
    validateSingleRelativeData(relativeData),
  );
  let valid = result.every((validationResult) => validationResult.valid);
  const errorMessages = result.map(
    (validationResult) => validationResult.errorMessages || {},
  );
  if (data.length === 0) {
    return {
      valid: false,
      errorMessages: [],
    };
  }
  return { valid, errorMessages };
};
