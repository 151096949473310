import { Box } from '@mui/material';
import { useRef, useState } from 'react';
import { NodeFormController } from '../common/controllers/nodeForm/NodeFormController';
import { usePedigreeLayout } from '../common/hooks/usePedigreeLayout';
import { usePedigreeContext } from '../common/pedigreeContext';
import Dialog from '../common/views/Dialog';
import { PedigreeActionsButtons } from '../common/views/PedigreeActionsButtons';
import PedigreeEdges from '../common/views/pedigreeRenderer/PedigreeEdges';
import { ResponsiveRenderer } from '../common/views/pedigreeRenderer/ResponsiveRenderer';
import { NodePatientController } from './controllers/NodePatientController';
import { PatientOnboardingController } from './controllers/PatientOnboardingController';

export const PedigreePatient = () => {
  const { pedigree, pedigreeType, NodeMenu } = usePedigreeContext();
  const stageRef = useRef<any>(null);

  const { layout, layoutSize } = usePedigreeLayout({
    pedigreeNodes: pedigree?.nodes || [],
    pedigreeType,
  });

  const [pedigreeNodesWithCancerHistory, setPedigreeNodesWithCancerHistory] =
    useState<string[]>([]);

  const [drawerStatus, setDrawerStatus] = useState<
    'onboarding' | 'nodeForm' | null
  >(null);

  const isNodeSelected = (pedigreeId: string) => {
    //if multiple compare with pedigreeNodesWithCancerHistory if not compare with selectedNodeId
    return true;
  };

  return (
    <Box>
      <PedigreeActionsButtons
        handleInfoIconClick={() => setDrawerStatus('onboarding')}
        direction="column"
      />
      <Dialog />
      {drawerStatus ? (
        <PatientOnboardingController
          pedigreeId={pedigree.id}
          pedigreeNodesWithCancerHistory={pedigreeNodesWithCancerHistory}
          setPedigreeNodesWithCancerHistory={setPedigreeNodesWithCancerHistory}
        />
      ) : (
        <NodeFormController />
      )}
      <ResponsiveRenderer stageRef={stageRef} layoutSize={layoutSize}>
        <PedigreeEdges
          links={layout?.links}
          strokeOptions={{
            color: 'black',
            width: 1,
          }}
        />
        {pedigree.nodes.map((pedigreeNode) => (
          <NodePatientController
            key={pedigreeNode.id}
            position={layout?.nodes[pedigreeNode.id]?.pos}
            pedigreeNode={pedigreeNode}
            isSelected={isNodeSelected(pedigreeNode.id)}
          />
        ))}
      </ResponsiveRenderer>
      {NodeMenu}
    </Box>
  );
};
