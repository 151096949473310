import { Button } from '@mui/material';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { DeletablePedigreeNode, DeletePedigreeNode } from '../../types';
import { DialogContentType } from '../../views/Dialog';

interface useDeleteNodeProps {
  pedigreeId?: string;
  pedigreeType: 'professional' | 'patient';
  setDialogContent: (content: DialogContentType) => void;
  deletePedigreeNode: DeletePedigreeNode;
  deletablePedigreeNode: DeletablePedigreeNode;
}

export const useDeleteNode = (props: useDeleteNodeProps) => {
  const { t } = useTranslation('pedigree');
  const queryClient = useQueryClient();

  const deleteMutation = useMutation(
    (pedigreeNodeId: string) =>
      props.deletePedigreeNode(
        props.pedigreeId || '',
        parseInt(pedigreeNodeId),
      ),
    {
      onSuccess: () => queryClient.invalidateQueries('pedigree'),
      onError: ({ response }, pedigreeNodeId) => {
        const { data } = response || {};
        if (data.statusCode === 400) {
          openDeleteErrorDialog(pedigreeNodeId, data.message);
        }
      },
    },
  );

  const deletableMutation = useMutation(
    (pedigreeNodeId: string) =>
      props.deletablePedigreeNode(
        props.pedigreeId || '',
        parseInt(pedigreeNodeId),
      ),
    {
      onSuccess: ({ canDelete, reason }, pedigreeNodeId) => {
        if (canDelete) {
          return openDeleteConfirmationDialog(pedigreeNodeId);
        }
        return openDeleteErrorDialog(pedigreeNodeId, reason);
      },
    },
  );

  const closeDialog = () => {
    props.setDialogContent({
      open: false,
      title: '',
      content: '',
      actions: [],
    });
  };

  const onConfirm = (pedigreeNodeId?: string) => {
    if (pedigreeNodeId) {
      deleteMutation.mutate(pedigreeNodeId);
      closeDialog();
    }
  };

  const openDeleteErrorDialog = (
    pedigreeNodeId: string,
    errorMessage: string,
  ) => {
    const dialogContent = getErrorDialogContent({
      errorMessage,
      pedigreeNodeId,
      pedigreeType: props.pedigreeType,
      open: true,
      t,
      closeDialog,
    });

    props.setDialogContent(dialogContent);
  };

  const openDeleteConfirmationDialog = (pedigreeNodeId: string) => {
    props.setDialogContent({
      open: true,
      title: t('node-member-dialog.delete.title.confirm'),
      content: t('node-member-dialog.delete.content.confirm'),
      actions: [
        <Button
          key="confirm-delete-dialog"
          variant="outlined"
          onClick={() => onConfirm(pedigreeNodeId)}
        >
          {t('node-member-dialog.delete.button.confirm')}
        </Button>,
        <Button
          key="close-delete-dialog"
          variant="contained"
          onClick={closeDialog}
        >
          {t('node-member-dialog.delete.button.cancel')}
        </Button>,
      ],
    });
  };

  const handleDeleteNodeAction = (pedigreeNodeId: string) => {
    deletableMutation.mutate(pedigreeNodeId);
  };

  return {
    handleDeleteNodeAction,
  };
};

type getErrorDialogContentProps = {
  errorMessage: string;
  pedigreeNodeId: string | null;
  pedigreeType: 'professional' | 'patient';
  closeDialog: () => void;
  open: boolean;
  t: TFunction;
};

const getErrorDialogContent = (
  props: getErrorDialogContentProps,
): DialogContentType => {
  const { errorMessage, pedigreeNodeId, pedigreeType, closeDialog, open, t } =
    props;

  const DELETE_ERROR_TO_DIALOG_CONTENT_MAP = {
    NODE_IS_INDEX: `${pedigreeType}-isNotAllow`,
    NODE_IS_INDEX_PARENT: `${pedigreeType}-isNotAllow`,
    'Index node cannot be deleted': `${pedigreeType}-isNotAllow`,
    'Parents of index node cannot be deleted': `${pedigreeType}-isNotAllow`,
    [`Pedigree is not connected after deleting node with id ${pedigreeNodeId}`]:
      'relative',
    NODE_IS_SINGLE_CONNECTION_IN_PEDIGREE: 'relative',
    NODE_HAS_HALF_SIBLING_CHILDREN: 'relative',
  };

  const content = DELETE_ERROR_TO_DIALOG_CONTENT_MAP[errorMessage];

  return {
    open,
    title: t('node-member-dialog.delete.title.isNotAllow'),
    content: t(`node-member-dialog.delete.content.${content}`),
    actions: [
      <Button
        key="close-delete-dialog"
        variant="contained"
        onClick={closeDialog}
      >
        {t('node-member-dialog.delete.button.isNotAllow')}
      </Button>,
    ],
  };
};
