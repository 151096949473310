import {
  coordQuad,
  Graph,
  graphStratify,
  MutGraphNode,
  SugiNode,
  sugiyama,
} from 'd3-dag';
import { PedigreeNodeSex, TwinRelation } from '../types';

export type PedigreeLayoutLink = {
  points: number[];
  type: 'line' | 'arc' | 'crossing';
};

export type PedigreeLayoutNode = {
  id: string;
  pos: { x: number; y: number };
};

type PedigreeLayout = {
  width: number;
  height: number;
  links: PedigreeLayoutLink[];
  nodes: { [id: string]: PedigreeLayoutNode };
};

export type PedigreeNode = {
  id: string;
  fatherId?: string;
  motherId?: string;
  partnerIds: string[];
  twinRelation?: TwinRelation;
  isIndex: boolean;
  sex: PedigreeNodeSex;
  yearOfBirth?: number;
  label: { width: number; height: number };
};
type PedigreeNodeWithDistance = PedigreeNode & { distanceFromIndex: number };
type ExtendedPedigreeNode = PedigreeNodeWithDistance & {
  parentalPathFromIndex: ('mother' | 'father')[];
};
type PedigreeLayers = ExtendedPedigreeNode[][];

type Partition = Record<string, { start: number; end: number }>;

type Relation = 'father' | 'mother' | 'partner' | 'child';
type QueueElement = {
  nodeId: string;
  sex: PedigreeNodeSex;
  previousNodeId: string | null;
  relationToPreviousNode: Relation | null;
  stepsFromIndex: number;
  parentalPathFromIndex: ('mother' | 'father')[];
};

type PedigreeLayoutOptions = {
  node: {
    height: number;
    width: number;
  };
  minGap: {
    horizontal: number;
    vertical: number;
  };
  sibshipGap: number;
  sibshipGapWithPartnershipArcs: number;
  overlappingSibshipGapOffset: number;
  partnershipArc: {
    startOffset: number;
    endOffset: number;
  };
  nodeLabel: {
    marginBottom: number;
  };
};

type LayoutNodeData = PedigreeNode & { distanceFromIndex: number };

type CustomSugiNode = SugiNode<LayoutNodeData, unknown>;

type SibshipLine = {
  parentCoord: { x: number; y: number };
  children: { x: number; y: number; twinRelation?: TwinRelation }[];

  y: number;
  xMin: number;
  xMax: number;
};

const RELATION_TO_GENERATION_DELTA_MAP: Map<Relation, -1 | 0 | 1> = new Map([
  ['father', -1],
  ['mother', -1],
  ['partner', 0],
  ['child', 1],
]);

class PedigreeLayering {
  private nodes: PedigreeNode[];
  private queue: QueueElement[];
  private setOfQueuedNodes: Set<string>;
  private layers: PedigreeLayers;

  constructor(nodes: PedigreeNode[]) {
    this.nodes = nodes;
    this.queue = [];
    this.setOfQueuedNodes = new Set<string>();
    this.layers = [];
  }

  public generate(): PedigreeLayers {
    this.initiateQueue();

    while (this.queue.length > 0) {
      const dequeuedItem = this.queue.shift();
      if (!dequeuedItem) {
        throw new Error('dequeuedItem is undefined');
      }

      this.addImmediateRelationsToQueue(dequeuedItem);
      this.insertNode(dequeuedItem);
    }

    return this.layers;
  }

  private initiateQueue(): void {
    const indexNode = this.nodes.find((node) => node.isIndex);
    if (!indexNode) {
      throw new Error('No index node found');
    }

    this.addQueueElementToQueue({
      nodeId: indexNode.id,
      sex: indexNode.sex,
      previousNodeId: null,
      relationToPreviousNode: null,
      stepsFromIndex: 0,
      parentalPathFromIndex: [],
    });
  }

  private addQueueElementToQueue(queueElement: QueueElement): void {
    if (this.setOfQueuedNodes.has(queueElement.nodeId)) {
      return;
    }
    this.queue.push(queueElement);
    this.setOfQueuedNodes.add(queueElement.nodeId);
  }

  private addImmediateRelationsToQueue(queueElement: QueueElement): void {
    const { nodeId, stepsFromIndex: currentStepFromIndex } = queueElement;
    const node = this.getNodeById(nodeId);
    if (node.fatherId) {
      this.addQueueElementToQueue({
        nodeId: node.fatherId,
        sex: PedigreeNodeSex.MALE,
        previousNodeId: node.id,
        relationToPreviousNode: 'father',
        stepsFromIndex: currentStepFromIndex + 1,
        parentalPathFromIndex: [
          ...queueElement.parentalPathFromIndex,
          'father',
        ],
      });
    }
    if (node.motherId) {
      this.addQueueElementToQueue({
        nodeId: node.motherId,
        sex: PedigreeNodeSex.FEMALE,
        previousNodeId: node.id,
        relationToPreviousNode: 'mother',
        stepsFromIndex: currentStepFromIndex + 1,
        parentalPathFromIndex: [
          ...queueElement.parentalPathFromIndex,
          'mother',
        ],
      });
    }

    node.partnerIds.forEach((partnerId) => {
      this.addQueueElementToQueue({
        nodeId: partnerId,
        sex:
          node.sex === PedigreeNodeSex.MALE
            ? PedigreeNodeSex.FEMALE
            : PedigreeNodeSex.MALE,
        previousNodeId: node.id,
        relationToPreviousNode: 'partner',
        stepsFromIndex: currentStepFromIndex + 1,
        parentalPathFromIndex: queueElement.parentalPathFromIndex,
      });
    });

    const children = this.getChildren(node);
    children.forEach((child) => {
      this.addQueueElementToQueue({
        nodeId: child.id,
        sex: child.sex,
        previousNodeId: node.id,
        relationToPreviousNode: 'child',
        stepsFromIndex: currentStepFromIndex + 1,
        parentalPathFromIndex: queueElement.parentalPathFromIndex,
      });
    });
  }

  private insertNode(queueElement: QueueElement): void {
    const {
      nodeId,
      relationToPreviousNode,
      previousNodeId,
      stepsFromIndex,
      parentalPathFromIndex,
    } = queueElement;
    const node = this.getNodeById(nodeId);

    const extendedNode: ExtendedPedigreeNode = {
      ...node,
      distanceFromIndex: stepsFromIndex,
      parentalPathFromIndex,
    };

    if (previousNodeId === null || relationToPreviousNode === null) {
      this.layers.push([extendedNode]);
      return;
    }

    const generation = this.determineGeneration(
      previousNodeId,
      relationToPreviousNode,
    );

    if (generation < 0) {
      this.layers.unshift([extendedNode]);
      return;
    }

    if (generation >= this.layers.length) {
      this.layers.push([extendedNode]);
      return;
    }

    switch (relationToPreviousNode) {
      case 'father':
        this.insertParentWithMinimalCrossedLines(extendedNode, generation);
        break;
      case 'mother':
        this.insertMother(extendedNode, generation);
        break;
      case 'partner':
        this.insertPartner(extendedNode, generation, previousNodeId);
        break;
      case 'child':
        this.insertChildWithMinimumCrossedLines(extendedNode, generation);
        break;
      default:
        throw new Error('Invalid relation to previous node');
    }
  }

  private sortByParentsAndAge = (children: PedigreeNode[]): PedigreeNode[] => {
    return children.sort((a, b) => {
      const parentsA = `${a.fatherId || 'none'}-${a.motherId || 'none'}`;
      const parentsB = `${b.fatherId || 'none'}-${b.motherId || 'none'}`;

      if (parentsA !== parentsB) {
        return parentsA.localeCompare(parentsB);
      }

      const yearA = a.yearOfBirth ?? Infinity;
      const yearB = b.yearOfBirth ?? Infinity;

      return yearB - yearA;
    });
  };

  private getChildren(node: PedigreeNode): PedigreeNode[] {
    const children = this.nodes.filter(
      (n) => n.fatherId === node.id || n.motherId === node.id,
    );

    return this.sortByParentsAndAge(children);
  }

  private getNodeById(id: string): PedigreeNode {
    const node = this.nodes.find((node) => node.id === id);
    if (!node) {
      throw new Error(`PedigreeNode with id ${id} not found`);
    }
    return node;
  }

  private determineGeneration(
    previousNodeId: string,
    relationToPreviousNode: Relation,
  ): number {
    const generationDelta = RELATION_TO_GENERATION_DELTA_MAP.get(
      relationToPreviousNode,
    );
    if (generationDelta === undefined) {
      throw new Error('Invalid relation to previous node');
    }

    const generationOfPreviousNode = this.getGenerationOfNode(previousNodeId);
    return generationOfPreviousNode + generationDelta;
  }

  private getGenerationOfNode(nodeId: string): number {
    for (let i = 0; i < this.layers.length; i++) {
      const generation = this.layers[i];
      const node = generation.find((n) => n.id === nodeId);
      if (node) {
        return i;
      }
    }
    throw new Error(`PedigreeNode with id ${nodeId} not found in layout`);
  }

  private getParentalEdges(
    child: PedigreeNode,
    childIndex: number,
    parentGenerationRow: PedigreeNode[],
  ) {
    const edges: [number, number][] = [];
    const fatherIndex = parentGenerationRow.findIndex(
      (n) => n.id === child.fatherId,
    );
    if (fatherIndex !== -1) {
      edges.push([fatherIndex, childIndex]);
    }
    const motherIndex = parentGenerationRow.findIndex(
      (n) => n.id === child.motherId,
    );
    if (motherIndex !== -1) {
      edges.push([motherIndex, childIndex]);
    }
    return edges;
  }

  private insertParentWithMinimalCrossedLines(
    parent: ExtendedPedigreeNode,
    generation: number,
  ): void {
    const generationRow = this.layers[generation];
    const nextGenerationRow = this.layers[generation + 1];
    const edges: [number, number][] = [];

    for (let i = 0; i < nextGenerationRow.length; i++) {
      const child = nextGenerationRow[i];
      edges.push(...this.getParentalEdges(child, i, generationRow));
    }

    const numberOfCrossedEdgesArray: number[] = [];

    for (let i = 0; i < generationRow.length + 1; i++) {
      const parentPos = i - 0.5;

      if (isSurroundedByPartners(i, generationRow)) {
        numberOfCrossedEdgesArray.push(Infinity);
        continue;
      }

      const parentEdges: [number, number][] = [];
      for (let i = 0; i < nextGenerationRow.length; i++) {
        if (
          nextGenerationRow[i].fatherId === parent.id ||
          nextGenerationRow[i].motherId === parent.id
        ) {
          parentEdges.push([parentPos, i]);
        }
      }

      numberOfCrossedEdgesArray.push(
        parentEdges
          .map((parentEdge) => {
            return edges.filter((edge) => {
              return (
                (parentEdge[0] < edge[0] && parentEdge[1] > edge[1]) ||
                (parentEdge[0] > edge[0] && parentEdge[1] < edge[1])
              );
            }).length;
          })
          .reduce((acc, val) => acc + val, 0),
      );
    }
    const bestIndex = numberOfCrossedEdgesArray.indexOf(
      Math.min(...numberOfCrossedEdgesArray),
    );

    this.insertNodeAtIndex(parent, generation, bestIndex);
  }

  private insertNodeAtIndex(
    node: ExtendedPedigreeNode,
    generation: number,
    index: number,
  ) {
    if (index === -1) {
      this.layers[generation].unshift(node);
    } else {
      this.layers[generation].splice(index, 0, node);
    }
  }

  private insertMother(node: ExtendedPedigreeNode, generation: number): void {
    const generationRow = this.layers[generation];
    const partnerInTheGeneration = generationRow.find((n) =>
      node.partnerIds.includes(n.id),
    );
    if (partnerInTheGeneration) {
      return this.insertPartner(node, generation, partnerInTheGeneration.id);
    }
    return this.insertParentWithMinimalCrossedLines(node, generation);
  }

  private insertPartner(
    node: ExtendedPedigreeNode,
    generation: number,
    partnerId: string,
  ): void {
    const generationRow = this.layers[generation];
    const partner = generationRow.find((n) => n.id === partnerId);
    const hasOtherPartnersInTheGeneration = partner?.partnerIds.filter((id) =>
      generationRow.find((n) => n.id === id),
    ).length;
    const partnerIndex = generationRow.findIndex((n) => n.id === partnerId);
    let nodeIndex =
      node.sex === PedigreeNodeSex.FEMALE ? partnerIndex + 1 : partnerIndex;
    if (hasOtherPartnersInTheGeneration) {
      nodeIndex =
        node.sex === PedigreeNodeSex.FEMALE ? partnerIndex : partnerIndex + 1;
    }
    this.insertNodeAtIndex(node, generation, nodeIndex);
  }

  private insertChildWithMinimumCrossedLines(
    node: ExtendedPedigreeNode,
    generation: number,
  ): void {
    const generationRow = this.layers[generation];
    const parentGeneration = this.layers[generation - 1];

    let edges: [number, number][] = [];

    for (let i = 0; i < generationRow.length; i++) {
      const child = generationRow[i];
      edges.push(...this.getParentalEdges(child, i, parentGeneration));
    }

    const {
      partitions: generationPartitions,
      pathLength: partitionPathLength,
    } = this.createPartitions(generationRow);

    const numberOfCrossedEdgesArray: number[] = [];
    const distanceFromSiblingArray: number[] = [];
    const distanceFromTwinArray: number[] = [];
    const isSurroundedByPartnersArray: boolean[] = [];
    const isInCorrectPartitionArray: boolean[] = [];
    const isSurroundedByTwinsArray: boolean[] = [];

    for (let i = 0; i < generationRow.length + 1; i++) {
      const childPos = i - 0.5; // Position between two nodes
      distanceFromTwinArray.push(
        getDistanceFromClosestTwin(node, childPos, generationRow),
      );
      distanceFromSiblingArray.push(
        getDistanceFromClosestSibling(node, childPos, generationRow),
      );
      isSurroundedByPartnersArray.push(
        isSurroundedByPartners(i, generationRow),
      );
      isSurroundedByTwinsArray.push(
        isSurroundedByNotNodesTwins(node, i, generationRow),
      );

      if (partitionPathLength !== 0) {
        const { start, end } =
          generationPartitions[
            node.parentalPathFromIndex.slice(0, partitionPathLength).join('')
          ];
        isInCorrectPartitionArray.push(i >= start && i <= end + 1);
      }

      const childEdges = this.getParentalEdges(
        node,
        childPos,
        parentGeneration,
      );

      numberOfCrossedEdgesArray.push(
        childEdges
          .map((childEdge) => {
            return edges.filter((edge) => {
              return (
                (childEdge[0] < edge[0] && childEdge[1] > edge[1]) ||
                (childEdge[0] > edge[0] && childEdge[1] < edge[1])
              );
            }).length;
          })
          .reduce((acc, val) => acc + val, 0),
      );
    }

    const combinedArray = numberOfCrossedEdgesArray.map(
      (val, i) =>
        val +
        2 * distanceFromTwinArray[i] +
        distanceFromSiblingArray[i] +
        (isSurroundedByTwinsArray[i] ? Infinity : 0) +
        (isSurroundedByPartnersArray[i] ? Infinity : 0) +
        (isInCorrectPartitionArray[i] ? 0 : 100),
    );

    const bestIndex = combinedArray.indexOf(Math.min(...combinedArray));

    this.insertNodeAtIndex(node, generation, bestIndex);
  }

  createPartitions(generationRow: ExtendedPedigreeNode[]): {
    partitions: Partition;
    pathLength: number;
  } {
    const partitions: Partition = {};
    const minPathLength = generationRow.reduce((acc, node) => {
      const pathLength = node.parentalPathFromIndex.length;
      return Math.min(acc, pathLength);
    }, Infinity);
    const parentalPathFromIndexArray = generationRow.map((node) =>
      node.parentalPathFromIndex.slice(0, minPathLength),
    );
    parentalPathFromIndexArray.forEach((path, i) => {
      const pathString = path.join('');
      if (!partitions[pathString]) {
        partitions[pathString] = { start: i, end: i };
      } else {
        partitions[pathString].end = i;
      }
    });
    return { partitions, pathLength: minPathLength };
  }
}

function getDistanceFromClosestTwin(
  node: PedigreeNode,
  childPos: number,
  generationRow: PedigreeNode[],
): number {
  const twinId = node.twinRelation?.id;
  if (twinId === undefined) {
    return 0;
  }

  const twins = generationRow.filter(
    (n) => n.twinRelation?.id === twinId && n.id !== node.id,
  );

  const minDistance = twins.reduce((acc, twin) => {
    const twinPos = generationRow.findIndex((n) => n.id === twin.id);
    return Math.min(acc, Math.abs(twinPos - childPos));
  }, Infinity);

  if (minDistance === Infinity) {
    return 0;
  }
  return minDistance;
}

function getDistanceFromClosestSibling(
  node: PedigreeNode,
  childPos: number,
  generationRow: PedigreeNode[],
): number {
  const siblings = generationRow.filter(
    (n) => n.fatherId === node.fatherId || n.motherId === node.motherId,
  );

  const minDistance = siblings.reduce((acc, sibling) => {
    const siblingPos = generationRow.findIndex((n) => n.id === sibling.id);
    return Math.min(acc, Math.abs(siblingPos - childPos));
  }, Infinity);

  if (minDistance === Infinity) {
    return 0;
  }
  return minDistance;
}

function isSurroundedByNotNodesTwins(
  node: PedigreeNode,
  index: number,
  generationRow: PedigreeNode[],
): boolean {
  const twinsToTheRightThatAreNotYourTwins = generationRow
    .slice(index)
    .filter(
      (n) =>
        n.twinRelation?.id !== undefined &&
        n.twinRelation?.id !== node.twinRelation?.id,
    );
  const nodesToTheLeftThatAreNotYourTwins = generationRow
    .slice(0, index)
    .filter(
      (n) =>
        n.twinRelation?.id !== undefined &&
        n.twinRelation?.id !== node.twinRelation?.id,
    );

  if (
    twinsToTheRightThatAreNotYourTwins.length === 0 ||
    nodesToTheLeftThatAreNotYourTwins.length === 0
  ) {
    return false;
  }

  const anyNodeToTheRightIsTwinOfAnyNodeToTheLeft =
    twinsToTheRightThatAreNotYourTwins.some((rightNode) =>
      nodesToTheLeftThatAreNotYourTwins.some(
        (leftNode) => leftNode.twinRelation?.id === rightNode.twinRelation?.id,
      ),
    );

  return anyNodeToTheRightIsTwinOfAnyNodeToTheLeft;
}

function isSurroundedByPartners(
  index: number,
  generationRow: PedigreeNode[],
): boolean {
  const rightNode = generationRow[index];
  const leftNode = generationRow[index - 1];
  if (!rightNode || !leftNode) {
    return false;
  }

  const nodeSubIndex = index - 0.5;

  const rightNodeHasPartnersToTheLeftOfNode = rightNode.partnerIds.some(
    (partnerId) => {
      const partnerIndex = generationRow.findIndex((n) => n.id === partnerId);
      return partnerIndex !== -1 && partnerIndex < nodeSubIndex;
    },
  );

  const leftNodeHasPartnersToTheRightOfNode = leftNode.partnerIds.some(
    (partnerId) => {
      const partnerIndex = generationRow.findIndex((n) => n.id === partnerId);
      return partnerIndex !== -1 && partnerIndex > nodeSubIndex;
    },
  );
  return (
    rightNodeHasPartnersToTheLeftOfNode || leftNodeHasPartnersToTheRightOfNode
  );
}

function createPedigreeLayout(
  pedigree: PedigreeNode[],
  options: PedigreeLayoutOptions,
): PedigreeLayout {
  const stratify = graphStratify()
    .id((d: PedigreeNode) => d.id)
    .parentIds((d: PedigreeNode) => {
      const parentIds = [];
      if (d.fatherId) parentIds.push(d.fatherId);
      if (d.motherId) parentIds.push(d.motherId);
      return parentIds;
    });
  const dag = stratify(
    pedigree.map((node) => ({ ...node, distanceFromIndex: 0 })),
  );

  const pedigreeLayers = new PedigreeLayering(pedigree).generate();
  const layerHeightMap = new Map<number, number>();
  const minNodeHeight = options.node.height + options.minGap.vertical;

  for (let layerIndex = 0; layerIndex < pedigreeLayers.length; layerIndex++) {
    const maxLayerHeight = pedigreeLayers[layerIndex].reduce((acc, node) => {
      const nodeHeight =
        node.label.height +
        options.nodeLabel.marginBottom +
        options.sibshipGap +
        options.node.height;
      return Math.max(acc, nodeHeight);
    }, minNodeHeight);
    layerHeightMap.set(layerIndex, maxLayerHeight);
  }

  const nodeHeightMap = pedigreeLayers
    .map((layer, layerIndex) => {
      return layer.map((node) => {
        return { id: node.id, height: layerHeightMap.get(layerIndex) || 0 };
      });
    })
    .flat()
    .reduce((acc, val) => {
      acc.set(val.id, val.height);
      return acc;
    }, new Map<string, number>());

  const layout = sugiyama()
    .layering(customLayering(pedigreeLayers))
    .decross(customDecross(pedigreeLayers))
    .coord(coordQuad())
    .gap([options.minGap.horizontal, 0])
    .nodeSize((node) => {
      const nodeHeight = layerHeightMap.get(node.uy || 0) || 0;
      return [options.node.width, nodeHeight];
    });
  const { width, height } = layout(dag as any);

  const dagNodes = [...dag.nodes()];

  adjustNodeYPosition(dagNodes, nodeHeightMap);

  let nodes = extractNodes(dagNodes);
  const pseudoPartnerNodes = extractPseudoPartnerNodes(dagNodes, options);
  nodes = { ...nodes, ...pseudoPartnerNodes };

  const partnerLinks = extractPartnerLinks(
    dagNodes,
    pseudoPartnerNodes,
    options,
  );
  const parentChildrenLinks = extractParentChildrenLinks(
    dagNodes,
    pseudoPartnerNodes,
    pedigreeLayers,
    options,
  );
  const links = [...partnerLinks, ...parentChildrenLinks];

  return { width, height, nodes, links };
}

function adjustNodeYPosition(
  dagNodes: MutGraphNode<PedigreeNodeWithDistance, undefined>[],
  nodeHeightMap: Map<string, number>,
) {
  for (const node of dagNodes) {
    const id = node.data.id;
    const nodeHeight = nodeHeightMap.get(id) || 0;
    if (node.uy) {
      node.uy = node.uy - nodeHeight / 2;
    }
  }
}

function extractNodes(
  dagNodes: MutGraphNode<PedigreeNodeWithDistance, undefined>[],
): { [id: string]: PedigreeLayoutNode } {
  const nodes: { [id: string]: PedigreeLayoutNode } = {};
  for (const node of dagNodes) {
    const id = node.data.id;
    nodes[id] = {
      id,
      pos: { x: node.ux || 0, y: node.uy || 0 },
    };
  }
  return nodes;
}

function extractPseudoPartnerNodes(
  dagNodes: MutGraphNode<PedigreeNodeWithDistance, undefined>[],
  options: PedigreeLayoutOptions,
): { [id: string]: PedigreeLayoutNode } {
  const pseudoPartnerNodes: { [id: string]: PedigreeLayoutNode } = {};
  const visitedNodes = new Set<string>();
  for (const node of dagNodes) {
    const { id, partnerIds, sex: nodeSex } = node.data;
    if (visitedNodes.has(id)) {
      continue;
    }
    visitedNodes.add(id);
    partnerIds.forEach((partnerId) => {
      const partnerDagNode = dagNodes.find((n) => n.data.id === partnerId);
      if (!partnerDagNode) {
        return;
      }
      const pos = {
        x: (node.x + partnerDagNode.x) / 2,
        y: (node.y + partnerDagNode.y) / 2,
      };
      if (hasNodesBetween(node, partnerDagNode, dagNodes)) {
        const shiftToPartner =
          node.data.distanceFromIndex < partnerDagNode.data.distanceFromIndex;
        const direction = Math.sign(node.x - partnerDagNode.x);
        pos.x =
          node.x -
          direction *
            (options.node.width / 2 + options.partnershipArc.endOffset);
        if (shiftToPartner) {
          pos.x =
            partnerDagNode.x +
            direction *
              (options.node.width / 2 +
                direction * options.partnershipArc.endOffset);
        }
      }
      const fatherId = nodeSex === PedigreeNodeSex.MALE ? id : partnerId;
      const motherId = nodeSex === PedigreeNodeSex.MALE ? partnerId : id;
      pseudoPartnerNodes[`p.${fatherId}.${motherId}`] = {
        id: `p.${fatherId}.${motherId}`,
        pos,
      };
    });
  }

  return pseudoPartnerNodes;
}

function extractParentChildrenLinks(
  dagNodes: MutGraphNode<PedigreeNodeWithDistance, undefined>[],
  pseudoPartnerNodes: { [id: string]: PedigreeLayoutNode },
  pedigreeLayers: PedigreeLayers,
  options: PedigreeLayoutOptions,
): PedigreeLayoutLink[] {
  const links: PedigreeLayoutLink[] = [];
  for (const layer of pedigreeLayers) {
    const siblingGroups = createSiblingGroups(layer, dagNodes);

    const sibshipLines: SibshipLine[] = [...siblingGroups.keys()].map(
      getSibshipLineConverter(siblingGroups, dagNodes, pseudoPartnerNodes),
    );

    links.push(...generateSibshipLinks(sibshipLines, options));
  }
  return links;
}

function generateSibshipLinks(
  sibshipLines: SibshipLine[],
  options: PedigreeLayoutOptions,
): PedigreeLayoutLink[] {
  const groupedSibshipLines = groupOverlappingSibshipLines(sibshipLines);
  const links: PedigreeLayoutLink[] = [];

  for (const sibshipLineGroup of groupedSibshipLines) {
    const orderedSibshipLines =
      orderSibshipLinesToMinimizeCrossings(sibshipLineGroup);

    if (
      hasOverlappingEnds(orderedSibshipLines) &&
      orderedSibshipLines.length === 2
    ) {
      links.push(
        ...generateSibshipLinksWithOverlappingEnds(
          orderedSibshipLines,
          options,
        ),
      );
      continue;
    }

    const placedSibshipLines: { [y: number]: SibshipLine[] } = {};
    let currentY: number;

    orderedSibshipLines.forEach((sibshipLine) => {
      if (!currentY) {
        currentY =
          sibshipLine.children[0].y -
          (options.node.height / 2 + options.sibshipGap);
      }
      if (
        isOverlappingWithPlacedSibshipLines(
          sibshipLine,
          placedSibshipLines[currentY] || [],
        )
      ) {
        currentY -= options.overlappingSibshipGapOffset;
      }
      sibshipLine.y = currentY;
      placedSibshipLines[currentY] = [
        ...(placedSibshipLines[currentY] || []),
        sibshipLine,
      ];

      links.push({
        points: [
          sibshipLine.xMin,
          sibshipLine.y,
          sibshipLine.xMax,
          sibshipLine.y,
        ],
        type: 'line',
      });
    });

    const processedTwins: Record<string, boolean> = {};

    orderedSibshipLines.forEach((sibshipLine) => {
      const otherSibshipLines = orderedSibshipLines.filter(
        (line) => line !== sibshipLine,
      );

      for (const child of sibshipLine.children) {
        const twinId = child.twinRelation?.id;
        if (twinId === undefined) {
          links.push(
            ...getLinksWithCrossings(
              {
                x: child.x,
                yMin: Math.min(sibshipLine.y, child.y),
                yMax: Math.max(sibshipLine.y, child.y),
              },
              otherSibshipLines,
            ),
          );
        } else {
          const twins = sibshipLine.children.filter(
            (sibshipChild) => sibshipChild.twinRelation?.id === twinId,
          );
          const twinX =
            twins.reduce((acc, twin) => acc + twin.x, 0) / twins.length;
          links.push({
            points: [twinX, sibshipLine.y, child.x, child.y],
            type: 'line',
          });

          if (
            twins.length > 1 &&
            child.twinRelation?.type === 'identical' &&
            !processedTwins[twinId]
          ) {
            const rightmostTwin = twins.reduce(
              (maxTwin, twin) => (twin.x > maxTwin.x ? twin : maxTwin),
              twins[0],
            );
            const leftmostTwin = twins.reduce(
              (minTwin, twin) => (twin.x < minTwin.x ? twin : minTwin),
              twins[0],
            );
            const twinLineY = sibshipLine.y + 10;

            const intercection1 = calculateIntersectionPoint({
              lineStart: { x: twinX, y: sibshipLine.y },
              lineEnd: { x: rightmostTwin.x, y: rightmostTwin.y },
              verticalOffset: twinLineY,
            });
            const intercection2 = calculateIntersectionPoint({
              lineStart: { x: twinX, y: sibshipLine.y },
              lineEnd: { x: leftmostTwin.x, y: leftmostTwin.y },
              verticalOffset: twinLineY,
            });

            links.push({
              points: [intercection1.x, twinLineY, intercection2.x, twinLineY],
              type: 'line',
            });
            processedTwins[twinId] = true;
          }
        }
      }

      links.push(
        ...getLinksWithCrossings(
          {
            x: sibshipLine.parentCoord.x,
            yMin: Math.min(sibshipLine.y, sibshipLine.parentCoord.y),
            yMax: Math.max(sibshipLine.y, sibshipLine.parentCoord.y),
          },
          otherSibshipLines,
        ),
      );
    });
  }
  return links;
}

type Point = { x: number; y: number };
const calculateIntersectionPoint = (params: {
  lineStart: Point;
  lineEnd: Point;
  verticalOffset: number;
}): { x: number; y: number } => {
  const { lineStart, lineEnd, verticalOffset } = params;
  const lineSlope = (lineEnd.y - lineStart.y) / (lineEnd.x - lineStart.x);
  const lineIntercept = lineStart.y - lineSlope * lineStart.x;
  const intersectionX = (verticalOffset - lineIntercept) / lineSlope;
  const intersectionY = lineSlope * intersectionX + lineIntercept;
  return { x: intersectionX, y: intersectionY };
};

function hasOverlappingEnds(sibshipLines: SibshipLine[]): boolean {
  // Note rounding to avoid floating point precision issues
  const xMinSet = new Set(sibshipLines.map((line) => Math.round(line.xMin)));
  const xMaxSet = new Set(sibshipLines.map((line) => Math.round(line.xMax)));
  return (
    xMinSet.size < sibshipLines.length || xMaxSet.size < sibshipLines.length
  );
}

function generateSibshipLinksWithOverlappingEnds(
  siblingLines: SibshipLine[],
  options: PedigreeLayoutOptions,
): PedigreeLayoutLink[] {
  const links: PedigreeLayoutLink[] = [];
  const placedSibshipLines: { xMin: number; xMax: number; y: number }[] = [];

  const line1 = siblingLines[0];
  const line2 = siblingLines[1];

  const line1y =
    line1.children[0].y -
    options.node.height / 2 -
    options.sibshipGap -
    options.overlappingSibshipGapOffset;

  const line2yAbove = line1y - options.overlappingSibshipGapOffset;
  const line2yBelow = line1y + options.overlappingSibshipGapOffset;

  const line2xShift = line2.xMin + (line2.xMax - line2.xMin) / 2;

  const addLine = (x1: number, x2: number, y: number) => {
    links.push({
      points: [x1, y, x2, y],
      type: 'line',
    });
    placedSibshipLines.push({
      xMin: Math.min(x1, x2),
      xMax: Math.max(x1, x2),
      y: y,
    });
  };

  addLine(line1.xMin, line1.xMax, line1y);
  addLine(line2.parentCoord.x, line2xShift, line2yAbove);
  addLine(line2xShift, line2.children[0].x, line2yBelow);

  links.push({
    points: [
      line1.parentCoord.x,
      line1.parentCoord.y,
      line1.parentCoord.x,
      line1y,
    ],
    type: 'line',
  });
  links.push({
    points: [
      line2.parentCoord.x,
      line2.parentCoord.y,
      line2.parentCoord.x,
      line2yAbove,
    ],
    type: 'line',
  });
  links.push({
    points: [
      line1.children[0].x,
      line1y,
      line1.children[0].x,
      line1.children[0].y,
    ],
    type: 'line',
  });
  links.push({
    points: [
      line2.children[0].x,
      line2yBelow,
      line2.children[0].x,
      line2.children[0].y,
    ],
    type: 'line',
  });
  links.push(
    ...getLinksWithCrossings(
      { x: line2xShift, yMin: line2yAbove, yMax: line2yBelow },
      placedSibshipLines,
    ),
  );

  return links;
}

function isOverlappingWithPlacedSibshipLines(
  newSibshipLine: SibshipLine,
  placedSibshipLines: SibshipLine[],
): boolean {
  return placedSibshipLines.some((sibshipLine) => {
    return (
      newSibshipLine.xMin < sibshipLine.xMax &&
      newSibshipLine.xMax > sibshipLine.xMin
    );
  });
}

function getSibshipLineConverter(
  siblingGroups: Map<
    string,
    MutGraphNode<PedigreeNodeWithDistance, undefined>[]
  >,
  dagNodes: MutGraphNode<PedigreeNodeWithDistance, undefined>[],
  pseudoPartnerNodes: { [id: string]: PedigreeLayoutNode },
): (siblingGroupKey: string) => SibshipLine {
  return (siblingGroupKey: string) => {
    const siblingGroup = siblingGroups.get(siblingGroupKey)!;
    let parentCoord: { x: number; y: number };
    if (siblingGroupKey.startsWith('p.')) {
      parentCoord = pseudoPartnerNodes[siblingGroupKey].pos;
    } else {
      const parentDagNode = dagNodes.find(
        (n) => n.data.id === siblingGroupKey,
      )!;
      parentCoord = { x: parentDagNode.x, y: parentDagNode.y };
    }

    const { xMin, xMax } = computeXExtremesForSiblings(siblingGroup);

    return {
      y: 0,
      xMin: Math.min(xMin, parentCoord.x),
      xMax: Math.max(xMax, parentCoord.x),
      parentCoord,
      children: siblingGroup.map((n) => ({
        x: n.x,
        y: n.y,
        twinRelation: n.data.twinRelation,
      })),
    };
  };
}

function computeXExtremesForSiblings(
  siblings: MutGraphNode<PedigreeNodeWithDistance, undefined>[],
): {
  xMin: number;
  xMax: number;
} {
  let xMin = Math.min(...siblings.map((n) => n.x));
  let xMax = Math.max(...siblings.map((n) => n.x));

  const rightmostSibling = siblings.find((sibling) => sibling.x === xMin);
  const leftmostSibling = siblings.find((sibling) => sibling.x === xMax);
  const rightmostSiblingIsTwin =
    rightmostSibling?.data.twinRelation?.id !== undefined;
  const leftmostSiblingIsTwin =
    leftmostSibling?.data.twinRelation?.id !== undefined;

  if (rightmostSiblingIsTwin) {
    const allTwins = siblings.filter(
      (sibling) =>
        sibling.data.twinRelation?.id ===
        rightmostSibling?.data.twinRelation?.id,
    );

    xMin = allTwins.reduce((acc, twin) => acc + twin.x, 0) / allTwins.length;
  }

  if (leftmostSiblingIsTwin) {
    const allTwins = siblings.filter(
      (sibling) =>
        sibling.data.twinRelation?.id ===
        leftmostSibling?.data.twinRelation?.id,
    );

    xMax = allTwins.reduce((acc, twin) => acc + twin.x, 0) / allTwins.length;
  }

  return { xMin, xMax };
}

function createSiblingGroups(
  layer: ExtendedPedigreeNode[],
  dagNodes: MutGraphNode<PedigreeNodeWithDistance, undefined>[],
): Map<string, MutGraphNode<PedigreeNodeWithDistance, undefined>[]> {
  const siblingGroups = new Map<
    string,
    MutGraphNode<PedigreeNodeWithDistance, undefined>[]
  >();

  for (const node of layer) {
    const { fatherId, motherId } = node;
    if (!fatherId && !motherId) {
      continue;
    }
    const fatherMotherArray = [fatherId, motherId].filter((val) => val);
    const siblingGroupKey =
      fatherMotherArray.length === 2
        ? `p.${fatherMotherArray.join('.')}`
        : fatherMotherArray[0];
    if (!siblingGroupKey) {
      continue;
    }

    const dagNode = dagNodes.find((n) => n.data.id === node.id)!;

    siblingGroups.set(siblingGroupKey, [
      ...(siblingGroups.get(siblingGroupKey) || []),
      dagNode,
    ]);
  }
  return siblingGroups;
}

function getLinksWithCrossings(
  line: { x: number; yMin: number; yMax: number },
  otherSibshipLines: { xMin: number; xMax: number; y: number }[],
): PedigreeLayoutLink[] {
  const links: PedigreeLayoutLink[] = [];
  const crossings: { x: number; y: number }[] = otherSibshipLines
    .filter((otherLine) => {
      const betweenXRange = line.x > otherLine.xMin && line.x < otherLine.xMax;

      const betweenYRange =
        line.yMin <= otherLine.y && line.yMax >= otherLine.y;

      const hasCrossing = betweenXRange && betweenYRange;
      return hasCrossing;
    })
    .map((otherLine) => {
      return { x: line.x, y: otherLine.y };
    })
    .sort((a, b) => a.y - b.y);

  if (crossings.length === 0) {
    return [{ points: [line.x, line.yMin, line.x, line.yMax], type: 'line' }];
  }

  for (let i = 0; i < crossings.length; i++) {
    const crossing = crossings[i];
    const nextCrossing = crossings[i + 1];
    if (i === 0) {
      links.push({
        points: [line.x, line.yMin, line.x, crossing.y - 6],
        type: 'line',
      });
      links.push({
        points: [crossing.x, crossing.y],
        type: 'crossing',
      });
    }
    if (nextCrossing) {
      links.push({
        points: [line.x, crossing.y + 6, line.x, nextCrossing.y - 6],
        type: 'line',
      });
      links.push({
        points: [nextCrossing.x, nextCrossing.y],
        type: 'crossing',
      });
    }
  }

  const lastCrossing = crossings[crossings.length - 1];
  links.push({
    points: [line.x, lastCrossing.y + 6, line.x, line.yMax],
    type: 'line',
  });

  return links;
}

function orderSibshipLinesToMinimizeCrossings(
  sibshipLines: SibshipLine[],
): SibshipLine[] {
  const sibshipPermutations = permute(sibshipLines);
  const { permutation: permutationWithMinCrossings } =
    sibshipPermutations.reduce(
      (acc, sibshipLines) => {
        const crossings = countSibshipLinesCrossings(sibshipLines);
        if (crossings <= acc.crossings) {
          return { crossings, permutation: sibshipLines };
        }
        return acc;
      },
      { crossings: Infinity, permutation: sibshipLines },
    );

  return permutationWithMinCrossings.reverse();
}

function countSibshipLinesCrossings(sibshipLines: SibshipLine[]): number {
  let crossings = 0;
  for (let i = 0; i < sibshipLines.length; i++) {
    const line = sibshipLines[i];
    const allParentXCoordBelow = sibshipLines
      .slice(i + 1)
      .map((line) => line.parentCoord.x)
      .flat();
    crossings += allParentXCoordBelow.filter(
      (x) => x >= line.xMin && x <= line.xMax,
    ).length;

    const allChildXCoordAbove = sibshipLines
      .slice(0, i)
      .map((line) => {
        return line.children.map((coord) => coord.x);
      })
      .flat();
    crossings += allChildXCoordAbove.filter(
      (x) => x >= line.xMin && x <= line.xMax,
    ).length;
  }
  return crossings;
}

function permute(sibshipLines: SibshipLine[]): SibshipLine[][] {
  if (sibshipLines.length === 0) {
    return [[]];
  }

  const permutations: SibshipLine[][] = [];

  for (let i = 0; i < sibshipLines.length; i++) {
    const currentLine = sibshipLines[i];
    const remainingLines = sibshipLines
      .slice(0, i)
      .concat(sibshipLines.slice(i + 1));
    const innerPermutations = permute(remainingLines);

    for (const innerPermutation of innerPermutations) {
      permutations.push([currentLine, ...innerPermutation]);
    }
  }

  return permutations;
}

function groupOverlappingSibshipLines(
  sibshipLines: SibshipLine[],
): SibshipLine[][] {
  sibshipLines.sort((a, b) => a.xMin - b.xMin);
  const groupedSibshipLines: SibshipLine[][] = [];
  let currentGroup: SibshipLine[] = [];
  for (const sibshipLine of sibshipLines) {
    if (currentGroup.length === 0) {
      currentGroup.push(sibshipLine);
      continue;
    }

    const lastLine = currentGroup.reduce((acc, line) => {
      return acc.xMax > line.xMax ? acc : line;
    }, currentGroup[0]);
    // Note the -5 offset to account for lines that are very close to each other
    if (sibshipLine.xMin - 5 < lastLine.xMax) {
      currentGroup.push(sibshipLine);
    } else {
      groupedSibshipLines.push(currentGroup);
      currentGroup = [sibshipLine];
    }
  }

  if (currentGroup.length > 0) {
    groupedSibshipLines.push(currentGroup);
  }

  return groupedSibshipLines;
}

function extractPartnerLinks(
  dagNodes: MutGraphNode<PedigreeNodeWithDistance, undefined>[],
  pseudoPartnerNodes: { [id: string]: PedigreeLayoutNode },
  options: PedigreeLayoutOptions,
) {
  const links: PedigreeLayoutLink[] = [];

  for (const pseudoPartnerNode of Object.values(pseudoPartnerNodes)) {
    const [fatherId, motherId] = pseudoPartnerNode.id.split('.').slice(1);
    const fatherDagNode = dagNodes.find((n) => n.data.id === fatherId);
    const motherDagNode = dagNodes.find((n) => n.data.id === motherId);
    if (!fatherDagNode || !motherDagNode) {
      continue;
    }

    if (!hasNodesBetween(fatherDagNode, motherDagNode, dagNodes)) {
      links.push({
        points: [
          fatherDagNode.x,
          fatherDagNode.y,
          motherDagNode.x,
          motherDagNode.y,
        ],
        type: 'line',
      });
      continue;
    }

    const linkPoints = generatePartnersArc(
      { pseudoPartnerNode, fatherDagNode, motherDagNode },
      options,
    );

    links.push({
      points: linkPoints,
      type: 'arc',
    });
  }

  return links;
}

function generatePartnersArc(
  nodes: {
    pseudoPartnerNode: PedigreeLayoutNode;
    fatherDagNode: MutGraphNode<PedigreeNodeWithDistance, undefined>;
    motherDagNode: MutGraphNode<PedigreeNodeWithDistance, undefined>;
  },
  options: PedigreeLayoutOptions,
) {
  const { pseudoPartnerNode, fatherDagNode, motherDagNode } = nodes;
  const arcStart = pseudoPartnerNode.pos.x;
  const direction = Math.sign(fatherDagNode.x - motherDagNode.x);
  const pseudoNodeToFatherDistance = Math.abs(
    fatherDagNode.x - pseudoPartnerNode.pos.x,
  );
  const pseudoNodeToMotherDistance = Math.abs(
    motherDagNode.x - pseudoPartnerNode.pos.x,
  );
  const pseudoNodeNextToFather =
    pseudoNodeToFatherDistance < pseudoNodeToMotherDistance;

  const arcEndOffset =
    options.partnershipArc.endOffset + options.node.width / 2;

  const arcEnd = pseudoNodeNextToFather
    ? motherDagNode.x + direction * arcEndOffset
    : fatherDagNode.x - direction * arcEndOffset;

  const linkStart = pseudoNodeNextToFather
    ? [fatherDagNode.x, fatherDagNode.y]
    : [motherDagNode.x, motherDagNode.y];
  const linkEnd = pseudoNodeNextToFather
    ? [motherDagNode.x, motherDagNode.y]
    : [fatherDagNode.x, fatherDagNode.y];
  const linkPoints = [
    ...linkStart,
    arcStart,
    fatherDagNode.y,
    arcEnd,
    motherDagNode.y,
    ...linkEnd,
  ];
  return linkPoints;
}

function hasNodesBetween(
  node1: MutGraphNode<LayoutNodeData, undefined>,
  node2: MutGraphNode<LayoutNodeData, undefined>,
  nodes: MutGraphNode<LayoutNodeData, undefined>[],
): boolean {
  if (node1.y !== node2.y) {
    return false;
  }
  const minX = Math.min(node1.x, node2.x);
  const maxX = Math.max(node1.x, node2.x);
  const nodesBetween = nodes.filter(
    (node) => node.y === node1.y && node.x > minX && node.x < maxX,
  );
  return nodesBetween.length > 0;
}

function customDecross(pedigreeLayers: PedigreeLayers) {
  return (layers: CustomSugiNode[][]): void => {
    const nodeOrder = new Map<string, number>();
    const distanceFromIndexMap = new Map<string, number>();

    for (const layer of pedigreeLayers) {
      for (const node of layer) {
        nodeOrder.set(node.id, layer.indexOf(node));
        distanceFromIndexMap.set(node.id, node.distanceFromIndex);
      }
    }

    for (const layer of layers) {
      layer.sort((a, b) => {
        if (a.data.role !== 'node' || b.data.role !== 'node') {
          return 0;
        }
        return (
          nodeOrder.get(a.data.node.data.id)! -
          nodeOrder.get(b.data.node.data.id)!
        );
      });
      for (const node of layer) {
        if (node.data.role === 'node') {
          node.data.node.data.distanceFromIndex = distanceFromIndexMap.get(
            node.data.node.data.id,
          )!;
        }
      }
    }
  };
}

function customLayering<N extends LayoutNodeData, L>(
  pedigreeLayers: PedigreeLayers,
) {
  const nodeLevelMap = new Map<string, number>();
  let layerIndex = 0;
  for (const layer of pedigreeLayers) {
    for (const node of layer) {
      nodeLevelMap.set(node.id, layerIndex);
    }
    layerIndex++;
  }

  const layeringFunc = (dag: Graph<N, L>): number => {
    const nodes = [...dag.nodes()];

    for (const node of nodes) {
      const nodeLevel = nodeLevelMap.get(node.data.id)!;
      node.uy = nodeLevel;
    }
    return pedigreeLayers.length - 1;
  };
  return layeringFunc;
}

export { createPedigreeLayout, PedigreeLayering };
