import { Box, Container, Fade, Stack } from '@mui/material';
import { useConsentManagementService } from 'api/consentManagement/ConsentManagementServiceProvider';
import { useStaffPedigreeCaseService } from 'api/pedigreeCase/staff-pedigree-case-service';
import { LoadingBackdrop } from 'components/common';
import { ConsentOverviewPanel } from 'iPedigree/features/patient/consent/ConsentOverviewPanel';
import PatientConsents from 'iPedigree/features/patient/consent/patientConsents';
import { RelativesSuggestionTable } from 'iPedigree/features/patient/consent/RelativesSuggestionTable';
import { ROUTES } from 'iPedigree/routes';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export const PatientConsentsPage = () => {
  const { patientId } = useParams<{ patientId: string }>();
  const service = useStaffPedigreeCaseService();
  const consentService = useConsentManagementService();
  const navigate = useNavigate();
  const location = useLocation();

  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);

  const { data: pedigreeCase, isLoading: isPedigreeCaseLoading } = useQuery(
    ['pedigreeCase', patientId],
    () => service.getPedigreeCase(patientId || ''),
    {
      enabled: !!patientId,
    },
  );

  const noPedigree =
    pedigreeCase?.status === 'caseCreated' ||
    pedigreeCase?.status === 'pedigreeRequested';

  const enabledGetSuggestions =
    !!patientId && !noPedigree && !isPedigreeCaseLoading;

  const { data: suggestions, isLoading: isSuggestionsLoading } = useQuery(
    ['requestSuggestions', patientId],
    () => consentService.getConsentRequestSuggestions(patientId || ''),
    {
      enabled: !!enabledGetSuggestions,
    },
  );

  const { data: consentRequest, isLoading: isConsentRequestLoading } = useQuery(
    ['consentRequest', patientId],
    () => consentService.getConsentRequestByPatientId(patientId || ''),
    {
      enabled: !!patientId,
    },
  );

  const isLoading =
    isPedigreeCaseLoading || isSuggestionsLoading || isConsentRequestLoading;

  const showConsentOverviewPanel =
    !consentRequest && (noPedigree || suggestions?.length === 0);

  const handleAddRelatives = () => {
    if (suggestions && suggestions?.length > 0) {
      return setShowSuggestions(true);
    }
    return navigate(`${location.pathname}/new-consent`, {
      state: { isAddNewConsentsToExistingRequest: true },
    });
  };

  if (isLoading) {
    return <LoadingBackdrop />;
  }

  if (showConsentOverviewPanel) {
    const status = noPedigree ? 'noPedigree' : 'noSuggestions';
    return (
      <Box pt={3} display="flex" justifyContent="center">
        <Stack maxWidth={700}>
          <ConsentOverviewPanel status={status} />
        </Stack>
      </Box>
    );
  }

  if (consentRequest) {
    return (
      <Container maxWidth="lg" sx={{ py: 3 }}>
        <Stack gap={4}>
          <PatientConsents
            consentRequest={consentRequest}
            {...(!showSuggestions
              ? {
                  onAddRelativesButtonClick: handleAddRelatives,
                }
              : {})}
            onSendReminderButtonClick={() =>
              navigate(`${ROUTES.patient}/${patientId}/consents/send-reminder`)
            }
          />
          <Fade in={showSuggestions} timeout={500}>
            <Stack>
              {suggestions && showSuggestions && (
                <RelativesSuggestionTable
                  suggestions={suggestions}
                  isAddNewConsentsToExistingRequest={true}
                  onCancelButtonClick={() => setShowSuggestions(false)}
                />
              )}
            </Stack>
          </Fade>
        </Stack>
      </Container>
    );
  }

  if (suggestions) {
    return (
      <Container maxWidth="lg" sx={{ py: 3 }}>
        <RelativesSuggestionTable suggestions={suggestions} />
      </Container>
    );
  }
};
