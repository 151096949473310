import { useUserService } from 'api/user/user-service';
import SendReminder from 'common/features/pedigreeCase/sendPedigreeRequestReminder/SendReminder';
import { LoadingBackdrop } from 'components/common';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

const SendRequestPage = () => {
  const { patientId } = useParams<{ patientId: string }>();
  const userService = useUserService();
  const { data: userPreferences, isLoading: userPreferencesLoading } = useQuery(
    'userPreferences',
    userService.getCurrentUserPreferences,
  );

  const showNotificationOptInPrompt = userPreferences === null;

  if (userPreferencesLoading) {
    return <LoadingBackdrop />;
  }

  if (!patientId) {
    throw new Error('Patient id is required');
  }

  return (
    <SendReminder
      patientId={patientId}
      showNotificationOptInPrompt={showNotificationOptInPrompt}
    />
  );
};

export default SendRequestPage;
