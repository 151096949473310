import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Alert,
  Button,
  Checkbox,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import {
  ConsentRequestSuggestion,
  Diagnose,
} from 'api/consentManagement/consent-management-service';
import { RelativeRelation } from 'iPedigree/common/RelativeRelation';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

type RelativesData = ConsentRequestSuggestion & {
  checked: boolean;
};

interface RelativesSuggestionTableProps {
  suggestions: ConsentRequestSuggestion[];
  isAddNewConsentsToExistingRequest?: boolean;
  onCancelButtonClick?: () => void;
}

export const RelativesSuggestionTable = (
  props: RelativesSuggestionTableProps,
) => {
  const { t } = useTranslation(['consent', 'common']);
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    suggestions,
    isAddNewConsentsToExistingRequest,
    onCancelButtonClick,
  } = props;

  const [relativesData, setRelativesData] = useState<RelativesData[]>([]);
  const [page, setPage] = useState(0);
  const totalNumberOfRows = relativesData.length;
  const limit = 20;

  const headings = [
    t('consents-relatives-table.header.relative'),
    t('consents-relatives-table.header.relation'),
    t('consents-relatives-table.header.deceased'),
    t('consents-relatives-table.header.diagnosisAndAge'),
    t('consents-relatives-table.header.pathogenicVariants'),
  ];

  const relatives = useMemo(() => {
    return relativesData.slice(page * limit, page * limit + limit);
  }, [page, relativesData]);

  const allRelativesAreChecked = useMemo(() => {
    return relatives.every((relative) => relative.checked);
  }, [relatives]);

  const handleSelectRelative = (index: number) => {
    setRelativesData((prevRelativesData) => {
      const adjustedIndex = page * limit + index;

      return prevRelativesData.map((relative, idx) =>
        idx === adjustedIndex
          ? { ...relative, checked: !relative.checked }
          : relative,
      );
    });
  };

  const handleSelectAllRelatives = () => {
    setRelativesData((prevRelativesData) => {
      return prevRelativesData.map((relative, index) => {
        const startIdx = page * limit;
        const endIdx = startIdx + limit;

        if (index >= startIdx && index < endIdx) {
          return {
            ...relative,
            checked: !allRelativesAreChecked,
          };
        }

        return relative;
      });
    });
  };

  const handleConfirmRelatives = () => {
    const selectedRelatives = relativesData.filter(
      (relative) => relative.checked,
    );

    return navigate(`${location.pathname}/new-consent`, {
      state: {
        selectedRelatives: selectedRelatives,
        isAddNewConsentsToExistingRequest: isAddNewConsentsToExistingRequest,
      },
    });
  };

  const setInitalRelativesData = useCallback(
    (relatives: ConsentRequestSuggestion[]) => {
      const relativesData = relatives.map((relative) => {
        return {
          ...relative,
          checked: false,
        };
      });
      setRelativesData(relativesData);
    },
    [],
  );

  useEffect(() => {
    setInitalRelativesData(suggestions);
  }, [suggestions, setInitalRelativesData]);

  const infoText = isAddNewConsentsToExistingRequest
    ? t('consents-relatives-table.remaning-relatives.info')
    : t('consents-relatives-table.info');

  return (
    <Stack gap={3} width="100%">
      <Typography variant={isAddNewConsentsToExistingRequest ? 'h3' : 'h2'}>
        {t('consents-relatives-table.title')}
      </Typography>
      <Alert
        severity="info"
        icon={<FontAwesomeIcon icon={faCircleInfo} />}
        sx={{
          backgroundColor: theme.palette.common.brand.infoBackground,
          whiteSpace: 'break-spaces',
        }}
      >
        {infoText}
      </Alert>
      <Typography variant={isAddNewConsentsToExistingRequest ? 'h5' : 'h4'}>
        {t('consents-relatives-table.description')}
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Checkbox
                  checked={allRelativesAreChecked}
                  onChange={handleSelectAllRelatives}
                />
              </TableCell>
              {headings.map((heading, index) => (
                <TableCell key={`heading-${index}`}>{heading}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {relatives.map((relativeRow, rowIdx: number) => (
              <TableRow key={`row-${rowIdx}`}>
                <TableCell>
                  <Checkbox
                    checked={relativeRow.checked}
                    onChange={() => handleSelectRelative(rowIdx)}
                  />
                </TableCell>
                <TableCell>{relativeRow.relativeName}</TableCell>
                <TableCell>
                  <RelativeRelation relation={relativeRow.relation} />
                </TableCell>
                <TableCell>
                  <RelativeDeceased deceased={relativeRow.deceased} />
                </TableCell>
                <TableCell>
                  <RelativeDiagnosis diagnoses={relativeRow.diagnoses} />
                </TableCell>
                <TableCell>
                  {relativeRow.pathogenicVariants.join(', ')}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={totalNumberOfRows}
        page={page}
        onPageChange={(_, nextPage) => {
          setPage(nextPage);
        }}
        rowsPerPage={limit}
        rowsPerPageOptions={[]}
      />
      <Stack direction="row" gap={3}>
        {onCancelButtonClick && (
          <Button
            variant="outlined"
            onClick={onCancelButtonClick}
            sx={{
              width: 'fit-content',
            }}
          >
            {t('common:button.cancel')}
          </Button>
        )}
        <Button
          variant="contained"
          onClick={handleConfirmRelatives}
          sx={{
            width: 'fit-content',
          }}
        >
          {t('common:button.continue')}
        </Button>
      </Stack>
    </Stack>
  );
};

const RelativeDeceased = ({ deceased }: { deceased: boolean }) => {
  const { t } = useTranslation('consent');
  const deceasedStatus = deceased ? t('common:yes') : t('common:no');

  return <>{deceasedStatus}</>;
};

const RelativeDiagnosis = ({ diagnoses }: { diagnoses: Diagnose[] }) => {
  return (
    <>
      {diagnoses.map((diagnosis, index) => (
        <Typography key={`diagnosis-${index}`}>
          {diagnosis.ageAtDiagnosis
            ? `${diagnosis.diseaseName}, ${diagnosis.ageAtDiagnosis}`
            : diagnosis.diseaseName}
        </Typography>
      ))}
    </>
  );
};
