import { UiSchema } from '@rjsf/utils';
import { DynamicForm } from 'common/features/dynamicForm';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { usePedigreeContext } from '../../pedigreeContext';
import { CustomizableFormData } from '../../types';

interface RelativeNodeFormControllerProps {
  uiSchema: UiSchema;
  enabled: boolean;
  pedigreeId: string;
  formRef: React.RefObject<HTMLFormElement>;
  onSubmit: (data: any) => void;
  setNodeFormData: (data: CustomizableFormData) => void;
}

export const RelativeNodeFormController = (
  props: RelativeNodeFormControllerProps,
) => {
  const { service, selectedNodeId, user } = usePedigreeContext();
  const { i18n } = useTranslation('pedigree');

  const { data: relativeForm } = useQuery(
    ['relativeForm', selectedNodeId, i18n.language],
    () => service.getRelativeForm(props.pedigreeId, selectedNodeId || ''),
    {
      enabled: !!props.enabled,
    },
  );

  return (
    <>
      {relativeForm && (
        <DynamicForm
          key={selectedNodeId}
          schema={relativeForm.jsonSchema}
          uiSchema={{ ...props.uiSchema, ...relativeForm?.uiSchema }}
          formData={relativeForm.formData}
          formContext={{ user }}
          onChange={({ formData }) => props.setNodeFormData(formData)}
          onSubmit={props.onSubmit}
          ref={props.formRef}
        />
      )}
    </>
  );
};
