import { PedigreeNode } from 'common/features/pedigree/common/types';
import { Pedigree } from 'myfamilytree/api/myFamilyTreeSubmission/my-family-tree-submission-service';
import React from 'react';
import { NodeMemberMenuValue } from '../controllers/PedigreeNodeMenuController';
import { usePedigreeAddNodeAction } from './usePedigreeAddNodeAction';
import { usePedigreeDeleteNodeAction } from './usePedigreeDeleteNodeAction';
import { usePedigreeDrawer } from './usePedigreeDrawer';

interface usePedigreeMutationProps {
  pedigree: Pedigree;
  selectedNodes: string[];
  setSelectedNodes: (nodes: string[]) => void;
}

export function usePedigreeMutation(props: usePedigreeMutationProps) {
  const pedigreeId = props.pedigree.id;

  const { deleteNodeDialog, handleDeleteNodeAction, isLoading } =
    usePedigreeDeleteNodeAction({
      pedigreeId,
    });

  const { addNodeDialog, addNodeSnackbar, handleAddNodeAction } =
    usePedigreeAddNodeAction({
      pedigree: props.pedigree,
    });

  const {
    drawer,
    openDrawer,
    handleSetPedigreeNode,
    showNodeAction,
    submissionRelativeFailedDialog,
    allowNodeClick,
  } = usePedigreeDrawer({
    pedigree: props.pedigree,
    selectedNodes: props.selectedNodes,
    setSelectedNodes: props.setSelectedNodes,
  });

  const handleNodeClick = (
    pedigreeNode: PedigreeNode,
    relationshipLabel: string,
  ) => {
    if (allowNodeClick) {
      handleSetPedigreeNode(pedigreeNode, relationshipLabel);
    }
  };

  const handleNodeMenuOptionClick = (
    value: NodeMemberMenuValue,
    pedigreeNodeId: string,
  ) => {
    if (value === 'delete') {
      handleDeleteNodeAction(pedigreeNodeId);
      return;
    }
    return handleAddNodeAction(value, pedigreeNodeId);
  };

  const dialogs = [
    <React.Fragment key="deleteNodeDialog">{deleteNodeDialog}</React.Fragment>,
    <React.Fragment key="addNodeDialog">{addNodeDialog}</React.Fragment>,
    <React.Fragment key="submissionRelativeFailedDialog">
      {submissionRelativeFailedDialog}
    </React.Fragment>,
  ];

  const snackBars = [
    <React.Fragment key="addSnackBar">{addNodeSnackbar}</React.Fragment>,
  ];

  return {
    drawer,
    dialogs,
    snackBars,
    isLoading,
    showNodeAction,
    allowNodeClick,
    openDrawer,
    handleNodeClick,
    handleNodeMenuOptionClick,
  };
}
