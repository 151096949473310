import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

interface DiagnosisFormHeaderProps {
  onDeleteDiagnosisClick: () => void;
  title: string;
}

export const DiagnosisFormHeader = (props: DiagnosisFormHeaderProps) => {
  const { onDeleteDiagnosisClick, title } = props;

  const theme = useTheme();
  const { t } = useTranslation(['pedigreeCaseNewPatient']);

  return (
    <Stack direction="row" justifyContent="space-between">
      <Typography variant="h5">{title}</Typography>
      <Tooltip title={t('tooltip-delete.title')} arrow>
        <IconButton
          size="small"
          onClick={onDeleteDiagnosisClick}
          sx={{
            color: theme.palette.common.brand.darkBlue,
            width: 24,
            height: 24,
          }}
        >
          <FontAwesomeIcon icon={faTrashCan} />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};
