import { useCallback, useEffect, useRef, useState } from 'react';

const useElementSize = (): {
  ref: React.RefObject<HTMLElement>;
  size: { width: number; height: number };
} => {
  const [size, setSize] = useState({ width: 0, height: 0 });
  const elementRef = useRef(null);

  const handleResize = useCallback((element: Element) => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const { width, height } = entry.contentRect;
        setSize({ width, height });
      }
    });
    resizeObserver.observe(element);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    const element = elementRef.current;
    if (!element) return;

    return handleResize(element);
  }, [handleResize]);

  return { ref: elementRef, size };
};

export default useElementSize;
