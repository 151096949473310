import { useTranslation } from 'react-i18next';

export const RelativeRelation = ({
  relation,
  lowercase = false,
}: {
  relation: string;
  lowercase?: boolean;
}) => {
  const { t, i18n } = useTranslation('relationship');
  const label = i18n.exists(`relationship:${relation}`)
    ? t(relation)
    : relation;

  const relationLabel = lowercase ? label.toLowerCase() : label;

  return <>{relationLabel}</>;
};
