import { UiSchema } from '@rjsf/utils';
import { DynamicForm } from 'common/features/dynamicForm';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { usePedigreeContext } from '../../pedigreeContext';
import { CustomizableFormData } from '../../types';

interface PatientNodeFormControllerProps {
  uiSchema: UiSchema;
  enabled: boolean;
  formRef: React.RefObject<HTMLFormElement>;
  onSubmit: (data: any) => void;
  setNodeFormData: (data: CustomizableFormData) => void;
}

export const PatientNodeFormController = (
  props: PatientNodeFormControllerProps,
) => {
  const { service, selectedNodeId, user } = usePedigreeContext();
  const { i18n } = useTranslation('pedigree');

  const { data: patientForm } = useQuery(
    ['patientForm', selectedNodeId, i18n.language],
    () => service.getPatientForm(),
    {
      enabled: !!props.enabled,
    },
  );

  return (
    <>
      {patientForm && (
        <DynamicForm
          key={selectedNodeId}
          schema={patientForm.jsonSchema}
          uiSchema={{ ...props.uiSchema, ...patientForm?.uiSchema }}
          formData={patientForm.formData}
          formContext={{ narrowForm: true, user }}
          onChange={({ formData }) => props.setNodeFormData(formData)}
          onSubmit={props.onSubmit}
          ref={props.formRef}
        />
      )}
    </>
  );
};
