import { faMinus, faPlus, faQuestion } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, useTheme } from '@mui/material';
import { GeneticTestResult } from 'common/features/pedigree/common/types';

export const GeneticTestMarker = ({
  result,
}: {
  result: GeneticTestResult;
}) => {
  const theme = useTheme();

  const iconMap = {
    positive: faPlus,
    negative: faMinus,
    unknown: faQuestion,
  };

  const icon = iconMap[result];

  return (
    <Box
      style={{
        width: 24,
        height: 24,
        borderRadius: '50%',
        backgroundColor: theme.palette.common.neutral[100],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <FontAwesomeIcon icon={icon} />
    </Box>
  );
};
