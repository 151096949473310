import { faDownload, faEye, faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Stack, Tooltip, styled } from '@mui/material';
import { usePdfService } from 'api/pdf/PdfServiceProvider';
import { useTranslation } from 'react-i18next';

interface Props {
  pdfLocationUrl?: string;
  pdfFileName?: string;
  showViewPdfButton?: boolean;
  onPdfActionClick?: () => void;
}
const StyledIconButton = styled(IconButton)(() => ({
  width: 40,
  height: 40,
}));

function PdfActionsController(props: Props) {
  const { t } = useTranslation('common');
  const { downloadPdf, printPdf, viewPdf } = usePdfService();
  const disabled = !props.pdfLocationUrl;
  const fileName = props.pdfFileName || 'consent.pdf';

  const handleViewPdfClick = () => {
    if (props.pdfLocationUrl) {
      viewPdf(props.pdfLocationUrl, fileName);
      props.onPdfActionClick?.();
    }
  };

  const handleDownloadPdfClick = () => {
    if (props.pdfLocationUrl) {
      downloadPdf(props.pdfLocationUrl, fileName);
      props.onPdfActionClick?.();
    }
  };

  const handlePrintPdfClick = () => {
    if (props.pdfLocationUrl) {
      printPdf(props.pdfLocationUrl);
      props.onPdfActionClick?.();
    }
  };

  return (
    <Stack direction="row" spacing={1} display="inline">
      {props.showViewPdfButton && (
        <Tooltip
          title={t('pdf-actions-controller.view-pdf')}
          placement="bottom"
          arrow
        >
          <span>
            <StyledIconButton
              color="primary"
              size="small"
              disabled={disabled}
              onClick={handleViewPdfClick}
            >
              <FontAwesomeIcon icon={faEye} />
            </StyledIconButton>
          </span>
        </Tooltip>
      )}
      <Tooltip
        title={t('pdf-actions-controller.download-pdf')}
        placement="bottom"
        arrow
      >
        <span>
          <StyledIconButton
            color="primary"
            size="small"
            disabled={disabled}
            onClick={handleDownloadPdfClick}
          >
            <FontAwesomeIcon icon={faDownload} />
          </StyledIconButton>
        </span>
      </Tooltip>
      <Tooltip
        title={t('pdf-actions-controller.print-pdf')}
        placement="bottom"
        arrow
      >
        <span>
          <StyledIconButton
            color="primary"
            size="small"
            disabled={disabled}
            onClick={handlePrintPdfClick}
          >
            <FontAwesomeIcon icon={faPrint} />
          </StyledIconButton>
        </span>
      </Tooltip>
    </Stack>
  );
}

export default PdfActionsController;
