import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePedigreeContext } from '../../pedigreeContext';
import { PedigreeNode, SharedParentRelationshipType } from '../../types';
import { findIndexNode, getAllBloodRelativesOf } from '../../utils/helpers';
import SelectBiologicalParentsForm from '../../views/nodeSelectionForm/SelectBiologicalParentsForm';
import SelectParentForm from '../../views/nodeSelectionForm/SelectParentForm';

interface ParentSelectionFormControllerProps {
  pedigreeNode?: PedigreeNode;
  showSelectParentForm: boolean;
  formError: boolean;
  setShowSelectParentForm: (value: boolean) => void;
  setFormError: (value: boolean) => void;
  handleCloseForm: () => void;
  handleBackConfirmParentForm: () => void;
}

const ParentSelectionFormController = ({
  pedigreeNode,
  showSelectParentForm,
  setShowSelectParentForm,
  formError,
  setFormError,
  handleCloseForm,
  handleBackConfirmParentForm,
}: ParentSelectionFormControllerProps) => {
  const { pedigree, selectedNodeId, drawerState, setHighlightedNodeIds } =
    usePedigreeContext();

  const { action, onClickConfirm, pedigreeNodeId } = drawerState || {};

  const { t } = useTranslation('pedigree');

  const [hasFixedParent, setHasFixedParent] = useState<boolean>(false);

  const [parentsValue, setParentsValue] = useState<{
    firstParent: SharedParentRelationshipType | null;
    otherParent: 'newPartner' | 'existingPartner' | null;
  }>({
    firstParent: null,
    otherParent: null,
  });

  const pedigreeNodeFirstParent = useMemo(() => {
    const parentId =
      parentsValue.firstParent === 'mother'
        ? pedigreeNode?.motherId
        : pedigreeNode?.fatherId;

    return pedigree.nodes.find((node) => node.id === parentId);
  }, [parentsValue, pedigree, pedigreeNode]);

  const firstParentHasMultiplePartners =
    !!pedigreeNodeFirstParent && pedigreeNodeFirstParent.partnerIds.length > 1;

  const handleParentChange = (
    value: string,
    id: 'firstParent' | 'otherParent',
  ) => {
    setParentsValue({ ...parentsValue, [id]: value });
    setFormError(false);
  };

  const confirmBiologicalParents = (existingPartnerId?: string) => {
    const { firstParent, otherParent } = parentsValue;

    if (!action || !firstParent || !pedigreeNodeId || !onClickConfirm) return;

    const otherParentIdSelected = existingPartnerId || selectedNodeId;

    const otherParentNodeId =
      firstParentHasMultiplePartners && otherParent === 'existingPartner'
        ? otherParentIdSelected
        : null;

    const validActions =
      action === 'addHalfBrother' ||
      action === 'addHalfSister' ||
      action === 'addHalfSiblingOfUnknownSex' ||
      action === 'changeParents';

    if (validActions) {
      onClickConfirm({
        action,
        otherParentNodeId,
        pedigreeNodeId,
        sharedRelationshipType: firstParent,
      });
    }

    return handleCloseForm();
  };

  const showError = () => {
    setFormError(true);
  };

  const filterNonBiologicParents = useMemo(() => {
    const { fatherId, motherId } = pedigreeNode ?? {};

    return (
      pedigreeNodeFirstParent?.partnerIds.filter(
        (partnerId) => partnerId !== fatherId && partnerId !== motherId,
      ) || []
    );
  }, [pedigreeNode, pedigreeNodeFirstParent]);

  const handleFirstParentWithMultiplePartners = () => {
    const { otherParent } = parentsValue;

    const showExistingPartner = (partnersIds: string[]) => {
      setHighlightedNodeIds(partnersIds);
      setShowSelectParentForm(true);
    };

    if (!otherParent) {
      return showError();
    }

    if (otherParent === 'newPartner') {
      return confirmBiologicalParents();
    }

    if (otherParent === 'existingPartner') {
      const partnerIds = filterNonBiologicParents;

      if (partnerIds.length === 1) {
        return confirmBiologicalParents(partnerIds[0]);
      }

      return showExistingPartner(partnerIds);
    }
  };

  const handleConfirmParentsClick = () => {
    const { firstParent } = parentsValue;

    if (!firstParent) {
      return showError();
    }

    if (firstParentHasMultiplePartners) {
      return handleFirstParentWithMultiplePartners();
    }

    return confirmBiologicalParents();
  };

  const handleConfirmParentSelectionClick = () => {
    if (selectedNodeId === pedigreeNodeId) {
      return showError();
    }
    return confirmBiologicalParents();
  };

  const hasFixedBloodRelatedParentWithIndex = useCallback(() => {
    const indexNode = findIndexNode(pedigree.nodes);
    if (!indexNode) return;

    const allBloodRelativesToIndex = getAllBloodRelativesOf(
      indexNode,
      pedigree.nodes,
    );
    const allBloodRelativesToIndexPartners = indexNode.partnerIds.flatMap(
      (partnerId) => {
        const node = pedigree.nodes.find((node) => node.id === partnerId);

        if (!node) {
          return [];
        }

        return getAllBloodRelativesOf(node, pedigree.nodes);
      },
    );
    const allBloodRelatives = [
      ...allBloodRelativesToIndex,
      ...allBloodRelativesToIndexPartners,
    ];

    const isMotherBloodRelative = allBloodRelatives.some(
      (node) => node.id === pedigreeNode?.motherId,
    );
    const isMotherPartnerToIndex = indexNode.partnerIds.some(
      (partnerId) => partnerId === pedigreeNode?.motherId,
    );
    const isFatherBloodRelative = allBloodRelatives.some(
      (node) => node.id === pedigreeNode?.fatherId,
    );
    const isFatherPartnerToIndex = indexNode.partnerIds.some(
      (partnerId) => partnerId === pedigreeNode?.fatherId,
    );

    const isMotherFirstParent = isMotherBloodRelative || isMotherPartnerToIndex;
    const isFatherFirstParent = isFatherBloodRelative || isFatherPartnerToIndex;

    if (isMotherFirstParent !== isFatherFirstParent) {
      setParentsValue({
        firstParent: isMotherFirstParent ? 'mother' : 'father',
        otherParent: null,
      });
      setHasFixedParent(true);
    }
  }, [pedigree.nodes, pedigreeNode?.fatherId, pedigreeNode?.motherId]);

  useEffect(() => {
    hasFixedBloodRelatedParentWithIndex();
  }, [hasFixedBloodRelatedParentWithIndex]);

  const selectParentSubtitle =
    action === 'changeParents'
      ? t('node-select-parent-form.subtitle.unchanged-parent')
      : t('node-select-parent-form.subtitle.shared-biological-parent');

  const existingPartnerLabel =
    action === 'changeParents'
      ? t('node-select-parent-form.label.existing-partner-of-first-parent')
      : t('node-select-parent-form.label.existing-partner');

  const newPartnerLabel =
    action === 'changeParents'
      ? t('node-select-parent-form.label.new-partner-of-first-parent')
      : t('node-select-parent-form.label.new-partner');

  return (
    <>
      {showSelectParentForm ? (
        <SelectParentForm
          label={t('node-select-parent-form.subtitle.select-partner')}
          errorMessage={t(
            'node-select-parent-form.error-message.no-partner-selected',
          )}
          formError={formError}
          onBackButtonClick={handleBackConfirmParentForm}
          onConfirmClick={handleConfirmParentSelectionClick}
        />
      ) : (
        <SelectBiologicalParentsForm
          selectParentSubtitle={selectParentSubtitle}
          existingPartnerLabel={existingPartnerLabel}
          newPartnerLabel={newPartnerLabel}
          parentsValue={parentsValue}
          hasFixedParent={hasFixedParent}
          formError={formError}
          showSecondBiologicalParentForm={
            firstParentHasMultiplePartners && !!parentsValue.firstParent
          }
          handleParentChange={handleParentChange}
          onCloseForm={handleCloseForm}
          onConfirmParents={handleConfirmParentsClick}
        />
      )}
    </>
  );
};

export default ParentSelectionFormController;
