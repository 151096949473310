import {
  faCircleExclamation,
  faWarning,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import { Button, Input, Stack, Typography, useTheme } from '@mui/material';
import { useStaffPedigreeCaseService } from 'api/pedigreeCase/staff-pedigree-case-service';
import { CircularLoading } from 'components/common/CircularLoading';
import Dialog from 'components/common/Dialog';
import { AutoCompleteInput } from 'components/inputs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

interface PedigreeImportFileSelectionDialogProps {
  isOpen: boolean;
  onClose: () => void;
  pedigreeCaseId: string;
  handleNavigateToPedigree: () => void;
}

export const PedigreeImportFileSelectionDialog = ({
  isOpen,
  onClose,
  pedigreeCaseId,
  handleNavigateToPedigree,
}: PedigreeImportFileSelectionDialogProps) => {
  const { t } = useTranslation('iPedigree');
  const service = useStaffPedigreeCaseService();
  const theme = useTheme();

  const [selectedOption, setSelectedOption] = useState<string>('CanRisk');
  const [formData, setFormData] = useState<FormData | null>(null);
  const [isDragover, setDragover] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [importFailed, setImportFailed] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const options = useMemo(() => [{ value: 'CanRisk', label: 'CanRisk' }], []);

  const containerBackgroundColor = useMemo(() => {
    if (isDragover) {
      return theme.palette.common.brand.blue;
    }
    if (errorMessage) {
      return theme.palette.error.light;
    }

    return 'transparent';
  }, [
    isDragover,
    errorMessage,
    theme.palette.common.brand.blue,
    theme.palette.error.light,
  ]);

  const resetErrorState = useCallback(() => {
    setImportFailed(false);
    setErrorMessage(null);
  }, []);

  const handleOnClose = useCallback(() => {
    setDragover(false);
    setIsLoading(false);
    setFormData(null);
    resetErrorState();
    onClose();
  }, [onClose, resetErrorState]);

  const submitMutation = useMutation(
    (formData: FormData) => service.submitCanriskFile(pedigreeCaseId, formData),
    {
      onSuccess: () => {
        handleNavigateToPedigree();
        handleOnClose();
      },
      onError: () => {
        setIsLoading(false);
        setImportFailed(true);
        setErrorMessage(t('pedigree-import-selection.box-file-error-failed'));
      },
    },
  );

  const retrySubmitMutation = useCallback(() => {
    if (formData) submitMutation.mutate(formData);
  }, [formData, submitMutation]);

  const handleUploadFile = useCallback(
    (file: File) => {
      setDragover(false);
      resetErrorState();
      if (file.type === 'text/plain') {
        setIsLoading(true);
        const formData = new FormData();
        formData.append('file', file);
        setFormData(formData);
        return submitMutation.mutate(formData);
      }
      return setErrorMessage(
        t('pedigree-import-selection.box-file-error-format'),
      );
    },
    [resetErrorState, submitMutation, t],
  );

  const handleOnDropFile = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      const file = event.dataTransfer?.files[0];
      if (file) handleUploadFile(file);
      return;
    },
    [handleUploadFile],
  );

  const handleFileChange = useCallback(
    (event: Event) => {
      const target = event.target as HTMLInputElement;
      const file = target?.files?.[0];
      if (file) handleUploadFile(file);
      return;
    },
    [handleUploadFile],
  );

  const handleImportByButton = useCallback(() => {
    const fileInput = document.getElementById('fileInput');
    if (fileInput) {
      fileInput.click();
      fileInput.addEventListener('change', handleFileChange);
    }
    return () => {
      fileInput?.removeEventListener('change', handleFileChange);
    };
  }, [handleFileChange]);

  useEffect(() => {
    const handleDragOver = (event: Event) => {
      event.preventDefault();
      resetErrorState();
      setDragover(true);
    };
    document.addEventListener('dragover', handleDragOver);
    return () => {
      document.removeEventListener('dragover', handleDragOver);
    };
  }, [resetErrorState]);

  const showImportSelectionBox = !isLoading && !isDragover && !importFailed;

  return (
    <Dialog
      open={isOpen}
      title={t('pedigree-import-selection.title')}
      element={
        <Stack gap={3}>
          <AutoCompleteInput
            id="pedigree-import-selection-option"
            label={t('pedigree-import-selection.label')}
            value={selectedOption}
            options={options}
            onChange={(value: string | null) => setSelectedOption(value || '')}
            disabled
            required
          />
          <Stack
            p={3}
            gap={3}
            alignItems="center"
            justifyContent="center"
            onDrop={handleOnDropFile}
            sx={{
              border: `1px dashed ${theme.palette.common.opacity.darkBlue[25]}`,
              borderRadius: 1,
              backgroundColor: containerBackgroundColor,
              height: 148,
            }}
          >
            {isLoading && (
              <CircularLoading
                label={t('pedigree-import-selection.box-file-loading-label')}
              />
            )}
            {isDragover && (
              <Typography variant="h5" color="#FFF">
                {t('pedigree-import-selection.box-file.dragover-text')}
              </Typography>
            )}
            {importFailed && (
              <ImportSelectionInfo errorMessage={errorMessage} />
            )}
            {showImportSelectionBox && (
              <ImportSelectionBox
                handleImport={handleImportByButton}
                errorMessage={errorMessage}
              />
            )}
          </Stack>
        </Stack>
      }
      actions={[
        <LoadingButton
          key="cancel-import-button"
          onClick={handleOnClose}
          variant="outlined"
          loading={isLoading}
        >
          {t('pedigree-import-selection.cancel.button-text')}
        </LoadingButton>,
        importFailed && (
          <Button
            key="retry-import-button"
            onClick={retrySubmitMutation}
            variant="contained"
          >
            {t('pedigree-import-selection.retry.button-text')}
          </Button>
        ),
      ]}
    />
  );
};

interface ImportSelectionBoxProps {
  handleImport: () => void;
  errorMessage: string | null;
}

const ImportSelectionBox = (props: ImportSelectionBoxProps) => {
  const { handleImport, errorMessage } = props;
  const { t } = useTranslation('iPedigree');
  const theme = useTheme();

  return (
    <>
      <Stack gap={1} alignItems="center">
        <Typography variant="h5">
          {t('pedigree-import-selection.box-file.title')}
        </Typography>
        <Typography
          variant="body1"
          color={theme.palette.common.opacity.darkBlue[70]}
        >
          {t('pedigree-import-selection.box-file.or')}
        </Typography>
        <Button
          key="browse-import-button"
          onClick={handleImport}
          variant="contained"
          size="large"
          sx={{ width: 198 }}
        >
          {t('pedigree-import-selection.box-file.button-text')}
          <Input id="fileInput" type="file" sx={{ display: 'none' }} />
        </Button>
      </Stack>
      <ImportSelectionInfo errorMessage={errorMessage} />
    </>
  );
};

interface ImportSelectionInfoProps {
  errorMessage: string | null;
}

const ImportSelectionInfo = (props: ImportSelectionInfoProps) => {
  const { t } = useTranslation('iPedigree');
  const theme = useTheme();
  const { errorMessage } = props;

  if (errorMessage) {
    return (
      <Stack direction="row" display="block" textAlign="center">
        <FontAwesomeIcon
          icon={faWarning}
          color={theme.palette.error.main}
          style={{
            marginRight: theme.spacing(1),
          }}
        />
        <Typography
          variant="caption"
          color={theme.palette.error.main}
          sx={{ whiteSpace: 'break-spaces' }}
        >
          {errorMessage}
        </Typography>
      </Stack>
    );
  }

  return (
    <Stack direction="row" gap={1} alignItems="center">
      <FontAwesomeIcon
        icon={faCircleExclamation}
        color={theme.palette.warning.main}
      />
      <Typography variant="caption" color={theme.palette.warning.main}>
        {t('pedigree-import-selection.box-file.info')}
      </Typography>
    </Stack>
  );
};
