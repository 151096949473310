import { AuthServiceProvider } from 'api/auth/AuthServicesProvider';
import i18n from 'assets/localization/i18n/eConsentI18nConfig';
import { NotificationProvider } from 'common/contexts/NotificationProvider';
import { SnackbarNotification } from 'common/layouts/notification/SnackbarNotification';
import { ResetPasswordPage } from 'iPedigree/pages/resetPassword/ResetPasswordPage';
import { I18nextProvider } from 'react-i18next';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import AuthenticatedRoutes from './AuthenticatedRoutes';

export const SUBMISSION_PATH = 'my-case';

export const ROUTES = {
  newPatient: '/new-patient',
  patient: '/patient',
  settings: '/settings',
  resetPassword: '/reset-password',
};

const router = createBrowserRouter([
  {
    path: '/*',
    element: <AuthenticatedRoutes />,
  },
  {
    path: `${ROUTES.resetPassword}`,
    element: (
      <AuthServiceProvider accessToken={null}>
        <ResetPasswordPage />
      </AuthServiceProvider>
    ),
  },
]);

export function IPedigreeRouter() {
  return (
    <I18nextProvider i18n={i18n}>
      <NotificationProvider>
        <RouterProvider router={router} />
        <SnackbarNotification />
      </NotificationProvider>
    </I18nextProvider>
  );
}
