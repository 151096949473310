import { Circle } from 'react-konva';
import { isPseudoPartnerId } from '../../../pedigreeProfessional';
import { PedigreeLayoutNode } from '../../utils/pedigree-layout';

interface PartnerConnectionsProps {
  pedigreeLayoutNodes?: { [id: string]: PedigreeLayoutNode };
  color: string;
}
export function PartnerConnections(props: PartnerConnectionsProps) {
  if (props.pedigreeLayoutNodes === undefined) return null;
  const nodeIds = Object.keys(props.pedigreeLayoutNodes);
  const partnerConnectionIds = nodeIds.filter((id) => isPseudoPartnerId(id));

  const partnerConnections = partnerConnectionIds.map((id) => {
    return props?.pedigreeLayoutNodes?.[id];
  });

  return partnerConnections.map((node, index) => {
    if (node === undefined) return null;
    return (
      <Circle
        x={node.pos.x}
        y={node.pos.y}
        fill={props.color}
        width={9}
        key={index}
      />
    );
  });
}
