import { useAuthService } from 'api/auth/AuthServicesProvider';
import { CreateNewPassword } from 'common/features/resetPassword/CreateNewPassword';
import { RequestConfirmation } from 'common/features/resetPassword/RequestConfirmation';
import { ResetPassword } from 'common/features/resetPassword/ResetPassword';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

export const ResetPasswordPage = () => {
  const service = useAuthService();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation('passwordSettings');

  const [error, setError] = useState<string>('');
  const [formData, setFormData] = useState<{ email: string } | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [showCreateNewPassword, setShowCreateNewPassword] =
    useState<boolean>(false);
  const [linkIsInvalid, setLinkInvalid] = useState<boolean>(false);

  const requestResetPasswordMutation = useMutation(
    (email: string) => service.requestResetPassword(email),
    {
      onSuccess: () => setShowConfirmation(true),
    },
  );

  const handleTokenValidation = useCallback(
    (valid: boolean) => {
      if (!valid) {
        setToken(null);
        setShowCreateNewPassword(false);
        setLinkInvalid(true);
        navigate('/reset-password', { replace: true });
      }
    },
    [navigate],
  );

  const handleRequestResetPassword = useCallback(() => {
    if (!formData?.email) {
      return setError(t('reset-password.email.required.error'));
    }
    if (!validateEmail(formData.email)) {
      return setError(t('reset-password.email.invalid.error'));
    }
    return requestResetPasswordMutation.mutate(formData.email);
  }, [formData?.email, requestResetPasswordMutation, t]);

  useEffect(() => {
    const hash = location.hash;
    if (hash.includes('token=')) {
      const tokenValue = hash.split('token=')[1];
      setToken(tokenValue);
      setShowCreateNewPassword(true);
    }
  }, [location.hash]);

  if (showCreateNewPassword && token) {
    return (
      <CreateNewPassword
        token={token}
        handleTokenValidation={handleTokenValidation}
      />
    );
  }

  if (showConfirmation && formData?.email) {
    return (
      <RequestConfirmation
        email={formData.email}
        onRequestAgain={handleRequestResetPassword}
      />
    );
  }

  return (
    <ResetPassword
      formData={formData}
      setFormData={setFormData}
      linkIsInvalid={linkIsInvalid}
      error={error}
      clearError={() => setError('')}
      onCancel={() => navigate('/')}
      handleRequestResetPasswordClick={handleRequestResetPassword}
    />
  );
};

const validateEmail = (email: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};
