import {
  Alert,
  Box,
  Button,
  Container,
  Divider,
  FormControlLabel,
  Snackbar,
  Stack,
  Switch,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { UserPreferences, useUserService } from 'api/user/user-service';
import {
  HeaderWithBackButton,
  LoadingBackdrop,
  SectionBox,
} from 'components/common';
import { ToastMessage } from 'components/common/ToastMessage';
import { TextInput } from 'components/inputs';
import Joi from 'joi';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

type SettingsForm = {
  email: string;
  phone: string;
  emailEnabled: boolean;
  smsEnabled: boolean;
};

const SettingsPage: React.FC = () => {
  const { t } = useTranslation('iPedigree');
  const userService = useUserService();
  const theme = useTheme();
  const navigate = useNavigate();
  const [settings, setSettings] = useState<SettingsForm>({
    email: '',
    phone: '',
    emailEnabled: false,
    smsEnabled: false,
  });
  const [isDirty, setIsDirty] = useState(false);
  const [errors, setErrors] = useState<{ email?: string; phone?: string }>({});
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { data: user, isLoading: isUserLoading } = useQuery(
    'user',
    userService.getCurrentUser,
  );
  const { data: userPreferences, isLoading: isUserPreferencesLoading } =
    useQuery('userPreferences', userService.getCurrentUserPreferences, {
      onSuccess: (data) => {
        if (data) {
          setSettings(userPreferencesToSettingsForm(data));
        }
      },
      onError: () => {
        setErrorMessage(t('settings.toast.error'));
        setShowErrorToast(true);
      },
      refetchOnWindowFocus: false,
    });

  const updateUserPreferencesMutation = useMutation(
    userService.putCurrentUserPreferences,
  );

  const handleEmailInputChange = (value: string) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      email: value,
    }));
    setIsDirty(true);
  };

  const handlePhoneInputChange = (value: string) => {
    const digitsOnly = value.replace(/\D/g, '');
    setSettings((prevSettings) => ({
      ...prevSettings,
      phone: digitsOnly,
    }));
    setIsDirty(true);
  };

  const handleCheckboxChange = (
    key: keyof typeof settings,
    checked: boolean,
  ) => {
    let newSettings = { ...settings };
    if (key === 'emailEnabled') {
      newSettings.email = checked ? user?.email || '' : '';
    }
    if (key === 'smsEnabled' && !checked) {
      newSettings.phone = '';
    }
    setSettings({
      ...newSettings,
      [key]: Boolean(checked),
    });
    setIsDirty(true);
  };

  const handleSubmit = (
    e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>,
  ) => {
    e.preventDefault();
    const validationErrors = validateSettings(settings, t);
    setErrors(validationErrors || {});
    if (validationErrors) return;

    const payload = settingsFormToUserPreferences(settings);
    updateUserPreferencesMutation.mutate(payload, {
      onSuccess: () => {
        setShowSuccessToast(true);
        setIsDirty(false);
      },
      onError: (error) => {
        setErrorMessage(t('settings.toast.error'));
        setShowErrorToast(true);
      },
    });
  };

  const navigateBack = () => {
    navigate(-1);
  };

  const handleCancel = () => {
    setSettings(userPreferencesToSettingsForm(userPreferences || null));
    navigateBack();
    setIsDirty(false);
  };

  const isLoading = isUserLoading || isUserPreferencesLoading;

  return (
    <Container maxWidth="sm" sx={{ mt: 4 }}>
      {isLoading && <LoadingBackdrop />}
      <HeaderWithBackButton headerVariant="h1" onBackButtonClick={navigateBack}>
        {t('settings.header')}
      </HeaderWithBackButton>
      <form onSubmit={handleSubmit}>
        <SectionBox sx={{ mt: 4 }}>
          <Stack direction="column" spacing={3}>
            <Typography variant="h2">
              {t('settings.notifications.title')}
            </Typography>
            <Typography variant="body1">
              {t('settings.notifications.description')}
            </Typography>

            <Stack direction="column" spacing={2}>
              <FormControlLabel
                control={
                  <Switch
                    name="emailEnabled"
                    checked={Boolean(settings.emailEnabled)}
                    onChange={(e) =>
                      handleCheckboxChange('emailEnabled', e.target.checked)
                    }
                  />
                }
                label={t('settings.notifications.emailEnabled.label')}
              />
              {settings.emailEnabled && (
                <TextInput
                  label={t('settings.notifications.email.label')}
                  type="email"
                  id="email"
                  value={settings.email}
                  onChange={(e) => handleEmailInputChange(e.target.value)}
                  required
                  error={errors.email}
                />
              )}
            </Stack>
            <Divider />
            <Stack direction="column" spacing={2}>
              <FormControlLabel
                control={
                  <Switch
                    name="smsEnabled"
                    checked={Boolean(settings.smsEnabled)}
                    onChange={(e) =>
                      handleCheckboxChange('smsEnabled', e.target.checked)
                    }
                  />
                }
                label={t('settings.notifications.smsEnabled.label')}
              />
              {settings.smsEnabled && (
                <TextInput
                  label={t('settings.notifications.phone.label')}
                  type="tel"
                  id="phone"
                  value={settings.phone}
                  onChange={(e) => handlePhoneInputChange(e.target.value)}
                  placeholder="07XXXXXXXX"
                  required
                  error={errors.phone}
                />
              )}
            </Stack>
          </Stack>
        </SectionBox>
        <Box mt={2}>
          <Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
            <Button
              fullWidth
              variant="outlined"
              onClick={handleCancel}
              disabled={!isDirty}
            >
              {t('settings.button.cancel')}
            </Button>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={!isDirty}
            >
              {t('settings.button.save')}
            </Button>
          </Stack>
        </Box>
      </form>
      <Snackbar
        open={showSuccessToast}
        autoHideDuration={6000}
        onClose={() => setShowSuccessToast(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        sx={{
          '.MuiSnackbarContent-root': {
            display: 'inline',
            minWidth: 'unset',
          },
          '.MuiSnackbarContent-message': {
            display: 'inline-flex',
            alignItems: 'center',
          },
        }}
        message={<ToastMessage message={t('settings.toast.success')} />}
      />
      <Snackbar
        open={showErrorToast}
        autoHideDuration={6000}
        onClose={() => setShowErrorToast(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setShowErrorToast(false)} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default SettingsPage;

function validateSettings(
  settings: {
    email: string;
    phone: string;
    emailEnabled: boolean;
    smsEnabled: boolean;
  },
  t: (key: string) => string,
): { email?: string; phone?: string } | null {
  const schema = Joi.object({
    email: settings.emailEnabled
      ? Joi.string()
          .email({ tlds: { allow: false } })
          .required()
          .messages({
            'string.email': t(
              'settings.notifications.validation.email.invalid',
            ),
            'string.empty': t(
              'settings.notifications.validation.email.required',
            ),
          })
      : Joi.string().optional().allow(''),
    phone: settings.smsEnabled
      ? Joi.string()
          .pattern(/^07\d{8}$/)
          .required()
          .messages({
            'string.pattern.base': t(
              'settings.notifications.validation.phone.invalid',
            ),
            'string.empty': t(
              'settings.notifications.validation.phone.required',
            ),
          })
      : Joi.string().optional().allow(''),
  });

  const { error } = schema.validate(
    { email: settings.email, phone: settings.phone },
    { abortEarly: false },
  );
  if (!error) return null;

  const validationErrors: { email?: string; phone?: string } = {};
  for (let item of error.details) {
    validationErrors[item.path[0] as keyof typeof validationErrors] =
      item.message;
  }
  return validationErrors;
}

function userPreferencesToSettingsForm(
  userPreferences: UserPreferences | null,
): SettingsForm {
  if (!userPreferences) {
    return {
      email: '',
      phone: '',
      emailEnabled: false,
      smsEnabled: false,
    };
  }

  return {
    email: userPreferences.notificationEmail || '',
    phone: userPreferences.notificationPhoneNumber || '',
    emailEnabled:
      userPreferences.receiveNotifications &&
      !!userPreferences.notificationEmail,
    smsEnabled:
      userPreferences.receiveNotifications &&
      !!userPreferences.notificationPhoneNumber,
  };
}

function settingsFormToUserPreferences(
  settings: SettingsForm,
): UserPreferences {
  return {
    receiveNotifications: Boolean(settings.emailEnabled || settings.smsEnabled),
    notificationEmail: settings.emailEnabled ? settings.email : undefined,
    notificationPhoneNumber: settings.smsEnabled ? settings.phone : undefined,
  };
}
