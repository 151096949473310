import { Stack, TableCell, TableRow } from '@mui/material';
import { CircularLoading } from 'components/common/CircularLoading';
import { useTranslation } from 'react-i18next';

export const TableLoading = ({ colSpan }: { colSpan: number }) => {
  const { t } = useTranslation('common');

  return (
    <TableRow>
      <TableCell colSpan={colSpan} sx={{ border: 'none', pt: 3, pb: 3 }}>
        <Stack alignItems="center">
          <CircularLoading label={t('loading-label')} />
        </Stack>
      </TableCell>
    </TableRow>
  );
};
