import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Stack,
  Tooltip,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { TextInput } from 'components/inputs';
import RelationshipInput from 'features/common/controllers/RelationshipInput';
import { SignerRelativeDeceased } from 'iPedigree/features/patient/common/SignerRelativeDeceased';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RelativeDataErrors, RelativeFormData } from './NewConsentForm';

interface RelativeFormRowProps {
  data: RelativeFormData;
  setData: (data: RelativeFormData) => void;
  errors: RelativeDataErrors;
  onDeleteClick: () => void;
  handleTextInputChangeFactory: (
    key: keyof RelativeFormData,
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleAutocompleteInputChangeFactory: (
    key: keyof RelativeFormData,
  ) => (value: string | null) => void;
}
const GRID_COL_WIDTH = [{ md: 6 }, { md: 6 }, { md: 6 }, { md: 4 }];

function RelativeFormRow(props: RelativeFormRowProps) {
  const { t } = useTranslation(['consent', 'common']);
  const theme = useTheme();
  const [initialFieldsState, setInitialFieldsState] = React.useState(false);
  const [disabledNameField, setDisabledNameField] = React.useState(false);

  const handleSignerTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newValue = event.target.value as 'first-degree' | 'specific-relative';
    props.setData({
      ...props.data,
      specificProxySigner: newValue === 'specific-relative',
    });
  };

  useEffect(() => {
    if (!initialFieldsState) {
      if (props.data.disabled && !!props.data.name) {
        setDisabledNameField(true);
      }
      setInitialFieldsState(true);
    }
  }, [initialFieldsState, props.data.disabled, props.data.name]);

  return (
    <Box>
      <Grid container spacing={3} columns={20}>
        <Grid {...GRID_COL_WIDTH[0]} sx={{ width: '100%' }}>
          <TextInput
            label={t('common:full-name')}
            id="name"
            value={props.data.name || ''}
            error={
              props?.errors?.name &&
              t(`input-validation.${props?.errors?.name}`)
            }
            onChange={props.handleTextInputChangeFactory('name')}
            textFieldProps={{
              disabled: disabledNameField,
            }}
          />
        </Grid>
        <Grid {...GRID_COL_WIDTH[1]} sx={{ width: '100%' }}>
          <RelationshipInput
            label={t('request-new-consent.section2.relative.relation')}
            value={props.data.relationshipToPatient || ''}
            onChange={props.handleAutocompleteInputChangeFactory(
              'relationshipToPatient',
            )}
            required
            error={
              props?.errors?.relationshipToPatient &&
              t(`input-validation.${props?.errors?.relationshipToPatient}`)
            }
            placeholder={t(
              'request-new-consent.section2.relative.relationship.placeholder',
            )}
            disabled={props.data.disabled}
          />
        </Grid>
        <Grid md="auto" sx={{ width: '100%' }}>
          <TextInput
            label={t('request-new-consent.section2.relative.personal-number')}
            id="personalNumber"
            value={props.data.personalNumber}
            onChange={props.handleTextInputChangeFactory('personalNumber')}
            placeholder={t('common:personal-number.placeholder')}
            error={
              props?.errors?.personalNumber &&
              t(`input-validation.${props?.errors?.personalNumber}`)
            }
            textFieldProps={{
              inputProps: {
                maxLength: 13,
                minLength: 13,
              },
            }}
            formLabelProps={{
              sx: {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              },
            }}
          />
        </Grid>
        <Grid md="auto">
          <Stack
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            mt={3}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.data.deceased}
                  disabled={props.data.disabled}
                  onChange={(event) =>
                    props.setData({
                      ...props.data,
                      deceased: event.target.checked,
                    })
                  }
                />
              }
              label={t('request-new-consent.section2.relative.deceased')}
            />
            <Tooltip title={t('common:tooltip.delete')} arrow>
              <IconButton
                size="small"
                onClick={props.onDeleteClick}
                sx={{
                  color: theme.palette.common.brand.darkBlue,
                  width: 24,
                  height: 24,
                }}
              >
                <FontAwesomeIcon icon={faTrashCan} />
              </IconButton>
            </Tooltip>
          </Stack>
        </Grid>
        {props.data.deceased && (
          <Grid md={20}>
            <SignerRelativeDeceased
              signerData={{
                proxySignerName: props.data.proxySignerName,
                proxySignerToConsenteeRelationship:
                  props.data.proxySignerToConsenteeRelationship,
                proxySignerPersonalNumber: props.data.proxySignerPersonalNumber,
              }}
              specificProxySigner={props.data.specificProxySigner}
              errors={props.errors}
              handleSignerTypeChange={handleSignerTypeChange}
              handleTextInputChangeFactory={props.handleTextInputChangeFactory}
              handleAutocompleteInputChangeFactory={
                props.handleAutocompleteInputChangeFactory
              }
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default RelativeFormRow;
