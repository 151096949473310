import { Button, Stack, useTheme } from '@mui/material';
import { getCurrentDate } from 'common/utils';
import Dialog from 'components/common/Dialog';
import {
  Children,
  cloneElement,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { DynamicFormContext } from '../..';
import { DiagnosisHeader } from './DiagnosisHeader';

type Status = {
  state: 'created' | 'updated' | 'confirmed';
  performedAt: string;
  performedBy: 'patient' | 'staff';
  name?: string;
};

type Diagnosis = {
  id: string | null;
  disease: string;
  ageAtDiagnosis: number;
  ageAtDiagnosisIsApproximate: boolean;
  treatedBy: string;
  notes: string;
  confirmed: boolean;
  status: Status;
  readonly: boolean;
};

type User = {
  name: string;
  email: string;
  phoneNumber: string;
};

interface DiagnosisItemProps {
  diagnosis: Diagnosis;
  children: ReactElement;
  user?: User;
  title: string;
  readonly: boolean;
  showDeleteButton: boolean;
  onDeleteDiagnosis: () => void;
}

export const DiagnosisItem = (props: DiagnosisItemProps) => {
  const {
    diagnosis,
    children,
    user,
    title,
    showDeleteButton,
    readonly,
    onDeleteDiagnosis,
  } = props;

  const { t } = useTranslation(['diagnosisForm']);
  const theme = useTheme();
  const formContext = useContext(DynamicFormContext);

  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [hasInitialValues, setHasInitialValues] = useState<boolean>(false);
  const [isEditable, setIsEditable] = useState<boolean>(true);
  const [diagnosisStatusText, setDiagnosisStatusText] = useState<
    string | undefined
  >();

  const handleDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const deleteDialogProps = useMemo(() => {
    return {
      open: isDeleteDialogOpen,
      title: t('dialog-delete.title'),
      content: t('dialog-delete.content'),
      actions: [
        <Button
          key="confirm-button-dialog"
          variant="contained"
          onClick={() => {
            onDeleteDiagnosis();
            handleDialogClose();
          }}
        >
          {t('dialog-delete.button.delete')}
        </Button>,
        <Button
          key="close-button-dialog"
          variant="outlined"
          onClick={handleDialogClose}
        >
          {t('dialog-delete.button.cancel')}
        </Button>,
      ],
    };
  }, [onDeleteDiagnosis, isDeleteDialogOpen, t]);

  const handleDiagnosisStatusText = useCallback(
    (status: Status) => {
      const { state, performedAt, performedBy, name } = status || {};
      if (!state || !performedAt || !performedBy) {
        return undefined;
      }

      if (performedBy === 'staff' && !name) {
        return undefined;
      }
      const isEdited = state === 'created' || state === 'updated';
      const label = isEdited ? 'edited' : 'confirmed';
      const text = t(`diagnosis-form.status-${label}`, {
        name: performedBy === 'patient' ? t('diagnosis-form.patient') : name,
        performedAt,
      });
      setDiagnosisStatusText(text);
    },
    [t],
  );

  const isDirty: boolean = useMemo(() => {
    return (
      Object.keys(diagnosis)?.length > 0 &&
      (diagnosis.disease !== '' ||
        diagnosis.ageAtDiagnosis !== null ||
        diagnosis.treatedBy !== '' ||
        diagnosis.ageAtDiagnosisIsApproximate ||
        diagnosis.notes !== '')
    );
  }, [diagnosis]);

  const handleDeleteDiagnosis = () => {
    if (isDirty) {
      setDeleteDialogOpen(true);
      return;
    }
    onDeleteDiagnosis();
  };

  const updateDiagnosisStatusText = (
    state: 'created' | 'updated' | 'confirmed',
    performedAt?: string,
    name?: string,
  ) => {
    handleDiagnosisStatusText({
      state,
      performedAt: performedAt || diagnosis.status?.performedAt,
      performedBy: 'staff',
      name: name || diagnosis.status?.name,
    });
  };

  const onConfirmDiagnosis = (value: boolean) => {
    children.props.onChange({
      ...diagnosis,
      confirmed: value,
    });
  };

  const handleEditDiagnosis = () => {
    updateDiagnosisStatusText('updated');
    onConfirmDiagnosis(false);
    return setIsEditable(true);
  };

  const handleConfirmDiagnosis = () => {
    const { disease } = diagnosis || {};

    if (!disease) {
      return formContext.formRef.current.validateForm();
    }

    const performedAt = getCurrentDate();
    const name = user?.name;
    updateDiagnosisStatusText('confirmed', performedAt, name);
    onConfirmDiagnosis(true);
    return setIsEditable(false);
  };

  const isReadOnly = diagnosis.readonly || readonly;
  const showConfirmAndEditButtons = children.props.schema.properties?.status;

  useEffect(() => {
    if (!showConfirmAndEditButtons) {
      return;
    }

    if (!hasInitialValues) {
      const status = diagnosis?.status || {};
      const isEditable = status?.state !== 'confirmed' ?? false;
      setIsEditable(isEditable);
      setHasInitialValues(true);
      handleDiagnosisStatusText(status);
    }
  }, [
    diagnosis,
    hasInitialValues,
    handleDiagnosisStatusText,
    showConfirmAndEditButtons,
  ]);

  return (
    <>
      <Stack gap={0.5}>
        <DiagnosisHeader
          title={title}
          subtitle={diagnosisStatusText}
          isConfirmed={!isEditable}
          onDeleteDiagnosisClick={handleDeleteDiagnosis}
          onEditDiagnosisClick={handleEditDiagnosis}
          onConfirmDiagnosisClick={handleConfirmDiagnosis}
          showActionButtons={!isReadOnly}
          showDeleteButton={
            showDeleteButton && !diagnosis.readonly && isEditable
          }
          showConfirmButton={isEditable && showConfirmAndEditButtons}
          showEditButton={!isEditable && showConfirmAndEditButtons}
        />
        <Stack
          borderLeft={`1px solid ${theme.palette.common.opacity.darkBlue[25]}`}
          pl={2}
          pt={2}
        >
          {isReadOnly || !isEditable ? (
            <DiagnosisReadonly children={children} />
          ) : (
            children
          )}
        </Stack>
      </Stack>
      <Dialog {...deleteDialogProps} />
    </>
  );
};

interface DiagnosisReadonlyProps {
  children: ReactElement;
}

const DiagnosisReadonly = (props: DiagnosisReadonlyProps) => {
  const { children } = props;

  const noRenderKeys = useMemo(() => {
    return ['ageAtDiagnosisIsApproximate'];
  }, []);

  const readOnlyModeElements = useMemo(() => {
    return Children.map(children, (child) => {
      return cloneElement(child, {
        readonly: true,
        uiSchema: {
          ...child.props.uiSchema,
          'ui:gapItems': 2,
          disease: {
            ...child.props.uiSchema.disease,
            'ui:title': '',
          },
          ...noRenderKeys.reduce(
            (acc: Record<string, { 'ui:widget': 'hidden' }>, key) => {
              acc[key] = { 'ui:widget': 'hidden' };
              return acc;
            },
            {},
          ),
        },
      });
    });
  }, [children, noRenderKeys]);

  return <>{readOnlyModeElements}</>;
};
