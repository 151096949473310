import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '@mui/material';
import ClickAwayListenerIconButton from 'myfamilytree/components/buttons/ClickAwayListenerIconButton';
import { useState } from 'react';
import PedigreeNodeMemberMenuView, {
  AddNodeAction,
} from '../views/PedigreeNodeMemberMenuView';

export type NodeMemberMenuValue = AddNodeAction | 'delete';

interface PedigreeNodeMemberMenuProps {
  handleMemberMenuOptionClick: (value: NodeMemberMenuValue) => void;
}

export function PedigreeNodeMemberMenuController(
  props: PedigreeNodeMemberMenuProps,
) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);

  const handleOpenMemberMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleCloseMemberMenuClick = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  return (
    <>
      <ClickAwayListenerIconButton
        onBtnClickAway={handleCloseMemberMenuClick}
        icon={faPlus}
        iconButton={{
          sx: {
            position: 'absolute',
            bottom: theme.spacing(-2.25),
            left: '50%',
            transform: 'translateX(-50%)',
            width: 37,
            height: 37,
            backgroundColor: 'white',
            boxShadow: 2,
            ':hover': { backgroundColor: 'common.brand.lightPurple' },
          },
          color: 'primary',
          onClick: handleOpenMemberMenuClick,
        }}
      />
      <PedigreeNodeMemberMenuView
        open={open}
        anchorEl={anchorEl}
        handleMemberMenuOptionClick={props.handleMemberMenuOptionClick}
      />
    </>
  );
}
