import { faBell } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Alert,
  Button,
  Container,
  Grid,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { ROUTES } from 'iPedigree/routes';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { ButtonAction } from './types';

interface NotificationIsSentConfirmationViewProps {
  title: string;
  description: string;
  actions: ButtonAction[];
  promptNotification?: boolean;
}

const NotificationIsSentConfirmationView = (
  props: NotificationIsSentConfirmationViewProps,
) => {
  return (
    <Container maxWidth="md">
      <Stack gap={3}>
        <Typography variant="h1">{props.title}</Typography>
        <Typography>{props.description}</Typography>
        {props.promptNotification && <NotificationOptInPrompt />}
        <Grid container spacing={2}>
          {props.actions?.map((action, index) => (
            <Grid item key={index} sm={6}>
              <Button
                size="large"
                sx={{ width: '100%' }}
                variant={action.primary ? 'contained' : 'outlined'}
                color="primary"
                onClick={action.onClick}
              >
                {action.label}
              </Button>
            </Grid>
          ))}
        </Grid>
      </Stack>
    </Container>
  );
};

const NotificationOptInPrompt = () => {
  const { t } = useTranslation('iPedigree');
  const navigate = useNavigate();

  const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    navigate(ROUTES.settings, { replace: true });
  };

  return (
    <Alert
      severity="warning"
      icon={
        <FontAwesomeIcon
          icon={faBell}
          style={{ fontSize: '20px', transform: 'translateY(-6px)' }}
        />
      }
      sx={{
        p: 2,
        backgroundColor: 'warning.light',
        '& .MuiAlert-message': {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        },
        '.MuiAlert-message': {
          p: 0,
        },
      }}
    >
      <>
        <Typography variant="body1">
          {t('notification-opt-in-prompt.title')}
        </Typography>
        <Typography variant="body1">
          <Link
            component={RouterLink}
            to={ROUTES.settings}
            color="primary"
            onClick={handleLinkClick}
          >
            {t('notification-opt-in-prompt.link')}
          </Link>{' '}
          {t('notification-opt-in-prompt.description')}
        </Typography>
      </>
    </Alert>
  );
};

export default NotificationIsSentConfirmationView;
