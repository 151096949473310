import { Button, Stack } from '@mui/material';
import { ArrayFieldTemplateProps } from '@rjsf/utils';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DiagnosisItem } from './DiagnosisItem';

export const DiagnosisTemplate = (props: ArrayFieldTemplateProps) => {
  const { t } = useTranslation(['diagnosisForm']);

  const title = props.uiSchema?.['ui:title'] ?? '';

  const showAddButton = props.canAdd && !props.readonly;

  const addButtonText =
    props.items.length >= 1
      ? t('diagnosis-form.button.add-another')
      : t('diagnosis-form.button.add');

  const canDeleteRemainingItem = props.items.length > 1;

  useEffect(() => {
    if (props.items.length === 0) {
      props.onAddClick();
    }
  }, [props, props.formData]);

  return (
    <Stack spacing={3}>
      {props.items.map((item, index) => (
        <DiagnosisItem
          key={props.formData[index]?.id || item.key}
          title={props.items.length > 1 ? `${title} ${index + 1}` : title}
          diagnosis={props.formData[index]}
          children={item.children}
          user={props.formContext?.user}
          onDeleteDiagnosis={item.onDropIndexClick(index)}
          readonly={props.readonly || false}
          showDeleteButton={canDeleteRemainingItem || index > 0}
        />
      ))}
      {showAddButton && (
        <Button variant="outlined" onClick={props.onAddClick}>
          {addButtonText}
        </Button>
      )}
    </Stack>
  );
};
