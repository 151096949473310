import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Button, Stack, Typography } from '@mui/material';
import { SectionBox } from 'components/common';
import { TitleWithIcon } from 'components/common/TitleWithIcon';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

export const ConsentOverviewPanel = ({
  status,
}: {
  status: 'noPedigree' | 'noSuggestions';
}) => {
  const { t } = useTranslation('consent');
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigate = () => {
    return navigate(`${location.pathname}/new-consent`);
  };

  const title =
    status === 'noPedigree'
      ? t('consent-overview-panel-no-pedigree.title')
      : t('consent-overview-panel.no-relatives.title');

  const description =
    status === 'noPedigree'
      ? t('consent-overview-panel-no-pedigree.description')
      : t('consent-overview-panel.no-relatives.description');

  return (
    <SectionBox sx={{ flexGrow: 1 }}>
      <Stack direction="column" spacing={3}>
        <TitleWithIcon icon={faInfoCircle} title={title} />
        <Typography
          variant="body1"
          sx={{
            whiteSpace: 'break-spaces',
          }}
        >
          {description}
        </Typography>
        <Button
          onClick={handleNavigate}
          variant="outlined"
          sx={{
            width: 'fit-content',
          }}
        >
          {t('consent-overview-panel.new-consent.button-text')}
        </Button>
      </Stack>
    </SectionBox>
  );
};
