import { useConsentSubmissionService } from 'api/consentSubmission/consentSubmissionServiceProvider';
import React from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { SUBMISSION_PATH } from 'routes/EConsentRouter';
import ConsentLookupView from '../views/ConsentLookupView';

interface ConsentLookupControllerProps {
  consentRequestId?: string;
}

function ConsentLookupController(
  props: ConsentLookupControllerProps,
): React.ReactElement {
  const service = useConsentSubmissionService();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [pinCode, setPinCode] = React.useState<string[]>(['', '', '', '']);

  const checkPinCodeMutation = useMutation(
    service.validateConsentRequestPinCode,
    {
      onSuccess: (data) => {
        const { id } = data;
        if (id) {
          navigate(
            `/${SUBMISSION_PATH}/${props.consentRequestId}/consents/${id}`,
          );
        }
      },
      onError: (error) => {
        setErrorMessage('Invalid pin code');
      },
    },
  );

  const handlePinCodeSubmit = () => {
    const joinedPin = pinCode.join('').toLowerCase();
    if (joinedPin.length < 4) {
      // TODO: Should use i18n. 
      // Note that e-consent submission is not localized at all.
      setErrorMessage('Pin-koden måste bestå av 4 tecken');
      return;
    }

    checkPinCodeMutation.mutate({
      consentRequestId: props.consentRequestId || '',
      pinCode: joinedPin,
    });
  };

  const handlePinCodeChange = (values: string[]) => {
    const newPinCode = [...values];
    setPinCode(newPinCode.map((value) => value.toUpperCase()));
    setErrorMessage('');
  };

  return (
    <ConsentLookupView
      onPinCodeSubmit={handlePinCodeSubmit}
      errorMessage={errorMessage}
      pinCode={pinCode}
      onPinCodeChange={handlePinCodeChange}
    />
  );
}

export default ConsentLookupController;
