import LoadingButton from '@mui/lab/LoadingButton';
import { Button, DialogContentText, Stack } from '@mui/material';
import { UiSchema } from '@rjsf/utils';
import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { usePedigreeContext } from '../../../common/pedigreeContext';
import { CustomizableFormData } from '../../types';
import Drawer from '../../views/Drawer';
import { NodeFormActionsButtons } from '../../views/nodeForm/NodeFormActionsButtons';
import { PatientNodeFormController } from './PatientNodeFormController';
import { RelativeNodeFormController } from './RelativeNodeFormController';

export const NodeFormController = () => {
  const {
    selectedNodeId,
    setSelectedNodeId,
    selectedNode,
    service,
    pedigree,
    setDialogContent,
    getLocalizedLabelOf,
  } = usePedigreeContext();
  const queryClient = useQueryClient();
  const { t, i18n } = useTranslation('pedigree');
  const formRef = useRef<HTMLFormElement | null>(null);

  const [nodeFormData, setNodeFormData] = useState<CustomizableFormData | null>(
    null,
  );
  const [nodeChangesSaved, setNodeChangesSaved] = useState(false);

  const selectedRelativeRelationLabel = useMemo(() => {
    if (selectedNode?.isIndex) {
      return selectedNode.name || '';
    }
    return selectedNodeId ? getLocalizedLabelOf(selectedNodeId) : '';
  }, [selectedNodeId, getLocalizedLabelOf, selectedNode]);

  const isDirty = useMemo(() => {
    const initialFormData = formRef.current?.getDefaultFormState();
    if (Object.keys(nodeFormData || {}).length >= 1) {
      return !isEqual(initialFormData, nodeFormData);
    }
    return false;
  }, [nodeFormData]);

  const uiSchema: UiSchema = {
    'ui:submitButtonOptions': {
      props: {
        disabled: true,
      },
      norender: true,
    },
    'ui:accordionsGap': 0.5,
  };

  const closeDialog = useCallback(() => {
    setDialogContent({
      open: false,
      title: '',
      content: '',
      actions: [],
    });
  }, [setDialogContent]);

  const removeFormQueries = useCallback(() => {
    queryClient.removeQueries(['patientForm', selectedNodeId, i18n.language]);
    queryClient.removeQueries(['relativeForm', selectedNodeId, i18n.language]);
  }, [i18n.language, queryClient, selectedNodeId]);

  const onClose = useCallback(() => {
    removeFormQueries();
    setNodeFormData(null);
    setSelectedNodeId(null);
    setNodeChangesSaved(false);
    closeDialog();
  }, [closeDialog, removeFormQueries, setSelectedNodeId]);

  const retrySubmit = () => {
    openFailedSubmitDialog(true);
    handleOnSubmit();
    return;
  };

  const openFailedSubmitDialog = (isLoading: boolean) => {
    setDialogContent({
      open: true,
      title: t('node-form-dialog.failed-submit.title'),
      content: (
        <DialogContentText
          id={'node-form-dialog'}
          sx={{
            whiteSpace: 'pre-wrap',
          }}
        >
          {t(`node-form-dialog.failed-submit.content`, {
            relative: selectedRelativeRelationLabel
              ? `(${selectedRelativeRelationLabel})`
              : '',
          })}
        </DialogContentText>
      ),
      actions: [
        <Button
          key="close-submit-dialog"
          variant="outlined"
          onClick={closeDialog}
          disabled={isLoading}
        >
          {t('node-form-dialog.failed-submit.buttonCancel')}
        </Button>,
        <LoadingButton
          key="confirm-submit-dialog"
          variant="contained"
          onClick={retrySubmit}
          loading={isLoading}
        >
          {t('node-form-dialog.failed-submit.buttonConfirm')}
        </LoadingButton>,
      ],
    });
  };

  const handleSuccessSubmit = () => {
    queryClient.invalidateQueries('pedigree');
    formRef?.current?.setUpdateFormState(true);
    setNodeFormData(null);
    setNodeChangesSaved(true);
  };

  const submitRelativeMutation = useMutation(
    (data: CustomizableFormData) => {
      return service?.putRelativeForm(pedigree.id, selectedNodeId || '', data);
    },
    {
      onSuccess: () => {
        handleSuccessSubmit();
      },
      onError: () => {
        openFailedSubmitDialog(false);
      },
    },
  );

  const submitIndexMutation = useMutation(
    (data: CustomizableFormData) => {
      return service?.putPatientForm(data);
    },
    {
      onSuccess: () => {
        handleSuccessSubmit();
      },
      onError: () => {
        openFailedSubmitDialog(false);
      },
    },
  );

  const onSubmit = ({ formData }: { formData: CustomizableFormData }) => {
    if (selectedNode?.isIndex) {
      submitIndexMutation.mutate(formData);
    } else {
      submitRelativeMutation.mutate(formData);
    }
  };

  const handleOnSubmit = () => {
    if (formRef.current) {
      formRef.current.submit();
    }
  };

  const openCancelEditDialog = useCallback(() => {
    setDialogContent({
      open: true,
      title: t('node-form-dialog.edit-cancel.title'),
      content: (
        <DialogContentText
          id="content-dialog"
          sx={{
            whiteSpace: 'pre-wrap',
          }}
        >
          {t('node-form-dialog.edit-cancel.content')}
        </DialogContentText>
      ),
      actions: [
        <Button key="close-button-dialog" variant="outlined" onClick={onClose}>
          {t('node-form-dialog.edit-cancel.buttonCancel')}
        </Button>,
        <Button
          key="confirm-button-dialog"
          variant="contained"
          onClick={closeDialog}
        >
          {t('node-form-dialog.edit-cancel.buttonConfirm')}
        </Button>,
      ],
    });
  }, [closeDialog, onClose, setDialogContent, t]);

  const handleOnCancel = useCallback(() => {
    if (isDirty) {
      openCancelEditDialog();
      return;
    }
    onClose();
  }, [isDirty, openCancelEditDialog, onClose]);

  const handleOnBackdropClick = useCallback(
    (event: MouseEvent) => {
      const target = event.target as EventTarget | null;

      if (target instanceof HTMLElement) {
        const isClickOnBackdrop =
          typeof target.className === 'string' &&
          target.className.includes('MuiBackdrop-root');

        if (isClickOnBackdrop) {
          handleOnCancel();
        }
      }
    },
    [handleOnCancel],
  );

  useEffect(() => {
    if (!!nodeChangesSaved && isDirty) {
      setNodeChangesSaved(false);
    }
  }, [isDirty, nodeChangesSaved]);

  useEffect(() => {
    window.addEventListener('click', handleOnBackdropClick);
    return () => {
      window.removeEventListener('click', handleOnBackdropClick);
    };
  }, [handleOnBackdropClick]);

  if (!selectedNodeId) {
    return null;
  }

  return (
    <Drawer
      title={selectedRelativeRelationLabel}
      isOpen={!!selectedNodeId}
      onClose={handleOnCancel}
      hideBackdrop={false}
    >
      <Stack pb={9.5}>
        {selectedNode?.isIndex ? (
          <PatientNodeFormController
            uiSchema={uiSchema}
            enabled={!isDirty}
            formRef={formRef}
            setNodeFormData={setNodeFormData}
            onSubmit={onSubmit}
          />
        ) : (
          <RelativeNodeFormController
            uiSchema={uiSchema}
            enabled={!isDirty}
            formRef={formRef}
            pedigreeId={pedigree.id}
            setNodeFormData={setNodeFormData}
            onSubmit={onSubmit}
          />
        )}
      </Stack>
      <NodeFormActionsButtons
        onClickSave={handleOnSubmit}
        onClickCancel={handleOnCancel}
        disabled={!isDirty}
        nodeChangesSaved={nodeChangesSaved}
      />
    </Drawer>
  );
};
