import { Drawer } from '@mui/material';

interface PatientOnboardingControllerProps {
  pedigreeId: string;
  pedigreeNodesWithCancerHistory: string[];
  setPedigreeNodesWithCancerHistory: (value: string[]) => void;
}

//component has not been implemented yet
export const PatientOnboardingController = (
  props: PatientOnboardingControllerProps,
) => {
  // const [drawerStatus, setDrawerStatus] = useState<
  //   'cancerHistory' | 'created' | 'info' | null
  // >(null);
  // const { service } = usePedigreeContext();

  // if cancerHistory service.sendRelativesWithCancerHistory
  return <Drawer />;
};
