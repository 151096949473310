import { Alert, Button, Stack, Typography } from '@mui/material';
import AuthLayout from 'components/common/AuthLayout';
import { TextInput } from 'components/inputs';
import { useTranslation } from 'react-i18next';

interface ResetPasswordProps {
  formData: { email: string } | null;
  setFormData: (data: { email: string }) => void;
  linkIsInvalid: boolean;
  error: string;
  clearError: () => void;
  onCancel: () => void;
  handleRequestResetPasswordClick: () => void;
}

export const ResetPassword = (props: ResetPasswordProps) => {
  const {
    formData,
    setFormData,
    linkIsInvalid,
    clearError,
    onCancel,
    handleRequestResetPasswordClick,
  } = props;
  const { t } = useTranslation('passwordSettings');

  const handleOnCancelClick = () => {
    clearError();
    onCancel();
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setFormData({ email: value });
    clearError();
  };

  return (
    <AuthLayout>
      <Stack direction="column" spacing={3} sx={{ minWidth: 416 }}>
        <Typography variant="h3">{t('reset-password.title')}</Typography>
        {linkIsInvalid && (
          <Alert icon={false} severity="error" variant="standard">
            {t('reset-password.link.expired.error')}
          </Alert>
        )}
        <Typography>{t('reset-password.content')}</Typography>
        <TextInput
          label={t('reset-password.email-input.label')}
          id="email"
          value={formData?.email || ''}
          onChange={handleOnChange}
          textFieldProps={{
            InputProps: { name: 'email' },
            style: { backgroundColor: 'white' },
          }}
          required
          error={props.error}
        />
        <Stack spacing={2}>
          <Button variant="contained" onClick={handleRequestResetPasswordClick}>
            {t('reset-password.button.reset.label')}
          </Button>
          <Button variant="outlined" onClick={handleOnCancelClick}>
            {t('reset-password.button.cancel.label')}
          </Button>
        </Stack>
      </Stack>
    </AuthLayout>
  );
};
