import { useTranslation } from 'react-i18next';
import { PedigreeNode } from '../types';
import {
  PedigreeRelationshipLabeler,
  RelativeKey,
} from '../utils/pedigree-node-relationship-labels';

function usePedigreeNodeRelationshipLabels(nodes: PedigreeNode[]): {
  pedigreeNodeRelationshipLabelKeys: { [id: string]: string };
  getLocalizedLabelOf(nodeId: string): string;
  getLabelKeyOf(nodeId: string): RelativeKey | null;
} {
  const { t, i18n } = useTranslation('relationship');

  const pedigreeRelationshipLabeler = new PedigreeRelationshipLabeler(nodes);
  const labelKeyArray = pedigreeRelationshipLabeler.generateLabelKeys();
  const labelKeyMap: { [id: string]: RelativeKey } = labelKeyArray.reduce(
    (acc, { id, relativeKey }) => {
      acc[id] = relativeKey;
      return acc;
    },
    {} as { [id: string]: RelativeKey },
  );

  const getLabelKeyOf = (nodeId: string): RelativeKey | null => {
    return labelKeyMap[nodeId] || null;
  };

  const getLocalizedLabelOf = (nodeId: string): string => {
    const labelKey = labelKeyMap[nodeId];
    if (i18n.exists('relationship:' + labelKey)) {
      return t(labelKey);
    }
    return labelKey || '';
  };

  return {
    pedigreeNodeRelationshipLabelKeys: labelKeyMap,
    getLocalizedLabelOf,
    getLabelKeyOf,
  };
}

export default usePedigreeNodeRelationshipLabels;
