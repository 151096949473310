import { Container, Stack } from '@mui/material';
import { useStaffPedigreeCaseService } from 'api/pedigreeCase/staff-pedigree-case-service';
import { useUserService } from 'api/user/user-service';
import SendRequest from 'common/features/pedigreeCase/sendPedigreeRequestReminder/SendRequest';
import { HeaderWithBackButton, LoadingBackdrop } from 'components/common';
import RequestPedigreeForm, {
  RequestPedigreeFormData,
} from 'iPedigree/features/patient/pedigree/RequestPedigreeForm';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

const SendRequestPage = () => {
  const { t } = useTranslation(['pedigreeCaseSendRequestReminder', 'common']);
  const navigate = useNavigate();
  const { patientId } = useParams<{ patientId: string }>();
  const pedigreeCaseService = useStaffPedigreeCaseService();
  const userService = useUserService();

  const [formData, setFormData] = useState<RequestPedigreeFormData>({
    email: '',
    phoneNumber: '',
    purpose: null,
  });

  const [showPedigreeRequestForm, setShowPedigreeRequestForm] = useState(true);

  const { data: pedigreeCase, isLoading: isPedigreeCaseLoading } = useQuery(
    ['pedigreeCase', patientId],
    () => {
      if (!patientId) {
        return Promise.resolve(null);
      }
      return pedigreeCaseService.getPedigreeCase(patientId);
    },
  );

  const pedigreeCaseId = pedigreeCase?.id;

  const { data: userPreferences, isLoading: isUserPreferencesLoading } =
    useQuery('userPreferences', userService.getCurrentUserPreferences);
  const isLoading = isPedigreeCaseLoading || isUserPreferencesLoading;

  const showNotificationOptInPrompt = userPreferences === null;

  if (!patientId || pedigreeCase === null) {
    throw new Error('Patient id is required');
  }

  if (isLoading || pedigreeCase === undefined) {
    return <LoadingBackdrop />;
  }

  const navigateBack = () => navigate(-1);

  const hasContactDetails =
    pedigreeCase?.patientDetails.email ||
    pedigreeCase?.patientDetails.phoneNumber;

  const hasConcerningCancer =
    pedigreeCase?.purpose && pedigreeCase.purpose.length > 0;

  if ((!hasContactDetails || !hasConcerningCancer) && showPedigreeRequestForm) {
    return (
      <Container maxWidth="sm">
        <Stack spacing={3}>
          <HeaderWithBackButton
            headerVariant="h1"
            onBackButtonClick={navigateBack}
          >
            {t('send-request.header')}
          </HeaderWithBackButton>
          <RequestPedigreeForm
            patient={{
              name: pedigreeCase.patientDetails.name,
              personalNumber: pedigreeCase.patientDetails.personalNumber,
            }}
            formData={formData}
            setFormData={setFormData}
            onContinueClick={() => setShowPedigreeRequestForm(false)}
            onCancelClick={navigateBack}
            askForContactDetails={!hasContactDetails}
            askForConcerningCancer={!hasConcerningCancer}
          />
        </Stack>
      </Container>
    );
  }

  const handleBackClick = () => {
    if (!hasContactDetails || !hasConcerningCancer) {
      return setShowPedigreeRequestForm(true);
    }
    return navigateBack();
  };

  const sendRequest = async (): Promise<void> => {
    if (!pedigreeCaseId) {
      throw new Error('Pedigree Case ID is required');
    }

    const purpose = formData.purpose;
    if (purpose !== null) {
      await pedigreeCaseService.updatePedigreeCasePurpose(
        pedigreeCaseId,
        purpose,
      );
    }

    if (!hasContactDetails && (formData.email || formData.phoneNumber)) {
      await pedigreeCaseService.updatePatientContactDetails(pedigreeCaseId, {
        email: formData.email,
        phoneNumber: formData.phoneNumber,
      });
    }

    await pedigreeCaseService.createRequest(pedigreeCaseId);
  };

  return (
    <SendRequest
      pedigreeCaseId={pedigreeCaseId || ''}
      patientId={patientId}
      onBackButtonClick={handleBackClick}
      sendRequest={sendRequest}
      showNotificationOptInPrompt={showNotificationOptInPrompt}
    />
  );
};

export default SendRequestPage;
