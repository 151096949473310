import { Button, Stack, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { InstructionBox } from './InstructionBox';

interface PedigreeCancerHistoryDrawerContentViewProps {
  onConfirmClick: () => void;
}

export const PedigreeCancerHistoryDrawerContentView = (
  props: PedigreeCancerHistoryDrawerContentViewProps,
) => {
  const { t } = useTranslation('myFamilyTree');

  return (
    <>
      <Stack gap={3} p={2}>
        <Typography variant="h3">
          {t('pedigree.onboarding-drawer.cancerHistory.title')}
        </Typography>
        <Typography>
          <Trans
            t={t}
            i18nKey="pedigree.onboarding-drawer.cancerHistory.description.paragraph1"
          />
        </Typography>
        <Typography>
          {t('pedigree.onboarding-drawer.cancerHistory.description.paragraph2')}
        </Typography>
        <Button variant="contained" onClick={props.onConfirmClick}>
          {t('pedigree.onboarding-drawer.cancerHistory.textButton')}
        </Button>
      </Stack>
      <Stack
        sx={{
          position: 'absolute',
          width: '100%',
          bottom: 0,
        }}
      >
        <InstructionBox />
      </Stack>
    </>
  );
};
