const DiamondIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.05037"
        y="6"
        width="7"
        height="7"
        rx="0.5"
        transform="rotate(-45 1.05037 6)"
        stroke="#233749"
      />
    </svg>
  );
};

export default DiamondIcon;
