import { Button, Stack, Typography, useTheme } from '@mui/material';
import AuthLayout from 'components/common/AuthLayout';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface RequestConfirmationProps {
  email: string;
  onRequestAgain: () => void;
}

export const RequestConfirmation = ({
  email,
  onRequestAgain,
}: RequestConfirmationProps) => {
  const { t } = useTranslation('passwordSettings');
  const theme = useTheme();
  const [showButton, setShowButton] = useState<boolean>(false);

  const showButtonAfterThirtySeconds = useCallback(() => {
    const THIRTY_SECONDS = 30000;
    setShowButton(false);
    setTimeout(() => {
      setShowButton(true);
    }, THIRTY_SECONDS);
  }, []);

  const handleOnRequestAgain = () => {
    onRequestAgain();
    showButtonAfterThirtySeconds();
  };

  useEffect(() => {
    showButtonAfterThirtySeconds();
  }, [showButtonAfterThirtySeconds]);

  return (
    <AuthLayout>
      <Stack direction="column" spacing={3} sx={{ minWidth: 416 }}>
        <Typography variant="h3">
          {t('reset-password.request-confirmation.title')}
        </Typography>
        <Typography variant="body1">
          {t('reset-password.request-confirmation.message.start')} {''}
          <span style={{ color: theme.palette.common.brand.blue }}>
            {email}
          </span>{' '}
          {t('reset-password.request-confirmation.message.end')}
        </Typography>
        <Typography variant="body1">
          {t('reset-password.request-confirmation.check-inbox')}
        </Typography>
        {showButton && (
          <Button
            variant="outlined"
            color="primary"
            onClick={handleOnRequestAgain}
          >
            {t('reset-password.request-confirmation.button-text')}
          </Button>
        )}
      </Stack>
    </AuthLayout>
  );
};
