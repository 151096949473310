import { LoadingButton } from '@mui/lab';
import { Fade, Stack, Typography } from '@mui/material';
import { usePasswordStrength } from 'common/features/passwordStrenght/hooks/usePasswordStrength';
import { PasswordStrength } from 'common/features/passwordStrenght/PasswordStrength';
import AuthLayout from 'components/common/AuthLayout';
import PasswordInput from 'components/inputs/PasswordInput';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  formRef: React.RefObject<HTMLFormElement>;
  isMutating: boolean;
  onChangeClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const ChangePasswordView: React.FC<Props> = (props) => {
  const { t } = useTranslation('all');
  const [password, setPassword] = useState<string>('');
  const [showPasswordStrength, setShowPasswordStrength] =
    useState<boolean>(false);

  const { passwordValidation } = usePasswordStrength(password);

  const maxLengthError = useMemo(() => {
    const { satisfiedPasswordCriteria } = passwordValidation;

    if (!satisfiedPasswordCriteria.includes('maxLength')) {
      return t('change-password.password-input.error.max-length');
    }
    return '';
  }, [passwordValidation, t]);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPassword(value);
  };

  const handleOnFocus = () => {
    setShowPasswordStrength(true);
  };

  const handleOnBlur = () => {
    if (!password) {
      setShowPasswordStrength(false);
    }
  };

  return (
    <AuthLayout>
      <form ref={props.formRef}>
        <Stack direction="column" spacing={3} sx={{ minWidth: 300 }}>
          <Typography variant="h4">{t('change-password.title')}</Typography>
          <Typography>{t('change-password.content')}</Typography>
          <PasswordInput
            label={t('change-password.new-password-input.label')}
            disabled={props.isMutating}
            error={maxLengthError}
            handleOnChange={handleOnChange}
            handleOnFocus={handleOnFocus}
            handleOnBlur={handleOnBlur}
          />
          {showPasswordStrength && (
            <Fade in={showPasswordStrength} timeout={250}>
              <Stack>
                <PasswordStrength
                  password={password}
                  passwordValidation={passwordValidation}
                />
              </Stack>
            </Fade>
          )}
          <LoadingButton
            variant="contained"
            color="primary"
            loading={props.isMutating}
            onClick={props.onChangeClick}
            disabled={!passwordValidation.isValid}
          >
            {t('change-password.button.label')}
          </LoadingButton>
        </Stack>
      </form>
    </AuthLayout>
  );
};

export default ChangePasswordView;
