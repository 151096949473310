import {
  IconDefinition,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  Popper as MuiPopper,
  Paper,
  PopperPlacementType,
  Stack,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { MouseEvent, ReactNode, useMemo, useState } from 'react';
import { NodeActionMenuArrow, arrowStyle } from './NodeActionMenuArrow';
import { Action } from './NodeActionsMenu';

export type Option = {
  value: Action;
  label: string;
  hidden?: boolean;
  selected?: boolean;
  icon?: IconDefinition;
  iconComponent?: ReactNode;
  subMenuOptions?: Option[];
  divider?: boolean;
};

interface NodeActionsMenuPopperProps {
  id: string;
  anchorElement: HTMLElement | null;
  options: Option[];
  offset?: number;
  placement?: PopperPlacementType;
  showArrow?: boolean;
  onMenuOptionClick: (action: Action) => void;
  onMenuOptionMouseOver?: (
    event: MouseEvent<HTMLElement>,
    action: Action,
  ) => void;
}

const Popper = styled(MuiPopper)(() => ({
  ...arrowStyle,
}));

export const NodeActionsMenuPopper = ({
  id,
  anchorElement,
  placement = 'right',
  offset = 22,
  showArrow = false,
  options,
  onMenuOptionClick,
  onMenuOptionMouseOver,
}: NodeActionsMenuPopperProps) => {
  const theme = useTheme();
  const [arrowRef, setArrowRef] = useState<HTMLDivElement | null>(null);

  const popperModifiers = useMemo(
    () => [
      { name: 'offset', options: { offset: [0, offset] } },
      { name: 'arrow', enabled: showArrow, options: { element: arrowRef } },
    ],
    [offset, showArrow, arrowRef],
  );

  const handleMouseOver = (event: MouseEvent<HTMLElement>, action: Action) => {
    if (onMenuOptionMouseOver) {
      onMenuOptionMouseOver(event, action);
    }
  };

  return (
    <Popper
      id={id}
      open={!!anchorElement}
      anchorEl={anchorElement}
      placement={placement}
      style={{ maxWidth: 204, minWidth: 112, zIndex: 10 }}
      modifiers={popperModifiers}
    >
      <Paper elevation={8} data-testid="paper">
        <List sx={{ padding: `${theme.spacing(0.5)} 0`, zIndex: 1 }}>
          {showArrow && <NodeActionMenuArrow ref={setArrowRef} />}
          {options?.map((option, index) => (
            <MenuOption
              key={index}
              option={option}
              optionKey={`${index}-${option.value}`}
              onMenuOptionClick={onMenuOptionClick}
              handleMouseOver={handleMouseOver}
            />
          ))}
        </List>
      </Paper>
    </Popper>
  );
};

interface MenuOptionProps {
  option: Option;
  optionKey: string;
  onMenuOptionClick: (action: Action) => void;
  handleMouseOver: (event: MouseEvent<HTMLElement>, action: Action) => void;
}

const MenuOption = (props: MenuOptionProps) => {
  const { option, optionKey, onMenuOptionClick, handleMouseOver } = props;
  const theme = useTheme();

  if (option.hidden) return null;

  const hasSubMenu = option.subMenuOptions && option.subMenuOptions.length > 0;
  const backgroundColor = option.selected
    ? theme.palette.common.brand.blue
    : 'white';
  const hoverBackgroundColor = option.selected
    ? theme.palette.common.brand.blue
    : theme.palette.common.brand.lightPurple;
  const textColor = option.selected
    ? 'white'
    : theme.palette.common.brand.darkBlue;

  return (
    <Stack key={optionKey}>
      <ListItemButton
        onClick={() => onMenuOptionClick(option.value)}
        sx={{
          padding: 0,
          backgroundColor,
          ':hover': { backgroundColor: hoverBackgroundColor },
        }}
      >
        <ListItemIcon sx={{ minWidth: 'unset', ml: 1, color: textColor }}>
          {option.iconComponent && option.iconComponent}
          {option.icon && <FontAwesomeIcon icon={option.icon} size="xs" />}
        </ListItemIcon>
        <Stack
          width="100%"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          p={1}
          id={`option-menu-${option.value}`}
          onMouseOver={(event) => handleMouseOver(event, option.value)}
        >
          <Typography sx={{ lineHeight: 1.4, color: textColor }}>
            {option.label}
          </Typography>
          {hasSubMenu && (
            <IconButton
              size="small"
              sx={{ color: theme.palette.common.brand.darkBlue }}
            >
              <FontAwesomeIcon icon={faChevronRight} size="sm" />
            </IconButton>
          )}
        </Stack>
      </ListItemButton>
      {option.divider && <Divider />}
    </Stack>
  );
};
