import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Stack, Typography, useTheme } from '@mui/material';
import {
  Diagnose,
  LocalizedDisease,
} from 'api/pedigreeCase/staff-pedigree-case-service';
import { SectionBox } from 'components/common';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { DiseaseOption } from '../views/DiagnosisFormFields';
import { DiagnosisFormItem } from '../views/DiagnosisFormItem';

export type DiagnosisItemErrors = {
  [key in keyof DiagnosisFormData]?: string | undefined;
};

export type DiagnoseValue = {
  [K in keyof Diagnose]: Diagnose[K];
};

export type DiagnosisFormData = {
  id: number;
} & Diagnose;

interface PatientDiagnosisFormControllerProps {
  diagnoses: DiagnosisFormData[];
  errors: DiagnosisItemErrors[];
  setDiagnoses: (data: DiagnosisFormData[]) => void;
  setErrors: (errors: DiagnosisItemErrors[]) => void;
  getDiseaseOptions: () => Promise<LocalizedDisease[]>;
}

function PatientDiagnosisFormController(
  props: PatientDiagnosisFormControllerProps,
) {
  const { diagnoses, errors, setDiagnoses, setErrors, getDiseaseOptions } =
    props || {};

  const { t } = useTranslation(['pedigreeCaseNewPatient']);
  const theme = useTheme();

  const { data: diseases } = useQuery('diseaseOptions', getDiseaseOptions);

  const diseaseOptions: DiseaseOption[] = useMemo(() => {
    if (diseases) {
      const options = diseases.map(({ id, localizedName }) => {
        return {
          label: localizedName,
          value: `${id}`,
        };
      });
      return options;
    }
    return [{ label: '', value: '' }];
  }, [diseases]);

  const addDiagnosis = () => {
    const newItemId =
      diagnoses.length > 0 ? Math.max(...diagnoses.map((d) => d.id)) + 1 : 1;

    const item = { ...emptyDiagnosis, id: newItemId };
    const newItems = [...diagnoses, item];
    setDiagnoses(newItems);
  };

  const deleteDiagnosis = (index: number) => {
    const newItems = diagnoses.filter((_, i) => i !== index);
    setDiagnoses(newItems);
  };

  const onDiagnoseUpdate = (diagnose: DiagnosisFormData, index: number) => {
    let newData = [...diagnoses];
    newData[index] = diagnose;
    setDiagnoses(newData);
  };

  const resetError = (index: number, key: keyof DiagnosisItemErrors) => {
    const newErrors = [...errors];
    newErrors[index] = { ...newErrors[index], [key]: '' };
    setErrors(newErrors);
  };

  const handleOnchange = <K extends keyof Diagnose>(
    key: K,
    value: DiagnoseValue[K],
    index: number,
  ) => {
    let inputValue = value;

    let newDiagnose = { ...diagnoses[index] };
    newDiagnose = { ...newDiagnose, [key]: inputValue };
    onDiagnoseUpdate(newDiagnose, index);
    resetError(index, key);
  };

  const buttonAddText =
    diagnoses.length >= 1
      ? t('diagnosis-form.button.add-another')
      : t('diagnosis-form.button.add');

  return (
    <SectionBox
      sx={{
        p: 4,
      }}
    >
      <Stack spacing={3}>
        <Typography variant="h2">{t(`section3.title`)}</Typography>
        <Typography
          variant="body2"
          color={theme.palette.common.opacity.darkBlue[70]}
        >
          {t('section3.description')}
        </Typography>
        <Stack gap={3}>
          {diagnoses.map((item, index) => (
            <DiagnosisFormItem
              key={item.id}
              diseaseOptions={diseaseOptions}
              diagnoses={item}
              titleItemNumber={index + 1}
              errors={errors && errors[index]}
              onDiagnoseFieldChange={(key, value) =>
                handleOnchange(key, value, index)
              }
              deleteDiagnosis={() => deleteDiagnosis(index)}
            />
          ))}
          <Button
            onClick={addDiagnosis}
            variant="outlined"
            sx={{ maxWidth: 'fit-content' }}
            startIcon={<FontAwesomeIcon icon={faPlus} />}
          >
            {buttonAddText}
          </Button>
        </Stack>
      </Stack>
    </SectionBox>
  );
}

export default PatientDiagnosisFormController;

export const emptyDiagnosis = {
  id: 1,
  disease: '',
  ageAtDiagnosis: null,
  ageIsApproximate: false,
  treatedBy: '',
  notes: '',
};
