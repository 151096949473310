import { useStaffPedigreeCaseService } from 'api/pedigreeCase/staff-pedigree-case-service';
import NewPatientFormController from 'common/features/pedigreeCase/newPatient/controllers/NewPatientFormController';
import LoadingBackdrop from 'components/common/LoadingBackdrop';
import { ROUTES } from 'iPedigree/routes';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

function NewPatientPage() {
  const navigate = useNavigate();

  const service = useStaffPedigreeCaseService();

  const createNewPedigreeCaseMutation = useMutation(
    service.createNewPedigreeCaseRequest,
    {
      onSuccess: (data) => {
        navigate(`${ROUTES.patient}/${data.patientId}`);
      },
    },
  );

  const handleCancel = () => {
    navigate('/');
  };

  return (
    <>
      {createNewPedigreeCaseMutation.isLoading && <LoadingBackdrop />}
      <NewPatientFormController
        onContinueClick={createNewPedigreeCaseMutation.mutate}
        onCancel={handleCancel}
        service={service}
      />
    </>
  );
}

export default NewPatientPage;
