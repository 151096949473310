import { InputAdornment } from '@mui/material';
import { FieldProps } from '@rjsf/utils';
import { TextInput } from 'components/inputs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ReadonlyView } from '../views/ReadonlyView';

export const NumberField = (props: FieldProps) => {
  const label = props.uiSchema?.['ui:title'] || props.schema?.title || '';
  const placeholder = props.uiSchema?.['ui:placeholder'];
  const unit: string = (props.uiSchema?.['ui:unit'] as string) || '';
  const inlineLabel = props.uiSchema?.['ui:inlineLabel'] || false;
  const inputWidth = props.uiSchema?.['ui:inputWidth'] || '100%';
  const isInteger = props.schema.type === 'integer';
  const disabled = props.uiSchema?.['ui:disabled'] || false;
  const onChange = props.onChange;

  const [numberValue, setNumberValue] = useState<string>('');

  const updateFieldValue = useCallback(
    (newValue: number | undefined) => {
      onChange(newValue);
      setNumberValue(newValue ? `${newValue}` : '');
    },
    [onChange],
  );

  const unwantedChars = useMemo(() => {
    let unwantedChars = ['e', 'E', '+'];
    if (isInteger) {
      unwantedChars = [...unwantedChars, '.', ','];
    }
    return unwantedChars;
  }, [isInteger]);

  const handleOnChange = useCallback(
    (value: string) => {
      let newValue: number | undefined;
      if (value === '') {
        newValue = undefined;
      } else if (isInteger) {
        const strippedValue = value.replace(/[^-0-9]/g, '');
        newValue = parseInt(strippedValue);
      } else {
        newValue = parseFloat(value);
      }
      updateFieldValue(newValue);
    },
    [isInteger, updateFieldValue],
  );

  useEffect(() => {
    if (!props.formData) {
      setNumberValue('');
      return;
    }

    if (!!props.formData && props.formData !== numberValue) {
      return setNumberValue(props.formData);
    }
  }, [numberValue, props.formData]);

  const handleWheel = (event: React.WheelEvent<HTMLInputElement>) => {
    if (document.activeElement === event.currentTarget) {
      event.currentTarget.blur();
    }
  };

  if (props.readonly) {
    const direction = inlineLabel ? 'row-reverse' : 'column';
    const gap = inlineLabel ? 1 : 0;
    const fieldValue = unit ? `${numberValue} ${unit}` : numberValue;
    return (
      <ReadonlyView
        title={label}
        value={fieldValue}
        direction={direction}
        gap={gap}
      />
    );
  }

  return (
    <TextInput
      id={props.idSchema.$id}
      fullWidth={false}
      label={label}
      value={numberValue}
      placeholder={placeholder}
      onChange={(e) => handleOnChange(e.target.value)}
      required={props.required}
      inlineLabel={inlineLabel}
      formLabelProps={{ sx: { whiteSpace: 'nowrap' } }}
      textFieldProps={{
        disabled: disabled,
        type: 'number',
        inputMode: 'numeric',
        style: { width: inputWidth },
        inputProps: {
          min: props.schema?.minimum,
          max: props.schema?.maximum,
          onWheel: handleWheel,
          onKeyPress: (e) =>
            unwantedChars.includes(e.key) && e.preventDefault(),
        },
        InputProps: {
          endAdornment: (
            <InputAdornment position="end">
              {unit && <span>{unit}</span>}
            </InputAdornment>
          ),
        },
      }}
    ></TextInput>
  );
};
