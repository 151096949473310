import { Stack } from '@mui/material';
import { AxiosError } from 'axios';
import { LoadingBackdrop } from 'components/common';
import { useMyFamilyTreeSubmissionService } from 'myfamilytree/api/myFamilyTreeSubmission/MyFamilyTreeSubmissionServiceProvider';
import InstructionalDialog from 'myfamilytree/features/myFamilyTreeSubmission/instructionalDialog/InstructionalDialog';
import { PersonalInfoFormController } from 'myfamilytree/features/myFamilyTreeSubmission/patientForm/controllers/PersonalInfoFormController';
import { PedigreeCaseConfirmationView } from 'myfamilytree/features/myFamilyTreeSubmission/pedigreeCaseConfirmation/views/PedigreeCaseConfirmationView';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { Layout } from './Layout';

interface Questionnaireprops {
  setHideFooter: (hide: boolean) => void;
}

export function Questionnaire(props: Questionnaireprops) {
  const service = useMyFamilyTreeSubmissionService();
  const navigate = useNavigate();

  const { pedigreeCaseId } = useParams();

  const { data: pedigreeCase, isLoading } = useQuery(
    ['pedigreeCase', pedigreeCaseId],
    () => service?.getPedigreeCase(pedigreeCaseId || ''),
    {
      onError: (error: AxiosError) => {
        if (error.response?.status === 404 || error.response?.status === 400) {
          navigate('../404');
          return;
        }
        if (error.response?.status === 403) {
          navigate('../');
          return;
        }
      },
    },
  );

  const isCaseConfirmation =
    pedigreeCase?.status === 'pedigreeReceived' ||
    pedigreeCase?.status === 'caseViewed';

  useEffect(() => {
    if (!isCaseConfirmation) {
      props.setHideFooter(true);
      return () => {
        props.setHideFooter(false);
      };
    }
  }, [isCaseConfirmation, props]);

  if (isLoading) {
    return <LoadingBackdrop open={isLoading} />;
  }

  if (isCaseConfirmation) {
    return <PedigreeCaseConfirmationView />;
  }

  return (
    <Stack position="relative" flexGrow={1}>
      <Layout>
        <PersonalInfoFormController />
      </Layout>
      <InstructionalDialog />
    </Stack>
  );
}
