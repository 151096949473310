import { faPlus, faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Button, Divider, Stack, Typography } from '@mui/material';
import { SectionBox } from 'components/common';
import Dialog from 'components/common/Dialog';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RelativeDataErrors, RelativeFormData } from './NewConsentForm';
import RelativeFormRowController from './RelativeFormRowForm';

interface RelativesFormProps {
  relatives: RelativeFormData[];
  setRelatives: (relatives: RelativeFormData[]) => void;
  errors: RelativeDataErrors[];
  setErrors: (errors: RelativeDataErrors[]) => void;
}

export const RelativesForm = (props: RelativesFormProps) => {
  const { t } = useTranslation('consent');
  const { relatives, setRelatives, errors, setErrors } = props;

  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState<boolean>(false);
  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);

  const deleteRelative = useCallback(
    (idx: number) => {
      const newData = [...relatives];
      newData.splice(idx, 1);
      setRelatives(newData);
      const newErrors = [...errors];

      newErrors.splice(idx, 1);
      setErrors(newErrors);
    },
    [relatives, setRelatives, errors, setErrors],
  );

  const handleConfirmDelete = useCallback(() => {
    if (deleteIndex !== null) {
      deleteRelative(deleteIndex);
      setIsOpenDeleteDialog(false);
      setDeleteIndex(null);
    }
  }, [deleteIndex, deleteRelative]);

  const deleteDialogProps = useMemo(() => {
    return {
      open: isOpenDeleteDialog,
      title: t('request-new-consent.delete-relative-confirmation.title'),
      actions: [
        <Button
          key="confirm-button-dialog"
          variant="contained"
          onClick={handleConfirmDelete}
        >
          {t('request-new-consent.delete-relative-confirmation.confirm')}
        </Button>,
        <Button
          key="close-button-dialog"
          variant="outlined"
          onClick={() => setIsOpenDeleteDialog(false)}
        >
          {t('request-new-consent.delete-relative-confirmation.cancel')}
        </Button>,
      ],
    };
  }, [handleConfirmDelete, isOpenDeleteDialog, t]);

  const handleRelativeDeleteClick = (idx: number) => {
    const hasChanged =
      relatives[idx].personalNumber !== '' ||
      relatives[idx].name !== '' ||
      relatives[idx].relationshipToPatient !== '' ||
      relatives[idx].proxySignerName !== '';

    if (hasChanged) {
      setDeleteIndex(idx);
      return setIsOpenDeleteDialog(true);
    }
    return deleteRelative(idx);
  };

  const handleRelativeChange = (idx: number, data: RelativeFormData) => {
    const newData = [...relatives];
    newData[idx] = data;
    setRelatives(newData);
  };

  const handleAddRelativeClick = () => {
    const newItemId =
      relatives.length > 0 ? Math.max(...relatives.map((d) => d.id)) + 1 : 1;
    const item = { ...defaultRelativeData, id: newItemId };
    const newData = [...relatives, item];
    setRelatives(newData);
  };

  return (
    <>
      <SectionBox>
        <Stack gap={4}>
          <Typography variant="h2">
            {t('request-new-consent.section2.title')}
          </Typography>
          {relatives.map((relative, index) => (
            <Fragment key={relative.id}>
              <RelativeFormRowController
                data={relative}
                setData={(data: RelativeFormData) => {
                  handleRelativeChange(index, data);
                }}
                errors={errors[index]}
                onDeleteClick={() => handleRelativeDeleteClick(index)}
              />
              <Divider />
            </Fragment>
          ))}
          {relatives.length === 0 && (
            <Alert severity="error" icon={<FontAwesomeIcon icon={faWarning} />}>
              {t('request-new-consent.section2.no-relatives')}
            </Alert>
          )}
          <Button
            onClick={handleAddRelativeClick}
            startIcon={
              <FontAwesomeIcon icon={faPlus} style={{ aspectRatio: 1 }} />
            }
            sx={{ width: 'fit-content' }}
          >
            {t('request-new-consent.section2.add-relative')}
          </Button>
        </Stack>
      </SectionBox>
      <Dialog {...deleteDialogProps} />
    </>
  );
};

export const defaultRelativeData: RelativeFormData = {
  id: 0,
  personalNumber: '',
  name: '',
  personId: undefined,
  relationshipToPatient: '',
  deceased: false,
  specificProxySigner: false,
  proxySignerName: '',
  proxySignerPersonalNumber: '',
  proxySignerToConsenteeRelationship: '',
};

export const errorMessageState: RelativeDataErrors = {
  personalNumber: '',
  name: '',
  personId: '',
  relationshipToPatient: '',
  proxySignerName: '',
  deceased: '',
  proxySignerPersonalNumber: '',
  proxySignerToConsenteeRelationship: '',
};
