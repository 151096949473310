import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePedigreeContext } from '../../pedigreeContext';
import { PedigreeNode } from '../../types';
import SelectParentForm from '../../views/nodeSelectionForm/SelectParentForm';
import SelectPartnerForm from '../../views/nodeSelectionForm/SelectPartnerForm';

interface AddChildParentSelectionFormControllerProps {
  pedigreeNode: PedigreeNode | undefined;
  showSelectParentForm: boolean;
  setShowSelectParentForm: (value: boolean) => void;
  formError: boolean;
  setFormError: (value: boolean) => void;
  handleCloseForm: () => void;
  handleBackConfirmParentForm: () => void;
}

const AddChildParentSelectionFormController = ({
  pedigreeNode,
  showSelectParentForm,
  setShowSelectParentForm,
  formError,
  setFormError,
  handleCloseForm,
  handleBackConfirmParentForm,
}: AddChildParentSelectionFormControllerProps) => {
  const { selectedNodeId, drawerState, setHighlightedNodeIds } =
    usePedigreeContext();
  const { t } = useTranslation('pedigree');

  const [partnerValue, setPartnerValue] = useState<string>('');
  const { action, pedigreeNodeId, onClickConfirm } = drawerState || {};

  const parentLabel = pedigreeNode?.sex === 'male' ? 'mother' : 'father';

  const addChildNode = (otherParentNodeId: string) => {
    if (!action || !pedigreeNodeId || !onClickConfirm) return;

    const validActions =
      action === 'addSon' ||
      action === 'addDaughter' ||
      action === 'addChildOfUnknownSex';

    if (validActions) {
      onClickConfirm({ action, pedigreeNodeId, otherParentNodeId });
    }
    return handleCloseForm();
  };

  const handleConfirmPartnerValue = () => {
    if (!pedigreeNode || !pedigreeNodeId) return;

    if (!partnerValue) {
      return setFormError(true);
    }

    if (partnerValue === 'existingPartner') {
      setHighlightedNodeIds(pedigreeNode.partnerIds);
      return setShowSelectParentForm(true);
    }

    if (partnerValue === 'newPartner') {
      return addChildNode(pedigreeNodeId);
    }
  };

  const confirmParentSelectionFromExistingPartner = () => {
    if (!pedigreeNodeId) return;

    if (selectedNodeId === pedigreeNodeId) {
      return setFormError(true);
    }

    return addChildNode(selectedNodeId || '');
  };

  const handleParentValue = (value: string) => {
    setPartnerValue(value);
    setFormError(false);
  };

  return (
    <>
      {showSelectParentForm ? (
        <SelectParentForm
          label={t('node-select-parent-form.subtitle.select-parent', {
            parent: t(`node-select-parent-form.parent.label.${parentLabel}`),
          })}
          errorMessage={t(
            'node-select-parent-form.error-message.no-parent-selected',
            {
              parent: t(`node-select-parent-form.parent.label.${parentLabel}`),
            },
          )}
          formError={formError}
          onBackButtonClick={handleBackConfirmParentForm}
          onConfirmClick={confirmParentSelectionFromExistingPartner}
        />
      ) : (
        <SelectPartnerForm
          parentLabel={parentLabel}
          partnerValue={partnerValue}
          formError={formError}
          handleCloseForm={handleCloseForm}
          handleParentValue={handleParentValue}
          handleConfirmPartnerValue={handleConfirmPartnerValue}
        />
      )}
    </>
  );
};

export default AddChildParentSelectionFormController;
