import { Container, Stack } from '@mui/material';
import { useStaffPedigreeCaseService } from 'api/pedigreeCase/staff-pedigree-case-service';
import { useUserContext } from 'common/contexts/UserProvider';
import EmptyState from 'iPedigree/common/EmptyState';
import Search from 'iPedigree/common/Search';
import { UserFilter } from 'iPedigree/common/UserFilter';
import { ROUTES } from 'iPedigree/routes';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import PanelHeader from './PanelHeader';
import { PatientTable } from './table/PatientTable';

function PatientListPanel() {
  const navigate = useNavigate();
  const { selectedUser } = useUserContext();
  const forUserId = selectedUser?.id ? `${selectedUser?.id}` : undefined;
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const limit = 20;

  const handleAddNewPatientClick = () => {
    navigate(ROUTES.newPatient);
  };

  const service = useStaffPedigreeCaseService();
  const { t } = useTranslation('iPedigree');

  const { data: paginatedIPedigreeCases, isLoading } = useQuery(
    ['ipedigree-cases', search, page, limit, forUserId],
    () =>
      service.getIpedigreeCases({
        searchQuery: search,
        limit: `${limit}`,
        offset: `${page * limit}`,
        forUserId,
      }),
  );

  const patientData = paginatedIPedigreeCases?.items;

  const totalNumberOfRows = paginatedIPedigreeCases?.count || 0;

  const numberOfRows = useMemo(() => {
    return patientData?.length || 0;
  }, [patientData]);

  const showSearch =
    (patientData && patientData.length > 0) || search.length > 0;

  const emptyStateText = {
    title:
      search.length > 0
        ? t('page-dashboard.table.no-results.title', {
            searchQuery: search,
          })
        : t('page-dashboard.table.patient-empty-state.title'),
    message:
      search.length > 0
        ? t('page-dashboard.table.no-results.description')
        : t('page-dashboard.table.patient-empty-state.description'),
  };

  return (
    <Container maxWidth={false} disableGutters>
      <PanelHeader
        onAddNewPatientClick={handleAddNewPatientClick}
        title={t('page-dashboard.patient-list.title')}
        buttonText={t('page-dashboard.new-patient.buttonText')}
      />
      <Stack direction="row" alignItems={'center'} gap={8}>
        <UserFilter />
        {showSearch && (
          <Search
            searchValue={search}
            setSearchValue={(searchValue: string) => {
              setSearch(searchValue);
            }}
            searchPlaceholder={t('search.placeholder-text')}
            searchLabel={t('search.search')}
          />
        )}
      </Stack>
      {numberOfRows === 0 && !isLoading ? (
        <EmptyState
          title={emptyStateText.title}
          message={emptyStateText.message}
        />
      ) : (
        <PatientTable
          patientData={patientData}
          totalNumberOfRows={totalNumberOfRows}
          page={page}
          limit={limit}
          isLoading={isLoading}
          setPage={setPage}
        />
      )}
    </Container>
  );
}

export default PatientListPanel;
