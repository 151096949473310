import { ConsentRequest } from 'api/consentManagement/consent-management-service';

export const mockConsentRequest: ConsentRequest = {
  id: '1',
  patientData: {
    personalNumber: '198505121234',
    name: 'John Doe',
    phoneNumber: '+46701234567',
    email: 'john.doe@example.com',
  },
  relatives: [
    {
      consentStatus: 'pending',
      name: 'Jane Doe',
      personalNumber: '198707151234',
      relationshipToPatient: 'Sister',
      deceased: false,
      updatedAt: '2023-01-01T00:00:00Z',
      createdAt: '2023-01-01T00:00:00Z',
      consentId: '1',
      new: false,
      pdfLocationUrl: 'consent1.pdf',
    },
    {
      consentStatus: 'accepted',
      name: 'Robert Doe',
      personalNumber: '196003101234',
      relationshipToPatient: 'Father',
      deceased: false,
      updatedAt: '2023-01-02T00:00:00Z',
      createdAt: '2023-01-02T00:00:00Z',
      consentId: '2',
      new: false,
      pdfLocationUrl: 'consent2.pdf',
    },
    {
      consentStatus: 'declined',
      name: 'Emily Doe',
      personalNumber: '196305151234',
      relationshipToPatient: 'Mother',
      deceased: false,
      updatedAt: '2023-01-03T00:00:00Z',
      createdAt: '2023-01-03T00:00:00Z',
      consentId: '3',
      new: true,
      pdfLocationUrl: 'consent3.pdf',
    },
    {
      consentStatus: 'pending',
      name: 'Michael Doe',
      personalNumber: '193512201234',
      relationshipToPatient: 'Grandfather',
      deceased: true,
      proxySignerName: 'Sarah Johnson',
      proxySignerPersonalNumber: '198012121234',
      proxySignerToConsenteeRelationship: 'Daughter',
      updatedAt: '2023-01-04T00:00:00Z',
      createdAt: '2023-01-04T00:00:00Z',
      consentId: '4',
      new: false,
      pdfLocationUrl: 'consent4.pdf',
    },
  ],
  consentFor: 'Genetic Testing',
};
