import { useFeature } from '@bucketco/react-sdk';
import { Container, Stack } from '@mui/material';
import { FEATURE_FLAGS } from 'common/contexts/BucketProvider';
import PatientOverviewPanel from 'common/features/pedigreeCase/patientOverview/PatientOverviewPanel';
import { EligibilityOfGeneticTesting } from 'iPedigree/features/patient/eligibility/EligibilityOfGeneticTesting';
import PedigreeOverviewPanel from 'iPedigree/features/patient/pedigree/PedigreeOverviewPanel';
import { useParams } from 'react-router-dom';

function PatientOverviewPage() {
  const { patientId } = useParams<{
    patientId: string;
  }>();
  const { isEnabled: isGeneticTestEligibilityEnabled } = useFeature(
    FEATURE_FLAGS.geneticTestEligibility,
  );

  if (!patientId) return null;

  return (
    <Container>
      <Stack direction="row" spacing={3} mt={3} mb={3} alignItems="flex-start">
        <Stack flexGrow={1} gap={3}>
          <PedigreeOverviewPanel patientId={patientId} />
          {isGeneticTestEligibilityEnabled && (
            <EligibilityOfGeneticTesting patientId={patientId} />
          )}
        </Stack>
        <PatientOverviewPanel patientId={patientId} />
      </Stack>
    </Container>
  );
}

export default PatientOverviewPage;
