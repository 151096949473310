import { Button } from '@mui/material';
import { useConsentManagementService } from 'api/consentManagement/ConsentManagementServiceProvider';
import PdfActionsController from 'features/consentManagement/common/controllers/PdfActionButtonsController';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { ConsentStatus } from 'types';

function useConsentActions() {
  const service = useConsentManagementService();
  const queryClient = useQueryClient();
  const setConsentViewedMutation = useMutation(service.setConsentViewed, {
    onSuccess: () => {
      queryClient.invalidateQueries('getUpdateListConsents');
      queryClient.invalidateQueries('listConsents');
      queryClient.invalidateQueries('consentRequest');
      queryClient.invalidateQueries('consents');
    },
  });

  const { t } = useTranslation(['all']);

  const getConsentActions = useCallback(
    (consent: {
      consentId: string;
      new: boolean;
      consentStatus: ConsentStatus;
      pdfLocationUrl?: string;
    }): React.ReactNode => {
      const handleSetPdfViewed = consent.new
        ? () => {
            setConsentViewedMutation.mutate(consent.consentId);
          }
        : undefined;
      if (consent.consentStatus === 'declined') {
        return (
          <Button
            variant="text"
            color="primary"
            disabled={handleSetPdfViewed === undefined}
            onClick={handleSetPdfViewed}
          >
            {consent.new
              ? t('consent-list-table-row-cell-button.confirm')
              : t('consent-list-table-row-cell-button.confirmed')}
          </Button>
        );
      }
      return (
        <PdfActionsController
          pdfLocationUrl={consent.pdfLocationUrl}
          showViewPdfButton
          onPdfActionClick={handleSetPdfViewed}
        />
      );
    },
    [setConsentViewedMutation, t],
  );

  return {
    getConsentActions,
    isMutating: setConsentViewedMutation.isLoading,
  };
}

export default useConsentActions;
