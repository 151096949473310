import {
  GetPedigreePdfQueryParams,
  useStaffPedigreeCaseService,
} from 'api/pedigreeCase/staff-pedigree-case-service';
import { useCallback, useMemo, useState } from 'react';
import { ExportFormat } from '../controllers/ExportDialogContentController';
import {
  DialogType,
  PrintExportDialogController,
} from '../controllers/PrintExportDialogController';

export function usePedigreePrintExport() {
  const [pedigreeCaseId, setPedigreeCaseId] = useState<string>('');
  const [dialogType, setDialogType] = useState<DialogType | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [queryParams, setQueryParams] =
    useState<GetPedigreePdfQueryParamsWithoutAnonymise>({});

  const isDialogOpen = useMemo(() => dialogType !== null, [dialogType]);
  const onDialogClose = useCallback(() => setDialogType(null), []);
  const service = useStaffPedigreeCaseService();

  const resetStates = useCallback(() => {
    setPedigreeCaseId('');
    setDialogType(null);
    setIsLoading(false);
  }, [setPedigreeCaseId, setDialogType, setIsLoading]);

  const onPrintClick = useCallback(
    (anonymized: boolean) => {
      setIsLoading(true);

      service
        .printPedigreePdf(pedigreeCaseId, {
          anonymise: anonymized,
          ...queryParams,
        })
        .then(() => {
          resetStates();
        });
    },
    [service, pedigreeCaseId, resetStates, queryParams],
  );

  const onExportClick = useCallback(
    (format: ExportFormat, anonymized: boolean) => {
      if (format === 'pdf') {
        setIsLoading(true);
        service
          .exportPedigreePdf(pedigreeCaseId, {
            anonymise: anonymized,
            ...queryParams,
          })
          .then(() => {
            resetStates();
          });
        return;
      } else if (format === 'canRisk') {
        setIsLoading(true);
        service.exportCanriskFile(pedigreeCaseId, anonymized).then(() => {
          resetStates();
        });
        return;
      } else {
        console.error('Unsupported format:', format);
      }
    },
    [service, pedigreeCaseId, resetStates, queryParams],
  );

  return {
    onPedigreePrintClick: (
      pedigreeCaseId: string,
      queryParams?: GetPedigreePdfQueryParamsWithoutAnonymise,
    ) => {
      setPedigreeCaseId(pedigreeCaseId);
      setQueryParams(queryParams ?? {});
      setDialogType('print');
    },
    onPedigreeDownloadClick: (
      pedigreeCaseId: string,
      queryParams?: GetPedigreePdfQueryParamsWithoutAnonymise,
    ) => {
      setPedigreeCaseId(pedigreeCaseId);
      setQueryParams(queryParams ?? {});
      setDialogType('export');
    },
    dialog: (
      <PrintExportDialogController
        onClose={onDialogClose}
        isOpen={isDialogOpen}
        onPrintClick={onPrintClick}
        onExportClick={onExportClick}
        isLoading={isLoading}
        dialogType={dialogType as DialogType}
      />
    ),
  };
}

export type GetPedigreePdfQueryParamsWithoutAnonymise = Omit<
  GetPedigreePdfQueryParams,
  'anonymise'
>;
