import { formatPersonalNumberInput } from 'features/common/utils';
import React from 'react';
import { RelativeDataErrors, RelativeFormData } from './NewConsentForm';
import RelativeFormRow from './RelativeFormRow';

interface RelativeFormRowControllerProps {
  data: RelativeFormData;
  setData: (data: RelativeFormData) => void;
  errors: RelativeDataErrors;
  onDeleteClick: () => void;
}

function RelativeFormRowController(props: RelativeFormRowControllerProps) {
  const handleTextInputChangeFactory =
    (key: keyof RelativeFormData) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let value = event.target.value;
      if (['personalNumber', 'proxySignerPersonalNumber'].includes(key)) {
        value = formatPersonalNumberInput(value);
      }
      props.setData({ ...props.data, [key]: value });
      if (props.errors && props.errors[key]) props.errors[key] = '';
    };

  const handleAutocompleteInputChangeFactory =
    (key: keyof RelativeFormData) => (value: string | null) => {
      props.setData({ ...props.data, [key]: value });
      if (props.errors && props.errors[key]) props.errors[key] = '';
    };

  return (
    <RelativeFormRow
      data={props.data}
      errors={props.errors}
      setData={props.setData}
      onDeleteClick={props.onDeleteClick}
      handleTextInputChangeFactory={handleTextInputChangeFactory}
      handleAutocompleteInputChangeFactory={
        handleAutocompleteInputChangeFactory
      }
    />
  );
}

export default RelativeFormRowController;
