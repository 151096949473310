import { BaseService } from 'api/base-service';
import {
  serviceHookFactory,
  serviceProviderFactory,
} from 'myfamilytree/common/index';
import React from 'react';

type PatientDetails = {
  email?: string;
  phoneNumber?: string;
};

export type EligibilityStatus =
  | 'ELIGIBLE'
  | 'POTENTIALLY_ELIGIBLE'
  | 'NOT_ELIGIBLE';

export type SupportingEvidence = {
  relationship: string;
  diseaseName: string;
  ageAtDiagnosis: number;
  isDiagnosisConfirmed: boolean;
};

export type SatisfiedCriteria = {
  description: string;
  sourceUrl: string | null;
  supportingEvidence: SupportingEvidence[] | null;
};

type Notes = {
  id: number;
  description: string;
};

export type EligibilityResults = {
  criteriaType: string;
  eligibilityStatus: EligibilityStatus;
  footnotes: Notes[];
  satisfiedCriteria: SatisfiedCriteria[];
};

class StaffPatientService extends BaseService {
  updatePatientDetails = async (
    patientId: string,
    patientData: PatientDetails,
  ) => {
    return this.axiosInstance.put(
      `/staff/patients/${patientId}/patient-details`,
      patientData,
    );
  };
  getEligibilityResults = async (
    patientId: string,
  ): Promise<EligibilityResults[]> => {
    return this.axiosInstance.get(
      `/staff/patients/${patientId}/eligibility/genetic-testing`,
    );
  };
}

const StaffPatientServiceContext = React.createContext<{
  service: StaffPatientService | null;
}>({ service: null });

export const StaffPatientServiceProvider = serviceProviderFactory(
  StaffPatientService,
  StaffPatientServiceContext,
);

export const useStaffPatientService = serviceHookFactory(
  StaffPatientService,
  StaffPatientServiceContext,
);
