import {
  faCircleInfo,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Alert,
  Checkbox,
  FormControl,
  FormHelperText,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SectionBox from 'components/common/SectionBox';
import { ConsentStatusView } from 'iPedigree/common/ConsentStatus';
import DateView from 'iPedigree/common/DateView';
import { RelativeRelation } from 'iPedigree/common/RelativeRelation';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConsentStatus } from 'types';

export interface Relative {
  id: string;
  name: string;
  relationship: string;
  deceased: boolean;
  status: ConsentStatus;
  date: string;
}

interface RelativeSelectorProps {
  relatives: Relative[];
  onSelect: (selectedRelatives: Relative[]) => void;
  error: boolean;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const RelativeSelector: React.FC<RelativeSelectorProps> = ({
  relatives,
  onSelect,
  error,
  setError,
}) => {
  const { t } = useTranslation(['consent', 'common']);
  const [selectedRelatives, setSelectedRelatives] = useState<Relative[]>([]);

  useEffect(() => {
    if (selectedRelatives.length > 0) {
      setError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRelatives]);

  const handleRelativeSelect = (relative: Relative) => {
    const newSelectedRelatives = selectedRelatives.includes(relative)
      ? selectedRelatives.filter((r) => r.id !== relative.id)
      : [...selectedRelatives, relative];
    setSelectedRelatives(newSelectedRelatives);
    onSelect(newSelectedRelatives);
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelectedRelatives = event.target.checked ? [...relatives] : [];
    setSelectedRelatives(newSelectedRelatives);
    onSelect(newSelectedRelatives);
  };

  const isAllSelected = selectedRelatives.length === relatives.length;

  return (
    <SectionBox>
      <Stack direction="column" gap={3}>
        <Typography variant="h2" gutterBottom>
          {t('relative-selector.title')}
        </Typography>
        <Alert
          severity="info"
          icon={<FontAwesomeIcon size="sm" icon={faCircleInfo} />}
        >
          {t('relative-selector.info-message')}
        </Alert>
        <FormControl error={error}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isAllSelected}
                      indeterminate={
                        selectedRelatives.length > 0 && !isAllSelected
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell>{t('relative-selector.relative')}</TableCell>
                  <TableCell>{t('relative-selector.relationship')}</TableCell>
                  <TableCell>{t('relative-selector.deceased')}</TableCell>
                  <TableCell>{t('relative-selector.status')}</TableCell>
                  <TableCell>{t('relative-selector.date')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {relatives.map((relative) => (
                  <StyledTableRow key={relative.id}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedRelatives.includes(relative)}
                        onChange={() => handleRelativeSelect(relative)}
                        sx={{
                          color: error ? 'error.main' : undefined,
                          '&.Mui-checked': {
                            color: error ? 'error.main' : undefined,
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell>{relative.name}</TableCell>
                    <TableCell>
                      <RelativeRelation relation={relative.relationship} />
                    </TableCell>
                    <TableCell>
                      {relative.deceased ? t('common:yes') : t('common:no')}
                    </TableCell>
                    <TableCell>
                      <ConsentStatusView status={relative.status} />
                    </TableCell>
                    <TableCell>
                      <DateView isoDate={relative.date} />
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {error && (
            <FormHelperText>
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                style={{ marginRight: 4 }}
              />
              {t('relative-selector.error-message')}
            </FormHelperText>
          )}
        </FormControl>
      </Stack>
    </SectionBox>
  );
};

export default RelativeSelector;
